import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr1Dec2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/Dec1.JPG" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>1 Dec, 2020</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and the President of the SDF party Shri Pawan Chamling answers the question about his failure to prepare alternative leadership
                           </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                    Former  “ ‘One of the biggest failures of the Chamling leadership is that he did not prepare an alternative leader who could lead after his retirement.’  This has been criticism against you by some. They also say that the SDF party is influenced by family and that there is no democracy in the party. How do you respond to these accusations?

                                </p>


                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> First of all, these accusations were not heard so much while we were in the government but it is after handing over the reins of the government that we hear more about them. Second, no one has made these accusations formally, whether within the party or outside. Some people in their political interest may say these things in passing but this too, it is not for sure. However, since these questions have been placed before me now, it is my democratic responsibility to answer them since I am a public representative. 
I cannot believe that people ask such foolish questions so seriously. These questions show that they have still not been able to truly accept the democratic system and are still undergoing a hangover of the old feudalistic mindset. That is why they are confused in politics even today. This is symptomatic of a belief system which views politics simply as a means to get elected and to rule. This thinking itself is undemocratic and anti-people. I believe those who pose these accusatory questions themselves don’t know what they are asking.


                                </p>
                                
                                <div>
                                <img class="w-100" src="assets/img/pressrelease/Dec1.JPG" alt="" />
                                <br/>
                                <br/>
                                
                                <p style={{color: '#000', fontSize:'14px'}}>
                                    "My democratic matra is - "janta rajma jantai raja"- this is one of the simplest definitions of democracy. I firmly believe that in a democratic system, people are the supreme power and they alone hold the prerogative to choose and reject the leadership as they will". 	

                                </p>
                                </div>

                                <p style={{color: '#000'}}>
                                    On the one hand, there is the accusation that there is no democracy within the SDF party and on the other hand, that I did not prepare an alternative leader. These accusations in themselves are contradictory. Only in monarchical traditions and dictatorships, does the king and the dictator, respectively, choose alternative leadership. And I am neither a king nor a dictator. Then how is it that I am expected to choose an alternative leader, something that is absolutely undemocratic? Those who pose these questions must do some serious reading of human history and look around the world to see how politics operate. 

                                </p>
                                 <p style={{color: '#000'}}>
                                    In all dictatorial and monarchical regimes, it is the prerogative of the chief ruler to choose the next leader or king. For example, in North Korea – Kim Il Sung, the founder of North Korea, made his son Kim Jong Il his successive leader. Later Kim Jong Il chose his son Kim Jong Un as the next leader of the Democratic People’s Republic of Korea (DPRK). With the fall of kingdoms, we witnessed the rise of democracies across the world. Some also became communist nations. Whether in democracies or communist regimes, the state can quickly become an autocratic state in the hands of the wrong leader. A case in point is Hitler and Nazi Germany. That is why, in a democracy, the people have an immense responsibility in choosing the right leadership and government. Democracy gives us not just our rights, but our responsibility too and if people cannot fulfill them, it can lead to misuse of power, to autocratic states, and as some terrible cases in history have shown – to genocide wiping away millions of people at the hands of the wrong leader. 

                                </p>
                                 <p style={{color: '#000'}}>
                                   In our country, we have a parliamentary form of government. Although we are known as the world’s biggest democracy, we are a country in which after independence the Gandhi-Nehru family ruled for 40 years. This reality is right in front of us today. Still, this kind of accumulation of political power in family politics is not unique to the Gandhi-Nehru family alone. Since Independence 88 families, 33 former kings and queens in India have  been democratically elected to rule in the Centre and the State. This has happened in Sikkim in the past too and is happening today as well. Family politics in India is nothing new. What is new is to be able to run the government for 25 consecutive years without having family involvement in politics. 



                                </p>
                                
                                <p style={{color: '#000'}}>
                                   In a democracy, it is the people who alone have the right to choose their leaders. For example, in the 2019 elections in Sikkim, the people chose the young leader PS Golay Tamang as an alternative leader to bring “parivartan” and a “government of the youth”. In the recent US elections, the American public chose Joe Biden as their leader and president at 78 years of experience and age. In the end, it is only the people who can choose an alternative leader and an alternative government in a democracy. It would be highly undemocratic and therefore reprehensible if the chief of a democratic political party or democratic government chose the next leadership.



                                </p>
                                
                                <p style={{color: '#000'}}>
                                    My democratic mantra is – “janta rajma jantai raja”- this is one of the simplest definitions of democracy. I firmly believe that in a democratic system, people are the supreme power and they alone hold the prerogative to choose and reject the leadership as they will. I, as a staunch democrat, will always uphold this principle. A democratic party is made by its leadership, workers, ideology, vision, principle, discipline, collective responsibility and sacrifice. The leader and the party workers have equal rights and responsibility in the party and all are equally answerable and responsible towards the public. This is the primary condition of being part of a democratic political party. It is a historical fact that any political party that works for the people is most vocal about its program, ideology and about the concerns of the people and the state.



                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                  When they voted me to head the state government for 25 years, it was a public mandate to serve them. I can say proudly that during all those years, not once did I use my position to provide political leverage to my family. On 4thMarch 1993, we formed the party and on the formation day itself, I declared that till the time I am in active politics, no one from my family will become MP, MLA or Minister, something that is such common practice in states across the country. In the 25 years that I was Chief Minister, we have held on to this principle. Whereas, the PM or the CM’s  children, wife and in laws, being citizens of the country, have every right according to the constitution to be in politics too. But still, according to the party principle and keeping my word to the people, I never involved my family in politics, although it was against their fundamental rights, for which my family always supported me and I am thankful to them. I have stood my ground to hold onto this democratic principle and it is something that I have followed during my entire time in power. I believe this is an example not only in Sikkim but the country.


                                </p>
                                
                                <p style={{color: '#000'}}>
                                    In 25 years of my government, I even did not give a government job to a single person from my family. My family members were not even given nominated governmental positions. I did not allot government sites. My family did not interfere in any of the government policies, programs, or administration. On the day of the formation of the government itself in 1994, I called everyone from my family in the evening and said to them that “You are a member of Pawan Chamling’s family but in relation to the Chief Minister, you will be equal with the janta and you will only have the access to the administration as the public does”. And I also advised them to never introduce themselves as the Chief Minister’s son or daughter or son-in-law and so on. It is easy to accuse family politics but to keep family aside and to run the state and the government is difficult.



                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                     
In our Sikkimese society, the practice of hitting political opponents below the belt and pulling down others to project oneself has become an unfortunate political culture. Whether branding SDF a matwali party, spreading false propaganda on lines of community or religion, blaming for nepotism and sometimes conducting cheap character assassination, this kind of negative politics has become the norm in Sikkim. 

                                </p>
                                <p style={{color: '#000'}}>
                                    But the question remains, why was this done so? It is because there is no political ideology or program to compete with that of SDF’s and we also have a solid history of achievements to prove the work that we have done, some of which has taken Sikkim to a global height. So the only way to counter us and to build their own political currency in the eyes of the people was to resort to negative politics and try to tarnish our image. This kind of politics will no longer work. There should be healthy and positive competition between political parties and politicians. And as for what we did and what we didn’t do, it is right in front of the public. And the work of our 25 years of government will be judged by history.”    
                                </p>

                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr1Dec2021;