import React from 'react';
import {Link} from 'react-router-dom';

const GoodGovernanceBody = () => {
    return(
        <>
            <div class="container section-margin">
            <div class="row">
                <div class="inner-box m-section good-gov-inner-page-container">
                    
                    
                    <ul>
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Unparalleled Poverty Reduction: </span></h4>
                            <p>The BPL percentage was 41.43% in 1993 and this decreased astoundingly to 8.19% in 2011-12. This further decreased to 3.85% in 2018-19. The trends in poverty reduction has not always remained so. During the L.D. Kazi government (1974-79), the BPL population actually rose from 46.14% in 1973 to 55.89% in 1977. Similarly, the BPL population again rose from 39.71% in 1983 to 41.43% in 1993 during the Nar Bahadur Bhandari government. It is only under Pawan Chamling’s government has seen consistent reduction in poverty over time.
</p>

<p>According to the Planning Commission in 2013, 9.85% rural population and 3.66% of the urban population of Sikkim was living below the poverty line, which was well below the national average of 25.7% rural population and 13.70% urban population living below the poverty line. In 2011-12, Sikkim’s gap ratio of 0.45 is the lowest in the country and this indicates the extent to which the average consumption of the poor falls below the poverty line.
</p>
                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>70% budget to rural development: </span></h4>
                            <p>As soon as the SDF government was formed in 1994, 70% of the government’s budget was allocated towards rural development. This reflected the strong focus of the SDF party under Pawan Chamling’s leadership to uplift the poor and create strong economic opportunities at the grassroots.
</p>


                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Unparalleled rise in GSDP:  </span></h4>
                            <p>The GSDP rose from 440.34 in 1994 to 26786.00 in 2019. enhanced 6083%
</p>


                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Unparalleled increase in State revenue:   </span></h4>
                            <p>The state revenue increased from 44.72 crores in 1994 to 12,052.24 crores. This was an increase by 27000%.
</p>

                        </li>
                        
                        <br />
                        
                        <li>
                             <div class="header-single-post" data-dsn-header="project">
                                <div class="post-parallax-wrapper" data-overlay="3">
                                    <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/good-governance/inside-page/Ecconomy-banner-2.png" alt=""
                                        data-dsn-header="blog" data-dsn-ajax="img" />
                                </div>
                            </div>
                        </li>
                        
                        <br /><br />
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Bringing in investment into Sikkim:   </span></h4>
                            <p>As an initiative to bring in investors into Sikkim, a series of meetings were held with investors.

</p>

                            <ul>
                                <li style={{paddingBottom: '10px'}}>On 27.12.2000 an NGO meet was organized at the India International Centre for Sikkim.
</li>
                                <li style={{paddingBottom: '10px'}}>On 22.2.2001 – an investors’ meet was organized in New Delhi for the projection of Sikkim to new investors.
</li>
                                <li style={{paddingBottom: '10px'}}>
                                    29.5.2001 – Investor meeting was held in Calcutta
                                </li>
                                
                                <li style={{paddingBottom: '10px'}}>
                                    26.9.2007- Investor meeting and conference was held at the Hotel Grand Hyatt, New York, USA.

                                </li>
                            </ul>

                        </li>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Making Sikkim a Pharmaceutical hub:    </span></h4>
                            <p>Sikkim has become an attractive place for pharma companies to set up their manufacturing units since 2008. More than 50 companies including have set up their base in Sikkim including Cipla, Sun Pharma, Zydus Cadila, Alembic, IPCA, Alkem Lab, Intas Pharma, Torrent Pharma and Unichem which are some of the major players in the pharma industry. Ramesh Kumar, who heads Cipla plant in Sikkim was quoted by the economic times saying, “Sikkim is a peaceful state and has huge potential for the manufacturing sector. There is minimal interference by the state government.” The pharma boom in Sikkim not only brought in investments in Sikkim but expanded employment opportunities for the people of Sikkim.

</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Hydro Power hub:    </span></h4>
                            <p>In 1994-95, the total capacity of power generation in Sikkim was only 32 MW. In 2018-19 this increased to 2800 MW. During the SDF government, 9 hydro power projects were completed. Hydro power became the major source of revenue for Sikkim.
</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Human Development Index: </span></h4>
                            <p>The HDI which is a composite index including measures of life expectancy, education, and per capita income,  rose from 0.582 in 1996 to 0.665 in 2006 for Sikkim. It is a measure of human development. Sikkim published two Human Development Reports under Pawan Chamling’s leadership. It is the only northeastern state to publish two reports. </p>

                        </li>
                        
                        
                    </ul>
                </div>
            </div>
          </div>
       

        </>

    );

}

export default GoodGovernanceBody;