import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import Header2 from "../components/nav/Header2";


import Footer2 from "../components/nav/Footer2";





const Quotes = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">
                        <div class="wrapper">

                                <div class="root-contact">
                                    
                                    {/* Header */}
                <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                    <div class="container" style={{paddingBottom: '50px'}}>
                        <div class="row ">
                            <div class="col-lg-12">
                                <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                    <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                        data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                        Quotes 
                                    </h2>

                                    <p class="infographic-page-header-desc" style={{fontSize: '1rem', fontWeight: '500', maxWidth:'100%', color:'#000', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px'}}>
                                    A collection of quotes by Pawan Chamling.
                                    </p>


                                </div>
                            </div>

                        </div>
                    </div>

                </section>


                                    {/* Body */}

   
                                    <div class="container-fluid gallery-col" style={{backgroundColor: '#fff', paddingBottom: '120px'}}>
            <div class="row" style={{margin: '0px !important'}}>
                <div class="col-md-4 box-im section-padding" style={{paddingTop:'60px', paddingLeft:'60px', paddingRight: '60px', paddingBottom: '0px' }}>
                    <div class="image-zoom" data-dsn="parallax">
                        <a class="with-caption image-link single-image" href="assets/img/quotes/1.jpg">
                            <img src="assets/img/quotes/1.jpg" alt="" />
                        </a>

                        <div class="caption" style={{color:'#000'}}>Share this : 

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-facebook-f" style={{color:'#000'}}></i>
                            </a>

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-instagram" style={{color:'#000'}}></i>
                            </a>
                            
                            <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-whatsapp" style={{color:'#000'}}></i>
                            </a>
                            
                        </div>
                    </div>
                </div>

                <div class="col-md-4 box-im section-padding" style={{paddingTop:'60px', paddingLeft:'60px', paddingRight: '60px', backgroundColor: '#fff', paddingBottom: '0px' }}>
                    <div class="image-zoom" data-dsn="parallax">
                        <a class="with-caption image-link single-image" href="assets/img/quotes/2.jpg">
                            <img src="assets/img/quotes/2.jpg" alt="" />
                        </a>
                        <div class="caption" style={{color:'#000'}}>Share this : 

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-facebook-f" style={{color:'#000'}}></i>
                            </a>

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-instagram" style={{color:'#000'}}></i>
                            </a>
                            
                            <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-whatsapp" style={{color:'#000'}}></i>
                            </a>
                            
                        </div>
                    </div>
                </div>

                <div class="col-md-4 box-im section-padding" style={{paddingTop:'60px', paddingLeft:'60px', paddingRight: '60px', paddingBottom: '0px' }}>
                    <div class="image-zoom" data-dsn="parallax">
                        <a class="with-caption image-link single-image" href="assets/img/quotes/3.jpg">
                            <img src="assets/img/quotes/3.jpg" alt="" />
                        </a>
                       <div class="caption" style={{color:'#000'}}>Share this : 

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-facebook-f" style={{color:'#000'}}></i>
                            </a>

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-instagram" style={{color:'#000'}}></i>
                            </a>
                            
                            <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-whatsapp" style={{color:'#000'}}></i>
                            </a>
                            
                        </div>
                    </div>
                </div>

            </div>

            
            
            <div class="row" style={{margin: '0px !important'}}>
                <div class="col-md-4 box-im section-padding" style={{paddingTop:'60px', paddingLeft:'60px', paddingRight: '60px', paddingBottom: '0px' }}>
                    <div class="image-zoom" data-dsn="parallax">
                        <a class="with-caption image-link single-image" href="assets/img/quotes/4.jpg">
                            <img src="assets/img/quotes/4.jpg" alt="" />
                        </a>

                        <div class="caption" style={{color:'#000'}}>Share this : 

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-facebook-f" style={{color:'#000'}}></i>
                            </a>

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-instagram" style={{color:'#000'}}></i>
                            </a>
                            
                            <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-whatsapp" style={{color:'#000'}}></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 box-im section-padding" style={{paddingTop:'60px', paddingLeft:'60px', paddingRight: '60px', backgroundColor: '#fff', paddingBottom: '0px' }}>
                    <div class="image-zoom" data-dsn="parallax">
                        <a class="with-caption image-link single-image" href="assets/img/quotes/5.jpg">
                            <img src="assets/img/quotes/5.jpg" alt="" />
                        </a>
                        <div class="caption" style={{color:'#000'}}>Share this : 

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-facebook-f" style={{color:'#000'}}></i>
                            </a>

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-instagram" style={{color:'#000'}}></i>
                            </a>
                            
                            <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-whatsapp" style={{color:'#000'}}></i>
                            </a>
                            
                        </div>
                    </div>
                </div>

                <div class="col-md-4 box-im section-padding" style={{paddingTop:'60px', paddingLeft:'60px', paddingRight: '60px', paddingBottom: '0px' }}>
                    <div class="image-zoom" data-dsn="parallax">
                        <a class="with-caption image-link single-image" href="assets/img/quotes/6.jpg">
                            <img src="assets/img/quotes/6.jpg" alt="" />
                        </a>
                       <div class="caption" style={{color:'#000'}}>Share this : 

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-facebook-f" style={{color:'#000'}}></i>
                            </a>

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-instagram" style={{color:'#000'}}></i>
                            </a>
                            
                            <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-whatsapp" style={{color:'#000'}}></i>
                            </a>
                            
                        </div>
                    </div>
                </div>

            </div>
            
            
            <div class="row" style={{margin: '0px !important'}}>
                <div class="col-md-4 box-im section-padding" style={{paddingTop:'60px', paddingLeft:'60px', paddingRight: '60px', paddingBottom: '0px' }}>
                    <div class="image-zoom" data-dsn="parallax">
                        <a class="with-caption image-link single-image" href="assets/img/quotes/7.jpg">
                            <img src="assets/img/quotes/7.jpg" alt="" />
                        </a>

                        <div class="caption" style={{color:'#000'}}>Share this : 

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-facebook-f" style={{color:'#000'}}></i>
                            </a>

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-instagram" style={{color:'#000'}}></i>
                            </a>
                            
                            <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-whatsapp" style={{color:'#000'}}></i>
                            </a>
                            
                        </div>
                    </div>
                </div>

                <div class="col-md-4 box-im section-padding" style={{paddingTop:'60px', paddingLeft:'60px', paddingRight: '60px', backgroundColor: '#fff', paddingBottom: '0px' }}>
                    <div class="image-zoom" data-dsn="parallax">
                        <a class="with-caption image-link single-image" href="assets/img/quotes/8.jpg">
                            <img src="assets/img/quotes/8.jpg" alt="" />
                        </a>
                        <div class="caption" style={{color:'#000'}}>Share this : 

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-facebook-f" style={{color:'#000'}}></i>
                            </a>

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-instagram" style={{color:'#000'}}></i>
                            </a>
                            
                            <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-whatsapp" style={{color:'#000'}}></i>
                            </a>
                            
                        </div>
                    </div>
                </div>

                <div class="col-md-4 box-im section-padding" style={{paddingTop:'60px', paddingLeft:'60px', paddingRight: '60px', paddingBottom: '0px' }}>
                    <div class="image-zoom" data-dsn="parallax">
                        <a class="with-caption image-link single-image" href="assets/img/quotes/9.jpg">
                            <img src="assets/img/quotes/9.jpg" alt="" />
                        </a>
                       <div class="caption" style={{color:'#000'}}>Share this : 

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-facebook-f" style={{color:'#000'}}></i>
                            </a>

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-instagram" style={{color:'#000'}}></i>
                            </a>
                            
                            <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-whatsapp" style={{color:'#000'}}></i>
                            </a>
                            
                        </div>
                    </div>
                </div>

            </div>
            
            
            
            <div class="row" style={{margin: '0px !important'}}>
                <div class="col-md-4 box-im section-padding" style={{paddingTop:'60px', paddingLeft:'60px', paddingRight: '60px', paddingBottom: '0px' }}>
                    <div class="image-zoom" data-dsn="parallax">
                        <a class="with-caption image-link single-image" href="assets/img/quotes/10.jpg">
                            <img src="assets/img/quotes/10.jpg" alt="" />
                        </a>

                        <div class="caption" style={{color:'#000'}}>Share this : 

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-facebook-f" style={{color:'#000'}}></i>
                            </a>

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-instagram" style={{color:'#000'}}></i>
                            </a>
                            
                            <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-whatsapp" style={{color:'#000'}}></i>
                            </a>
                            
                        </div>
                    </div>
                </div>

                <div class="col-md-4 box-im section-padding" style={{paddingTop:'60px', paddingLeft:'60px', paddingRight: '60px', backgroundColor: '#fff', paddingBottom: '0px' }}>
                    <div class="image-zoom" data-dsn="parallax">
                        <a class="with-caption image-link single-image" href="assets/img/quotes/11.jpg">
                            <img src="assets/img/quotes/11.jpg" alt="" />
                        </a>
                        <div class="caption" style={{color:'#000'}}>Share this : 

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-facebook-f" style={{color:'#000'}}></i>
                            </a>

                             <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-instagram" style={{color:'#000'}}></i>
                            </a>
                            
                            <a href="#" style={{color:'#000'}}>
                                <i class="fab fa-whatsapp" style={{color:'#000'}}></i>
                            </a>
                            
                        </div>
                    </div>
                </div>

                

            </div>
            
    
          
        </div>








                                    </div>
                       

                                <Footer2 />

                        </div>
                    </div>
                </main>

            </>
    );

};

export default Quotes;