import React from 'react';
import {a} from 'react-router-dom';

const HeaderTimeline = () => {

    function refreshPage() {
        window.location.reload(false);
      }

    return(
        <div class="dsn-nav-bar">
        <div class="site-header" >
            <div class="extend-container">
                <div class="inner-header">
                    <div class="main-logo" style={{width: 'auto'}}>
                        <a  href="/" onClick={refreshPage}>
                            
                            <p style={{fontWeight: '700', fontSize: '18PX', color: '#fff'}}>Pawan Chamling</p>
                        </a>
                    </div>
                </div>
                <nav class=" accent-menu main-navigation" style={{width: '80%'}}>
                    <ul class="extend-container">
                        
                        <li class="custom-drop-down">
                            <a href="/about">About</a>
                            <ul>
                                <li><a href="/biography" onClick={refreshPage}>Biography</a></li>
                                <li><a href="/timeline2" onClick={refreshPage}>Timeline</a></li>
                                <li><a href="/award" onClick={refreshPage}>Awards</a></li>
                                <li><a href="/constituency" onClick={refreshPage}>Consitituency</a></li>
                                <li><a href="/on-pawan-chamling" onClick={refreshPage}>ON PAWAN CHAMLING</a></li>
                            </ul>
                        </li>
                        <li class="">
                            <a href="/good-governance" onClick={refreshPage}>Good Governance</a>
                            
                        </li>
                        <li class="custom-drop-down">
                            <a href="/media" onClick={refreshPage}>Media</a>
                            <ul>
                                <li><a href="/gallery" onClick={refreshPage}>Gallery</a></li>
                                <li><a href="/speeches" onClick={refreshPage}>Speeches</a></li>
                                <li><a href="/interviews" onClick={refreshPage}>Interviews</a></li>
                                <li><a href="/press-releases" onClick={refreshPage}>Press releases</a></li>
                                
                            </ul>
                        </li>
                        <li class="custom-drop-down">
                            <a href="#">Sikkim First</a>
                            <ul>
                                <li><a href="/infographic" onClick={refreshPage}>Infographics</a></li>
                                <li><a href="/achievements" onClick={refreshPage}>Achievements</a></li>
                                
                            </ul>
                        </li>

                        <li class="custom-drop-down">
                            <a href="#">PC Library</a>
                            <ul>
                                <li><a href="/books" onClick={refreshPage}>Books</a></li>
                                <li><a href="/music" onClick={refreshPage}>Music</a></li>
                                <li><a href="/quotes" onClick={refreshPage}>Quotes</a></li>
                                <li><a href="/blogs" onClick={refreshPage}>Blog</a></li>
                                <li><a href="/text-speeches" onClick={refreshPage}>Text Speeches</a></li>
                            </ul>
                        </li>

                        <li class="custom-drop-down">
                            <a href="#">Connect</a>
                            <ul>
                                <li><a href="/contacts" onClick={refreshPage}>Contact</a></li>
                                <li><a href="/request-a-meeting" onClick={refreshPage}>Request A Meeting</a></li>
                                
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <div class="header-top header-top-hamburger">
            <div class="header-container">
                <div class="logo main-logo">
                    <a href="/" onClick={refreshPage}>
                        <p style={{fontWeight: '700', fontSize: '14PX', color: '#fff'}}>Pawan Chamling</p>

                    </a>
                </div>

                <div class="menu-icon" data-dsn="parallax" data-dsn-move="5">
                    <div class="icon-m">
                        <i class="menu-icon-close fas fa-times"></i>
                        <span class="menu-icon__line menu-icon__line-left"></span>
                        <span class="menu-icon__line"></span>
                        <span class="menu-icon__line menu-icon__line-right"></span>
                    </div>

                    <div class="text-menu">
                        <div class="text-button">Menu</div>
                        <div class="text-open">Open</div>
                        <div class="text-close">Close</div>
                    </div>
                </div>

                <div class="nav">
                    <div class="inner">
                        <div class="nav__content">

                        </div>
                    </div>
                </div>
                <div class="nav-content">
                    <div class="inner-content">
                        <address class="v-middle">
                            <span>SDF BHAWAN</span> 
                            <span>INDIRA BYPASS</span>
                            <span>GANGTOK EAST SIKKIM</span>
                        </address>
                    </div>

                </div>
            </div>
        </div>
    </div>
    );

};

export default HeaderTimeline;