import React from 'react';

const ConstituencyHeader = () => {
    return(
        <>
              <header class="conHeader" style={{paddingTop: '200px'}} >
      <img src="assets/img/consituency-logo.jpeg" alt="SDF"/>

      <h1 style={{color: '#fff', textTransform: 'capitalize'}}>Constituency</h1>
      
      <p style={{color: '#fff', textAlign: 'center'}}>
        Pawan Chamling’s career in electoral politics began in 1982 when he was elected uncontested as the President 
        <br />
        of the Yangang Gram Panchayat. Since then, he has contested in 11 general elections, winning every single seat.

    </p>
    </header>
        </>
    );
};

export default ConstituencyHeader;