import React from 'react';

const YouthHeader = () => {
    return(
        <>
            <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                    <div class="container" style={{paddingBottom: '50px'}}>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                    <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                        data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                       Youth
                                    </h2>

                                    <p class="economy-page-header-desc" style={{fontSize: '1rem', fontWeight: '500', maxWidth:'100%', color:'#000', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px'}}>
                                    The youth of Sikkim has been a primary focus for the SDF government and several schemes have been designed to skill the youth, to provide employment opportunities and to support their entrepreneurial endeavours. Some of the key policies implemented are the OFOJ scheme, Capacity Building and the Chief Minister’s Start-up Scheme.

                                   
                                    </p>


                                </div>
                            </div>

                        </div>
                    </div>

                </section>

                <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/good-governance/inside-page/Sikkim-youth-banner-3.png" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                        
                    </div>
                  

                </div>
            </header>
        </>
    );
};

export default YouthHeader;