import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr11Jully2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/11July.jpeg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>11 Jully, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and the President of the SDF party, Shri Pawan Chamling, has condemned the violent attack on student Shri Arun Limboo in the strongest words possible and has demanded that Shri PS Tamang step down as Chief Minister so that a more humane SKM leader can take over. The increasing violence in Sikkim under Shri Tamang’s watch is due to the pro-violence statements made by him and his colleagues openly in public meetings.
                           </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                    “ Question: A violent attack on a student, Mr Arun Limboo, by SKM party workers at his residence, has caused state-wide shock. What is even more shocking is that the party has not disciplined them and some fake IDs are justifying the act. What would you like to say about this issue?


                                </p>

                                

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> I am deeply anguished and saddened to see vocal citizens attacked, one after the other. Some have been attacked physically, others have been attacked through mental torture, administrative and legal victimisation. But I am not surprised by all of these things. I had seen it coming. The SKM leadership is openly flaunting goons and their muscle power. Remember that Sikkim is under a goonda Raj! Goonda Rajma, Goondai Raja.



                                </p>

                                <img class="w-100" src="assets/img/pressrelease/11July.jpeg" alt=""/>
                                <br/>
                                <br/>
                            
                        

                                <p style={{color: '#000'}}>
                                   I strongly believe that the SKM government and Mr PS Tamang have to do the following three things immediately.

                                </p>
                                 <p style={{color: '#000'}}>
                                    1. Chief Minister PS Tamang needs to withdraw his pro-violence statements like “Goley’s team is ready”, “I will show you who Goley is”, “Kutai diyera aaunu, khayera hoina” (Come to me after beating others but do not come to me if you are beaten up”) and step down. He and his hate-speeches  have been the cause of the increasing violence and unrest in Sikkim. He is openly promoting a murderous brand of politics openly abusing youth power. 

                                </p>
                                 <p style={{color: '#000'}}>
                                   2. Let the SKM party choose someone without criminal charges to head up the government. I am sure the party has more humane leaders who would be able to become Sikkim’s chief minister and restore some amount of peace and security.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   3. Let the party withdraw statements like - “sarkar hamilai chahincha, Kutenai hunchha ki, lutera huncha, marera huncha” (we need to form the government at any cost whether it means by beating, looting or murdering), “we will finish Chamling any time”. These murderous attacks on innocent people are nothing but the goons obeying the command of their brutal leaders. These leaders have blood on their hands.



                                </p>
                                
                                <p style={{color: '#000'}}>
                                   If they do not do these three things, there will be more and more attacks on vocal citizens and the opposition leader. They physically attacked me when I was still CM. They stoned my vehicle in Poklok Kamrang during the by-poll election, our party spokesman, Mr JB Darnal was attacked with a weapon and nearly lost his life. The list goes on and on. 



                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                  I urge the people to be courageous and oppose this murderous brand of politics adopted by the SKM party. 



                                </p>
                                
                                <p style={{color: '#000'}}>
                                   I convey my heartfelt sympathy to Mr Arun Limboo. I stand with him and pray for his speedy recovery. May courageous voices long live!



                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                     
The entire Sikkim knows that what he is speaking for are genuine issues which need advocacy. I share his concerns about the injustice done against 4 students expelled from Geyzing college, the SKM party’s lies about LT seat and all other 10 and 100 days promises. We need more and more Arun Limboos to speak for Sikkim. 


                                </p>
                                <p style={{color: '#000'}}>
                                   Finally, let me remind all my fellow Sikkimese citizens that violence and lies that the SKM party is spreading will stand fully justified if we tolerate them silently.”

                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr11Jully2021;