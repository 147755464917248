import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import Header2 from "../components/nav/Header2";


import Footer2 from "../components/nav/Footer2";

import "../speeches.css";




const Speeches = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">
                        <div class="wrapper">

                                <div class="root-contact">
                                    
                                    {/* Header */}
                                    <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                                        <div class="container" style={{paddingBottom: '50px'}}>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                                        <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                                            data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                                        Speeches
                                                        </h2>

                                                        <p class="economy-page-header-desc" style={{fontSize: '1rem', fontWeight: '500', maxWidth:'100%', color:'#000', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px'}}>
                                                        Collection of speeches by Pawan Chamling
                                                        </p>


                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </section>


                                    {/* Body */}

                                    <div class="container" style={{marginTop: '0px'}}>


                                    <div id="page-container" style={{padding: '0px'}}>
                                    
                                    <div id="main-container">
                                        <div class="post-list">
                                            <div class="inner-post-list">
                                                <div id="daily-dose-text" style={{color: '#000'}}>Latest Speeches:</div>

                                                <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                    <iframe width="257" height="193" src="https://www.youtube.com/embed/Xazt3VxYFKo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/Xazt3VxYFKo">
                                            
                                            <h3 class="small-post-title">
                                              Foundation Stone Laying Ceremony of Ropeway, 10/11/2016
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                    <iframe width="257" height="193" src="https://www.youtube.com/embed/G2we2Yed0fs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                
                                
                                </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/G2we2Yed0fs">
                                            
                                            <h3 class="small-post-title">
                                              Tourism Fest at Assam Lingzey, 30/12/2015
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                    <iframe width="257" height="193" src="https://www.youtube.com/embed/3bO7oo2fDSo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                
                                </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/3bO7oo2fDSo">
                                            
                                            <h3 class="small-post-title">
                                              NE Regional Workshop on Labour Co-op, 22/12/2015
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                    <iframe width="257" height="193" src="https://www.youtube.com/embed/5izoT_IRUgs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/5izoT_IRUgs">
                                            
                                            <h3 class="small-post-title">
                                              State Conference on Panchayat and ULBs, 16/11/2013
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                    <iframe width="257" height="193" src="https://www.youtube.com/embed/EyWs1fZ3oTo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                
                                </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/EyWs1fZ3oTo">
                                            
                                            <h3 class="small-post-title">
                                              Sikkim Organic Mission Orientation Programme, 14/10/2014
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                        <iframe width="257" height="193" src="https://www.youtube.com/embed/9M1qauz8qSg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/9M1qauz8qSg">
                                            
                                            <h3 class="small-post-title">
                                              Tendong Lho Rumfaat, 08/08/2014
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                         <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                        <iframe width="257" height="193" src="https://www.youtube.com/embed/WD2zwXUyD5w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/WD2zwXUyD5w">
                                            
                                            <h3 class="small-post-title">
                                              State Youth Meet, 20/06/2014
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                        <iframe width="257" height="193" src="https://www.youtube.com/embed/rNIvym_kJE0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/rNIvym_kJE0">
                                            
                                            <h3 class="small-post-title">
                                              Distribution of Laptop to Students, 01/10/2013
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                        <iframe width="257" height="193" src="https://www.youtube.com/embed/1vnG1NPxG5M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/1vnG1NPxG5M">
                                            
                                            <h3 class="small-post-title">
                                              State Green Mission, 15/06/2013
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                        <iframe width="257" height="193" src="https://www.youtube.com/embed/a57AcJGUJg0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                
                                </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/a57AcJGUJg0">
                                            
                                            <h3 class="small-post-title">
                                              State May Day Celebration, 01/05/2008
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                        <iframe width="257" height="193" src="https://www.youtube.com/embed/Ws4dhJStrJM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                
                                </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/Ws4dhJStrJM">
                                            
                                            <h3 class="small-post-title">
                                              Birth Anniversary of Teyongshi Sreejunga, 04/12/2006
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                        <iframe width="257" height="193" src="https://www.youtube.com/embed/PLll3lKm6Gk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/PLll3lKm6Gk">
                                            
                                            <h3 class="small-post-title">
                                              SDF Central Committee Meet, 2003
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                        <iframe width="257" height="193" src="https://www.youtube.com/embed/0EeN73DHMK8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/0EeN73DHMK8">
                                            
                                            <h3 class="small-post-title">
                                              Janata Mela Pakyong, 20/01/2003
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                        <iframe width="257" height="193" src="https://www.youtube.com/embed/1wPrizj0kno" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    
                                </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/1wPrizj0kno">
                                            
                                            <h3 class="small-post-title">
                                              Total Sanitation Campaign, 10/07/2002
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                 <iframe width="257" height="193" src="https://www.youtube.com/embed/E5lDSz59Hb0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>   
                                    </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/E5lDSz59Hb0">
                                            
                                            <h3 class="small-post-title">
                                              Panchayat Sammelan, 13/05/2003
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                 <iframe width="257" height="193" src="https://www.youtube.com/embed/mbZzK4E4fRI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    
                                    </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/mbZzK4E4fRI">
                                            
                                            <h3 class="small-post-title">
                                              Darap Public Meeting, 13/06/2002
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                  <iframe width="257" height="193" src="https://www.youtube.com/embed/fksOo-IOewg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/fksOo-IOewg">
                                            
                                            <h3 class="small-post-title">
                                              Decadal Convention of SDF, 03/03/2002
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                  <iframe width="257" height="193" src="https://www.youtube.com/embed/90UvHhl8ock" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
                                    
                                    </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/90UvHhl8ock">
                                            
                                            <h3 class="small-post-title">
                                              Government Formation Day, 12/12/2001
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                  <iframe width="257" height="193" src="https://www.youtube.com/embed/90UvHhl8ock" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
                                    
                                    </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/90UvHhl8ock">
                                            
                                            <h3 class="small-post-title">
                                              Government Formation Day, 12/12/2001
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                  <iframe width="257" height="193" src="https://www.youtube.com/embed/GXbjxCYxRH8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
                                    </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/GXbjxCYxRH8">
                                            
                                            <h3 class="small-post-title">
                                              Rabong Tourism Festival, 08/04/2006
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                  <iframe width="257" height="193" src="https://www.youtube.com/embed/3bW5n6KRAL0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
                                    
                                    </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/3bW5n6KRAL0">
                                            
                                            <h3 class="small-post-title">
                                              21st State Day Celebration, 16/05/1996
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                         <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                  <iframe width="257" height="193" src="https://www.youtube.com/embed/MA12QNH-udw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
                                    </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/MA12QNH-udw">
                                            
                                            <h3 class="small-post-title">
                                               SDF Kranti Diwas, 22/06/2015
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                  <iframe width="257" height="193" src="https://www.youtube.com/embed/wQYEKCF4bNc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    
                                    </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/wQYEKCF4bNc">
                                            
                                            <h3 class="small-post-title">
                                               Civil Service Day, 21/04/2015
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                  <iframe width="257" height="193" src="https://www.youtube.com/embed/j_UGosVT6kM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/j_UGosVT6kM">
                                            
                                            <h3 class="small-post-title">
                                               With Students at Namchi, South Sikkim, 18/04/2015
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                  <iframe width="257" height="193" src="https://www.youtube.com/embed/K1-izPxOw1M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    
                                    </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/K1-izPxOw1M">
                                            
                                            <h3 class="small-post-title">
                                               With Traders at Singtam, East Sikkim, 09/03/2015
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                  <iframe width="257" height="193" src="https://www.youtube.com/embed/PZ5jkKUvxjU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/PZ5jkKUvxjU">
                                            
                                            <h3 class="small-post-title">
                                               Decadal Convention of SDF, 2-4/03/2002
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                  <iframe width="257" height="193" src="https://www.youtube.com/embed/AZf0s53XICQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
                                    
                                    </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/AZf0s53XICQ">
                                            
                                            <h3 class="small-post-title">
                                               Sikkim Organic State Declaration, 18/01/2016
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                  <iframe width="257" height="193" src="https://www.youtube.com/embed/vBGCvZO8bBk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/vBGCvZO8bBk">
                                            
                                            <h3 class="small-post-title">
                                               Sikkim Organic State Declaration, 18/01/2016
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                   <iframe width="257" height="193" src="https://www.youtube.com/embed/nqhtyZ1oSAY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    
                                    </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/nqhtyZ1oSAY">
                                            
                                            <h3 class="small-post-title">
                                               Meeting with Government Employees, 2015
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                    <iframe width="257" height="193" src="https://www.youtube.com/embed/MA12QNH-udw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/MA12QNH-udw">
                                            
                                            <h3 class="small-post-title">
                                               SDF Kranti Diwas, 22/06/2015
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                     <iframe width="257" height="193" src="https://www.youtube.com/embed/wQYEKCF4bNc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                     </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/wQYEKCF4bNc">
                                            
                                            <h3 class="small-post-title">
                                               Civil Service Day, 21/04/2015
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                   <iframe width="257" height="193" src="https://www.youtube.com/embed/j_UGosVT6kM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                     </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/j_UGosVT6kM">
                                            
                                            <h3 class="small-post-title">
                                               With Students at Namchi, South Sikkim, 18/04/2015
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                   <iframe width="257" height="193" src="https://www.youtube.com/embed/K1-izPxOw1M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                     </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/K1-izPxOw1M">
                                            
                                            <h3 class="small-post-title">
                                                With Traders at Singtam, East Sikkim, 09/03/2015
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                   <iframe width="257" height="193" src="https://www.youtube.com/embed/GXbjxCYxRH8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    
                                    </div>
                                     <div class="post-content-container">
                                        <div class="post-content">
                                            <Link to="https://youtu.be/GXbjxCYxRH8">
                                                
                                                <h3 class="small-post-title">
                                                  Rabong Tourism Festival, 08/04/2006
                                                    </h3>
                                           </Link>
                                           <div class="post-meta-link">
                                               (Mero Sapanako Sikkim)
                                           </div>
                                        </div>
                                     </div>
                             
                           </div>
                        </div>
                        
                        
                         <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                   <iframe width="257" height="193" src="https://www.youtube.com/embed/PZ5jkKUvxjU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
                                     </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://youtu.be/PZ5jkKUvxjU">
                                            
                                            <h3 class="small-post-title">
                                                Decadal Convention of SDF, 2-4/03/2002
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                    <iframe width="257" height="193" src="https://www.youtube.com/embed/dZE_h8Tgl9g?list=PLlTpHxBygx7wqWotZEeCCnydPYd6Os5ZS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                     
                                     </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://www.youtube.com/watch?v=dZE_h8Tgl9g&list=PLlTpHxBygx7wqWotZEeCCnydPYd6Os5ZS">
                                            
                                            <h3 class="small-post-title">
                                                Geyzing Public Meeting, 08/10/1993 
                                                </h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        <div data-max-pages="6" data-next-page="" id="post-877" class="link-true post post-877 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="877">
                           <div class="inner-post">
                             
                                 <div class="feat-img-container">
                                     
                                     <iframe width="257" height="193" src="https://www.youtube.com/embed/OPOYVMJBqTw?list=PLlTpHxBygx7wqWotZEeCCnydPYd6Os5ZS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            
                                     
                                     </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                      <Link to="https://www.youtube.com/watch?list=PLlTpHxBygx7wqWotZEeCCnydPYd6Os5ZS&v=OPOYVMJBqTw" target="_blank" class="link-container">
                                            <h3 class="small-post-title">NANDU GAON PUBLIC MEET, 1993</h3>
                                       </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) 
                                       </div>
                                    </div>
                                 </div>
                              
                           </div>
                        </div>
                        <div data-max-pages="6" data-next-page="" id="post-873" class="link-true post post-873 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="873">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                     <iframe width="257" height="193" src="https://www.youtube.com/embed/2JOkYWfJ_uY?list=PLlTpHxBygx7wqWotZEeCCnydPYd6Os5ZS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            
                                     
                                     </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://www.youtube.com/watch?list=PLlTpHxBygx7wqWotZEeCCnydPYd6Os5ZS&v=2JOkYWfJ_uY&feature=emb_logo" target="_blank" class="link-container">
                                            <h3 class="small-post-title">Jorethang Public Meeting, 20/03/1994</h3>
                                        </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim)
                                           </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        <div data-max-pages="6" data-next-page="" id="post-869" class="link-true post post-869 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="869">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     <iframe width="257" height="193" src="https://www.youtube.com/embed/kiHGLaDmeH8?list=PLlTpHxBygx7wqWotZEeCCnydPYd6Os5ZS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            
                                     </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://www.youtube.com/watch?list=PLlTpHxBygx7wqWotZEeCCnydPYd6Os5ZS&v=kiHGLaDmeH8" target="_blank" class="link-container">
                                         <h3 class="small-post-title">Declaration of Dharmadatt Path, Soreng</h3>
                                         </Link>
                                       <div class="post-meta-link">
                                           16/02/1995 (Mero Sapanako Sikkim)
                                        </div>
                                    </div>
                                 </div>
                              
                           </div>
                        </div>
                        <div data-max-pages="6" data-next-page="" id="post-865" class="link-true post post-865 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="865">
                           <div class="inner-post">
                             
                                 <div class="feat-img-container">
                                     
                                       <iframe width="257" height="193" src="https://www.youtube.com/embed/hw6lfeoBhrs?list=PLlTpHxBygx7wqWotZEeCCnydPYd6Os5ZS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            
                                     
                                     </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                      <Link to="https://www.youtube.com/watch?list=PLlTpHxBygx7wqWotZEeCCnydPYd6Os5ZS&v=hw6lfeoBhrs" target="_blank" class="link-container">
                                       <h3 class="small-post-title">1st Anniversary of SDF Govt. 12/12/1995</h3>
                                        </Link>
                                       <div class="post-meta-link">
                                           (Mero Sapanako Sikkim) </div>
                                    </div>
                                 </div>
                             
                           </div>
                        </div>
                        <div data-max-pages="6" data-next-page="" id="post-861" class="link-true post post-861 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="861">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                    <iframe width="257" height="193" src="https://www.youtube.com/embed/hw6lfeoBhrs?list=PLlTpHxBygx7wqWotZEeCCnydPYd6Os5ZS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                     
                                     </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://www.youtube.com/watch?list=PLlTpHxBygx7wqWotZEeCCnydPYd6Os5ZS&v=hw6lfeoBhrs&feature=emb_logo" target="_blank" class="link-container">
                                           <h3 class="small-post-title">1st Anniversary of SDF Govt. 12/12/1995 </h3>
                                       </Link>
                                       <div class="post-meta-link">(Mero Sapanako Sikkim) </div>
                                    </div>
                                 </div>
                              
                           </div>
                        </div>
                        <div data-max-pages="6" data-next-page="<Link to=&quot;https://dannpetty.com/page/2/&quot; >Load More</Link>" id="post-857" class="link-true post post-857 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="857">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                     <iframe width="257" height="193" src="https://www.youtube.com/embed/n0FsUjrORzU?list=PLlTpHxBygx7wqWotZEeCCnydPYd6Os5ZS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                     
                                     </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://www.youtube.com/watch?list=PLlTpHxBygx7wqWotZEeCCnydPYd6Os5ZS&v=n0FsUjrORzU" target="_blank" class="link-container">
                                       <h3 class="small-post-title">STATE LEVEL PANCHYAT SAMMELAN 19/06/1995</h3>
                                       </Link>
                                       <div class="post-meta-link">(Mero Sapanako Sikkim) </div>
                                    </div>
                                 </div>
                              
                           </div>
                        </div>
                        <div data-max-pages="6" data-next-page="<Link to=&quot;https://dannpetty.com/page/2/&quot; >Load More</Link>" id="post-853" class="link-true post post-853 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="853">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                     <iframe width="257" height="193" src="https://www.youtube.com/embed/n0FsUjrORzU?list=PLlTpHxBygx7wqWotZEeCCnydPYd6Os5ZS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                     
                                     </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://www.youtube.com/watch?list=PLlTpHxBygx7wqWotZEeCCnydPYd6Os5ZS&v=n0FsUjrORzU" target="_blank" class="link-container">
                                       <h3 class="small-post-title">STATE LEVEL PANCHYAT SAMMELAN 19/06/1995</h3>
                                       </Link>
                                       <div class="post-meta-link">(Mero Sapanako Sikkim) </div>
                                    </div>
                                 </div>
                              
                           </div>
                        </div>

                        <div data-max-pages="6" data-next-page="<Link to=&quot;https://dannpetty.com/page/2/&quot; >Load More</Link>" id="post-853" class="link-true post post-853 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="853">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                 <iframe width="257" height="193" src="https://www.youtube.com/embed/oQROE6wV00k?list=PLlTpHxBygx7wqWotZEeCCnydPYd6Os5ZS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                     
                                     </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                        <Link to="https://www.youtube.com/watch?list=PLlTpHxBygx7wqWotZEeCCnydPYd6Os5ZS&v=oQROE6wV00k" target="_blank" class="link-container">
                                        <h3 class="small-post-title">STATE LEVEL PANCHYAT SAMMELAN 19/06/1995</h3>
                                       </Link>
                                       <div class="post-meta-link">(Mero Sapanako Sikkim) </div>
                                    </div>
                                 </div>
                              
                           </div>
                        </div>
                        {/* <div data-max-pages="6" data-next-page="<Link to=&quot;https://dannpetty.com/page/2/&quot; >Load More</Link>" id="post-746" class="link-true post post-746 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="746">
                           <div class="inner-post">
                              
                                 <div class="feat-img-container">
                                     
                                     <iframe width="257" height="193" src="https://www.youtube.com/embed/oQROE6wV00k?list=PLlTpHxBygx7wqWotZEeCCnydPYd6Os5ZS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                     
                                     </div>
                                 <div class="post-content-container">
                                    <div class="post-content">
                                    <Link to="https://www.youtube.com/watch?list=PLlTpHxBygx7wqWotZEeCCnydPYd6Os5ZS&v=oQROE6wV00k" target="_blank" class="link-container">
                                       <h3 class="small-post-title">Pakyong  Public  Meeting   24/04/1994</h3>
                                       </Link>
                                       <div class="post-meta-link">(Mero Sapanako Sikkim) </div>
                                    </div>
                                 </div>
                              </Link>
                           </div>
                        </div> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                       




                                <Footer2 />

                        </div>
                    </div>
                </main>

            </>
    );

};

export default Speeches;