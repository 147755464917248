import React from 'react';

const AwardForStateHeader = () => {
    return(
        <>
            <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                    <div class="container" style={{paddingBottom: '50px'}}>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                    <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                        data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                        Awards For The State
                                    </h2>

                                    <p class="economy-page-header-desc" style={{maxWidth:'100%', color:'#000', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px'}}>
                                    For a state once unknown to the outside world, Sikkim has received over 200 national and international awards over the last 24 years in various areas of governance and policy.
                                    </p>


                                </div>
                            </div>

                        </div>
                    </div>

                </section>
        </>
    );
};

export default AwardForStateHeader;