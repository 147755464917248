import React from 'react';
import {Link} from 'react-router-dom';

const AwardOfPc = () => {
    return(
        <>
           <div class="header home" style={{marginBottom: '20px'}}>
                    <Link to="#" class="link-block w-inline-block">
                    <h1 class="heading-15 inline heading-underline" style={{fontSize: '32px',
                        fontWeight: '700', borderBottom: '1px dashed rgba(0, 0, 0, 0.5)',
                        color: '#333',
                        textDecoration: 'none'}}> Awards – Pawan Chamling</h1>
                    </Link>
            </div>

            <div class="row">

                
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/BHARAT-SHIROMANI-AWARD.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/BHARAT-SHIROMANI-AWARD.jpg 500w, assets/img/awards/BHARAT-SHIROMANI-AWARD.jpg 800w, assets/img/awards/BHARAT-SHIROMANI-AWARD.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        <div> 
                        <h2 style={{fontSize: '18px', color: '#000', fontweight: '700'}}>Awarded By</h2>
                            <p style={{color: '#000', fontsize: '12px'}}>

                                Shiromani Institute, New Delhi - 1997
                            </p>
                            
                        </div>
                    </div>


                    <div class="div-block-57" >
                     <Link to="" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Bharat Shiromani Award - 1997</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/Greenest-Chief-Minister-Of-India---1998.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/Greenest-Chief-Minister-Of-India---1998.jpg 500w, assets/img/awards/Greenest-Chief-Minister-Of-India---1998.jpg 800w, assets/img/awards/Greenest-Chief-Minister-Of-India---1998.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        <div> 
                        <h2 style={{fontSize: '18px', color: '#000', fontweight: '700'}}>Awarded By</h2>
                            <p style={{color: '#000', fontsize: '12px'}}>

                                Centre of Science and Environment, New Delhi - 1998
                            </p>
                        </div>
                    </div>


                    <div class="div-block-57" >
                     <Link to="" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Pawan Chamling: The Greenest Chief Minister Of India - 1998</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/Manav-seva-puraskar.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/Manav-seva-puraskar.jpg 500w, assets/img/awards/Manav-seva-puraskar.jpg 800w, assets/img/awards/Manav-seva-puraskar.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        <div> 
                        <h2 style={{fontSize: '18px', color: '#000', fontweight: '700'}}>Awarded By</h2>
                            <p style={{color: '#000', fontsize: '12px'}}>
                               Institute Of Economic Studies, New Delhi - 1999
                            </p>
                        </div>
                    </div>


                    <div class="div-block-57" >
                     <Link to="" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Manav Seva Puraskar - 1999</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/DOCTOR-OF-PHILOSOPHY.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/DOCTOR-OF-PHILOSOPHY.jpg 500w, assets/img/awards/DOCTOR-OF-PHILOSOPHY.jpg 800w, assets/img/awards/DOCTOR-OF-PHILOSOPHY.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        <div> 
                        <h2 style={{fontSize: '18px', color: '#000', fontweight: '700'}}>Awarded By</h2>
                            <p style={{color: '#000', fontsize: '12px'}}>

                                Sikkim Manipal University - 2003
                            </p>
                        </div>
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Doctorate Of Philosophy ( Honoris Causa) - 2003</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/Leadership-and-good-governance-award-2009.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/Leadership-and-good-governance-award-2009.jpg 500w, assets/img/awards/Leadership-and-good-governance-award-2009.jpg 800w, assets/img/awards/Leadership-and-good-governance-award-2009.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        <div> 
                        <h2 style={{fontSize: '18px', color: '#000', fontweight: '700'}}>Awarded By</h2>
                            <p style={{color: '#000', fontsize: '12px'}}>

                                Universal Peace Federation and The Interreligious and International Federation - 2009
                            </p>
                        </div>
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Leadership and Good Governance Award - 2009</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/Mother-Teresa-lifetime-achievement-award--2009.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/Mother-Teresa-lifetime-achievement-award--2009.jpg 500w, assets/img/awards/Mother-Teresa-lifetime-achievement-award--2009.jpg 800w, assets/img/awards/Mother-Teresa-lifetime-achievement-award--2009.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        <div> 
                        <h2 style={{fontSize: '18px', color: '#000', fontweight: '700'}}>Awarded By</h2>
                            <p style={{color: '#000', fontsize: '12px'}}>

                                Mother Teresa’s International & Millennium Awards Committee - 2009
                            </p>
                        </div>
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Mother Teresa Lifetime Achievement Award - 2009</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/LIFETIME-ACHIVEMENT-IN-LITERATURE-2001.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/LIFETIME-ACHIVEMENT-IN-LITERATURE-2001.jpg 500w, assets/img/awards/LIFETIME-ACHIVEMENT-IN-LITERATURE-2001.jpg 800w, assets/img/awards/LIFETIME-ACHIVEMENT-IN-LITERATURE-2001.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        <div> 
                        <h2 style={{fontSize: '18px', color: '#000', fontweight: '700'}}>Awarded By</h2>
                            <p style={{color: '#000', fontsize: '12px'}}>

                                India International Friendship Society (IIFS) - 2010
                            </p>
                        </div>
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Lifetime Achievement Award - 2010</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/AMBASSDOR-FOR-PEACE.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/AMBASSDOR-FOR-PEACE.jpg 500w, assets/img/awards/AMBASSDOR-FOR-PEACE.jpg 800w, assets/img/awards/AMBASSDOR-FOR-PEACE.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        <div> 
                        <h2 style={{fontSize: '18px', color: '#000', fontweight: '700'}}>Awarded By</h2>
                            <p style={{color: '#000', fontsize: '12px'}}>

                                Universal Peace Federation, SIkkim Chapter - 2010
                            </p>
                        </div>
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >International Ambassador for Peace Award - 2010</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/Best-personalities-of-India-award-2010.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/Best-personalities-of-India-award-2010.jpg 500w, assets/img/awards/Best-personalities-of-India-award-2010.jpg 800w, assets/img/awards/Best-personalities-of-India-award-2010.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        <div> 
                        <h2 style={{fontSize: '18px', color: '#000', fontweight: '700'}}>Awarded By</h2>
                            <p style={{color: '#000', fontsize: '12px'}}>

                                Friendship Forum of India - 2010
                            </p>
                        </div>
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Best Personalities of India Award - 2010</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/Bharat-Asmita-Jeevan-Gaurav-Puraskar.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/Bharat-Asmita-Jeevan-Gaurav-Puraskar.jpg 500w, assets/img/awards/Bharat-Asmita-Jeevan-Gaurav-Puraskar.jpg 800w, assets/img/awards/Bharat-Asmita-Jeevan-Gaurav-Puraskar.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        <div> 
                        <h2 style={{fontSize: '18px', color: '#000', fontweight: '700'}}>Awarded By</h2>
                            <p style={{color: '#000', fontsize: '12px'}}>
                                Bharat Asmita Foundation, MIT Group of Institutions, Pune - 2012
                            </p>
                        </div>
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Bharat Asmita Jeevan Gaurav Puraskar - 2012</h4>
                     </Link>
                    
                  </div>
                </div>
                

                 
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/Sustainable-Development-Leadership-Award.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/Sustainable-Development-Leadership-Award.jpg 500w, assets/img/awards/Sustainable-Development-Leadership-Award.jpg 800w, assets/img/awards/Sustainable-Development-Leadership-Award.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        <div> 
                        <h2 style={{fontSize: '18px', color: '#000', fontweight: '700'}}>Awarded By</h2>
                            <p style={{color: '#000', fontsize: '12px'}}>
                                The Energy & Resource Institute (TERI), New Delhi - 2016
                            </p>
                        </div>
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Sustainable Development Leadership Award - 2016</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/one-world-award-2017.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/one-world-award-2017.jpg 500w, assets/img/awards/one-world-award-2017.jpg 800w, assets/img/awards/one-world-award-2017.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        <div> 
                        <h2 style={{fontSize: '18px', color: '#000', fontweight: '700'}}>Awarded By</h2>
                            <p style={{color: '#000', fontsize: '12px'}}>
                                Rapunzel - 2017
                            </p>
                        </div>
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >One World Award (Grand Prix) - 2017</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/First-Bhaiton-Singh-Shekhawat-Lifetime-Achievement-Honour-In-Public-Service-2017.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/First-Bhaiton-Singh-Shekhawat-Lifetime-Achievement-Honour-In-Public-Service-2017.jpg 500w, assets/img/awards/First-Bhaiton-Singh-Shekhawat-Lifetime-Achievement-Honour-In-Public-Service-2017.jpg 800w, assets/img/awards/First-Bhaiton-Singh-Shekhawat-Lifetime-Achievement-Honour-In-Public-Service-2017.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        <div> 
                        <h2 style={{fontSize: '18px', color: '#000', fontweight: '700'}}>Awarded By</h2>
                            <p style={{color: '#000', fontsize: '12px'}}>
                                The Family of Bhairon Singh Shekhaat, Former Vice President Of India - 2017
                            </p>
                        </div>
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >First Bhairon Singh Shekhawat Lifetime Achievement Honour in Public Service - 2017</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/Doctor-of-philosophy-(honours-vaisa)-2017.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/Doctor-of-philosophy-(honours-vaisa)-2017.jpg 500w, assets/img/awards/Doctor-of-philosophy-(honours-vaisa)-2017.jpg 800w, assets/img/awards/Doctor-of-philosophy-(honours-vaisa)-2017.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        <div> 
                        <h2 style={{fontSize: '18px', color: '#000', fontweight: '700'}}>Awarded By</h2>
                            <p style={{color: '#000', fontsize: '12px'}}>
                               Sikkim Central University  - 2017
                            </p>
                        </div>
                    </div>


                    <div class="div-block-57" >
                     <Link to="" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Doctor of Philosophy (honoris causa)  - 2017</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/Ambassador-of-organic-Himalaya-and-organic-world.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/Ambassador-of-organic-Himalaya-and-organic-world.jpg 500w, assets/img/awards/Ambassador-of-organic-Himalaya-and-organic-world.jpg 800w, assets/img/awards/Ambassador-of-organic-Himalaya-and-organic-world.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        <div> 
                        <h2 style={{fontSize: '18px', color: '#000', fontweight: '700'}}>Awarded By</h2>
                            <p style={{color: '#000', fontsize: '12px'}}>
                               Navdanya NGO, New Delhi - 2017
                            </p>
                        </div>
                    </div>


                    <div class="div-block-57" >
                     <Link to="" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Ambassador of Organic Himalaya and Organic World - 2017</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/DOCTOR-IN-LITERATURE---2017.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/DOCTOR-IN-LITERATURE---2017.jpg 500w, assets/img/awards/DOCTOR-IN-LITERATURE---2017.jpg 800w, assets/img/awards/DOCTOR-IN-LITERATURE---2017.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        <div> 
                        <h2 style={{fontSize: '18px', color: '#000', fontweight: '700'}}>Awarded By</h2>
                            <p style={{color: '#000', fontsize: '12px'}}>
                               SRM University - 2017
                            </p>
                        </div>
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Doctorate of Literature (honoris causa) - 2017</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/Doctorate-of-literature-(honoris-causa)-By-ICFAI-University.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/Doctorate-of-literature-(honoris-causa)-By-ICFAI-University.jpg 500w, assets/img/awards/Doctorate-of-literature-(honoris-causa)-By-ICFAI-University.jpg 800w, assets/img/awards/Doctorate-of-literature-(honoris-causa)-By-ICFAI-University.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        <div> 
                        <h2 style={{fontSize: '18px', color: '#000', fontweight: '700'}}>Awarded By</h2>
                            <p style={{color: '#000', fontsize: '12px'}}>
                               ICFAI University - 2018
                            </p>
                        </div>
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Doctorate of Literature (honoris causa) - 2018</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/World-Book-Of-Records.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/World-Book-Of-Records.jpg 500w, assets/img/awards/World-Book-Of-Records.jpg 800w, assets/img/awards/World-Book-Of-Records.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        <div> 
                        <h2 style={{fontSize: '18px', color: '#000', fontweight: '700'}}>Awarded By</h2>
                            <p style={{color: '#000', fontsize: '12px'}}>
                               World Book of Records, London - 2018
                            </p>
                        </div>
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >World Book of Records - 2018</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/Future-policy-gold-award.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/Future-policy-gold-award.jpg 500w, assets/img/awards/Future-policy-gold-award.jpg 800w, assets/img/awards/Future-policy-gold-award.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        <div> 
                        <h2 style={{fontSize: '18px', color: '#000', fontweight: '700'}}>Awarded By</h2>
                            <p style={{color: '#000', fontsize: '12px'}}>
                               United Nation’s Food and Agriculture Organisation, World Future Council and IFOAM Organics International - 2018
                            </p>
                        </div>
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Future Policy Gold Award - 2018</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/Kirati-Yalamber-Smriti-Samman.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/Kirati-Yalamber-Smriti-Samman.jpg 500w, assets/img/awards/Kirati-Yalamber-Smriti-Samman.jpg 800w, assets/img/awards/Kirati-Yalamber-Smriti-Samman.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        <div> 
                        <h2 style={{fontSize: '18px', color: '#000', fontweight: '700'}}>Awarded By</h2>
                            <p style={{color: '#000', fontsize: '12px'}}>
                               Conferred the Kirati Yalamber Smriti Samman by Kirat Rai Yayokha on 17th Jan 2020 in Kathmandu, Nepal. 
                               The dignitaries present at the programme were Chief Minister of Pradesh No. 1, Sher Dhan Rai, 
                               former ministers and members of the Federal Parliament of Nepal, among others.
                            </p>
                        </div>
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Kirati Yalamber Smriti Samman</h4>
                     </Link>
                    
                  </div>
                </div>
                
              
            </div>
            
            <div class="row" >
                <div class="col-md-12" style={{textAlign: 'right'}}>
                <div class="link-custom">
                                <Link class="image-zoom" to="/award-As-CM" style={{borderColor: 'rgb(238, 238, 238)',
                                transformOrigin: '0px 0px 0px', transform: 'matrix(1, 0, 0, 1, 0, 0)' }}>
                                    <span style={{color: '#000'}}>Show More</span>
                                <div class="icon-circle" style={{transformOrigin: '0px 0px 0px', transform: 'matrix(1, 0, 0, 1, 0, 0)'}}></div></Link>
                            </div>
                </div>
            </div>
        </>
    );
};

export default AwardOfPc;