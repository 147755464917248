import React from 'react';

const WomenBody = () => {
    return(
        <>
                      <div class="container section-margin">
            <div class="row">
                <div class="good-gov-inner-page-container">
                    
                    
                    <ul>
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Prerna Yojana scheme: </span></h4>
                            <p>This scheme was started on 7th Jan 2004 to encourage the girl child in pursuing education. According to this scheme, girl students are provided scholarship on merit.
</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Small Family scheme:  </span></h4>
                            <p>began in 1997 as an incentive and encouragement for the girl child to complete her education and delay marriage. According to this scheme, the girl child would receive Rs 5000 once she reaches the age of 13.
</p>


                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Sikkim State Commission for Women: </span></h4>
                            <p>was constituted as a statutory body under the State Commission for Women Act, 2001 in order to protect women’s rights, eradicate domestic violence and deliver justice. The Commission was set up in 2001.

</p>


                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Reservation for women in Panchayati Raj: </span></h4>
                            <p>In 2011, through the Sikkim Panchayat (Amendment) Bill, 50% reservation was accorded to women in panchayati raj.

</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>33% Reservation:  </span></h4>
                            <p>Women are given 33% reservation in jobs and higher education.


</p>


                        </li>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>NOC of wife in sale of land:  </span></h4>
                            <p>In the sale of land by husband, it has been made mandatory to also acquire the NOC of the wife and vice versa.
</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Mother’s name mandatory in documents: </span></h4>
                            <p>It has also been made mandatory to register the name of the mother along with the father in all relevant documents.
</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'60px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Sikkim Succession Act 2008:  </span></h4>
                            <p>According to the Act, in case of the death of a man, his successors including his sons and daughters have equal right to his property.

</p>

                        </li>
                        
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            
                            <div class="row">
                                <div class="col-md-1 col-xs-4">
                                    <div class="icon" alt="Check icon" style={{fontSize: '45px', lineHeight: '35px', marginRight: '0px'}}>✔</div>
                                </div>
                                <div clas="col-md-11 col-xs-8">
                                    <h4 style={{fontSize: '19px', fontWeight: '700', color: '#000', marginBottom:'10px', paddingTop:'10px'}}>First woman Chief Secretary of Sikkim, IAS R. Ongmu, was appointed under the SDF Government in 2013.
                                    </h4>
                                </div>
                            </div>
                           

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            
                            <div class="row">
                                <div class="col-md-1 col-xs-4">
                                    <div class="icon" alt="Check              icon" style={{fontSize: '45px', lineHeight: '35px', marginRight: '0px'}}>✔</div>
                                </div>
                                <div clas="col-md-11 col-xs-8">
                                    <h4 style={{fontSize: '19px', fontWeight: '700', color: '#000', marginBottom:'10px', paddingTop:'10px'}}>First woman Speaker of Sikkim, Kalawati Subba, was elected during the SDF Government in 1999.

</h4>

                                </div>
                            </div>
                           
                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            
                            
                            <div class="row">
                                <div class="col-md-1">
                                    <div class="icon" alt="Check              icon" style={{fontSize: '45px', lineHeight: '35px', marginRight: '0px'}}>✔</div>
                                </div>
                                <div clas="col-md-11">
                                    <h4 style={{fontSize: '19px', fontWeight: '700', color: '#000', marginBottom:'10px', paddingTop:'10px'}}>First woman Minister of Sikkim, R. Ongmu, was elected during the SDF Government in 1999.

</h4>

                                </div>
                            </div>
                           

                        </li>
                        
                        
                      
                        
                        
                    </ul>
                </div>
            </div>
          </div>
        </>
    );
};

export default WomenBody;