import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import Header2 from "../components/nav/Header2";


import Footer2 from "../components/nav/Footer2";





const Infographic = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">
                        <div class="wrapper">

                                <div class="root-contact">
                                    
                                    {/* Header */}
                <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                    <div class="container" style={{paddingBottom: '50px'}}>
                        <div class="row ">
                            <div class="col-lg-12">
                                <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                    <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                        data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                        INFOGRAPHICS 
                                    </h2>

                                    <p class="infographic-page-header-desc" style={{fontSize: '1rem', fontWeight: '500', maxWidth:'100%', color:'#000', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px'}}>
                                    	From a little known Himalayan state and one of the poorest states in India in 1994, Sikkim has grown leaps and bounds in various sectors under Pawan Chamling’s governance. From unparalleled poverty reduction to an exponential rise in GSDP, many indicators point to the strong trajectory of growth and development under the SDF government.

                                    </p>


                                </div>
                            </div>

                        </div>
                    </div>

                </section>


                                    {/* Body */}

   
                                    <div class="container-fluid gallery-col" style={{backgroundColor: '#fff', paddingBottom: '120px'}}>
            <div class="row" style={{margin: '0px !important'}}>
                <div class="col-md-4 box-im section-padding" style={{paddingTop:'60px', paddingLeft:'60px', paddingRight: '60px', paddingBottom: '0px' }}>
                    <div class="image-zoom" data-dsn="parallax">
                        <Link class="with-caption image-link single-image" to="assets/img/infographics/Growth-in-education-Institutions.png">
                            <img src="assets/img/infographics/Growth-in-education-Institutions.png" alt="" />
                        </Link>

                        <div class="caption" style={{color: '#000'}}>Share this : 

                             <Link to="#" style={{color: '#000'}}>
                                <i class="fab fa-facebook-f" style={{color: '#000'}}></i>
                            </Link>

                             <Link to="#" style={{color: '#000'}}>
                                <i class="fab fa-instagram" style={{color: '#000'}}></i>
                            </Link>
                            
                        </div>
                    </div>
                </div>

                <div class="col-md-4 box-im section-padding" style={{paddingTop:'60px', paddingLeft:'60px', paddingRight: '60px', backgroundColor: '#fff', paddingBottom: '0px' }}>
                    <div class="image-zoom" data-dsn="parallax">
                        <Link class="with-caption image-link single-image" to="assets/img/infographics/GSDP.png">
                            <img src="assets/img/infographics/GSDP.png" alt="" />
                        </Link>
                        <div class="caption" style={{color: '#000'}}>Share this : 

                             <Link to="#" style={{color: '#000'}}>
                                <i class="fab fa-facebook-f" style={{color: '#000'}}></i>
                            </Link>

                             <Link to="#" style={{color: '#000'}}>
                                <i class="fab fa-instagram" style={{color: '#000'}}></i>
                            </Link>
                            
                        </div>
                    </div>
                </div>

                <div class="col-md-4 box-im section-padding" style={{paddingTop:'60px', paddingLeft:'60px', paddingRight: '60px', paddingBottom: '0px' }}>
                    <div class="image-zoom" data-dsn="parallax">
                        <Link class="with-caption image-link single-image" to="assets/img/infographics/Increase-in-forest-cover.png">
                            <img src="assets/img/infographics/Increase-in-forest-cover.png" alt="" />
                        </Link>
                       <div class="caption" style={{color: '#000'}}>Share this : 

                             <Link to="#" style={{color: '#000'}}>
                                <i class="fab fa-facebook-f" style={{color: '#000'}}></i>
                            </Link>

                             <Link to="#" style={{color: '#000'}}>
                                <i class="fab fa-instagram" style={{color: '#000'}}></i>
                            </Link>
                            
                        </div>
                    </div>
                </div>

            </div>

            <div class="row" style={{margin: '0px !important'}}>
                <div class="col-md-4 box-im section-padding" style={{paddingTop:'60px', paddingLeft:'60px', paddingRight: '60px', paddingBottom: '0px' }}>
                    <div class="with-caption image-link image-zoom" data-dsn="parallax">
                        <Link class="single-image" to="assets/img/infographics/Life-expectancy-infographics.png">
                            <img src="assets/img/infographics/Life-expectancy-infographics.png" alt="" />
                        </Link>

                        <div class="caption" style={{color: '#000'}}>Share this : 

                             <Link to="#" style={{color: '#000'}}>
                                <i class="fab fa-facebook-f" style={{color: '#000'}}></i>
                            </Link>

                             <Link to="#" style={{color: '#000'}}>
                                <i class="fab fa-instagram" style={{color: '#000'}}></i>
                            </Link>
                            
                        </div>
                    </div>
                </div>

                <div class="col-md-4 box-im section-padding" style={{paddingTop:'60px', paddingLeft:'60px', paddingRight: '60px', backgroundColor: '#fff', paddingBottom: '0px' }}>
                    <div class="image-zoom" data-dsn="parallax">
                        <Link class="with-caption image-link single-image" to="assets/img/infographics/Literacy-Rate-Info.png">
                            <img src="assets/img/infographics/Literacy-Rate-Info.png" alt="" />
                        </Link>
                        <div class="caption" style={{color: '#000'}}>Share this : 

                             <Link to="#" style={{color: '#000'}}>
                                <i class="fab fa-facebook-f" style={{color: '#000'}}></i>
                            </Link>

                             <Link to="#" style={{color: '#000'}}>
                                <i class="fab fa-instagram" style={{color: '#000'}}></i>
                            </Link>
                            
                        </div>
                    </div>
                </div>

                <div class="col-md-4 box-im section-padding" style={{paddingTop:'60px', paddingLeft:'60px', paddingRight: '60px', paddingBottom: '0px' }}>
                    <div class="image-zoom" data-dsn="parallax">
                        <Link class="with-caption image-link single-image" to="assets/img/infographics/Power-Generation.png">
                            <img src="assets/img/infographics/Power-Generation.png" alt="" />
                        </Link>
                       <div class="caption" style={{color: '#000'}}>Share this : 

                             <Link to="#" style={{color: '#000'}}>
                                <i class="fab fa-facebook-f" style={{color: '#000'}}></i>
                            </Link>

                             <Link to="#" style={{color: '#000'}}>
                                <i class="fab fa-instagram" style={{color: '#000'}}></i>
                            </Link>
                            
                        </div>
                    </div>
                </div>

            </div>
            
            
            
            <div class="row" style={{margin: '0px !important'}}>
                <div class="col-md-4 box-im section-padding" style={{paddingTop:'60px', paddingLeft:'60px', paddingRight: '60px', paddingBottom: '0px' }}>
                    <div class="with-caption image-link image-zoom" data-dsn="parallax">
                        <Link class="single-image" to="assets/img/infographics/reduction-in-poverty.png">
                            <img src="assets/img/infographics/reduction-in-poverty.png" alt="" />
                        </Link>

                        <div class="caption" style={{color: '#000'}}>Share this : 

                             <Link to="#" style={{color: '#000'}}>
                                <i class="fab fa-facebook-f" style={{color: '#000'}}></i>
                            </Link>

                             <Link to="#" style={{color: '#000'}}>
                                <i class="fab fa-instagram" style={{color: '#000'}}></i>
                            </Link>
                            
                        </div>
                    </div>
                </div>


            </div>



          
        </div>








                                    </div>
                       

                                <Footer2 />

                        </div>
                    </div>
                </main>

            </>
    );

};

export default Infographic;