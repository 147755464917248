import React from 'react';

const WelfareBody = () => {
    return(
        <>
                     <div class="container section-margin">
            <div class="row">
                <div class="good-gov-inner-page-container">
                    
                    
                    <ul>
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>CMRHM: </span></h4>
                            <p>The Chamling administration launched an ambitious "Kutcha House Free Sikkim by 2020", of which the Chief Minister's Rural Housing Mission (CMRHM) was a major program. One storey buildings were built by the government for the poor at a cost of around 12 lakh each. Over 6000 houses were completed and 3500 houses were at different stages of completion in 2018-19. This unique mission has remained incomplete as his party did not get the mandate to form the government in 2019 but thousands of people were benefited by this unique scheme. This was the first time any state in India had undertaken such an ambitious project of providing housing.
</p>
                            <p>
                                The mission to provide housing for the people did not begin with CMRHM. In fact it started right in 1995. The rural housing scheme began in 1995 and 30 GCI sheets and Rs 20,000 were distributed to the public. These benefits were distributed to over 1 lakh homes. After this, a program began for building houses for the people with GCI roofs, totalling an amount of 3 lakhs each. The program then evolved to building houses with brick walls and GCI sheets at 5 lakhs. Over 30,000 houses were built under the 3 and 5 lakh schemes. After this, the CMRHM scheme was started with one floor RCC building, with the provision of extension in coming days, keeping in mind the future aspirations of the people. The SDF party began the work of building pucca houses for the poor right since 1995.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Land for the Landless:  </span></h4>
                            <p>According to this scheme, 1/4th acre of land were given to the landless. The government would buy the land and give to the landless or sukkumbasis and around 5000 beneficiaries received land under this scheme. </p>


                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>REDRH: </span></h4>
                            <p>Through the Reconstruction of Earthquake Damaged Rural Houses (REDRH) scheme, 7929 houses damaged in the earthquake of 2011 were to be reconstructed with a central allocation of Rs 389.83 crores under the prime Minister’s Special Relief Package from the Planning Commission.

</p>


                        </li>
                        
                        
                                                <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Nirmal Rajya :  </span></h4>
                            <p>The sanitation facilities were upgraded in all rural areas of Sikkim. The achievement in this front led Sikkim to be the first Nirmal Rajya in 2008.


</p>
</li>

                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Housing Projects : </span></h4>
                            <p>Housing projects were built at Rangpo and Gangtok at Sichey, providing housing free of cost for the poor. 

</p>

                        </li>
                        



                        
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Antyodaya Annadan Yojana (AAY):  </span></h4>
                            <p>According to this scheme, 35kg rice is distributed free of cost to beneficiaries per month per household. Furthermore, households with more than six members are entitled an additional 15 kgs of rice for free per month.

</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Mukhya Mantri Khadya Suraksha Abhyan (MMKSA): </span></h4>
                            <p>All households below the poverty line are entitled to 35kgs of rice per house hold per month at Rs 2 per kg. Households having more than 6 members are entitled to an additional 15kgs rice per month at the same subsidised rate.
</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>PENSION SCHEMES:  </span></h4>
                            <ul>
                                <li>
                                    <p>Old age pension - upto Rs 2000</p>
                                </li>
                                
                                <li>
                                    <p>Samajik Sewa Bhatta - Rs 1500</p>
                                </li>
                                
                                <li>
                                    <p>Unmarried woman pension - Rs 2000</p>
                                </li>
                                
                                <li>
                                    <p>Physically challenged - Rs 1500 </p>
                                </li>
                                
                                <li>
                                    <p>Daily wages for MR employees - Rs 300</p>
                                </li>
                                
                                <li>
                                    <p>Proud Mother scheme - 6000 after first baby, 10000 after second baby</p>
                                </li>
                            </ul>

                        </li>
                        
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Aid to farmers:   </span></h4>
                            <p>Seeds of various crops were provided free of cost as well as green houses, plants, planting material, saplings, milching cows, buffalos, heifers, fish for fishery scheme, piglets, goats, etc. Major towns have a Kisan Bazaar where Sikkimese farmers can sell their products directly. They do not have to pay any tax to the government. </p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Universal Inclusion Program:  </span></h4>
                            <p>In 2003, bank accounts were opened in the name of the mother of the household and 5 insurances of different types were also covered under this. 

</p>

                        </li>
                        
                        
                      
                        
                        
                    </ul>
                </div>
            </div>
          </div>
        </>
    );
};

export default WelfareBody;