import React from 'react';

const EnvironmentBody = () => {
    return(
        <>
                  <div class="container section-margin">
            <div class="row">
                <div class="good-gov-inner-page-container">
                    
                   
                    <ul>
                    <h4 style={{fontWeight: '700', color: '#000'}}>MAJOR ACHIEVEMENTS</h4>
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>First Organic Sikkim in the World: </span></h4>
                            <p>Sikkim was declared a 100% organic state by the Prime Minister of India Narendra Modi on 18.1.2016. In its organic journey which began in 2003 with a resolution passed in the Sikkim Legislative Assembly to turn Sikkim into a completely organic state, Sikkim has taken several steps from banning chemical fertilizers in 2003, institutionalising Sikkim Organic Mission, providing training and capacity building for farmers, to certifying 75,000 hectares of land as completely organic. What started with a vision has turned into a reality that has been globally recognised by esteemed organizations like Food and Agriculture Organization (FAO) of the United Nations, Rapunzel and IFOAM Organics. The organic brand has become a marker of Sikkim’s identity today.
</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Increase in Forest Cover:  </span></h4>
                            <p>Sikkim became the only state to have increased its forest cover  in the past two decades. The forest cover in the state increased to 47.34 per cent in 2013 from 43.95 per cent in 1993, the only state to achieve this.</p>


                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Ten Minutes to Earth: </span></h4>
                            <p>The 10 Minutes’ to Earth programme was conceived and launched by Pawan Chamling on 6th July 2009. The program was envisioned as a participatory plantation program in which all people of Sikkim are encouraged to plant saplings, dedicating just ten minutes towards mother earth.

</p>


                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Ban on Plastic and non-biodegradable materials: </span></h4>
                            <p>On 4.6.1998, single use plastic and non-biodegradable substances were banned in Sikkim. Sikkim became the first state in India to do so.

</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Khangchendzonga National Park (KNP):  </span></h4>
                            <p>The KNP was inscribed in 2016 as India’s first “Mixed World Heritage Site” on the UNESCO World Heritage List, fulfilling the criteria under both natural and cultural heritage.


</p>

                        </li>
                        
                        
                        
                        <p style={{color: '#000'}}>Here is a chronology of major green initiatives and policy decisions taken by the SDF government over the past 2.5 decades. </p>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>1995:  </span></h4>
                            <p>State Forest Policy on Environment, Forests & Land Use formulated.

</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>1995: </span></h4>
                            <p>Declared as ‘Harit Kranti Baras’ and 2000-2010 as ‘Harit Kranti Dashak’. Distribution of free seedlings and massive afforestation and reforestation programmes were conducted.

</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>1996:  </span></h4>
                            <p>(Notification 8th July) Declaration of Barsey Rhododendron Sanctuary for protection of wildlife and its environment. 
</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>1997:  </span></h4>
                            <p>Sikkim Non-biodegradable Garbage (Control) Act, which for the first time prohibited throwing of non-degradable garbage in public drains and sewerage. The Act was amended in 1998.

</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>1997:  </span></h4>
                            <p>Sikkim Prohibition of Smoking & Non-Smokers’ Health Protection Act, enacted to provide for the prohibition of smoking in places of public work or use and in public service vehicles in the State. 

</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>1998:  </span></h4>
                            <p>Sikkim Forest Department Reward Rules, enforced for grant of rewards to informers and non-gazetted officers in respect of offences reported, detected and acted upon by Forest Officers.

</p>

                        </li>
                        
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>1998:  </span></h4>
                            <p>Sikkim Forests (Compounding of Offences) Rules, notified compounding of offences with regard to seizure of forest produce and tools, carts vehicles etc.

</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>1998 (4th June):  </span></h4>
                            <p>Ban on plastics was enforced. 

</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>1998 (26th June):  </span></h4>
                            <p>Constitution of State Level Committee, Joint Forest Management Committee and Village Forest Protection Committee constituted for  protection of forests/plantations and Khasmal/Goucharan. 

</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>1998 (2nd September):  </span></h4>
                            <p>Entry fees to KNP & Wildlife Sanctuaries were revised.

</p>

                        </li>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>1999 (5th June):  </span></h4>
                            <p>Bulbuley Smriti Van inaugurated as a mass movement involving NGOs, general public from the level of villagers to State level functionaries for conservation of nature.
</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>1999:  </span></h4>
                            <p>Sikkim Transit of Timber & Other Forest Produce Rules, stipulates regulation of transit of forest produce by means of permits and checks at forest check-posts. The rules were amended in 2006.
</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>1999:  </span></h4>
                            <p>Pawan Chamling was voted as ‘The Greenest Chief Minister in the Country’ by ‘Down to Earth’, a science & environment fortnightly magazine. They lauded the Chief Minister saying “Your commitment to environment and sustainable development in the face of numerous economic and political hurdles is exemplary [and your]” efforts are an inspiration and example for rest of the CMs of this country”.</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2000:  </span></h4>
                            <p>Ban on cutting, lopping & collection of leaves of dhupi nagbeli & mosses for decoration purposes for public or private functions was imposed. 

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2000:  </span></h4>
                            <p>Sikkim Forests Cattle Trespass Rules, established cattle pounds in the interest of protection of forests from unauthorized grazing.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2000:  </span></h4>
                            <p>(30th April) Awards for Rajya Van Sangrakshan Evam Paryavaran Puraskar for promotion, development conservation and protection of environment & wildlife. 

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2000:  </span></h4>
                            <p>(3rd May) Display of signboards and hoardings by Government and private agencies/individuals on road reserves as publicity on forest, environment. 

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2000:  </span></h4>
                            <p>Sikkim Forests, Water Courses & Road Reserve (Preservation & Protection) Amendment Act. The amendments were with regard to the penalties imposed on defaulters.
                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2001:  </span></h4>
                            <p>Ban on collection of medicinal plants & non-timber forest produce for commercial Purposes was imposed.
                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2001:  </span></h4>
                            <p>Sikkim Private & Other Non- Forest Lands Tree Felling Rules, lays downs terms and conditions with regard to felling of trees.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2001:  </span></h4>
                            <p>Declaration of sacred peaks, caves, rocks, lakes, places of worship  hot springs etc. This was done for documentation of all sacred places of worship and institutions in Sikkim which are 100 years old and above. 
                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2001:  </span></h4>
                            <p>Ban on litter of plastic bags and containers in National Parks & Sanctuaries by people visiting these parks and sanctuaries was imposed. Tourists were also prohibited from carrying plastic bags and containers to these places.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2001 (22nd June):  </span></h4>
                            <p>Ban on Killing of Wild Animals. 
                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2001 (29th August):  </span></h4>
                            <p>Use of wood alternative such as pre-fabricated Iron trusses, doors, window frames, readymade panelling boards etc. in construction works within Sikkim in place of timber. 
                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2001:  </span></h4>
                            <p>Establishment of Bio-Diversity Park at Tendong. 

                            </p>

                        </li>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2002:  </span></h4>
                            <p>Formation of Eco-clubs in Schools. 
                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2002:  </span></h4>
                            <p>Reinforcement of ban on pan masala/gutka (first enforced in the State  in March 1995.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2002:  </span></h4>
                            <p>Sikkim Forest (Detection, Enquiry & Disposal of Forest Offences) Rules, formulated empowered the Inquiry Officer to conduct inquiry on and investigation for forest offences.
                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2002 (7th March):  </span></h4>
                            <p>Establishment of Eco-Development Committee for ensuring protection of forests, environment and bio-diversity. 
                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2002 (5th September):  </span></h4>
                            <p>Declaration of Pangolakha Wildlife Sanctuary. 

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2002 (19th September):  </span></h4>
                            <p>State Wildlife Advisory Board was established.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2004 (25th August):  </span></h4>
                            <p>MG Marg-Litter and Spit Free Zone-Notification. 

                            </p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2004 (12th February):  </span></h4>
                            <p>Registration of private nurseries as income generating  activities.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2005 (27th December):   </span></h4>
                            <p>Establishing of a High Level Task Force to study the issue of environmental management and suggest policies for their implementation. 

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2005:   </span></h4>
                            <p>Sikkim Ecology Fund & Environment Cess Act, to provide for the protection and improvement of environment, and appointment of environment cess collecting authorities.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2005 (1st February):   </span></h4>
                            <p>Introduction of Environmental Education in School- Circular.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2005:   </span></h4>
                            <p>The concept of establishing smriti vans was started for involving the general public at the village level on a large scale for conservation of nature. Trees could be planted in memory of loved ones to commemorate special occasions linked with them.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2005 (February):   </span></h4>
                            <p>Kitam Reserve Forest was declared  as Kitam Bird Sanctuary considering its geomorphological and avi-fauna importance.

                            </p>

                        </li>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2006 (24th February):    </span></h4>
                            <p>Launching of State Green Mission. State Level Green Mission Committee and Constituency Level Green Task Force constituted.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2006:    </span></h4>
                            <p>Sikkim Forests (Allotment of Areas for Quarrying of Sand & Stone) Rules,  lays down regulations for quarrying.
                            </p>

                        </li>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2006:    </span></h4>
                            <p>Sikkim Private & Other Non-Forest Lands Tree Felling Rules, laid down terms and conditions for felling trees in private holdings.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2006 (7th March): </span></h4>
                            <p>Sikkim State Biodiversity Board was established.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2006 (11th August):  </span></h4>
                            <p>Guidelines were laid for conservation of lakes in partnership with  Gram Panchayats and Pokhri Sanrakshan Samiti. 
                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2006 (10th February):  </span></h4>
                            <p>Appointment of Himal Rakshak. 
                            </p>

                        </li>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2006 (10th February):  </span></h4>
                            <p>Sikkim Wildlife (Regulation of Trekking) Rules for protection of wildlife and penalties for offenders.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2006 (28th March):   </span></h4>
                            <p>Establishment of Bio-diversity Agency and District Environment Committee to co-ordinate and implement activities relating to environmental awareness, education and information in the State.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2006 (14th September):    </span></h4>
                            <p>State Biological Diversity Rules. 
                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2007 (4th October):    </span></h4>
                            <p>Sikkim Forest (Grant of Permission to Enter Reserve Forests) Rules.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2007 (3rd July):    </span></h4>
                            <p>State Board for Wildlife.

                            </p>

                        </li>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2007 (26th September):    </span></h4>
                            <p>Glacier and Climate Change Commission. 

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2007 (4th October):    </span></h4>
                            <p>Rules framed for entering Reserve Forests.


                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2008 (19th February):     </span></h4>
                            <p>Chief Minister, Pawan Chamling participated in the Governor’s Global Summit in California, USA. He was one of the Signatories of Global Climate Solutions Declaration.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2008 (29th April):     </span></h4>
                            <p>Segregation of Biodegradable & Non-biodegradable Wastes in Shops-Notice. 

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2008:     </span></h4>
                            <p>Sikkim Non-biodegradable Garbage (Control)- Amendment Rules.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2008 (6th December):     </span></h4>
                            <p>Enforcement Squad was formed to conduct surprise checks for implementation of tobacco control formed. 

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2008 (6th December):     </span></h4>
                            <p>State Tobacco Control Cell established. 

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2008 (6th December):     </span></h4>
                            <p>District Tobacco Control Cell established to prohibit smoking in public places.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2008 (19th March):     </span></h4>
                            <p>Constitution of State Pollution Control Board. 

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2008 :     </span></h4>
                            <p>Temi tea garden became a fully organic tea estate.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2009 (6th July): </span></h4>
                            <p>10 Minutes’ to Earth, a novel and innovative programme was conceived and launched by the Chief Minister.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2010 (24th June): </span></h4>
                            <p>Declaration of Dates for observance of  Green Mission & 10 Minutes to Earth.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2010: </span></h4>
                            <p>Fees for mountaineering expeditions booked through registered travel agency and sponsored by Sonam Gyatso Mountaineering were given rebate.                       
                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2011 (26th November): </span></h4>
                            <p>Chief Minister’s Green School Rolling Trophy-cum-Green School State Awards constituted.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2012 (19th May): </span></h4>
                            <p>Eco-Tourism Policy of the State formed.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2013 (21st June):  </span></h4>
                            <p>Paryavaran Mahostav.
                            </p>

                        </li>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2014 (19th December): </span></h4>
                            <p>Ban on bursting of fire crackers. 
                            </p>

                        </li>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2015 (5th January):  </span></h4>
                            <p>Ban on burning of agricultural wastes etc.
                            </p>

                        </li>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2015 (22nd December):   </span></h4>
                            <p>Ban on use of diclofenac sodium.

                            </p>

                        </li>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>2016 (18th January): </span></h4>
                            <p>Sikkim was declared as the first and only organic farming state in the world

                            </p>

                        </li>
                        
                    </ul>
                </div>
            </div>
          </div>
        </>
    );
};

export default EnvironmentBody;