import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr14Feb2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                        <div class="header-single-post" data-dsn-header="project">
                            <div class="post-parallax-wrapper" data-overlay="3">
                                <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/SDF.jpeg" alt=""
                                    data-dsn-header="blog" data-dsn-ajax="img" />
                            </div>
                            <div class="container">

                                <div class="inner-box m-section">
                                    <div class="post-info">
                                        <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>14 Feb, 2021</a>
                                            <div class="blog-post-cat dsn-link">
                                                <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                            </div>
                                    </div>

                                    <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                                        Former Chief Minister and SDF President responds to questions about the arrest of two students of Geyzing colleges who were later called anti-nationals by the SKM spokesperson Mr Jacob Khaling
                                    </h3>
                                
                                </div>

                            </div>

                        </div>
                    </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#">
                                            <span>#OFOJ</span>
                                        </a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                   “Question: The Geyzing College students who were seeking an appointment with the HRDD Minister ended up encountering a legal hurdle as two of them were arrested by the Sikkim police. Moreover, in a shocking statement, the SKM spokesperson called them terrorists and anti-national and asked the Centre to investigate the issue. Now some critics say that the SDF doesn’t have any moral ground to condemn it as some students were manhandled by Sikkim police during the SDF governance. How would you respond to this?


                                </p>

                                

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> First of all, let me make a categorical statement here that calling students “anti-national and terrorists” is unprecedented in the history of Sikkim, unheard of and highly condemnable. The government chose to arrest students who sought an appointment with a Minister demanding development in their college and then calling them “anti-national” plainly indicates that this government knows nothing about the fundamental rights of citizens in a democratic country, let alone the dreams and aspirations of students. I have not been able to digest the fact that such an utterly reprehensible statement was made against students by the spokesperson of Sikkim’s ruling party. And what shocks me furthermore is that the government doesn’t feel the need to withdraw the statement, let alone apologise to the students. I, as a concerned citizen of this land, and a responsible politician, vehemently abhor and condemn this statement and demand the instant withdrawal of the statement and unconditional apology to the students. I congratulate the students who showed such exemplary courage and determination to stand for their rights against all odds. Our party and I stand with them for their rights, dignity and demand. I appeal to the people of Sikkim to stand in solidarity with them for their rights and dignity as well. I have always said and I will say it again that when injustice and lies are quietly accepted, they will be justified, thereby suppressing justice and truth.



                                </p>
                                
                                <div>
                                <img class="w-100" src="assets/img/pressrelease/14Feb.jpeg" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: '#000', fontSize: '14px'}}>
                                    The SKM government arrested Geyzing College students demanding development in their college campus and called them antinational. Calling students antinational is unprecedented in the history of Sikkim.	

                                </p>
                                </div>

                                <p style={{color: '#000'}}>
                                    Let me draw your attention to the increasing insecurity students have been facing under this government. The raising of black flags against the government by the students of Sikkim State University last year and the shameless favouritism shown in the admission of students in the Soreng BEd college recently for which students had to raise the issue publically are glaring examples of how students are being treated by this government. One of the prime duties of any government is to protect and nurture them. Ignoring their cries and compelling them to stage a dharna even for a simple appointment with a minister and then calling them anti-national has brought disrepute to Sikkim, the stain of which will remain forever.


                                </p>
                                 <p style={{color: '#000'}}>
                                    This state government doesn’t cease to shock us. Just a few days ago, 
                                    a man who goes by the nickname, Nabbey, openly threatened Arun Subba, 
                                    a student, in broad daylight in Gangtok. The man in question pounced on Arun, 
                                    tore off his mask and told him that his days were numbered and he would soon be gone. 
                                    Strangely, this barbaric incident took place just after Chief Minister Shri PS Goley 
                                    in his foundation speech had issued an open threat saying ‘PS Goley’s team is ready 
                                    for anyone who underestimates him and the SKM government’. Remember, Mr Arun Subba is one 
                                    of the most vocal students who has been raising several issues with the government. 
                                    This intolerance against a conscientious student shown by the government poses a serious 
                                    threat to the lives and future of our young people. I seriously condemn this shocking incident and ask 
                                    the administration to arrest the man in question and ensure complete safety for Mr Subba. 

                                </p>
                                 <p style={{color: '#000'}}>
                                   Now comparing this incident with the 2014 student rally during the SDF governance I do not believe is not fair. I do admit that the 2014 incident was extremely unfortunate. The students’ demand for an exam fee hike rollback should have been immediately and amicably dealt with. It was an internal matter of the college. The students first went to the college authorities and they were apparently asked to approach the HRDD. The students felt dissatisfied with the way their demand was dealt with and they decided to organize a peaceful protest. Soon, some supporters of the SKM party hijacked the protest. The vehicles of the Chief Judge and police officers were pelted with stones. The highway was blocked. There was a serious law and order situation and police had to intervene to bring the chaos under control. I was not in the state at the time. As soon as the matter was brought to my notice, I intervened and I declared that education would be free right up to the college level.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Let me reiterate that the students facing such a situation were unfortunate. 
                                   But do not compare it with the incident of raiding the place where students were peacefully 
                                   resting in the night, snatching their phones,  taking them into custody, imposing various IPCs on them, 
                                   calling them anti-national and terrorists and then intimidating them with investigations by Central agencies. 
                                   Do not understate the severity of the SKM government’s disrespect, hatred and injustice against students. 
                                   Do not distract public attention from such a serious matter of the injustice and bullying our hapless students 
                                   are facing under this aggressive government.”



                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr14Feb2021;