import React from 'react';
import {Link} from 'react-router-dom';

const OnPawanChamlingBody = () => {
    return(
        <>
                    <div class="container" style={{marginTop:'0px'}}>
        <div class="row">
            <div class="col-md-4">
                <div class="card-sl">
                    <div class="card-image">
                        <img
                            src="assets/img/on-pawan-chamling/Narendra-Damodar-Modi.png" />
                    </div>
               



<div class="container" style={{marginTop:'0px'}}>
   
    <div class="share-window">

        <div class="share-bar" style={{marginLeft:'25px'}}>
            <div class="trigger"><Link to="#"><i class="fab fa-facebook-f"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-twitter"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-pinterest-p"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-linkedin-in"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-whatsapp"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fas fa-paper-plane"></i></Link></div>
        </div>
    </div>
    <div class="share">
        <div class="trigger share-btn"><Link to="#"><i class="fas fa-plus"></i> Share</Link></div>
    </div>

</div>

                  




                </div>
            </div>
            
            


            <div class="col-md-4">
                <div class="card-sl">
                    <div class="card-image">
                        <img
                            src="assets/img/on-pawan-chamling/pranab-mukherjee.jpeg" />
                    </div>

                    

                    <div class="container" style={{marginTop:'0px'}}>
   
    <div class="share-window">

        <div class="share-bar" style={{marginLeft:'25px'}}>
            <div class="trigger"><Link to="#"><i class="fab fa-facebook-f"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-twitter"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-pinterest-p"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-linkedin-in"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-whatsapp"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fas fa-paper-plane"></i></Link></div>
        </div>
    </div>
    <div class="share">
        <div class="trigger share-btn"><Link to="#"><i class="fas fa-plus"></i> Share</Link></div>
    </div>

</div>


                </div>
            </div>






            <div class="col-md-4">
                <div class="card-sl">
                    <div class="card-image">
                        <img
                            src="assets/img/on-pawan-chamling/A.PJ.-ABDUL-KALAM.png" />
                    </div>

                    
                    <div class="container" style={{marginTop:'0px'}}>
   
    <div class="share-window">

        <div class="share-bar" style={{marginLeft:'25px'}}>
            <div class="trigger"><Link to="#"><i class="fab fa-facebook-f"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-twitter"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-pinterest-p"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-linkedin-in"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-whatsapp"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fas fa-paper-plane"></i></Link></div>
        </div>
    </div>
    <div class="share">
        <div class="trigger share-btn"><Link to="#"><i class="fas fa-plus"></i> Share</Link></div>
    </div>
 
</div>


                </div>
            </div>

        </div>  

    </div>
          
          
          
          
          
          
          <div class="container" style={{marginTop: '60px'}}>
        <div class="row">
            <div class="col-md-4">
                <div class="card-sl">
                    <div class="card-image">
                        <img
                            src="assets/img/on-pawan-chamling/K.C.-PANT.png" />
                    </div>
              



<div class="container" style={{marginTop:'0px'}}>
   
    <div class="share-window">

        <div class="share-bar" style={{marginLeft:'25px'}}>
            <div class="trigger"><Link to="#"><i class="fab fa-facebook-f"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-twitter"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-pinterest-p"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-linkedin-in"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-whatsapp"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fas fa-paper-plane"></i></Link></div>
        </div>
    </div>
    <div class="share">
        <div class="trigger share-btn"><Link to="#"><i class="fas fa-plus"></i> Share</Link></div>
    </div>

</div>

                  




                </div>
            </div>
            
            


            <div class="col-md-4">
                <div class="card-sl">
                    <div class="card-image">
                        <img
                            src="assets/img/on-pawan-chamling/Lise-Grande.png" />
                    </div>

                    

                    <div class="container" style={{marginTop:'0px'}}>
   
    <div class="share-window">

        <div class="share-bar" style={{marginLeft:'25px'}}>
            <div class="trigger"><Link to="#"><i class="fab fa-facebook-f"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-twitter"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-pinterest-p"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-linkedin-in"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-whatsapp"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fas fa-paper-plane"></i></Link></div>
        </div>
    </div>
    <div class="share">
        <div class="trigger share-btn"><Link to="#"><i class="fas fa-plus"></i> Share</Link></div>
    </div>

</div>


                </div>
            </div>






            <div class="col-md-4">
                <div class="card-sl">
                    <div class="card-image">
                        <img
                            src="assets/img/on-pawan-chamling/LK-ADvani.png" />
                    </div>

                    <div class="container" style={{marginTop:'0px'}}>
   
    <div class="share-window">

        <div class="share-bar" style={{marginLeft:'25px'}}>
            <div class="trigger"><Link to="#"><i class="fab fa-facebook-f"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-twitter"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-pinterest-p"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-linkedin-in"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-whatsapp"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fas fa-paper-plane"></i></Link></div>
        </div>
    </div>
    <div class="share">
        <div class="trigger share-btn"><Link to="#"><i class="fas fa-plus"></i> Share</Link></div>
    </div>

</div>


                </div>
            </div>


            


        </div>  
    </div>
          
          
          
          
                    <div class="container" style={{marginTop: '60px'}}>
        <div class="row">
            <div class="col-md-4">
                <div class="card-sl">
                    <div class="card-image">
                        <img
                            src="assets/img/on-pawan-chamling/MADHU-DANDAVATE.png" />
                    </div>
            



<div class="container" style={{marginTop:'0px'}}>
   
    <div class="share-window">

        <div class="share-bar" style={{marginLeft:'25px'}}>
            <div class="trigger"><Link to="#"><i class="fab fa-facebook-f"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-twitter"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-pinterest-p"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-linkedin-in"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-whatsapp"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fas fa-paper-plane"></i></Link></div>
        </div>
    </div>
    <div class="share">
        <div class="trigger share-btn"><Link to="#"><i class="fas fa-plus"></i> Share</Link></div>
    </div>

</div>

                  




                </div>
            </div>
            
            


            <div class="col-md-4">
                <div class="card-sl">
                    <div class="card-image">
                        <img
                            src="assets/img/on-pawan-chamling/mohini-giri.png" />
                    </div>

                    
                    <div class="container" style={{marginTop:'0px'}}>
   
    <div class="share-window">

        <div class="share-bar" style={{marginLeft:'25px'}}>
            <div class="trigger"><Link to="#"><i class="fab fa-facebook-f"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-twitter"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-pinterest-p"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-linkedin-in"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-whatsapp"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fas fa-paper-plane"></i></Link></div>
        </div>
    </div>
    <div class="share">
        <div class="trigger share-btn"><Link to="#"><i class="fas fa-plus"></i> Share</Link></div>
    </div>

</div>


                </div>
            </div>






            <div class="col-md-4">
                <div class="card-sl">
                    <div class="card-image">
                        <img
                            src="assets/img/on-pawan-chamling/P.V.-NARASIMHA-RAO.png" />
                    </div>

                    
                    <div class="container" style={{marginTop:'0px'}}>
   
    <div class="share-window">

        <div class="share-bar" style={{marginLeft:'25px'}}>
            <div class="trigger"><Link to="#"><i class="fab fa-facebook-f"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-twitter"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-pinterest-p"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-linkedin-in"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-whatsapp"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fas fa-paper-plane"></i></Link></div>
        </div>
    </div>
    <div class="share">
        <div class="trigger share-btn"><Link to="#"><i class="fas fa-plus"></i> Share</Link></div>
    </div>

</div>


                </div>
            </div>


            


        </div>  
    </div>
    
    
          
          
          
 
                    
          <div class="container" style={{marginTop: '60px'}}>
        <div class="row">
            <div class="col-md-4">
                <div class="card-sl">
                    <div class="card-image">
                        <img
                            src="assets/img/on-pawan-chamling/Ram-Jethmalani.png" />
                    </div>
            



<div class="container" style={{marginTop:'0px'}}>
   
    <div class="share-window">

        <div class="share-bar" style={{marginLeft:'25px'}}>
            <div class="trigger"><Link to="#"><i class="fab fa-facebook-f"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-twitter"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-pinterest-p"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-linkedin-in"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-whatsapp"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fas fa-paper-plane"></i></Link></div>
        </div>
    </div>
    <div class="share">
        <div class="trigger share-btn"><Link to="#"><i class="fas fa-plus"></i> Share</Link></div>
    </div>

</div>

                  




                </div>
            </div>
            
            


            <div class="col-md-4">
                <div class="card-sl">
                    <div class="card-image">
                        <img
                            src="assets/img/on-pawan-chamling/Shri-P.-Chidambaram.png" />
                    </div>

                    

                    <div class="container" style={{marginTop:'0px'}}>
   
    <div class="share-window">

        <div class="share-bar" style={{marginLeft:'25px'}}>
            <div class="trigger"><Link to="#"><i class="fab fa-facebook-f"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-twitter"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-pinterest-p"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-linkedin-in"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-whatsapp"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fas fa-paper-plane"></i></Link></div>
        </div>
    </div>
    <div class="share">
        <div class="trigger share-btn"><Link to="#"><i class="fas fa-plus"></i> Share</Link></div>
    </div>

</div>


                </div>
            </div>






            <div class="col-md-4">
                <div class="card-sl">
                    <div class="card-image">
                        <img
                            src="assets/img/on-pawan-chamling/SUNITA-NARAIN.png" />
                    </div>

                    
                    <div class="container" style={{marginTop:'0px'}}>
   
    <div class="share-window">

        <div class="share-bar" style={{marginLeft:'25px'}}>
            <div class="trigger"><Link to="#"><i class="fab fa-facebook-f"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-twitter"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-pinterest-p"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-linkedin-in"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-whatsapp"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fas fa-paper-plane"></i></Link></div>
        </div>
    </div>
    <div class="share">
        <div class="trigger share-btn"><Link to="#"><i class="fas fa-plus"></i> Share</Link></div>
    </div>

</div>


                </div>
            </div>


            


        </div>  
    </div>
          
          
          
          
                    <div class="container" style={{marginTop: '60px'}}>
        <div class="row">
            <div class="col-md-4">
                <div class="card-sl">
                    <div class="card-image">
                        <img
                            src="assets/img/on-pawan-chamling/K.R-NARAYANAN.png" />
                    </div>
         



<div class="container" style={{marginTop:'0px'}}>
   
    <div class="share-window">

        <div class="share-bar" style={{marginLeft:'25px'}}>
            <div class="trigger"><Link to="#"><i class="fab fa-facebook-f"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-twitter"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-pinterest-p"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-linkedin-in"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-whatsapp"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fas fa-paper-plane"></i></Link></div>
        </div>
    </div>
    <div class="share">
        <div class="trigger share-btn"><Link to="#"><i class="fas fa-plus"></i> Share</Link></div>
    </div>

</div>

                  




                </div>
            </div>
            
            


            <div class="col-md-4" style={{paddingBottom: '80px'}}>
                <div class="card-sl">
                    <div class="card-image">
                        <img
                            src="assets/img/on-pawan-chamling/ATAL-BIHARI-VAJPAYEE.png" />
                    </div>

                   

                    <div class="container" style={{marginTop:'0px'}}>
   
    <div class="share-window">

        <div class="share-bar" style={{marginLeft:'25px'}}>
            <div class="trigger"><Link to="#"><i class="fab fa-facebook-f"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-twitter"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-pinterest-p"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-linkedin-in"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fab fa-whatsapp"></i></Link></div>
            <div class="trigger"><Link to="#"><i class="fas fa-paper-plane"></i></Link></div>
        </div>
    </div>
    <div class="share">
        <div class="trigger share-btn"><Link to="#"><i class="fas fa-plus"></i> Share</Link></div>
    </div>

</div>


                </div>
            </div>





            


        </div>  
    </div>
        </>
    );
};

export default OnPawanChamlingBody;