import React from 'react';

const YInstitutionBody = () => {
    return(
        <>
                     <div class="container section-margin">
            <div class="row">
                <div class="good-gov-inner-page-container">
                    
                    
                    <ul>
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Nepali Literature and Cultural Research Centre at Geyzing: </span></h4>
                            <p>inaugurated by Pawan Chamling on 13th July 2011. The centre, spread across 26 acres includes a life sized statue of Aadikavi Bhanu Bhakra Acharya, a research centre, seminar hall and library.
</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Manan Kendra:  </span></h4>
                            <p>Manan Kendra, envisioned as a culture centre houses a 200 seater community hall. Manan Kendra was inaugurated on 11.7.2012. From July 2012-June 2016, the government of Sikkim collected 41 lakhs as revenue from various programs held at the centre.
</p>


                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Sikkim State Museum: </span></h4>
                            <p>With a sanctioned cost of Rs 14.43 crores, the Sikkim State Museum is set to be a first of its kind centre in Sikkim showcasing the life, culture and history of the state. It is the first State Museum of the state.

</p>


                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Statue of Chogyal Palden Thondup Namgyal: </span></h4>
                            <p>A life sized statue of Chogyal Palden Thondup Namgyal was built near Namgyal Institute of Tibetology, Deorali. It was unveiled on 4.4.2003.

</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Sub divisional libraries:  </span></h4>
                            <p>were set up at the four subdivisional offices with one librarian each. One at Chungthang in North district, Pakyong in East district, Rabongla in South district and Soreng in west district. This was done soon after the formation of the SDF government. 

</p>


                        </li>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Children’s library :  </span></h4>
                            <p>was set up in the State Central library in 2002.

</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                          <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Gyan Mandir:  </span></h4>
                            <p>Gyan Mandir, currently under construction, was started with the foundation stone being laid on 6.2.2018. The ambitious project is poised to be a centre of intellectual exchange with a massive library, space for art exhibition, a cultural centre and seminar halls. The project has the capacity to house at least 20 lakh volume of books, as well as an extensive e-library of all the latest journals, documents and manuscripts. The project will be a great resource for knowledge and research for the students and the general public of Sikkim.

</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                           <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>JUDICIAL INSITUTIONS:  </span></h4>
                            <ul>
                                <li>
                                    <p> Sikkim Lokayukta Act 2012 passed by the state government Sikkim SLA</p>
                                </li>
                                <li>
                                   <p> Sessions courts established at Mangan and Geyzing</p>
                                </li>
                                <li>
                                    <p>Judicial Magistrate Courts at all sub-divisional levels</p>
                                </li>
                            </ul>

                        </li>
                        
                      
                        
                      
                        
                        
                    </ul>
                </div>
            </div>
          </div>
        </>
    );
};

export default YInstitutionBody;