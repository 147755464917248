import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr8Aug2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/8Aug.jpg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>8 Aug, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and President of the SDF party has reminded the SKM government of its endless number of unfulfilled party and has condemned the politics of lies adopted by the SKM party. He has said that the PS Tamang led SKM party has replaced the Rule of Law with Rule of Lies
                           </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                   “Q. The SKM government has been widely criticised for their politics of lies. Mr Bhaichung Bhutia famously joked about SKM press conferences that they are nothing but an endless series of lies. What would you say about this politics of lying becoming more and more acceptable?

                                </p>

                                

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> Let the world know that Sikkim is being governed by the world’s champion liar. A bigger and more terrible liar has never been elected anywhere in the world in recent memory. Our current Chief Minister is a specimen of leadership that is founded in no principles and no morality. He is incapable of feeling ashamed of all his lies, deceit and failure. He has created history by inaugurating an Age of Lies in Sikkim politics. He has exemplified how telling lies can give you success. He changed the Rule of Law into the Rule of Lies and Prajatantra  into Premsingh-tantra. He started a new brand of politics called Goley-tantra. Let me define Goley-tantra. It is a form of politics of lies in which the chief tactic has been the exploitation of the people’s desperation and gullibility. He duped youth saying that there would be no unemployed youth in Sikkim after 100 days of SKM governance. He duped the LT communities by promising them the grant of seats within 10 days. He duped the Buddhist community by promising the visit of the Karmapa within 10 days. What happened to the promise of ST status for 12 left out communities? Had he not promised to ask his son to resign after he became MLA from Poklok Kamrang? The list of unfulfilled promises  is endless.



                                </p>

                                <img class="w-100" src="assets/img/pressrelease/8Aug.jpg" alt="" />
                                <br/>
                                <br/>

                                <p style={{color: '#000'}}>
                                    When people question him, he silences them with threats, victimisation and violence. Many joined the SKM party to prevent the transfer of their spouses and children. 

                                </p>
                                 <p style={{color: '#000'}}>
                                    The biggest achievements of the SKM govt have been the spread of lies, deceit, deception and inter-faith and inter-community hatred and mistrust. There is such a dearth of developmental projects that the Chief Minister eating corn on the roadside or aloo chewra becomes state headlines. His greatest ability is to hold his own umbrella and that too grabs headlines. That’s a good gesture but when that becomes the SKM party’s achievement to flaunt, we have reasons to worry.  

                                </p>
                                 <p style={{color: '#000'}}>
                                   If lies were generated in mines, the SKM party would have fully exhausted the mine. The party is led by a bunch of master-liars who speak lies with such confidence that if they say that they will give 30 thousand jobs in 100 days, the audience will assume that somebody is already knocking on their door with a job appointment order. They are the magicians of the dark art of deceit and preachers of lies. Seeking truth in their statements is like seeking gold in the gutter. 
I dare them to sleep with their party manifesto under their pillow and to start the day by reading it thoroughly. Do it every day. If you have a human heart, one day your lies written in that book will at least give you a sense of guilt. Remember that the people’s silence is not the acceptance of your lies. They laugh at your manifesto and press conferences and read and watch them for mere entertainment. The people of Sikkim are afraid of some numbers and phrases like - 10 days, 100 days, 30000, 10,000, five lakh annually, cancer hospital, Mintokgang, Puja financial aid, etc.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                  They say three things cannot be hidden long - the sun, the moon and the truth. It would be better for the SKM party to realise it.



                                </p>
                                
                                <p style={{color: '#000'}}>
                                    In the end, let me warn them that their lies are working for them but the impacts of their lies on Sikkim will haunt Sikkim for centuries to come. Sikkim had borne the brunt of their lies somehow but Sikkim cannot afford to any longer. 




                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                 Telling lies is a sin but accepting lies repeatedly is a greater sin. Sikkim is bearing the consequences of this sin. I hope the Sikkimese people will not continue to allow the SKM lies to prevail in future. We need to uproot the politics of lies and make Sikkim beautiful again.”



                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr8Aug2021;