import React from 'react';

const BiographyDidYouKnow = () => {
    return (
        <>
                            <section style={{background: '#eee'}}>
                    <div class="container">
                        <div class="row" style={{padding: '40px'}}>
                            <div class="col-md-12">
                                <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                    data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{textAlign: 'center', color: '#153493', fontWeight: '800',  
                                    paddingTop: '20px'}}>
                                    DID YOU KNOW?
                                </h2>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section style={{margin: '100px 0px'}}>
                    <div class="row">
                        <div class="col-md-12 biography-did-col" >
                             <h4 class="title" style={{color: '#153493', fontWeight: '800', textAlign:'center'}}>First political leader to go underground:</h4>
                                            <p class="description" style={{color: '#153493', marginTop:'10px', textAlign:'center', padding:'0px 180px'}}>
                                Pawan Chamling is the first political leader in the history of Sikkim to go underground. He was booked under the Terrorist and Disruptive Activities(Prevention) Act (TADA), on 18th June 1993 for by the Bhandari government. He remained underground till 22nd September 1993 when re-entered Sikkim after acquiring an anticipatory bail from the Supreme Court of India.
         </p>
                        </div>
                        <div class="col-md-12 biography-did-col" style={{marginTop: '50px'}} >
                            <h4 class="title" style={{color: '#153493', fontWeight: '800', textAlign:'center'}}>Won every election he has ever stood for: </h4>
                                            <p class="description" style={{color: '#153493', marginTop:'10px', textAlign:'center', padding:'0px 180px'}}>
                               Pawan Chamling has won every single election since 1982 when he was elected uncontested as the Panchayat of Yangang. Since then he has won Sikkim Legislative  elections of 1985, 1989, 1994, 1999, 2004, 2014, and in 2019, he won elections from both constituencies he had contested from – Namchi-Singithang and Poklok-Kamrang.         </p>
                        </div>
                        
                        <div class="col-md-12 biography-did-col" style={{marginTop: '50px'}}>
                            <h4 class="title" style={{color: '#153493', fontWeight: '800', textAlign:'center'}}>Only opposition leader in Sikkim Legislative Assembly: </h4>
                                            <p class="description" style={{color: '#153493', marginTop:'10px', textAlign:'center', padding:'0px 180px'}}>
                               In 1993, Pawan Chamling was the lone opposition in the Sikkim Legislative Assembly before he turned the tides of history by overthrowing the 14 yearlong government of Nar Bahadur Bhandari. As they often say, “history repeats itself”, Pawan Chamling today is again the only opposition leader in the Sikkim Legislative Assembly (SLA) with the remaining 31 MLAs as part of the SKM-BJP alliance. Perhaps history is awaiting to be made.
        </p>
                        </div>
                    </div>
                </section>
        </>
    );
};

export default BiographyDidYouKnow;