import React from 'react';

const BiographyBrand = () => {
    return(
        <>
                <section>
                    <div class="container">
                        <div class="row" style={{padding: '20px'}}>
                            <div class="col-md-12">
                                <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                    data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{textAlign: 'center', color: '#153493', fontWeight: '800',  paddingTop: '20px'}}>
                                    BRAND PAWAN CHAMLING

                                </h2>
                                
                                
                                <div class="items">
                                      <div class="item biography-item" style={{backgroundColor:'#153493', padding: '50px 130px'}}>
                                          
                                          <div class="row">
                                              <div class="col-md-1">
                                                  <div class="icon" alt="Check              icon" style={{fontSize: '45px', lineHeight: '55px', marginRight: '36px', color: '#fff'}}>✔</div>
                                              </div>
                                              <div class="col-md-11">
                                                   <div class="content">
                                          <h4 class="title" style={{color: '#fff', fontWeight: '800'}}>LONGEST SERVING CHIEF MINISTER OF INDIA :</h4>
                                            <p class="description" style={{color: '#fff', marginTop:'10px', fontSize:'14px'}}>
                                 Pawan Chamling is the longest serving Chief Minister in the history of India. On 28th April 2018, he broke the record previously held by Jyoti Basu, Chief Minister of West Bengal. This record was 

                                                 </p>
                                
                                
                                        </div>
                                              </div>
                                          </div>
                                          
                                          
                                          <div class="row">
                                              <div class="col-md-1">
                                                  <div class="icon" alt="Check              icon" style={{fontSize: '45px', lineHeight: '55px', marginRight: '36px', color: '#fff'}}>✔</div>
                                              </div>
                                              <div class="col-md-11">
                                                   <div class="content">
                                          <h4 class="title" style={{color: '#fff', fontWeight: '800'}}>POET, PUBLISHER AND LYRICIST: </h4>
                                            <p class="description" style={{color: '#fff', marginTop:'10px', fontSize:'14px'}}>
                                Pawan Chamling wrote his first poem ‘Veer ko Parichay’ or Introduction of the Valiant or at the age of 17 which was published in Kanchenjunga magazine in 1967. His revolutionary poems like ‘Mero Rato Bhaley’, ‘Sattah’,  ‘Aatma Swikriti’ are also well celebrated. He founded Nirman, a monthly Nepali literary magazine. He also established Nirman Prakashan which has published more than 400 books. He is a renowned lyricist with popular songs such as ‘Hazar juni samma’ and ‘Malai Sikkim Manparcha’.
            </p>
                                        </div>
                                              </div>
                                          </div>
                                          
                                          
                                          <div class="row">
                                              <div class="col-md-1">
                                                  <div class="icon" alt="Check              icon" style={{fontSize: '45px', lineHeight: '55px', marginRight: '36px', color: '#fff'}}>✔</div>
                                              </div>
                                              <div class="col-md-11">
                                                   <div class="content">
                                          <h4 class="title" style={{color: '#fff', fontWeight: '800'}}>ORGANIC LEADER: </h4>
                                            <p class="description" style={{color: '#fff', marginTop:'10px', fontSize:'14px'}}>
                                                Pawan Chamling is most well known for his environment friendly policies which has put Sikkim on the global map as a leader in the organic world. Some of Sikkim’s most celebrated achievements in this area are 100% organic state,  first state in India to ban single use plastic and grazing in forest area, Khangchendzonga National Park (KNP) as India’s first UNESCO Mixed Heritage Site, Ten Minutes to Earth which is a massive plantation drive program and perhaps the only state where forest cover has increased from 43.95% in 1993 to 47.34% in 2013.
                                            </p>
                                        </div>
                                              </div>
                                          </div>
                                          
                                          
                                          <div class="row">
                                              <div class="col-md-1">
                                                  <div class="icon" alt="Check              icon" style={{fontSize: '45px', lineHeight: '55px', marginRight: '36px', color: '#fff'}}>✔</div>
                                              </div>
                                              <div class="col-md-11">
                                                   <div class="content">
                                          <h4 class="title" style={{color: '#fff', fontWeight: '800'}}>ARCHITECT OF MODERN SIKKIM: </h4>
                                            <p class="description" style={{color: '#fff', marginTop:'10px', fontSize:'14px'}}>
                               Pawan Chamling has been the architect of Sikkim’s era of modernization, economic growth and industrialization. Under his leadership, Sikkim saw the steepest fall in poverty from 41.43% BPL in 1993 to 3.85% BPL in 2019, a rise in life expectancy by 11 years, democratization of education and health, rise of Sikkim as the first organic state in the world,  growth of Sikkim as a haven for multinational companies, a state where peace was its foremost brand and where basic minimum needs were fulfilled.
                                </p>
                                        </div>
                                              </div>
                                          </div>
                                          
                                          
                                          
                        
                                        
                                       
                                      </div>
                                      
                                  </div>

                            </div>
                        </div>
                    </div>
                </section>
        </>
    );
};

export default BiographyBrand;