import React from 'react';

const BiographyBanner = () => {
    return(
            <>
                 <div class="chunk" data-kind="volunteer-link" id="chunk-volunteer-link">
                        <div class="big-section-link work-with-us" id="work-with-us" 
                            style={{backgroundImage: 'url(assets/img/biography/biographybanner1.jpg)', width: '100%',
                            minHeight: '450px',
                            maxWidth: 'none',
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            backgroundSize: 'cover',
                            paddingTop: '150px'}}>
                          <h2 class="title jantaraj-title" style={{marginBottom:'50px',
                            fontSize: '80px'}}>"I am a soldier of democracy – Pawan Chamling"</h2>
                          
                        </div>
                        
                        
                    </div>
            </>
    );
};

export default BiographyBanner;