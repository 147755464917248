import React from 'react';
import {Link} from 'react-router-dom';

const GoodGovernanceBody = () => {
    return(
        <>
            <div class="container" style={{marginTop: '0px', marginBottom: '100px'}}>
  <main class="grid">
      <div class="row">
          <div class="col-md-3 col-xs-6 grid-inside">
               <article>
                  <Link to="/economy">
                  <div class="text">
                      <div class="good-governance-img">
                      <img class="img-responsive" src="assets/img/good-governance/Economy (1).png" />
                      </div>
                    <h3 style={{marginTop:'15px', marginBottom: '10px', color: '#000', fontWeight: '700', lineHeight:'22px', fontSize: '18px'}}>ECONOMY</h3>
                    <p style={{color: '#000', fontSize: '1rem', fontWeight: '500'}}>Under Pawan Chamling’s leadership, Sikkim saw the steepest fall in poverty... </p>
                  </div>
                  </Link>
                </article>
          </div>
          
          <div class="col-md-3 col-xs-6 grid-inside">
              <article>
                 <Link to="/health">
                  <div class="text">
                      <img src="assets/img/good-governance/Health (1).png" />
                    <h3 style={{marginTop:'15px', marginBottom: '10px', color: '#000', fontWeight: '700', lineHeight:'22px', fontSize: '18px'}}>HEALTH</h3>
                        <p style={{color: '#000', fontSize: '1rem', fontWeight: '500'}}>The 1002 bedded STNM hospital, second only to AIIMS, Delhi, is a multispecialty hospital built... 
                        </p>
                  </div>
                  </Link>
                </article>
          </div>
          
          <div class="col-md-3 col-xs-6 grid-inside">
              <article>
                 <Link to="/political-achievements">
                  <div class="text">
                      <img src="assets/img/good-governance/Political achievement (1).png" />
                    <h3 style={{marginTop:'15px', marginBottom: '10px', color: '#000', fontWeight: '700', lineHeight:'22px', fontSize: '18px'}}>POLITICAL ACHIEVEMENTS</h3>
                    <p style={{color: '#000', fontSize: '1rem', fontWeight: '500'}}>Under Pawan Chamling’s leadership, Sikkim has made several major political achievements ...  </p>
                   
                  </div>
                  </Link>
              </article>
          </div>
          
          <div class="col-md-3 col-xs-6 grid-inside">
              <article>
                 <Link to="/welfare">
                  <div class="text">
                      <img src="assets/img/good-governance/Welfare (1).png" />
                    <h3 style={{marginTop:'15px', marginBottom: '10px', color: '#000', fontWeight: '700', lineHeight:'22px', fontSize: '18px'}}>WELFARE</h3>
                    <p style={{color: '#000', fontSize: '1rem', fontWeight: '500'}}>The Chamling administration launched an ambitious "Kutcha House Free Sikkim by 2020"... </p>
                  </div>
                  </Link>
                </article>
          </div>
          
        
          
      </div>
   
        <div class="row">
              <div class="col-md-3 col-xs-6 grid-inside">
                  <article>
                 <Link to="/infrastructure">
                  <div class="text">
                      <img src="assets/img/good-governance/Infrastructure (1).png" />
                    <h3 style={{marginTop:'15px', marginBottom: '10px', color: '#000', fontWeight: '700', lineHeight:'22px', fontSize: '18px'}}>INFRASTRUCTURE</h3>
                    <p style={{color: '#000', fontSize: '1rem', fontWeight: '500'}}>In 1993, Sikkim had a road network of 1800 km which increased to 10,000 km...</p>
                  </div>
                  </Link>
                </article>
          </div>
          
          
          <div class="col-md-3 col-xs-6 grid-inside">
                 <article>
                 <Link to="/governance">
                  <div class="text">
                      <img src="assets/img/good-governance/grassroot governance (1).png" />
                    <h3 style={{marginTop:'15px', marginBottom: '10px', color: '#000', fontWeight: '700', lineHeight:'22px', fontSize: '18px'}}>GRASSROOTS GOVERNANCE</h3>
                    <p style={{color: '#000', fontSize: '1rem', fontWeight: '500'}}>The Janta Mela was a one of a kind program and probably first in the world...  </p>
                   
                  </div>
                  </Link>
                </article>
          </div>
          
          
          <div class="col-md-3 col-xs-6 grid-inside">
                   <article>
                     <Link to="/education">
                      <div class="text">
                          <img src="assets/img/good-governance/education (1).png" />
                        <h3 style={{marginTop:'15px', marginBottom: '10px', color: '#000', fontWeight: '700', lineHeight:'22px', fontSize: '18px'}}>EDUCATION</h3>
                        <p style={{color: '#000', fontSize: '1rem', fontWeight: '500'}}>Sikkim is the only state in India where education is free ...  </p>
                       
                      </div>
                      </Link>
                    </article>
          </div>
          
          
          <div class="col-md-3 col-xs-6 grid-inside">
                    <article>
                     <Link to="/tourism">
                      <div class="text">
                          <img src="assets/img/good-governance/tourism (1).png" />
                            <h3 style={{marginTop:'15px', marginBottom: '10px', color: '#000', fontWeight: '700', lineHeight:'22px', fontSize: '18px'}}>TOURISM</h3>
                        <p style={{color: '#000', fontSize: '1rem', fontWeight: '500'}}>Due to the thrust of the SDF government on tourism with the development of pilgrimage...  </p>
                       
                      </div>
                      </Link>
                    </article>
          </div>
          
          
          
          
          
        </div>
        
        <div class="row">
            <div class="col-md-3 col-xs-6 grid-inside">
                    <article>
                     <Link to="/women-empowerment">
                      <div class="text">
                          <img src="assets/img/good-governance/Women (2).png" />
                            <h3 style={{marginTop:'15px', marginBottom: '10px', color: '#000', fontWeight: '700', lineHeight:'22px', fontSize: '18px'}}>WOMEN</h3>
                        <p style={{color: '#000', fontSize: '1rem', fontWeight: '500'}}>This scheme was started on 7th Jan 2004 to encourage the girl child ...  </p>
                       
                      </div>
                      </Link>
                    </article>
          </div>
          
          <div class="col-md-3 col-xs-6 grid-inside">
                    <article>
                     <Link to="/youth">
                      <div class="text">
                          <img src="assets/img/good-governance/youth.png" />
                            <h3 style={{marginTop:'15px', marginBottom: '10px', color: '#000', fontWeight: '700', lineHeight:'22px', fontSize: '18px'}}>YOUTH</h3>
                        <p style={{color: '#000', fontSize: '1rem', fontWeight: '500'}}>This unique scheme was launched on 15.8.2017 to promote entrepreneurial...  </p>
                       
                      </div>
                      </Link>
                    </article>
          </div>
          
          <div class="col-md-3 col-xs-6 grid-inside">
                    <article>
                     <Link to="/environment">
                      <div class="text">
                          <img src="assets/img/good-governance/environment (1).png" />
                            <h3 style={{marginTop:'15px', marginBottom: '10px', color: '#000', fontWeight: '700', lineHeight:'22px', fontSize: '18px'}}>ENVIRONMENT</h3>
                        <p style={{color: '#000', fontSize: '1rem', fontWeight: '500'}}>Pawan Chamling led SDF government in Sikkim has created green milestones that has catapulted Sikkim ...  </p>
                       
                      </div>
                      </Link>
                    </article>
          </div>
          
          <div class="col-md-3 col-xs-6 grid-inside">
                    <article>
                     <Link to="/institution">
                      <div class="text">
                          <img src="assets/img/good-governance/Institution.png" />
                            <h3 style={{marginTop:'15px', marginBottom: '10px', color: '#000', fontWeight: '700', lineHeight:'22px', fontSize: '18px'}}>INSTITUTIONS</h3>
                        <p style={{color: '#000', fontSize: '1rem', fontWeight: '500'}}>Nepali Literature and Cultural Research Centre at Geyzing...  </p>
                       
                      </div>
                      </Link>
                    </article>
          </div>
          
          
          
        </div>
    
    
    {/* <div class="row">
        <div class="container" style={{marginTop: '0px', marginBottom: '100px'}}>
            <main class="grid">
                <div class="row">
                    <artical>
                        
                    </artical>
                </div>
            </main>
        </div>
    </div> */}
    

  </main>
</div>
        </>
    );
};

export default GoodGovernanceBody;