import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr13Sep2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/13Sep.png" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>13 Sep, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and the President of SDF party Shri Pawan Chamling advice the Sikkim Government to aim to become India’s first Covid Free State by way of massive vaccination programme across the State. He also asked the Government to make all the necessary preparation massively against the impending third wave of the Corona Virus.
                           
                           </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                    “ Question: The SKM’s  “Pariwartan” campaign brought about a change of guard in Sikkim. People voted for change. How have you been observing this CHANGE?


                                </p>

                               

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> I have been seeing a ground-breaking PARIWARTAN under the SKM governance from day one. If anyone says that the SKM government failed to deliver on their promise of PARIWARTAN, I disagree. Sikkim has been witnessing a series of changes.


                                </p>

                                <img class="w-100" src="assets/img/pressrelease/13Sep.png" alt=""/>
                                <br/>
                                <br/>

                                <p style={{color: '#000'}}>
                                    Let me list some of them:
1. Sikkim now has a “Chief Minister’s Team” over and above the cabinet that will ensure that no one underestimates the CM. There are teams for all purposes - burning effigies, threatening and assaulting vocal citizens, silencing their own vocal party cadres, conducting press conferences to abuse the opposition, etc.
 <br/><br/>
Apart from that, the government is now run by a handful of bureaucrats as per their whims and fancies. The CM, Ministers and Advisors are dancing to their tune like puppets. Is this not ‘pariwartan’? 
 

                                </p>
                                 <p style={{color: '#000'}}>
                                    2. The government is run through press conferences. In order to justify the government’s highhandedness and quell the people’s cry for justice, the government uses unions to conduct counter-press conferences. We strove for a united Sikkim. They divide and rule. Is that not ‘pariwartan’? 

                                </p>
                                 <p style={{color: '#000'}}>
                                   3. Students have lost their right to an education. Teachers have had to sit for dharna just for an emergency job extension. Taxi drivers have had to find alternative jobs. Many drivers are working as porters. Sikkim experienced massive revenue generation through the fining of drivers and increasing vehicle taxes. Compare that to our CMSS under which hundreds of youth in Sikkim got luxury taxies. Is that not ‘pariwartan’?




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   4. We made education free and for all. Now students have been expelled for wanting to meet the HRDD Minister. Can there be a more radical change than this?




                                </p>
                                
                                <p style={{color: '#000'}}>
                                    5. The gradual dilution of Sikkim’s global image of organic farming, cleanliness, green economy and peace. Just two years ago, Sikkim’s organic success was being talked about globally. Sikkim won the Future Policy Gold Award- the biggest award in the policy. No one is talking about organic Sikkim now. Sikkim is now witnessing a massive cutting down of trees and the timber is being supplied beyond Tista. People have been raising concerns about the declining image of Sikkim police now. Does no one see this ‘pariwartan’?




                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                 7. The dilution of Sikkim’s distinct identity. Concerned citizens of Sikkim have been expressing increasing worry about Sikkim losing her special identity provided under Article 173F. Is that not ‘pariwartan’?
 <br/><br/>
When the Chief Ministers of other states go out of their states on official tour, they feel proud. Things have come to such a pass that every time our present Chief Minister goes outside of Sikkim, there is fear, insecurity and suspicion among the people. What a ‘pariwartan’.



                                </p>
                                
                                <p style={{color: '#000'}}>
                                    8. Sikkim has now grabbed the national media attention in human trafficking, smuggling of hard drugs and the worst Covid management in the country. Isn’t that ‘pariwartan’?




                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                     
9. Despite the pandemic, Sikkim recorded the highest number of protests against the government in the last 2 years. The number of protests during two years under the SKM government is far more than the total number of protests under our 25 years of governance. This is one area where the SKM government has beaten the SDF government. I admit our defeat here. We granted major demands before people even came to us like the implementation of the Mandal Commission Report, Primitive Tribe Status for the Lepcha community, recognition of ethnic languages as official languages, OBC status for those left out of the state list, the introduction of ethnic languages in government schools across the state, free education up to the college level, a Buddhist University, Sanskrit Mahabidyalaya, colleges across the state, etc. The list is endless. There was no people-government conflict, no single dharna, no arrest for all of these major demands. But today, even to come to a government office, one has to risk crossing section 144. Isn’t that ‘pariwartan’?


                                </p>
                                <p style={{color: '#000'}}>
                                   10. The rule of law has been replaced with the rule of lies. This is the biggest change in Sikkim. Their election manifesto has been a compilation of lies, lies and more lies. We respected our election manifesto as a sacred book of the people’s trust. They consider it a playbook to fool innocent people. We can look at our five election manifestos with a deep sense of pride and satisfaction. They must look at their book with shamelessness and cruelty towards the people’s aspirations. To me, that had been one of the biggest ‘pariwartans’.   
                                </p>
                                
                                <p style={{color: '#000'}}>
                                   For the people who say that the SKM party failed to bring about pariwartan, I have a simple explanation. There is a vast difference between ‘the pariwatan the SKM leaders wanted” and “the pariwartan you, the innocent people wanted”.
                                   <br/>
1.     You must accept that the SKM party brought about the pariwartan they wanted. They changed Sikkim as per their desire and blueprint.
<br/><br/>
2.     If you want to see the “pariwartan that you desired”, then you have to look at the pages of the SKM party’s election manifesto - that is the only place it can be found.
   
                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr13Sep2021;