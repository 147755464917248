import React, {useEffect} from 'react';

import HeaderTimeline from "../components/nav/HeaderTimeline";



// import Footer3 from "../components/nav/Footer3";
import TimelineHeader from '../components/timeline/TimelineHeader';
import TimelineBody from "../components/timeline/TimelineBody";

import "../timeline.css";


const Timeline = () => {

   

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grid.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/timeline.js`);
        
    },[]);

    return(
            <>
                <HeaderTimeline />

               
                            <div class="timeline-container" id="timeline-1" style={{paddingTop: '200px'}}>
                                <TimelineHeader />
                                <TimelineBody />
                            </div>

                
                    


            </>
    );

};

export default Timeline;