import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr27June2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/Aug1.jpg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>27 June, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and the SDF President Shri Pawan Chamling explained how the SDF party’s inherent green policies and the politics of ecology gave rise to a number of innovative initiatives like Green Revolution Year,
                            
                           </h3>
                           <h3>
                           Green Revolution Decade, Smriti Van, Ten Minutes to Earth, Heritage Tree, Mit Tree and Tree Adoption and so on. The SDF party observed Ten Minutes to Earth and observed a web panel in which eminent environmentalist Ms Sunita Narain gave a keynote speech. Shri Chamling extends thanks to all his party colleagues, participating environmentalists and well-wishers.

                           </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                   “Q. How was the program 'Ten Minutes to Earth' born? How is it going after the SDF party lost the 2019 election ?


                                </p>

                                

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize: '22px', fontWeight: '700'}}>Ans:</span> Ten Minutes to Earth, a mass forestation program was born out of the wedlock of our concern for the environment and politics of the environment. Ours is a political party whose constitution includes environmental protection as a major political program. You will notice that all of our election manifestoes prioritized nature and environmental protection as major agenda items. I personally believe that the conservation of nature is supreme and holds the key to the conservation of the living world. My politics have been driven by this basic ideology. Our government designed and adopted an environment-friendly developmental model. “No development at the cost of the environment” was our mantra. We promoted clean power and clean industries. We closed down Sikkim Ferro Alloys Limited as it was causing huge pollution.  As well, pharmaceutical companies that breach environmental norms have been closed. We wanted to inculcate environment appropriate behaviour in Sikkim. We started the Smriti Van program with a view to making it a public movement. But it ended up becoming a government program. I had the vision of including every single citizen – from students to professors, from youth to retired professionals, from rural farmers to urban dwellers - people from all walks of life to be involved in this environment protection campaign. Ten Minutes to Earth is the outcome of that idea.



                                </p>
                                
                                <div>
                                <img class="w-100" src="assets/img/pressrelease/27June.jpeg" alt="" />
                                
                                <p style={{color: '#000', fontSize: '14px'}}>
                                    Celebrating Ten Minutes to Earth 2021 below Samdruptse, South Sikkim, with SDF party members	

                                </p>
                                </div>

                                <p style={{color: '#000'}}>
                                   While running the government in Sikkim, I was driven by a deep conviction and ambition that Sikkim can and must become a torchbearer in the politics of ecology. I wanted Sikkim to be at the forefront in proving to the world that development and environmental conservation can go together.  That led to the birth of several innovative initiatives like Green Revolution Year (Harit Kranti Varsha) 1996, the Green Revolution Decade 2000-2010, and the State Green Mission to ensure massive plantation work in coordination with the community. The mission was funded through the receipt of a 2% budget from the Public Works Department and Rural Management and Development Department as well as a 1% budget from all other Departments. We constituted a number of agencies, commissions and Acts from the State Environment Agency, Climate Change Commission, Sikkim Ecology Fund, Environment Cess Act to the River Course Development Project. All these were done to form a network of policy initiatives which would drive the development agenda in the state.


                                </p>
                                 <p style={{color: '#000'}}>
                                   Ten Minutes to Earth, Heritage Tree, Mit Tree (ceremonial friendship with trees) and Tree Adoption were all a part of our vision for a Green Sikkim becoming the torchbearer for a green world. Our innovations were not just political programs but the outworking of our deep commitment to the environment. The nation and the world were keeping a close watch. I was recognized as the Greenest Chief Minister of India in 1998. Sikkim became the only state to have recorded an increase in forest cover (an increase of 4%). We became the first Organic State in the entire world in 2015. Sikkim went on to win the Future Policy Gold award.
 

                                </p>
                                 <p style={{color: '#000'}}>
                                  Unfortunately, the state government under the SKM government chose to side-line all of these globally acclaimed policies and initiatives. Our organic state status is now shrouded in suspicion. The Ten Minutes to Earth, Tree Adoption and Mit Tree programs have all been dumped into the dustbin by the state government.



                                </p>
                                
                                <p style={{color: '#000'}}>
                                  However, the SDF party never gave up. We were able to organize a Ten Minutes to Earth program for the second year after being out of the government since 2919. This year our theme was Ten Minutes to Earth - Lessons for the Future. A number of people and some small children participated in plantation activities all across the state. I am happy to note that the well-wishers of the Ten Minutes to Earth program planted tree in countries like the USA (Boston) Nepal, Bhutan and Darjeeling. The message of the program have been conveyed in 20 countries of the world. The SDF party also organized a web panel which was participated in by eminent environmentalist, Ms Sunita Narain, Director General of the Center of Science and Environment who gave a keynote speech. She said, “What Sikkim has shown in 1998 and is important for us to showcase, even today, is not that the environment does not make for good development. In fact, what Sikkim showed very clearly is that the environment and development go together.” She also said that the SDF government stood for the conviction that for Sikkim, the basis for existence is the protection of the environment  - but not just the protection of it, but also to use the environment for the development of a prosperous economy without damaging it. It was more than encouraging to hear an evaluation of our eco-friendly policies from a globally acclaimed environmentalist like her.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                    Our panellists were Mr Amba Jamir, Vice President at Integrated Mountain Initiative (IMI) Mrs Usha Lachungpa, Sikkim's famed environmentalist and retired forester, Ms Archana Vaidya, Environment Lawyer and Dr Smriti Basnet, Glaciologist. All of them shared their well-researched concerns, experiences and extremely valuable suggestions. Mr Jamir recommended that Ten Minutes to Earth be promoted by the NEDA. Archana Vaidya proposed that Ten Minutes to Earth is a program worthy of national replication. Ms Vaidya and Mrs Lachungpa advocated for the continuation of the program at the Panchayati Raj Institution level for a wider and deeper reach. Dr. Basnet shared some valuable thoughts like exploring new ways to live a nature-friendly life and making ecotourism self-sustainable locally. I have taken note of the comments of all the panellists which I can’t mention right now due to a lack of space.



                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                  I take this opportunity to thank all of those distinguished guests. I also thank all my fellow Sikkimese who participated in the Ten Minutes to Earth program this year. My hearty thanks to my colleagues who made such a grand program a huge success. My special thanks to Mr. PD Rai, former MP and others for organizing and conducting the panel discussion beautifully.”



                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr27June2021;