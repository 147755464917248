import React from 'react';

const YouthBody = () => {
    return(
        <>
                      <div class="container section-margin">
            <div class="row">
                <div class="good-gov-inner-page-container">
                    
                    
                    <ul>
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Chief Minister's Start-up Scheme (CMSS) 2017: </span></h4>
                            <p>This unique scheme was launched on 15.8.2017 to promote entrepreneurial ventures and self-employment opportunities in the state. The Scheme provides financial contribution assistance of 35% of the project cost for financially viable/bankable projects for ventures that entail a total investment up to ₹ 20 lakh. Hundreds of Sikkimese youth were benefitted from this generous scheme. 

</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>One Family One Job 2018:  </span></h4>
                            <p>One Family One Job is one of the most innovative, pro-poor and bold decisions ever taken by a state government in India. Around 20,000 Sikkimese youth were provided with government jobs. The philosophy behind this scheme was to provide at least one government job to every Sikkimese family. Not every family has members who can crack competitive exams. He has promised jobs to 30,000 more youth of Sikkim if his party comes back to power.
</p>


                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Chief Minister Self Employment Scheme (CMSES) 2005: </span></h4>
                            <p>The Scheme was designed by the Sikkim Government to provide employment for setting up micro enterprises by unemployed youth. The loan of up to ₹10 lakh was provided for hundreds of youth who set up business in various fields like the service sector, agriculture  and allied, animal husbandry, technical trades, artisan and traditional occupations etc. The loan was interest free for the first two years from the date of disbursement. In 2017, all loans totalling more than 100 crores were written off. 

</p>


                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Exemption on Trade License Fee 2003: </span></h4>
                            <p>In order to promote entrepreneurship among the local youth, the state government exempted 50% of the total trade license fee. This is unique to Sikkim. This innovative and inclusive policy decision encouraged Sikkimese youth to venture into entrepreneurial world without worrying much about trade license fee. Sikkim saw a tremendous rise in entrepreneurship in the state.

</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Sports Infrastructure:  </span></h4>
                            <p>After setting up the Department of Sports and Youth Affairs in 1995, a strong focus was placed on sports infrastructure to provide opportunity for the youth of Sikkim to hone their skills in sports. From the transformation of Palzor Stadium to a turf field of international standards to Bhaichung Stadium, Soreng, Jorethang ang Geyzing stadiums to fields at every subdivisional, gymnasium halls and archery fields, SDF has placed great importance in expanding the sports infrastructure in Sikkim. 

</p>


                        </li>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>State Institute for Capacity Building:  </span></h4>
                            <p>The State Institute for Capacity Building (SICB), Karfectar, was inaugurated on 10th Dec 2009. The establishment of SICB was approved as per the Cabinet Memorandum No 417/GOS/DOP/CB-108/09 dated 17.11.2009. The institute was set up to provide training options for unemployed youth so that they may be able to explore career options post training. The SICB was established under the Directorate of Capacity Building which was announced on 26th March 2007 in the Sikkim Legislative Assembly.
</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Job Reservation: </span></h4>
                            <p>2% reservation for artists and sportsmen were allotted in government jobs to encourage youth of Sikkim in artistic and sports field.
</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Monetary Incentives for Athletes:  </span></h4>
                            <p>In order to encourage the youth of Sikkim to pursue their talent in the sports field, the SDF government instituted various monetary incentives for athletes who win medals in various major sports events. In the Olympics, 1 crore reward has been set for gold medallist, 50 lakhs for silver and 25 lakhs for bronze medallist. In the Asian Games, it is 20 lakhs for gold, 10 lakhs for silver medallist and 5 lakhs for bronze medallist. In the National games, 5 lakhs has been set for the gold, 3 lakhs for silver and 2 lakhs for bronze. Similarly, for  Junior National Championships, 50 thousand for gold, 30 thousand for silver and 20 thousand for bronze. 

</p>

                        </li>
                        
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                           <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Youth hostel built in Gangtok and Namchi</span>
</h4>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Taxi permit: </span></h4>
                            <p>Taxi permit to the unemployed youth on a priority basis. </p>
                           
                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                           <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Contractor enlistment: </span></h4>
                           <p>Contractor enlistment to the educated unemployed youth on a priority basis.


</p>

                        </li>
                        
                        
                      
                        
                        
                    </ul>
                </div>
            </div>
          </div>
        </>
    );
};

export default YouthBody;