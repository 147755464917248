import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr30May2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/Aug1.jpg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>30 May, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and the SDF President Shri Pawan Chamling asks the government to form a committee consisting of professionals to study losses and grievances of students and plan to vaccinate students on a priority basis with a goal to open schools for students in a phased manner. He also shared other suggestions with the government regarding the problems faced by students due to the Covid pandemic
                           </h3>
                           
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                 “Q. The Covid pandemic has ravaged education like never before. The loss that the students have had to bear is crushing. What would you like to tell the government as part of your suggestion? Can the state government do more than what is being done?

                                </p>


                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> Let me begin by saying that the loss faced by students has been the heaviest of all after the loss of life. Many are losing jobs, health, income, business, freedom to go out, an opportunity to enjoy life, etc. If our life is not lost, we can regain them to some extent. But our students’ future is being robbed of them. I am concerned about our students who are our future. There is no more powerful weapon than education that empowers individuals and yet that very education is eluding these students. The whole generation is facing a massive, unprecedented and damaging loss. There is a saying, the loss faced by one generation can have an impact on five subsequent generations.

                                </p>
                                
                                <div>
                                <img class="w-100" src="assets/img/pressrelease/EmptyClassroom.jpeg" alt="" />
                                <p style={{color: '#000', fontSize: '14px'}}>
                                    A whole generation of young people in Sikkim facing the brunt of the pandemic.	

                                </p>
                                </div>

                                <p style={{color: '#000'}}>
                                    I would like to put forward the following suggestions:
                                    <br/>
A.      The state government must constitute a committee to study the losses faced by students and their grievances. The committee, unlike the Economic Revival Committee that was formed earlier, must comprise dedicated and able professionals. The losses and grievances faced by students are complicated and need serious study. The committee can come up with actionable recommendations as soon as possible. It is unfortunate that the state government has been directionless.


                                </p>
                                 <p style={{color: '#000'}}>
                                    B.      Right now, I am thinking of those students from class X and above who are in that critical juncture of life in terms of shaping their future. The state government has to prioritize the immediate vaccination of students from X and above within the next one month with an aim to reopen schools for them from July. Lower classes can be reopened in a phased manner. The Government of India will soon be procuring Pfizer which is the only tested vaccine for young people from 12 and above. The Sikkim Government must start lobbying with the Centre to get Pfizer for Sikkim students from Class X and above. It is possible.


                                </p>
                                 <p style={{color: '#000'}}>
                                   C.      The HRDD has to form a team comprising able academicians, educationists and teachers to devise ‘remedial strategies’ to help students cope with this unprecedented situation. Even when regular classes restart, many students will need remedial classes. Remedial classes have to be professionally designed to help students compensate for the losses they incurred during online classes.



                                </p>
                                
                                <p style={{color: '#000'}}>
                                  D.      The Sikkim Government must direct the mobile phone network operators in the state to ensure better connectivity in the state. Students have been complaining of poor connectivity which is hampering their online classes. Many students have either never joined or stopped joining online classes due to poor connectivity. They have been complaining for one year and the government hasn’t listened to their cries. 


                                </p>
                                
                                <p style={{color: '#000'}}>
                                    E.       The government must initiate a process to start assessing the mental health issues, particularly of students. Psychologists all around the world have been asking governments to take this issue seriously. We have a huge responsibility to help students cope with Covid-pandemic related mental stress and all levels of depression. I suggest the government to form a team of able psychological professionals and doctors.   



                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                  It is very sad to note that the government is ignoring any advice from outside the government. Their arrogance is aggravating the pandemic. Our advice mustn’t be taken politically. This is advice for the welfare of our people. The government must be humble enough to accept the mistakes that have happened and embrace good suggestions. We should be willing to do anything that will improve the situation. Remember, right now Sikkim is the ONLY state in a red zone.”


                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr30May2021;