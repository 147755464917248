import React from 'react';
import {Link} from 'react-router-dom';

const cardBooksWrap = {
    transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
    transformStyle: 'preserve-3d'
};

const cardBook2 = {
    transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', 
    transformStyle: 'preserve-3d'
};

const cardBook3 = {
    transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', 
    transformStyle: 'preserve-3d'
};

const HomeReadingList = () =>{

    return(
        <>

        
            

<section class="section bg-beige-dark reading-list" style={{backgroundColor: '#eee'}}>
   <div class="container">
      <div class="section-title">
         <h3 class="h2" style={{color: '#000'}}>Reading List </h3>
      </div>
      <div class="w-dyn-list">
         <div role="list" class="grid-categories grid-categories-books w-dyn-items">
            <div role="listitem" class="w-dyn-item">
               <Link to="/speeches" class="card-wrap card-wrap-books w-inline-block">
                  <img src="assets/img/icons/speech-icon.svg" alt="biographies &amp; memoirs" class="card-icon card-icon-books" />
                  <h4 class="h4" style={{color: '#000'}}>Speeches</h4>
                  <div class="card-books-wrap" style={cardBooksWrap}>
                      
                      <img data-w-id="158ddf0a-6085-2fa7-748a-4f9eaf9bb9aa" src="assets/img/books/SPEECH-BACK.png" alt="Shoe Dog by Phil Knight" class="card-book _2" style={cardBook2} />
                      <img data-w-id="88394e29-6a63-e5ff-2eda-206158c73594" src="assets/img/books/SPEECH-FRONT.png" alt="Educated by Tara Westover" class="card-book _3" style={cardBook3} />
                      </div>
               </Link>
            </div>
            <div role="listitem" class="w-dyn-item">
               <Link to="/gallery" class="card-wrap card-wrap-books w-inline-block">
                  <img src="assets/img/icons/gallery-icon.svg" alt="business &amp; management" class="card-icon card-icon-books" />
                  <h4 class="h4" style={{color: '#000'}}>Gallery</h4>
                  <div class="card-books-wrap" style={cardBooksWrap}>
                      <img data-w-id="158ddf0a-6085-2fa7-748a-4f9eaf9bb9aa" src="assets/img/books/GALLERY-BACK (1).png" alt="Loonshots by Safi Bahcall" class="card-book _2" style={cardBook2} />
                      <img data-w-id="88394e29-6a63-e5ff-2eda-206158c73594" src="assets/img/books/GALLERY-FRONT (1).png" alt="Zero to One by Peter Thiel and Blake Masters" class="card-book _3" style={cardBook3} />
                     
                      </div>
               </Link>
            </div>
            <div role="listitem" class="w-dyn-item">
               <Link to="/infographic" class="card-wrap card-wrap-books w-inline-block">
                  <img src="assets/img/icons/infographics-icon.svg" alt="design" class="card-icon card-icon-books" />
                  <h4 class="h4" style={{color: '#000'}}>Infographics</h4>
                  <div class="card-books-wrap" style={cardBooksWrap}>
                      <img data-w-id="158ddf0a-6085-2fa7-748a-4f9eaf9bb9aa" src="assets/img/reading-list/infographic-back.png" alt="How to by Michael Bierut" class="card-book _2" style={cardBook2} />
                      <img data-w-id="88394e29-6a63-e5ff-2eda-206158c73594" src="assets/img/reading-list/infographic-front.png" alt="Grid Systems in Graphic Design by Josef Müller-Brockmann" class="card-book _3" style={cardBook3} />
                     
                      </div>
               </Link>
            </div>
            <div role="listitem" class="w-dyn-item">
               <Link to="/books" class="card-wrap card-wrap-books w-inline-block">
                  <img src="assets/img/icons/books-icon.svg" alt="fiction" class="card-icon card-icon-books" />
                  <h4 class="h4" style={{color: '#000'}}>Books</h4>
                  <div class="card-books-wrap" style={cardBooksWrap}>
                      <img data-w-id="158ddf0a-6085-2fa7-748a-4f9eaf9bb9aa" src="assets/img/reading-list/book-back.png" alt="" class="card-book _2" style={cardBook2} />
                      <img data-w-id="88394e29-6a63-e5ff-2eda-206158c73594" src="assets/img/reading-list/book-front.png" alt="" class="card-book _3" style={cardBook3} />
                  
                      
                      </div>
               </Link>
            </div>
            <div role="listitem" class="w-dyn-item">
               <Link to="/quotes" class="card-wrap card-wrap-books w-inline-block">
                  <img src="assets/img/icons/quotes-icon.svg" alt="health &amp; fitness" class="card-icon card-icon-books" />
                  <h4 class="h4" style={{color: '#000'}}>Quotes</h4>
                  <div class="card-books-wrap" style={cardBooksWrap}>
                      <img data-w-id="158ddf0a-6085-2fa7-748a-4f9eaf9bb9aa" src="assets/img/reading-list/quotes-book-back.png" alt="" class="card-book _2" style={cardBook2} />
                      <img data-w-id="88394e29-6a63-e5ff-2eda-206158c73594" src="assets/img/reading-list/quote-book-front.png" alt="" class="card-book _3" style={cardBook3} />
                    
                      </div>
               </Link>
            </div>
            <div role="listitem" class="w-dyn-item">
               <Link to="#" class="card-wrap card-wrap-books w-inline-block">
                  <img src="assets/img/icons/write-to-pc-icon.svg" alt="non-fiction" class="card-icon card-icon-books" />
                  <h4 class="h4" style={{color: '#000'}}>Write To PC</h4>
                  <div class="card-books-wrap" style={cardBooksWrap}>
                      <img data-w-id="158ddf0a-6085-2fa7-748a-4f9eaf9bb9aa" src="assets/img/reading-list/write-to-pc-back.png" alt="" class="card-book _2" style={cardBook2} />
                      <img data-w-id="88394e29-6a63-e5ff-2eda-206158c73594" src="assets/img/reading-list/write-to-pc-front.png" alt="" class="card-book _3" style={cardBook3} />
                      </div>
               </Link>
            </div>
            <div role="listitem" class="w-dyn-item">
               <Link to="/press-releases" class="card-wrap card-wrap-books w-inline-block">
                  <img src="assets/img/icons/news-icon.svg" alt="science &amp; nature" class="card-icon card-icon-books" />
                  <h4 class="h4" style={{color: '#000'}}>News</h4>
                  <div class="card-books-wrap" style={cardBooksWrap}>
                      <img data-w-id="158ddf0a-6085-2fa7-748a-4f9eaf9bb9aa" src="assets/img/reading-list/news-back (4).png" alt="" class="card-book _2" style={cardBook2} />
                      <img data-w-id="88394e29-6a63-e5ff-2eda-206158c73594" src="assets/img/books/NEWS-FRONT (1).png" alt="" class="card-book _3" style={cardBook3} />
                      
       
                      
                      </div>
               </Link>
            </div>
            <div role="listitem" class="w-dyn-item">
               <Link to="#" class="card-wrap card-wrap-books w-inline-block">
                  <img src="assets/img/icons/newsletter-icon.svg" alt="technology" class="card-icon card-icon-books" />
                  <h4 class="h4" style={{color: '#000'}}>Subscribe To Newsletter</h4>
                  <div class="card-books-wrap" style={cardBooksWrap}>
                      <img data-w-id="158ddf0a-6085-2fa7-748a-4f9eaf9bb9aa" src="assets/img/reading-list/newsletter-back.png" alt="" class="card-book _2" style={cardBook2} />
                      <img data-w-id="88394e29-6a63-e5ff-2eda-206158c73594" src="assets/img/reading-list/newsletter-front.png" alt="" class="card-book _3" style={cardBook2} />
                   
                      </div>
               </Link>
            </div>
         </div>
      </div>

   </div>
</section>
        </>
    );
};

export default HomeReadingList;