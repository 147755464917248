import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import Header2 from "../components/nav/Header2";


import Footer2 from "../components/nav/Footer2";

import "../style.css";
import "../widgets.css";
import "../responsive.css";



const TextSpeeches = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">
                        <div class="wrapper">

                                <div class="root-contact">
                                    
                                    {/* Header */}
                <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                    <div class="container" style={{paddingBottom: '50px'}}>
                        <div class="row ">
                            <div class="col-lg-12">
                                <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                    <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                        data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                        Text Speeches 
                                    </h2>

                                    <p class="infographic-page-header-desc" style={{fontSize: '1rem', fontWeight: '500', maxWidth:'100%', color:'#000', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px'}}>
                                    A collection of speeches delivered by Pawan Chamling.
                                    </p>


                                </div>
                            </div>

                        </div>
                    </div>

                </section>


        {/* Body */}

   
        <section class="trending pt-65 pb-65 position-relative">
            <div class="container">
                <div class="row">
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" 
                            style={{backgroundImage: 'url(assets/img/pHOTO01.jpg)' }}>
                                <Link class="img-link" to="/independence-day"></Link> 
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-1 shadown-1 text-dark button-shadow hover-up-3"
                                    style={{ backgroundColor: 'rgb(53, 89, 199)' }}>2004</Link> 
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background:' #fff',
    borderRadius: '0 0 10px 10px' }}>
                                <div class="post-card-content">
                                    <h5 class="post-title font-md" style={{color: '#000'}}>
                                        <Link to="/independence-day">58th Independence Day Message</Link> 
                                    </h5>
                                    <p style={{ fontSize: '1rem' }}>Speech delivered on 15th August 2004 in Gangtok, Sikkim.</p>
                                </div>
                            </div>
                        </div>
                    </article>

                   <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" 
                            style={{backgroundImage: 'url(assets/img/text-speech-4.jpeg)'}}>
                                <Link class="img-link" to="/republic-day"></Link> 
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-1 shadown-1 text-dark button-shadow hover-up-3">2012</Link> 
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background:' #fff',
    borderRadius: '0 0 10px 10px' }}>
                                <div class="post-card-content">
                                    
                                    <h5 class="post-title font-md" style={{color: '#000'}}>
                                        <Link to="/republic-day">63rd Republic Day</Link> 
                                    </h5>
                                    <p style={{ fontSize: '1rem' }}>Gangtok, January 26, 2012</p>
                                </div>
                            </div>
                        </div>
                    </article>

                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pHOTO03.jpg)'}} 
                            >
                                <Link class="img-link" to="/state-day"></Link> 
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-1 shadown-1 text-dark button-shadow hover-up-3">2013</Link> 
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background:' #fff',
    borderRadius: '0 0 10px 10px' }}>
                                <div class="post-card-content">
                                    
                                    <h5 class="post-title font-md" style={{color: '#000'}}>
                                        <Link to="/state-day">38th State Day Message</Link> 
                                    </h5>
                                    <p style={{ fontSize: '1rem' }}>Gangtok May 16, 2013</p>
                                </div>
                            </div>
                        </div>
                    </article>

                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pHOTO04.jpg)'}}
                            >
                                <Link class="img-link" to="/planning-commission-meeting"></Link> 
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-1 shadown-1 text-dark button-shadow hover-up-3">2013</Link> 
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background:' #fff',
    borderRadius: '0 0 10px 10px' }}>
                                <div class="post-card-content">
                                    <h5 class="post-title font-md" style={{color: '#000'}}>
                                        <Link to="/planning-commission-meeting">Planning Commission Meeting</Link> 
                                    </h5>
                                    <p style={{ fontSize: '1rem' }}>Yojana Bhawan, New Delhi April 03, 2013</p>
                                </div>
                            </div>
                        </div>
                    </article>

                   
                   
                </div>
            </div>
        </section>








                                    </div>
                       

                                <Footer2 />

                        </div>
                    </div>
                </main>

            </>
    );

};

export default TextSpeeches;