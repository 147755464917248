import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr25April2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/RamRajya.jpeg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>25 April, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and SDF President Shri Pawan Chamling replying to a question about the quality of governance in the state says that the SKM government is not only inefficient but it is all cruel which is more dangerous. He said that every section of the Sikkimese population feels betrayed. False promises and merciless acts of expelling students, threatening government employees and silencing concerned voices have caused a huge loss for Sikkim and Sikkimese people.
                           </h3>
                           
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#">
                                            <span>#OFOJ</span>
                                        </a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                                    <p>
                                    “ Q. As the lone opposition MLA in the Sikkim Legislative Assembly, how do you rate the present state government? What do you think are the biggest benefits and losses for Sikkim under this government?
                                    </p>
                                
                               


                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> The level of satisfaction among the people of Sikkim regarding the quality of governance has reached its lowest point in Sikkim history. There is widespread frustration, dissatisfaction and anguish in people from all walks of life. The villagers feel betrayed. The city and town dwellers fell neglected. The housewives who were promised a 5 lakh grant per year feel betrayed. The unemployed youth who were promised 30 thousand jobs and a 10  thousand rupees monthly unemployment allowance feel deceived. The OFOJ, MR and Adhoc employees who were promised regularization of their jobs in the first 100 days of their governance feel cheated. The government employees who receive open threats in public from the Chief Minister himself feel terrorized and humiliated. The entrepreneurs who have had to deal with the government’s policies that have benefited the business people from outside feel discouraged. You remember the recent chicken rate fiasco. The drivers who were promised stars and were given nothing feel grassed. The media are being arm-twisted. The fear of victimization of family members is rampant among those who question the government. Tell me one section of the Sikkimese population who feels satisfied with the performance of this government. There is just a handful who cozy up to the CM along with the Ministers and MLAs who are garnering benefits in every possible way.



                                </p>
                                
                                <div>
                                    <img class="w-100" src="assets/img/pressrelease/RamRajya.jpeg" alt="" />
                                    <p style={{color: '#000', fontSize: '14px'}}>
                                        In SKM's Ram Rajya, students fighting for their rights get expelled from their college	

                                    </p>
                                </div>

                                <p style={{color: '#000'}}>
                                    Let me add that this government is not only inefficient, but it is also an extremely cruel regime. Cruelty is more dangerous than inefficiency. Just to give you an example, any person would cry if he or she saw someone missing the opportunity to go to school. But this government has so mercilessly expelled four students from their college. Anybody would flinch to think about the death of others - but the head of this government openly brags about “his team” and his ability to use “another pen”. That is the version of Ram Rajya he had promised. I wonder which Ramayan he studied. Our Chief Minister talking about peace and security is a joke. What right does the one who habitually threatens people, have to talk about peace and security? Who will believe such hypocrisy?


                                </p>
                                 <p style={{color: '#000'}}>
                                    In a democracy, there are two things which are called democratic dividends and democratic deficits. When democracy flourishes, everyone benefits. The state enjoys a steadily growing economy, peace, equality of opportunity, social harmony and the overall enhancement of human value in general. These are democratic dividends. Sikkim enjoyed optimum democratic dividends under the SDF governance. 

                                </p>
                                 <p style={{color: '#000'}}>
                                  However, when the government becomes inefficient, everyone loses. Peace and liberty become the victims of poor governance. The government restricts the freedom of the people and media to express their opinions in order to hide the government's inefficiency. Why does the government send dozens of people to conduct press conferences when concerned and responsible citizens raise their concerns against the government’s wrongdoings? Instead of lecturing the media, they should focus on governance and address the people’s issues. Instead of expressing their anger and frustration in public, they should humbly admit their mistakes and amend them.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Behavioural economics is the study of the psychological factors behind the decisions made by individuals. For example, when a person loses, let's say 100 rupees, its value suddenly seems higher than he had reckoned when the money was not lost. We know the real value of something only when we lose it. That is exactly what is happening right now in Sikkim. The very people who took the SDF government for granted have now begun to see the real value of it. They are missing us. Everywhere I go, people say that. 



                                </p>
                                
                                <p style={{color: '#000'}}>
                                    People call me and send messages to me saying that now they know what a big loss it was to vote the SDF out of government. To me, that is behavioural politics.”




                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr25April2021;