import React from 'react';

const ConstituencyBody = () => {
    return(
        <>
          <div class="table_wrapper" style={{textAlign: 'center'}}>
            
            <table id="table_medals" >
              <thead style={{padding: '10px'}}>
                  <tr >
                      <th id="medal_rank" class=" active" style={{padding: '15px'}}>Year</th>
                      <th id="medal_team" class="">Constituency</th>
                      <th id="medal_gold">Political Party</th>
                      <th id="medal_silver">Result</th>
                      <th id="medal_bronze">Position</th>
                      <th id="medal_total" class="">Votes</th>
                      <th id="medal_total" class="">% Votes</th>
                </tr>
            </thead>
              <tbody>
                  <tr class="">
                      <td class="medal_rank">1985</td>
                      <td class="medal_team">Damthang</td>
                      <td class="medal_gold">SSP</td>
                      <td class="medal_silver">Won</td>
                      <td class="medal_bronze">1st/8</td>
                      <td class="medal_total">2,281	</td>
                      <td class="medal_total">73.79	</td>
                   </tr>
                   
                   
                   <tr class="">
                      <td class="medal_rank">1989</td>
                      <td class="medal_team">Damthang</td>
                      <td class="medal_gold">SSP</td>
                      <td class="medal_silver">Won</td>
                      <td class="medal_bronze">1st/2</td>
                      <td class="medal_total">4,227	</td>
                      <td class="medal_total">94.27	</td>
                   </tr>
                   
                   
                   <tr class="">
                      <td class="medal_rank">1994</td>
                      <td class="medal_team">Damthang</td>
                      <td class="medal_gold">SDF</td>
                      <td class="medal_silver">Won</td>
                      <td class="medal_bronze">1st/4</td>
                      <td class="medal_total">3,904	</td>
                      <td class="medal_total">70.27	</td>
                   </tr>
                   
                   <tr class="">
                      <td class="medal_rank">1999</td>
                      <td class="medal_team">Damthang</td>
                      <td class="medal_gold">SDF</td>
                      <td class="medal_silver">Won</td>
                      <td class="medal_bronze">1st/3</td>
                      <td class="medal_total">4,952	</td>
                      <td class="medal_total">72.26	</td>
                   </tr>
                   
                   <tr class="">
                      <td class="medal_rank">2004</td>
                      <td class="medal_team">Damthang</td>
                      <td class="medal_gold">SDF</td>
                      <td class="medal_silver">Won</td>
                      <td class="medal_bronze">1st/1</td>
                      <td class="medal_total">-	</td>
                      <td class="medal_total">-	</td>
                   </tr>
                   
                   <tr class="">
                      <td class="medal_rank">2009</td>
                      <td class="medal_team">Poklok-Kamrang</td>
                      <td class="medal_gold">SDF</td>
                      <td class="medal_silver">Won</td>
                      <td class="medal_bronze">1st/6</td>
                      <td class="medal_total">7,379	</td>
                      <td class="medal_total">80.68	</td>
                   </tr>
                   
                   
                   <tr class="">
                      <td class="medal_rank">2009</td>
                      <td class="medal_team">Namchi-Singhithang</td>
                      <td class="medal_gold">SDF</td>
                      <td class="medal_silver">Won</td>
                      <td class="medal_bronze">1st/6</td>
                      <td class="medal_total">5,653	</td>
                      <td class="medal_total">80.97	</td>
                   </tr>
                   
                   <tr class="">
                      <td class="medal_rank">2014</td>
                      <td class="medal_team">Namchi-Singhithang</td>
                      <td class="medal_gold">SDF</td>
                      <td class="medal_silver">Won</td>
                      <td class="medal_bronze">1st/3</td>
                      <td class="medal_total">4,774	</td>
                      <td class="medal_total">55.08	</td>
                   </tr>
                   
                   <tr class="">
                      <td class="medal_rank">2014</td>
                      <td class="medal_team">Rangang-Yangang</td>
                      <td class="medal_gold">SDF</td>
                      <td class="medal_silver">Won</td>
                      <td class="medal_bronze">1st/3</td>
                      <td class="medal_total">6,343	</td>
                      <td class="medal_total">63.84	</td>
                   </tr>
                   
                   <tr class="">
                      <td class="medal_rank">2019</td>
                      <td class="medal_team">Poklok-Kamrang</td>
                      <td class="medal_gold">SDF</td>
                      <td class="medal_silver">Won</td>
                      <td class="medal_bronze">1st/5</td>
                      <td class="medal_total">7,731	</td>
                      <td class="medal_total">59.09</td>
                   </tr>
                   
                   <tr class="">
                      <td class="medal_rank">2019</td>
                      <td class="medal_team">Namchi-Singhithang</td>
                      <td class="medal_gold">SDF</td>
                      <td class="medal_silver">Won</td>
                      <td class="medal_bronze">1st/5</td>
                      <td class="medal_total">5,054	</td>
                      <td class="medal_total">50.31</td>
                   </tr>
                          
                       
              
              
              <tfoot>
                  
                
              
              </tfoot>

                </tbody>

            </table>
            
            
            
              </div>
        </>
    );
};

export default ConstituencyBody;