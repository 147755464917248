import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import Header2 from "../../components/nav/Header2";


import Footer2 from "../../components/nav/Footer2";





const IndependenceDay = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">
                    <header>
                        <div class="header-single-post" data-dsn-header="project">
                            <div class="post-parallax-wrapper" data-overlay="3">
                                <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pHOTO01.jpg" alt=""
                                    data-dsn-header="blog" data-dsn-ajax="img" />
                            </div>
                            <div class="container">

                                <div class="inner-box m-section">
                                    <div class="post-info">
                                        <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>15th August 2004</a>
                                        <div class="blog-post-cat dsn-link">
                                            <a href="#" style={{color: '#000'}}>Speech delivered on 15th August 2004 in Gangtok, Sikkim.</a>
                                        </div>
                                    </div>

                                    <h3 class="title-box mt-10" style={{color: '#000'}}>58th Independence Day Message </h3>
                                </div>

                            </div>

                        </div>
                    </header>


                        <div class="wrapper">

                        <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tagged with: </h5>
                                        <a href="#"><span>Lorem ipsum</span></a>
                                        <a href="#"><span>Lorem ipsum</span></a>
                                        <a href="#"><span>Lorem ipsum</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                                <p style={{color: '#000'}}>
                                    <a href="#" style={{color: '#000'}}>Respected fellow Sikkimese people,</a>
                                    <br/>
                                    On the auspicious occasion of the 58th Independence Day of the nation, I extend  my heartiest greetings to my fellow Sikkimese people and fellow Indians. Having been chosen to lead the third consecutive term of government, I feel privileged to address my fellow countrymen in the capacity of a Chief Minister which was made possible by the great tradition of freedom and free choice won through decades of struggle. On this occasion, I also would like to pay tribute to the scores of freedom fighters who have left behind examples of supreme sacrifice and patriotism to the nation.
</p>

                               

                                <p style={{color: '#000'}}>
                                    I would like to begin my message today by putting forward my view of independence. I have always maintained that political freedom is not in and of itself complete. Along with political freedom, what is equally necessary is economic, social, cultural and linguistic freedom. This has become especially pertinent in    today's context where development is taking place rapidly on all fronts and human problems have become multi-dimensional. People's wants and aspirations have  become more intricate than ever. Today, the world no longer remains uni-polar in nature. Together with the development of human thought, all areas of human development have also become broader and more inclusive. Hence, political freedom alone shall not guarantee complete freedom to man.
                                     Similique.
                                </p>

                                <img class="w-100" src="assets/img/pHOTO01.jpg" alt="" />

                                <p style={{color: '#000'}}>
                                    Many of the values we cherished and the obstacles we faced in the 20th century no longer remain or are no longer relevant. We do not come across instances of explicit colonialism, nor   do we find examples of one-nation subjugation upon the globe. We are past the era when one powerful nation would enslave another with its superior military power. However, we are still far from achieving complete balance of power among the comity of nations. We still find  instances of imperialist tendencies in different forms and designs. We need to understand these changes in the new century. This shall also lend us new understanding of the concept of freedom in its different forms and shades.
                                </p>
                                 <p style={{color: '#000'}}>
                                    We freed our country from colonialism half a century back in 1947. For the  last 58 years, we have been a free country. We no longer need to wage a military war to free our nation. We are not required to invest our mind and our might for the political independence of our nation. But, are we free in the comprehensive sense of the term, especially in sense of freedom that human beinsg aspire towards? Should our definition of freedom only limit us to freeing our country from British imperialism? This auspicious occasion urges us to find answers to these questions. And we must forge ahead to find the answers.

                                </p>
                                 <p style={{color: '#000'}}>
                                   Today we are crippled by many problems as we have become a prisoner of our circumstance. Our country is free and led by self-governing citizens. However, even after achieving freedom for so many years now, we still need to struggle for social, economic and cultural freedom. Today the world is beset with formidable challenges for which we must find solutions.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   There has been an increase in social and economic disparity. Poverty, illiteracy, starvation, unemployment, communalism, class and caste discrimination are raising their ugly heads. Ecological imbalance and breakdown of our social fabric are matters of serious concern. Generational gap in terms of values and ideals  is equally worrying. Social dilemma and self-centeredness have become the order of the day. Unless we free ourselves from all these ills, we cannot claim to be a fully liberalized citizenry.
                                </p>
                                
                                <p style={{color: '#000'}}>
                                   How well are we positioned to defend ourselves against the ill effects of globalization? Therefore, we have to wage a continuous battle to keep ourselves free from its adverse consequences. It becomes our collective responsibility to keep people safe from these issues. We have been fighting against these forces to realize our vision of  making Sikkim a model state in the country. You have been witness to our sincere endeavors all these years and I am certain you can also gauge the impact of the State Government’s positive interventions.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                   As a result of our positive policy frameworks, what we witness are not only the spate of developmental activities but mental evolution as well. Sikkim today is considered at par with the most developed states of the country. You are aware that in the recently-held Chief Minister's Conclave in New Delhi, Sikkim ranked among the top states in the country. In fact, we enjoyed the recognition of being the number one state in the education sector among all the smaller states of India. I express my sincere thanks and congratulations to the Sikkimese people for this honour bestowed upon our state. You are aware that as of today, Sikkim is the best performing state in the country. In the same spirit, I beg to submit to the people that we have to sustain the momentum to make Sikkim the best state in the country.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                   As mentioned earlier, political freedom alone cannot guarantee complete freedom. Although, we gained freedom from the colonial regime, there are many internal and external problems that we still face today. India is struggling hard to achieve this second phase of freedom. We, on our part, are fully committed to this task. Many of the positive changes that we have brought about in recent years are palpable in our state.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Based on our performance, the respected Sikkimese people voted for us for the third consecutive term in a row. Overwhelmed and humbled by the love and faith that you have bestowed on us, we have gathered great vigour and received tremendous encouragement to work harder still for the collective welfare of the Sikkimese people.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   We have been at the helm of governance  for the last ten years and all that we have done for the Sikkimese  people are there for everyone to see. To use the phrase, our work is an open book. Our endeavours have been recognized one after another at the national level. To receive the prestigious Bharat Shiromani award for the emotional integration of Sikkim into the mainstream and restoring the institution of democracy in our state was a definitive indicator of our commitment to and achievement in fostering national unity.  Likewise, on the environmental front, we received the Greenest Chief Minister of India award and the Manipal University recognized our efforts in poverty alleviation. And more recently, on 6th August, Sikkim was adjudged as the Best Performing State, topping the list in the education sector. All of this go a long way in the success story of Sikkim  that we have crafted in all these years.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   It has been twenty-nine years since Sikkim became a part of the Indian Union. However, the process of development began only ten years back. In this short span of time, it is great a achievement to be adjudged the best performing state. Having done this, our next challenge must be to make Sikkim the best state in the country. On this occasion, I  appeal to my fellow Sikkimese people to contribute and work hard towards achieving this goal.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   During the last ten years in the government, we have achieved unprecedented success in different sectors, be it in education, health, rural development, animal husbandry, agriculture/horticulture, floriculture, hydro-power, tourism, environmental conservation, women empowernent, or power decentralization and Panchayati Raj. We have created history and set new standards - both in terms of qualitative improvements and in delivery.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   When we took over the reign of the State Government in 1994, we were worried to see the near absence of intellectual exercises on Sikkim that is crucial for good governance. There was lack of clear-cut policy frameworks and the earlier government had opted for a piece-meal approach in addressing crucial socio-economic issues. We decided to end this adhoc system and lend them a more formal and institutional shape. As a result, the State Planning Commission came into being with a panel of top economists drawn from national institutions. The government commissioned top economic professionals for preparing the Human Development Report and Vision Document on Sikkim. These two documents for the first time helped in determining our strengths, our present status as well as a road map for future development. We also have in place today the District Planning to oversee planning and implementation of various schemes at the district level.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   We have formed an Investment Board to work out broad policy frameworks while dealing with the prospective investors from outside the state. We have also formed State Patent Right Board. We established the Sikkim Akademi, the State Museum and instituted Sikkim Study Series with a view to provide institutional support to the preservation of local languages, culture and traditions. Under the Sikkim Study Series, we commissioned professionals to prepare authentic documents on various aspects of Sikkimese society ­ its culture, language, literature, politics, geography, environment etc. Some volumes have already been prepared and released. We have introduced  the Single Window System in order to avoid administrative delay and for quick delivery of service to the public. Today, we have forty fully operational Community Information Centres equipped with computers. We are also in the process of opening a Software Technology Park in Sikkim.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Looking at the uncertain and unplanned development strategy adopted by the earlier government, we carried out a SWOT analysis. On the basis of this analysis, we sought to reposition ourselves in delivering quality service to the people. Sikkim, characterized by hilly terrain has its weaknesses, but this equally presents us with its strength too. Based on our findings,   we set out to identify our priorities that would best take care of our developmental needs and aspirations of the people. Ten years down the line, we have proposed a definite preference of our strength based on our natural resources. I have submitted to my fellow Sikkimese people over the years that all our development strategies shall be based on  our natural endowments of Sikkim.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Sikkim has today become an exemplary state in the country. Especially concerning peace, security and political stability, Sikkim has earned great distinction in the country. Rule of law has been made the order of the day. There is complete transparency in the political arena and administration. On the political front, we have again achieved many of our demands as per our policy. Constitutional recognition of Limboo and Tamang as STs, MBC status and facilities to the notified OBCs of the state and the left-out communities like Bahun, Chettri, Newar and Bhujel have been accorded the status of OBCs at the state level with all facilities admissible to the backward classes. 
                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Besides, our government has also reserved seats for women, STs, SCs and the OBCs in the Panchayati Raj and in government jobs. The other pending demands of the Sikkimese people are being discussed in different forums at the center. We have placed our demands concerning seat reservation for the Limboo and Tamang in the State Legislative Assembly, tribal status for the MBCs, recognition of OBCs at the national  level, recognition of Nepali community as the minority community, etc. During my recent  visit to New Delhi, I have formally placed these demands with the Prime Minister, Home Minister, Finance Minister and others, and have submitted our Memorandum. I have also been able to extract positive responses from all of them.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Complete freedom of cultural and linguistic practices has been the hallmark of Sikkimese society. Dialects spoken by different communities have been recognized as State Languages, paving the way for a formal process of preservation and promotion of regional languages and cultures. Various festivals belonging to different communities have been declared as government gazetted holiday. We have safeguarded religious freedom and tolerance. Assistance is provided to preserve  and promote different religious sites. The installation of the tallest statue of Guru Padmasambhava ever (in the whole world) has become a wonder in its magnitude, splendour and its spiritual substance.
                                </p>
                                
                                 <p style={{color: '#000'}}>
                                   Sikkim has been inducted into the North-Eastern Council bringing in many financial, technological and institutional supports for the overall development of the state. A policy  decision has been taken at the national level to open the traditional Nathu la route for trade with Tibet.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   When we took over the government in 1994, the internal revenue was a mere Rs. 44.72 crores which has now risen to Rs. 300 crores. Plans are afoot to improve this revenue figure to an impressive  mark of Rs. 1500 crores by the year 2015.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   The first major policy intervention that we made was to allocate seventy percent of the plan annual budget for rural upliftment. Under this policy, we carried out many people­centric schemes and programmes for uplifting the living conditions of the Sikkimese people. Sikkim is the first ever state to invest such  a major chunk of its state budget for the rural sector. The list of our pro-poor policies is long. Some that I would like to mention are the Land Bank Scheme for the landless Sikkimese, grants of Rs. 20,000 and GCI for rural housing, free distribution 35 kilograms of rice to families falling below the poverty line, distribution of subsidized rice at Rs. 4 per kilogram for the rural population. Alongside, every household is provided with potable drinking water, electricity and road connectivity. Free  electricity is provided to people of BPL category.
                                </p>
                                
                                <p style={{color: '#000'}}>
                                   In order to make education and health easily accessible, a number of schools and health centers have been established across the state. Senior citizens and the physically challenged Sikkimese people are being given a monthly pension of Rs. 300. The government initiated people-centric schemes like the Janta Mela to provide facilities to the public directly and transparently. For the welfare of the poor Sikkimese coming to the capital from far-flung areas, a Janta Bhawan was recently inaugurated and dedicated to the people of Sikkim on August 12, 2004. We have further strengthened the legal infrastructure by setting up various courts in the state.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   The government, as you are all aware, has affected an unprecedented increase in the pay scale of the government employees coupled with the granting of promotions. The State Government has created a conducive atmosphere of democratic peace and overall dignity to work in. When we formed the government, the daily wages of our labourers was only Rs. 20. Today, it has increased manifold to Rs. 100. In collaboration with the central ministry, we created a separate branch of Indian Reserved Battalion under Sikkim Police to ease the crisis of growing unemployment among the youth.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   To provide easy accessibility to quality education, we have opened up sufficient schools at the village level. Colleges have been established in every district. We have a Degree College in Namchi, another college is soon to be established at Rhenock. We have also laid the foundation stone of Science College at Soreng. Sanskrit Mahavidyalya, a Medical College, Engineering College, higher technical study centers have already been established in our state. All low cost school buildings have been transformed into concrete structures. A policy decision has been taken to establish a Dental College in the state. Preliminary works have begun for the establishment of own university in Sikkim.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Our government is serious about making education the highest priority. Our ideal is to see that the society benefits from education and the educated. Still, our belief is that the noble goal of education cannot be defined on the basis of a degree or certificate earned from a given institution. Our belief is to ensure that every individual who passes out from an institution with flying colours should equally be equipped enough to stand the vicissitudes of life and sail through the many hurdles that life presents.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Besides education, we are equally concerned about the promotion of sports in the state. We have developed the necessary infrastructure for the promotion of sports. The State Government is undertaking a major project to develop national Khel Gaon of international standards for enabling our young and budding sportspersons to explore their choice of sport. Sports are equally encouraged at the village level with the development of sports facilities at the village level   itself. We want sports to be an integral part of the lives of our people.
                                </p>
                                
                                 <p style={{color: '#000'}}>
                                   In the health sector, our government has achieved considerable success. With the establishment of modern hospitals in all the districts, we have also upgraded the STNM                      Hospital by linking it up with the prestigious AIIMS in New Delhi through telemedicine. Sikkim was the first state in the country to carry out the Hepatitis B  vaccination for  children free of cost. Our government is now working towards covering all BPL families under the health insurance scheme. We are seriously working on framing a  law on the compulsory annual health check-ups for all senior citizens in the state. Mental alertness and physical fitness is crucial to make a healthy person. We are sincerely working towards this goal to make our society more robust and our lives more healthy and meaningful.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   The government over the decades has been initiating several schemes to eradicate poverty. For the overall progress of the state, our government is seeking to make the entire state a poverty-free state. Besides granting immediate benefits, we are also trying  to promote the people’s productivity in various sectors. We are now promoting a campaign to make each household an industrial unit, with each household taking up at least one productive  scheme.
                                </p>
                                
                                <p style={{color: '#000'}}>
                                   The State Government  has always been involved in tapping the immense potential of our youth force. For the entrepreneurial and hard working  youths, we have always played the role of a good facilitator. We are sincerely seized of the need to generate as many opportunities as possible to engage our youth in business, trade and other private ventures. Unlike in the past, where the government was the sole arbitrator and the propeller of all economic activities, we have today a wide spectrum of agencies outside the government. Non-governmental organizations, foreign investors, multi-national companies are all actively involved in shaping the lives of our people. The role of our youth is crucial and they should be an active and capable partner to the various economic actors that we increasingly witness in Sikkim today. Youth cannot possibly excel on the basis of easy handouts. What they require is to associate themselves with the various institutions and technology houses in Sikkim today, with the aim to  meaningfully contribute to the  nation-building process.

                                </p>
                                
                                 <p style={{color: '#000'}}>
                                   Respected Sikkimese citizens, agriculture is the mainstay of our people. Hence, we want to promote agricultural activities in the state based on modem and scientific methods. A greater thrust would be given this time to viable and feasible area-specific cultivation of crops. Cultivation of cash crops like cardamom, ginger, peas, passion fruit, pumpkin,
squash, mushroom, pears and orange shall be our priority. The production of potato seeds, flower seedlings and pea seeds shall be encouraged in the state. We shall seek to bring about rural prosperity through scientific farming of all these crops. The State Government shall systematically discourage the use of chemical fertilizers and shall strongly  encourage the use of organic manure in order to retain the fertility of our soil. By doing so, the State Government shall create and promote a new brand of our produce. By 2009, our goal is to make Sikkim a fully organic state.
                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Together with agriculture and horticulture, the promotion of animal husbandry is also our priority. Dairy, piggery, poultry and fishery shall be promoted and encouraged to attain self-sufficiency in meat, fowl, fish and milk production in the state.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Tourism is an important area of operation, both for economic freedom and for easing the pressure on government for employment. In fact, we want to promote  tourism as the new profession of the Sikkimese people. We have initiated various measures to promote village tourism, enlisting every village within the ambit of tourism   activities. Major attention shall be given to the promotion of snow sports like skating, water sports and environment-related tourism. We would like to give tourism an altogether new dimension to include and highlight our natural resources including waterfalls, lakes and forests. We would give focus to cultural festivals, flower festivals and pilgrimage tourism. We are working sincerely towards making eco-friendly tourism as the mainstay profession of the Sikkimese people.
                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Sikkim is a rich reservoir of natural resources and this surely is our strength and a competitive advantage in relation to other states in the country. We feel that without tapping our vast natural resources otherwise wasted, we will fall short in bringing about our desired level of progress and prosperity. We want to make good use of our resources for the betterment of the Sikkimese people. Hence, generating hydro-power in the state is our priority. You are all aware that we have been developing the long stretch of Teesta basin for generation of electricity in collaboration with reputed power corporations of the country. We are forging ahead with our plan to improve our  generating capacity to 4000 MW of hydro-electricity by 2015. Apart from what nature offers us, we have limited choice in feasible economic activities.   Hence, we have to utilize our limited resources wisely to give a fresh lease of life and direction to our state's economy. Sustainability, which continues to remain our challenge, should be key in any economic project. This would serve twin purpose of conservation and securing  
sustainable resource base for our coming generations.
                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Besides tapping the huge potential of our natural resources, we will concentrate on the areas of promotion of quality education, health, sports, rural development and youth activities.

                                </p>
                                
                                 <p style={{color: '#000'}}>
                                   Respected citizens, on the occasion of the Independence Day, I would like to put forward some new programmes that we are going to undertake in the days to come:

                                </p>
                                
                                <ul>
                                    
                                    <li>
                                        <p style={{color: '#000'}}>In order to strengthen the institution of Panchayati Raj, the process of power decentralization shall be further expanded and broadened. We will divide Sikkim into thirty administrative blocks and establish Block Development Offices in each one of them. In the next financial year, work shall commence for the development of infrastructure including offices in all the thirty blocks.
</p>
                                    </li>
                                    
                                    <li>
                                        <p style={{color: '#000'}}>
                                            In order to ensure greater participation of the general public in state administration, Panchayat members shall be further empowered and made more accountable.

                                        </p>
                                    </li>
                                    
                                    <li>
                                        <p style={{color: '#000'}}>
                                            More power shall be given to the Zilla Panchayats for taking up various schemes in public interest.

                                        </p>
                                    </li>
                                    
                                    <li>
                                        <p style={{color: '#000'}}>
                                            Works within the limit of Rs. 50 lakhs shall be implemented at the Zilla Panchayat level.

                                        </p>
                                    </li>
                                    
                                    <li>
                                        <p style={{color: '#000'}}>
                                            One regional Secretariat shall be established at Jorethang for South and West
Sikkim.
                                        </p>
                                    </li>
                                    
                                    <li>
                                        <p style={{color: '#000'}}>
                                            Financial aid shall be given to college students falling under the BPL category.
                                        </p>
                                    </li>
                                    
                                     <li>
                                        <p style={{color: '#000'}}>
                                            All people below poverty line shall be covered under the Health Insurance Scheme.

                                        </p>
                                    </li>
                                    
                                    <li>
                                        <p style={{color: '#000'}}>
                                            All physically-challenged citizens of Sikkim shall be given a monthly pension  of Rs. 500.

                                        </p>
                                    </li>
                                    
                                    <li>
                                        <p style={{color: '#000'}}>
                                            An additional 10,000 households shall be identified for  the distribution of subsidized rice at the rate of Rs.4.00 per kilogram.

                                        </p>
                                    </li>
                                    
                                    <li>
                                        <p style={{color: '#000'}}>
                                            To improve agricultural output, a total of five crores worth of passion fruit seedlings  shall be provided to the farmers for mass fruit farming.
                                        </p>
                                    </li>
                                    
                                    <li>
                                        <p style={{color: '#000'}}>
                                            By 2009, a total of five crores of orange and pear saplings each shall be supplied to the farmers for cultivation.

                                        </p>
                                    </li>
                                    
                                    <li>
                                        <p style={{color: '#000'}}>
                                            To transform Sikkim into a poverty-free state, all poverty alleviation incentives and schemes launched and implemented so far shall be retained.

                                        </p>
                                    </li>
                                    
                                    <li>
                                        <p style={{color: '#000'}}>
                                            We shall further intensify our campaign to provide quality education and health services.
All possible avenues for self-employment shall be explored for the benefit of educated unemployed and our youth force. All programmes undertaken by the government shall have a special bearing on the needs and aspirations of the youths.

                                        </p>
                                    </li>
                                    
                                    <li>
                                        <p style={{color: '#000'}}>
                                           We shall undertake plans, programmes and policy decisions necessary to achieve our goal of making Sikkim the very best state in the country.

                                        </p>
                                    </li>
                                    
                                     <li>
                                        <p style={{color: '#000'}}>
                                           The government shall formulate and implement long term programs so that the work that we accomplish in the next 5 years shall continue to bear fruit even 100 years after for the welfare of generations to come.

                                        </p>
                                    </li>
                                    
                                </ul>
                                
                                 <p style={{color: '#000'}}>
                                   Under the democratic form of government,  people are the ultimate custodians and masters of rights and privileges under the constitution. However, this equally implies that the responsibility of nation-building also rests with the people. A master cannot be one who claims only rights and forgoes responsibility. Hence, safeguarding the integrity and sovereignty of our country is the sole responsibility of the people. The dignity of a nation depends upon the character of its citizens.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                  Our government has launched and implemented a number of people-centric programmes to ensure to the people complete freedom in its holistic sense. In the days  to come, we shall continue to follow the same spirit that has guided us thus far in the service of the people to achieve peace, progress and prosperity for Sikkim.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                  In conclusion, I beg your permission to quote a definition of democracy and freedom offered by a scholar. It reads, "Freedom is when the people can speak. Democracy is when the government listens." As said earlier in many occasions, our government has restored the lost voices of the people and ensured the freedom to see, hear and speak their minds without fear. Our government has listened to your calling, to your needs  and to your aspirations.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                    
                                    Respected fellow citizens,
                                    
                                    <br /><br />

                                 Today we are marching ahead towards a new horizon: a horizon that leads to a prosperous and developed Sikkim. We can feel the warmth of a new vista of peace, prosperity and economic freedom. However, there is a general attitude growing among us to dishonor the very dignity of labour. We seem to have forgotten the biblical injunction to earn our bread with the sweat of our brows! That does not augur well for our future progress. This attitude causes stagnation and not progress. We must promote a sense of honour for labour and we must respect its values. We must promote a positive outlook in service of society, our state and nation. Every citizen should be dutiful in their responsibility. Our society is also adversely influenced by the narrow mindset of communalism. We cannot afford to subscribe to such limited thinking as it will only push us backwards in time. 
Suffice it to remember that the rights and privileges of a citizen are safeguarded by the Constitution and is provided for in the law of the land.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                  In this globalized world, our immediate challenge is to rescue human values; values such as mutual respect, brotherhood and humanism from the clutches of the self-centeredness. We must contribute towards revitalizing human faith in the inherent goodness of man and creation.
                                </p>
                                
                                <p style={{color: '#000'}}>
                                    With a humble appeal, I once again extend my warm greetings and best wishes  to all Sikkimese people on this great occasion.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                    Jai Hind!
                                </p>
                                

                            </div>
                        </div>

         


                    </div>


                </div>

          

               <div class="clearfix"></div>

               <Footer2 />


                        </div>
                    </div>
                </main>

            </>
    );

};

export default IndependenceDay;