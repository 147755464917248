import React, {useEffect} from 'react';

import Header2 from "../components/nav/Header2";


import Footer2 from "../components/nav/Footer2";

// import "../goodgov.css";




const Gallery = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">
                        <div class="wrapper">

                                <div class="root-contact">
                                    
                                    {/* Header */}
                                    <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                                        <div class="container" style={{paddingBottom: '50px'}}>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                                        <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                                            data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                                        Gallery
                                                        </h2>

                                                        <p class="economy-page-header-desc" style={{fontSize: '1rem', fontWeight: '500', maxWidth:'100%', color:'#000', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px'}}>
                                                        Photo archive of Pawan Chamling.
                                                        </p>


                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </section>


                                    {/* Body */}

                        <div class="gallery-portfolio section-margin" style={{marginTop:'0px'}}>
                            <a class="link-pop" href="assets/img/Gallery 1.jpg"
                                data-source="assets/img/Gallery 1.jpg">
                                <img src="assets/img/Gallery 1.jpg" alt="" />
                                <div class="cap">
                                    <span>PAWAN CHAMLING WITH PUBLIC AT NAMPHOK SECONDARY SCHOOL</span>
                                </div>
                            </a>
                            <a class="link-pop" href="assets/img/Gallery 2.jpg"
                                data-source="assets/img/Gallery 2.jpg">
                                <img src="assets/img/Gallery 2.jpg" alt="" />
                                <div class="cap">
                                    <span></span>
                                </div>
                            </a>

                            <a class="link-pop" href="assets/img/Gallery 3.png"
                                data-source="assets/img/Gallery 3.png">
                                <img src="assets/img/Gallery 3.png" alt="" />
                                <div class="cap">
                                    <span>Dalai Lama at Samduptse, Namchi</span>
                                </div>
                            </a>

                            <a class="link-pop" href="assets/img/Gallery 5.png"
                                data-source="assets/img/Gallery 5.png">
                                <img src="assets/img/Gallery 5.png" alt="" />
                                <div class="cap">
                                    <span>Assembly Session on 24th February 2003</span>
                                </div>
                            </a>

                            <a class="link-pop" href="assets/img/Gallery 8.png"
                                data-source="assets/img/Gallery 7.png">
                                <img src="assets/img/Gallery 8.png" alt="" />
                                <div class="cap">
                                    <span></span>
                                </div>
                            </a>
                            
                            <a class="link-pop" href="assets/img/gallery/chief-minister-holding-a-school-boy-at-thinley.png"
                                data-source="assets/img/Gallery 7.png">
                                <img src="assets/img/gallery/chief-minister-holding-a-school-boy-at-thinley.png" alt="" />
                                <div class="cap">
                                    <span>Chief Minister holding a school boy at Thinley</span>
                                </div>
                            </a>
                            
                            <a class="link-pop" href="assets/img/gallery/gallery-7.png"
                                data-source="assets/img/Gallery 7.png">
                                <img src="assets/img/gallery/gallery-7.png" alt="" />
                                <div class="cap">
                                    <span></span>
                                </div>
                            </a>
                            
                            <a class="link-pop" href="assets/img/gallery/gallery-8.png"
                                data-source="assets/img/Gallery 7.png">
                                <img src="assets/img/gallery/gallery-8.png" alt="" />
                                <div class="cap">
                                    <span></span>
                                </div>
                            </a>

                        </div>




                                </div>

                                <Footer2 />

                        </div>
                    </div>
                </main>

            </>
    );

};

export default Gallery;