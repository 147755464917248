import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr18April2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/Aug1.jpg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>18 April, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and the SDF president Shri Pawan Chamling condemns the objectionable speech given by the Chief Minister Shri PS Tamang on Ambedkar Jayanti. He maintains that Chief Minster must refrain from abusing his constitutional post. Chief Minister Tamang openly talking about breaking laws to promote government employees and openly intimidating them was unfortunate. The state government must ensure punctuality and sincerity by inspiring and motivating not by threatening them. He demands an apology from Sikkim Chief Minister for such undemocratic speech and unconstitutional language.
                           </h3>
                           
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#">
                                            <span>#OFOJ</span>
                                        </a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                  “Chief Minister Shri PS Tamang’s speech, particularly in reference to the punctuality of state bureaucrats, given on BR Ambedkar Jayanti received mixed reactions. How would you rate his speech?


                                </p>

                                

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize: '22px', fontWeight: '700'}}>Ans:</span> First of all, let me note that Ambedkar Jayanti is a national festival where we celebrate the legacy of our constitution drafter. What happened in Sikkim on that auspicious day made a mockery of our constitution, which safeguards the lives of our people. The citizens of Sikkim received open threats and intimidation from the one who heads the state government. I felt ashamed as a Sikkimese citizen. What a disgrace to the nation, people and our national hero, the late Shri BR Ambedkar!



                                </p>
                                
                                <div>
                                <img class="w-100" src="assets/img/pressrelease/18April.jpeg" alt="" />
                                <br />
                                <br />
                                <p style={{color: '#000'}}>
                                    The Chief Minister of Sikkim P.S. Golay threatened and humiliated government employees on the occasion of Ambedkar Jayanti 2021.	

                                </p>
                                </div>

                                <p style={{color: '#000'}}>
                                   The post of Chief Minister is a constitutional post. Anyone occupying that chair must rise above petty, undignified and inappropriate thinking, language and behaviour. I watched in horror as Shri PS Tamang, our Chief Minister,  openly declared that he has promoted many bureaucrats by breaking laws. Has anyone understood the significance of such a public speech by the head of the state government? He is openly saying that he is above the law of the land. He is proudly declaring that he is bulldozing the laws of the land. What is the outside world thinking as they look at how our state administration is being run? In a civilized and democratically conscious society, any Chief Minister or public representative who would brag about breaking the law would be instantly asked to step down. If he has any moral values left in him, he must immediately step down. Does the SKM party not have a wiser and more responsible leader to lead the government? Is this really the best they can offer? How long will Sikkim be forced to go through such a trauma of shame, terror and circus at the highest level of administration?
He did not stop there. He continued on, bragging about his ability to do things beyond what people are thinking. He told the people that he knows how to make government employees work. He proudly stated, “I can use vigilance, I can start inquiries...I will start compulsory retirement...I will make you know who I am, who PS Goley is...I am educated and I know how to write with a pen and I also know how to write with other things...I know how to use other 'things' and I have done that earlier also”.


                                </p>
                                 <p style={{color: '#000'}}>
                                    Is this the Chief Minister of an elected government speaking or a despot issuing threats from his throne? I fully agree that as Chief Minister it is his responsibility to demand accountability from all government employees and ensure that the people’s work is done. I fully agree that all government employees have a primary duty to serve the people of Sikkim. Punctuality, dutifulness, commitment and hard work are essential components of their lives as government employees. But the Chief Minister has no right to terrorize and intimidate them by bragging about vigilance, other inquiry agencies, compulsory retirement, writing with pen and using 'other tactics', etc. This is making a mockery of democracy. This is blatantly savage. I seriously wonder if even in the stone age, were village heads so brutal and savage? 

                                </p>
                                 <p style={{color: '#000'}}>
                                  As Chief Minister, he must inspire, motivate and encourage. He is not there to threaten, shame and blackmail the people. He made a complete mess of such a dignified national celebration as Ambedkar Jayanti.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   When people listen to the Chief Minister on such an auspicious occasion, they should go back home feeling inspired - not humiliated and terrorized. I totally condemn such barbaric language and such an unconstitutional speech by the Chief Minister of Sikkim. Moreover, he apparently directed a few people to conduct a sting operation. It was painful and disturbing to see them going with cameras to inspect government offices and streaming it live. To see sincere government employees being captured on camera was disturbing. Why should they face the cameras of some private individuals to display their punctuality when there are officially appointed heads of departments? 




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   I demand an unconditional apology from him to all government employees and the people of Sikkim.”



                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr18April2021;