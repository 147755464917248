import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import Header2 from "../../components/nav/Header2";


import Footer2 from "../../components/nav/Footer2";





const StateDay = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">
                    <header>
                        <div class="header-single-post" data-dsn-header="project">
                            <div class="post-parallax-wrapper" data-overlay="3">
                                <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/text-speech-4.jpeg" alt=""
                                    data-dsn-header="blog" data-dsn-ajax="img" />
                            </div>
                            <div class="container">

                                <div class="inner-box m-section">
                                    <div class="post-info">
                                        <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>Gangtok</a>
                                        <div class="blog-post-cat dsn-link">
                                            <a href="#" style={{color: '#000'}}>January 26, 2012</a>
                                        </div>
                                    </div>

                                    <h3 class="title-box mt-10" style={{color: '#000'}}>State Day Message </h3>
                                </div>

                            </div>

                        </div>
                    </header>


                        <div class="wrapper">

                        <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tagged with: </h5>
                                        <a href="#"><span>Lorem ipsum</span></a>
                                        <a href="#"><span>Lorem ipsum</span></a>
                                        <a href="#"><span>Lorem ipsum</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p style={{color: '#000'}}>
                                    <a href="#" style={{color: '#000'}}>My fellow Sikkimese people,</a>
                                    <br/>
                                   On the auspicious occasion of the 38th State Day, I offer my hearty greetings and congratulations to  my  fellow Sikkimese people  for attaining commendable progress under the democratic dispensation provided by the Constitution of India. The collective decision to  embrace a democratic form of government was largely due to a sharp socio-economic    divide amongst the people resulting in popular demand for democracy. Now that we are under a                                   democratic system, we must see that freedom and equality  generates  equal outcomes and opportunities for the people across the social-economic  spectrum, irrespective of caste, creed or sex. Here lies our challenge as democratic citizens of this great Nation.

</p>

                                
                                <p style={{color: '#000'}}>
                                    After its formal integration with the largest democratic Nation in the world, Sikkim has moved  ahead by leaps and bounds in all spheres of development. Fully utilizing our democratic privilege, we have designed our development strategy based on our   natural and  human resource. 

                                </p>
                                
                                <p style={{color: '#000'}}>
                                    On the formation of the  Sikkim Democratic Front government on December 12, 1994, we took extra care to impress upon our people to view themselves as supreme in a  democracy and offered myself as the ‘first servant’ of the people. Today, while we observe our State Day, I am pleased to know that the  people of Sikkim have vindicated the decision so taken in 1975 by assuming a responsible role to develop, sustain and promote a  democratic system  and democratic values in the State.
                                </p>

                                <img class="w-100" src="assets/img/text-speech-4.jpeg" alt="" />

                                <p style={{color: '#000'}}>
                                    <a href="#" style={{color: '#000'}}>Fellow citizens,</a>
                                    <br/>
                                   Sikkim has made tremendous progress on all fronts. Under education and health sectors, we have become a model State investing as high as 20 percent budget annually on education alone. Through the introduction of large number of incentives, from free education  to ambitious scholarship programs, we are preparing our young to become global citizens, competing at the national and international level. We have programs and schemes that address the aspirations of all sections of the population. For example, under the Capacity Building program, we have set up a total of 41 Livelihood Schools across the State to train our  youths. Now, school drop outs too can skill themselves in basic trades like carpentry, masonry, floriculture and computer software to name a few. One of  our most futuristic programs has been the training of  our young graduates for IAS and allied courses etc. in reputed institutes in the Country. I congratulate the three daughters                          of Sikkim who have made us proud with their mark at the national level by clearing the IAS examinations,  2013.

                                </p>
                                 <p style={{color: '#000'}}>
                                    There is a definite improvement in life expectancy by over 2 years among the Sikkimese. Through the CATCH program, we have revolutionized the concept of health care in Sikkim by offering preventive care through early diagnostics. By constructing the 575-bedded multi-specialty Hospital in Gangtok, we will fully modernize health infrastructure in Sikkim.

                                
                                </p>
                                 <p style={{color: '#000'}}>
                                   In the area of rural housing, we have remained a pioneer in the Country. We provide free modern houses with all basic amenities costing over Rs. 5 lakhs        to those in need. We have accomplished this by dovetailing all central flagship  programs like Indira Awas Yojana with our state program to provide a concrete house  to our people. In fact, I am happy to recall the observation of the Planning Commission in New Delhi  proposing a similar integration to be achieved by all States  in the Country, following  Sikkim’s model. Sikkim perhaps is the only State in the entire  Country to provide free distribution of rice to BPL families and subsidized rice at Rs. 1 per kg to the marginally poor family. Sikkim has already established its  power in the floriculture sector. Marketing of flowers as commercial products has been successfully tapped in the State and a large numbers of                    flower growers in the State are earning a good livelihood, thus improve their living standards.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   You are aware that Sikkim is the only State in India to propose organic  farming with the aim to transform Sikkim into a completely organic State. We are in  the advanced stage of organic certification of our cultivable land. The decision to go for organic farming was taken after long deliberations. We have limited land for cultivation  and so we cannot possibly enhance the volume of production after a certain limit. Therefore, the idea has been to add value to our products through organic farming. Our farming community would also learn new skills and techniques to enhance productivity. This will enhance the profession of our farmers and at the same time, also increase the longevity and health of our people.
                                </p>
                                
                                <p style={{color: '#000'}}>
                                    
                                   
                                    
                                   People from far and near have appreciated our organic initiative and the State of Kerala deputes  officials and growers on a regular basis to Sikkim to learn our organic model. Authorities  in  Argentina have shown  keen interest to work jointly as a  large number from the farming community there too are adopting organic farming. I have a firm   conviction that the whole of India will take a lesson from our experience to adopt the organic way in days to come. 

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                   The State Government has successfully introduced the concept of eco-friendly tourism with promotion of home stays, village tourism, culture  tourism, adventure tourism along with the concepts of banbas and akantavas in Sikkim. On a very conservative estimate, more than five thousand Sikkimese youth are gainfully engaged directly in running homestays in Sikkim which are proving to be  a   great success. Introducing a host of unique tourist destinations in Sikkim, we have developed the Char Dham complex, a 150 feet tall                     statue of Guru Padmasambhava, 137.50 feet tall statue of Lord Buddha at Ravangla, and the 150 feet    tall statue of Chenregiz,  currently being constructed at Pelling. This has not only provided  spiritual         sanctuary to the world but it has also brought about cultural  integration of the State with the Indian mainstream.

                                
                                </p>
                                
                                
                                
                                <p style={{color: '#000'}}>
                                  The investment scenario in Sikkim has been very encouraging. However, stringent conditions have been laid down to ensure that environmental sanctity of  Sikkim remains undisturbed with sustainability as the basic mantra. In a special gesture, we plant 10 trees against one tree felled for bona fide use or for institutional expansion  that are deemed unavoidable. Therefore, in the area of nature conservation, we have been regularly recognized regionally, nationally and internationally.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                  As a Special Category State, we enjoy certain exceptions in terms of fiscal devolution. However, we must continue to devise our own strategies to expand our internal revenue   generation base. I am very pleased to share with my fellow Sikkimese people that Sikkim recorded the highest economic growth in the Country   at 22.8 percent during the 11th Plan period. This was due to our committed efforts,  steady policy interventions and political commitment of  the Sikkim Democratic Front government over the last 19 years. The commissioning  of  the 510 MW Teesta-IV and the 60 MW Rangit-II Hydro-Electric Projects and the setting up of industrial units including pharma units, breweries and hotels etc. brightened our economic prospects.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                  The 99 MW Chujachen Hydro Electric Power Project is complete and ready to be commissioned later this year. It is expected to add over Rs. 50 crores annually to our revenue. Nine mega hydro projects are currently under various stages of construction.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   In the absence of an airport and railway network which are yet to be completed, the issue of connectivity  poses a problem in  Sikkim. In spite of a hilly and challenging landscape, the Sikkim Democratic Front government has added an additional 1900 kms of new roads since 1994. The State of Sikkim was pleased to receive the Bharat Nirman Award received from the Central Government for this achievement. The construction of the new  Greenfield Airport by Airport Authority of India shall be completed for operation  by March 2014. The rail link upto the border town of Rangpo has been sanctioned and the work has also begun.

                                </p>
                                <p style={{color: '#000'}}>
                                    <a href="#" style={{color: '#000'}}>Respected fellow Sikkimese people,</a>
                                    <br/>
                                  Sikkim surrounded by international borders on three sides has always been in the danger of nefarious activities spilling over into our peaceful    State. However, against all odds, we have consistently maintained peace and tranquility  in a border state like ours. This is a rare example in the Country. The fact that Sikkim is free   from any form of  unrest should be fully appreciated by our fellow Indians.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                  As citizens living in the largest democratic nation in the world, we need to be wholly responsible towards the state and we must  reinforce the power of the people. We must empower the people, making them aware of their crucial role and responsibility in the functioning of the government and in co-creating the future of the people and the state.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                    <a href="#" style={{color: '#000'}}>Respected fellow Sikkimese people,</a>
                                    <br/>
                                  Based on the collective mandate of the people, we are duty bound to serve the larger cause of the people. We are directed by the constitution to safeguard the interest and liberty of the people. As a democratic nation, we must continue to ensure that issues concerning the interest and wellbeing of the  people find prominence in our political discourse. I  call upon all my fellow compatriots representing different political parties to have supreme faith in the Constitution and to represent the peoples’ hopes and aspirations with deep responsibility.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   On this auspicious occasion of the 38th State Day, I once again congratulate all my  fellow Sikkimese people and wish them all the best in days to come.
                                </p>
                                
                               
                                
                                 <p style={{color: '#000'}}>
                                  Thank you.
                                  
                                  <br/>
                                    Jai Hind.
                                </p>
                                

                            </div>
                        </div>

         


                    </div>


                </div>

          

               <div class="clearfix"></div>

               <Footer2 />


                        </div>
                    </div>
                </main>

            </>
    );

};

export default StateDay;