import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr9May2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/9May.jpeg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>9 May, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and the President of the SDF party Shri Pawan Chamling offers three important suggestions to the government and the SDF party office at Jorethang for use by the state government as Covid facilities.
                            
                           </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                   “ Q. The situation of Sikkim under the Covid-19 pandemic needs a deep analysis. As a former Chief Minister of Sikkim, what have you observed? Are you alarmed, disappointed, dissatisfied or satisfied with how we are fighting this battle?


                                </p>

                                

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span>The devastation caused by Covid-19 worldwide has grieved all of humanity. Every day we go to bed with an aching heart and wake up with an anxious heart. The loss of human lives, economic disaster, unrest and sufferings have made the earth a grieving planet.


                                </p>

                                <img class="w-100" src="assets/img/pressrelease/9May.jpeg" alt="" />
                                <br/>
                                <br/>

                                <p style={{color: '#000'}}>
                                    The biggest source of help and relief is the government because it is the government that has resources at its disposal that it can mobilize to save as many lives as possible, minimize damage and provide assistance. 


                                </p>
                                 <p style={{color: '#000'}}>
                                    As a senior leader in the state, I would like to draw the state government’s attention to the following three significant points:
 

                                </p>
                                 <p style={{color: '#000'}}>
                                  1. Reinforcement of medical infrastructure: The medical fraternity is under tremendous stress. The hospital is overflowing with Covid patients. I reiterate my suggestion to open Covid wards in all the district hospitals. Doctors, nurses and other health workers under Covid duty need all the facilities available to them. In order to protect their families from possible infection, they should be provided with good lodging facilities in nearby hotels. They need complete moral support and encouragement. I had asked the government to cover their lives with life insurance of Rs 1 crore. I reiterate that suggestion. The Health Department should have a ‘study team’ to study the global trend of coronavirus and the best treatment methods.
 <br/><br/>
Health workers assigned with Covid duty must be given rapid basic training on Covid care and Covid treatment.
  <br/><br/>
There is a possibility of a health care worker shortage soon. The government must look at the possibility of preparing and engaging students from local nursing and medical colleges as part of the Covid workforce. Also, unemployed nurses and doctors can be employed to meet the emergency need.  




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   2. Solid Economic Revival Strategy: Some studies show that there is a general increase of poverty viz., 20 per cent among urban and 15 per cent among rural populations. The price of food grains has increased nearly 240% in general. We have no idea what the finding and recommendations of the Sikkim Economic Revival Committee have been. I had suggested earlier that the state government must allocate Rs 1000 crore as a Covid Management Fund. I would like to once again ask the government to set aside this amount as a State Covid disaster response fund. The government has to devise a mechanism to give relief money directly to the poor and to ration essential commodities. Farmers, unsalaried workers and drivers must be provided with economic relief at the earliest. The humiliation that drivers had to go through during the first lockdown of endlessly waiting for relief must be avoided. Many drivers have not received their relief money of Rs. 5000 even today. Local entrepreneurs and small business vendors whose businesses have closed down under the stress of the pandemic must be identified and compensated with the sole objective of reviving their business. No business should be allowed to die in Sikkim. We need local businesses to grow.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                    3. Strict implementation of Covid protocols. The Sikkim administration has appeared to be the most confused one in the whole country. Frequently changing notifications and defiance of protocols by leaders and senior bureaucrats have caused a demoralizing impact on the common people. The government must punish erring government officials and powerful politicians according to the law. Notifications must be thoroughly discussed and thought through before releasing them.




                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                  I on behalf of the SDF party and the people of Sikkim would like to offer the SDF party office at Jorethang for use by the government as Covid ward, if necessary. The government can use that building to treat Covid patients.



                                </p>
                                
                                <p style={{color: '#000'}}>
                                    The government’s primary and supreme duty is to save the lives of its citizens at any cost. I hope that the government will focus on working sincerely and seriously and not let us down.  I wish all the people a safe and healthy life.”




                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr9May2021;