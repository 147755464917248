import React from 'react';
import {Link} from 'react-router-dom';

const AwardAsCmBody = () => {
    return(
        <>
                        <div class="row">
             

             <div class="table_wrapper">
           
       <table id="table_medals" >
         <thead style={{padding: '10px'}}>
             <tr >
                 <th id="medal_rank" class=" active" style={{padding: '15px'}}>Year</th>
                 <th id="medal_team" class="">Name of Award</th>
                 <th id="medal_gold">Awarded By</th>
                
           </tr>
       </thead>
         <tbody>
             
             <tr class="">
                 <td class="medal_rank">1987</td>
                 <td class="medal_team">Chintan Puraskar  </td>
                 <td class="medal_gold">Sikkim Sahitya Parishad</td>
                
              </tr>
               <tr class="">
                 <td class="medal_rank">1995</td>
                 <td class="medal_team">Balraj Sahani National Award  </td>
                 <td class="medal_gold">All India Artists Association</td>
                
              </tr>
             <tr class="">
                 <td class="medal_rank">1997</td>
                 <td class="medal_team">Bharat Shiromani Award</td>
                 <td class="medal_gold">Shiromani Institute, New Delhi</td>
                 
              </tr>
              
              
              <tr class="">
                 <td class="medal_rank">1998</td>
                 <td class="medal_team">Pawan Chamling: The Greenest Chief Minister Of India</td>
                 <td class="medal_gold">Centre of Science and Environment, New Delhi</td>
                
              </tr>
              
               <tr class="">
                 <td class="medal_rank">1998</td>
                 <td class="medal_team">Man Of The Year</td>
                 <td class="medal_gold">Biographical Institute, USA</td>
                
              </tr>
              
              <tr class="">
                 <td class="medal_rank">1999</td>
                 <td class="medal_team">Man of Dedication Award</td>
                 <td class="medal_gold">Manav Uthan Seva Samiti</td>
                
              </tr>
              
              <tr class="">
                 <td class="medal_rank">2000</td>
                 <td class="medal_team">Outstanding Intellectual of the 21st Century</td>
                 <td class="medal_gold">The International Biographic Centre, Cambridge, London</td>
                
              </tr>
              
               <tr class="">
                 <td class="medal_rank">2003</td>
                 <td class="medal_team">Doctorate Of Philosophy ( Honoris Causa)</td>
                 <td class="medal_gold">Sikkim Manipal University</td>
                
              </tr>
              
               <tr class="">
                 <td class="medal_rank">2003</td>
                 <td class="medal_team">Poets' Foundation Award </td>
                 <td class="medal_gold">Poet's Foundation Academy, Kolkata</td>
                
              </tr>
              
              <tr class="">
                 <td class="medal_rank">2009</td>
                 <td class="medal_team">Leadership and Good Governance Award</td>
                 <td class="medal_gold">Universal Peace Federation and The Interreligious and International Federation</td>
                
              </tr>
              
              <tr class="">
                 <td class="medal_rank">2009</td>
                 <td class="medal_team">Mother Teresa Lifetime Achievement Award</td>
                 <td class="medal_gold">Mother Teresa’s International & Millennium Awards Committee</td>
                
              </tr>
              
              <tr class="">
                 <td class="medal_rank">2009</td>
                 <td class="medal_team">Rising Personalities of India Award</td>
                 <td class="medal_gold">International Penguin Publishing House</td>
                
              </tr>
              
               <tr class="">
                 <td class="medal_rank">2010</td>
                 <td class="medal_team">Lifetime Achievement Award </td>
                 <td class="medal_gold">India International Friendship Society (IIFS) </td>
                
              </tr>
              
              <tr class="">
                 <td class="medal_rank">2010</td>
                 <td class="medal_team">International Ambassador for Peace Award</td>
                 <td class="medal_gold">Universal Peace Federation, SIkkim Chapter</td>
                
              </tr>
              
              <tr class="">
                 <td class="medal_rank">2010</td>
                 <td class="medal_team">Thekong Ambassador Of Unity And Peace Award</td>
                 <td class="medal_gold">Pang Lhabsol Committee</td>
                
              </tr>
              
               <tr class="">
                 <td class="medal_rank">2010</td>
                 <td class="medal_team">Kalinga Award </td>
                 <td class="medal_gold">KIIT University, Bhubaneshwar</td>
                
              </tr>
              
              <tr class="">
                 <td class="medal_rank">2010</td>
                 <td class="medal_team">Bhanu Puraskar</td>
                 <td class="medal_gold">Sikkim Sahitya Parishad</td>
                
              </tr>
              
               <tr class="">
                 <td class="medal_rank">2010</td>
                 <td class="medal_team">Best Personalities of India Award</td>
                 <td class="medal_gold">Friendship Forum of India</td>
                
              </tr>
              
              
              
              <tr class="">
                 <td class="medal_rank">2012</td>
                 <td class="medal_team">Bharat Asmita Jeevan Gaurav Puraskar</td>
                 <td class="medal_gold">Bharat Asmita Foundation, MIT Group of Institutions, Pune</td>
                
              </tr>
              
              <tr class="">
                 <td class="medal_rank">2013</td>
                 <td class="medal_team">Program Leadership Award</td>
                 <td class="medal_gold">Agriculture Leadership Award</td>
                
              </tr>
              
              <tr class="">
                 <td class="medal_rank">2016</td>
                 <td class="medal_team">Sustainable Development Leadership Award</td>
                 <td class="medal_gold">The Energy & Resource Institute (TERI), New Delhi</td>
                
              </tr>
              
              <tr class="">
                 <td class="medal_rank">2016</td>
                 <td class="medal_team">Yug Kavi Siddhicharan Award</td>
                 <td class="medal_gold">Siddhicharan Shrestha Academy</td>
                
              </tr>
              
               <tr class="">
                 <td class="medal_rank">2016</td>
                 <td class="medal_team">Vishwa Vyaktitwa Samman</td>
                 <td class="medal_gold">The International Nepali Artists Samaj </td>
                
              </tr>
              
              <tr class="">
                 <td class="medal_rank">2016</td>
                 <td class="medal_team">Samaj Kalyan Puraskar</td>
                 <td class="medal_gold">Gorkha Dukha Niwarak Sangh, Darjeeling </td>
                
              </tr>
              
              <tr class="">
                 <td class="medal_rank">2017</td>
                 <td class="medal_team">One World Award (Grand Prix)</td>
                 <td class="medal_gold">Rapunzel </td>
                
              </tr>
              
              <tr class="">
                 <td class="medal_rank">2017</td>
                 <td class="medal_team">First Bhairon Singh Shekhawat Lifetime Achievement Honour in Public Service</td>
                 <td class="medal_gold">The Family of Bhairon Singh Shekhaat, Former Vice President Of India </td>
                
              </tr>
              
              <tr class="">
                 <td class="medal_rank">2017</td>
                 <td class="medal_team">Doctor of Philosophy (honoris causa) </td>
                 <td class="medal_gold">Sikkim Central University </td>
                
              </tr>
              
              <tr class="">
                 <td class="medal_rank">2017</td>
                 <td class="medal_team">Ambassador of Organic Himalaya and Organic World </td>
                 <td class="medal_gold">Navdanya NGO, New Delhi</td>
                
              </tr>
              
               <tr class="">
                 <td class="medal_rank">2017</td>
                 <td class="medal_team">Doctorate of Literature (honoris causa) </td>
                 <td class="medal_gold">SRM University </td>
                
              </tr>
              
              <tr class="">
                 <td class="medal_rank">2018</td>
                 <td class="medal_team">Doctorate of Literature (honoris causa) </td>
                 <td class="medal_gold">ICFAI University </td>
                
              </tr>
              
              <tr class="">
                 <td class="medal_rank">2018</td>
                 <td class="medal_team">World Book of Records </td>
                 <td class="medal_gold">World Book of Records, London </td>
                
              </tr>
              
              <tr class="">
                 <td class="medal_rank">2018</td>
                 <td class="medal_team">Future Policy Gold Award </td>
                 <td class="medal_gold">United Nation’s Food and Agriculture Organisation, World Future Council and IFOAM Organics International </td>
                
              </tr>
              
               
              
              

         
         
         
         <tfoot>
             
           
         
         </tfoot>
         </tbody>
       </table>
       
       
       
         </div>
             
             
             
           </div>
        </>
    );
};

export default AwardAsCmBody;