import React, {useEffect} from 'react';

import Header2 from "../components/nav/Header2";
import ConstituencyHeader from "../components/constituency/ConstituencyHeader";
import ConstituencyBody from "../components/constituency/ConstituencyBody";



import Footer3 from "../components/nav/Footer3";

import "../constituency.css";




const Constituency = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grid.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>
                <div className="bodycontain" style={{backgroundImage: 'url(assets/img/sikkim_sdf_supporter_20090516.png?interpolation=lanczos-none&resize=2120:1200)' }}>
                <Header2 />

                <main class="main-root conMain" style={{display: 'block'}}>
                    <div id="dsn-scrollbar">
                        <div class="wrapper">

                                {/* Header */}
                                <ConstituencyHeader />
                                <div class="root-contact" style={{paddingBottom: '200px'}}>
                                    <ConstituencyBody />
                                </div>

                                <Footer3 />

                        </div>
                    </div>
                </main>

                </div>

            </>
    );

};

export default Constituency;