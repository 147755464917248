import React from 'react';

const HomeAbout = () =>{
    return(
        <>
            <section class="intro-about section-margin">
                    <div class="container" style={{paddingBottom: '50px' }}>
                        <div class="row home-brief-section-row">
                            <div class="col-lg-8">
                                <div class="intro-content-text" >

                                    <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                        data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color: '#000', textAlign: 'left'}}>
                                       BRIEF ABOUT <br/> PAWAN CHAMLING
                                    </h2>

                                    <p data-dsn-animate="text" style={{color: '#000', maxWidth: '90%', fontSize: '0.9em'}}>Pawan Chamling is the President of the Sikkim Democratic Front, 
                                    the 29th senior most political party in India. He is also the longest serving Chief Minister in the history of India, 
                                    being elected five times consecutively as the Chief Minister of Sikkim. An environmentalist, a champion of social justice and a fierce advocate of women’s rights, 
                                    Pawan Chamling has used his voice to empower the oppressed. Well known in the Nepali literary world, Pawan Chamling is a renowned poet, publisher and lyricist as well. 
                                    He is also the architect of the first fully Organic State in the world.</p>

                                    <p data-dns-animate="text" style={{color: '#000', maxWidth: '570px'}}>
                                        
                                    </p>

                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div style={{backgroundColor: '#3559c7', height:'35px', borderTopLeftRadius: '17px',
                                borderTopRightRadius: '17px', textAlign: 'left', paddingLeft: '20px', color: '#fff', fontWeight: '700'}}>
                                        <p>Recent News</p>
                                </div>
                                
                                <div style={{width: '100%', height: 'auto', backgroundColor: '#eee'}}>
                                <div id="district-image-container" >
                                   <img id="district-image" src="assets/img/WhatsApp Image 2022-03-03 at 8.27.32 PM.jpeg" />
                                </div>



                                
                                <div id="district-image-subheader" style={{padding: '40px 0', 
backgroundColor: '#3559c7', width: '100%', borderBottomRightRadius: '17px', borderBottomLeftRadius: '17px'}}>
                                    
                                   <h2 id="district-image-title" style={{textAlign: 'center', fontSize: '21px', marginBottom: '5px', color:'#fff'}}>
                                      SDF FOUNDATION DAY
                                   </h2>
                                   <h3 id="district-image-subtitle" style={{textAlign: 'center', fontSize: '21px', marginBottom: '5px', color:' #fff'}}>
                                      4TH MARCH 2022
                                   </h3>

                                   <div class="link-custom" style={{textAlign: 'center'}}>
                                    <a  href="press-releases.php" data-dsn="parallax" style={{color: '#fff', borderColor: '#ddd', textAlign: 'center'}}>
                                        <span style={{color: '#fff'}}>Known More</span>
                                    </a>
                                    </div>
                                  
                                </div>


                                </div>


                            </div>
                        </div>
                    </div>

                </section>
        </>
    );
}

export default HomeAbout;