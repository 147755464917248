import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import Header2 from "../../components/nav/Header2";


import Footer2 from "../../components/nav/Footer2";





const SikkimAlandOfSocialJustice = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">
                        <div class="wrapper">

                                <div class="root-contact">
                                    
                                    {/* Header */}
                <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/blog-inside.jpeg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container" style={{textAlign: 'left'}}>

                        <div class="inner-box m-section" style={{textAlign: 'left'}}>
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>25 April, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'26px'}}>SIKKIM: A LAND OF SOCIAL JUSTICE
                            

                            
                            </h3>
                        </div>

                    </div>

                    </div>
                </header>


                                {/* Body */}

   
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                         

                            <div class="post-content" style={{ background: '#fff' }}>
                               
                                <p style={{color: '#000'}}>
                                    India is one among the nations that upholds the principle of Social justice. Among the objectives stated in the preamble of the Constitution, the people of India resolve to constitute India into a sovereign republic and to secure social, economic, and political justice to all its citizens, thus reflecting the hopes and aspirations of all. Yet, enunciating the principle of social justice is one thing, but achieving it is quite another. In order to achieve social justice, we, the people of India, need to address the myriad problems that people face in this country because of caste, gender, religion, culture, tradition and others. The principle of Social justice can become inoperative in the absence of peace and security and in the absence of respect for all human rights and fundamental freedoms.

                                </p>
                                
                                <div>
                                    <img class="w-100" src="assets/img/sikkim.jpg" alt="" />
                                    
                                </div>
                                
                                <p style={{color: '#000'}}>
                                    Sikkim has become an excellent role model of peace for the entire nation in offering a paradigm of equal status and opportunities. Sikkim has truly become a Land of Social Justice - a place where no section of society has been neglected and no individual has been ignored. Probably no state Government in the Indian history has ever been so compassionate and generous as the Pawan Chamling led Sikkim Democratic Front (SDF) Government. 25 years of repeated electoral victories  and  the peaceful governance he has maintained is a clear reflection of the fact that his political campaign stood far above caste politics. Interethnic harmony and religious tolerance coupled with unprecedented prosperity have played a key role in reinforcing communal harmony in Sikkim like never before. 

                                </p>
                                    
                                    
                                <p style={{color: '#000'}}>
                                    Pawan Chamling is the only chief minister in the country who has not merged his party with any national party. The decentralised governance in Sikkim is backed by well thought through policies with an aim to reach out to the problems of the people at grassroot level. This would not have been possible in case of merger with national parties. 

                                </p>
                                
                                 <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>DECENTRALISED GOVERNANCE</h3>
                                 <p style={{color: '#000'}}>
                                    Empowerment of the people through decentralisation and institutional governance has been one of the highlights of Chamling's tenure. The slogan of SDF has been to inspire villagers to take control of their governance. He has established unique forums of direct civic engagement that include undertaking “Sikkim Brahmans” with the entire government machinery –Ministers, MLAs, Departmental Heads – through  the state to listen to people and address their concerns directly. He has also championed decentralised governance through decentralization and devolution of power in the Panchayati Raj, so as to ensure that people in the remotest parts have their demands met. Decentralised governance has made tremendous impact on the overall human development of the state. Sikkim recorded a maximum acceleration in Human Development Index (HDI) and became among the top 6 states in HDI in the country.

                                </p>
                                
                                 <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>RECORD BREAKING POVERTY REDUCTION AND ECONOMIC GROWTH</h3>
                                 <p style={{color: '#000'}}>
                                 Prior to 1994, the economic divide in Sikkim was massive and poverty was endemic.  The poverty level was highest among the northern states of India. The percentage of the population below poverty line was 41.43 percent in 1993. The first four governments of Sikkim miserably failed to come up with any solutions to deal with the underlying issues of stratification and Sikkim continued to remain an unequal society and true development eluded the state even after the abolition of the feudal system. 

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   The SDF Government right from the beginning immediately initiated a series of poverty reduction schemes and measures and worked strenuously towards eradicating poverty and during the first ten years in power, the poverty rate was reduced to 30.09 percent. By September 2013, Sikkim recorded the steepest fall in poverty rate from 30.09 percent in 2004 to 8.19 percent in 2011-12 which became nationally reported news. This decreased even further to 3.85 percent in 2019. 

                                </p>
                                
                                <p style={{color: '#000'}}>
                                    Chamling’s government had devised rural schemes with great care to benefit the people living at the grassroot of the society. Some of the significant decisions that were taken to benefit the poor were 100 percent electrification, free electricity upto 50 units, free distribution of rice through Annadan Yojana at 35 kg monthly, simple licensing system for rural traders and businessmen, a Land Bank Scheme to provide land worth Rs 1 lakh for the landless to name a few.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   There is unanimity even among his critics that Chamling has been rewarded by his voters for bringing about unprecedented development in the state. As per data released by the Union ministry for statistics and programme implementation, Sikkim's GDP growth has consistently been above the national average and its per capita income is the third highest among states at Rs. 2,10,394, which is double the national average of Rs 1,03,219.   
                                </p>
                                
                                <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>INCLUSIVE POLITICS</h3>
                                 <p style={{color: '#000'}}>
                                Society has seen enough growth that excludes the poor. Inclusive, collective growth and prosperity of the Sikkimese society was the motto of the SDF Government. The Sikkim Government under the leadership of Pawan Chamling has consistently made efforts at all times to ensure that Sikkim’s developmental model is based on the premise of inclusive growth with an aim to make Sikkim a self-sufficient state. The SDF Government’s sensitivity and commitment to equality, right to housing, universal health care, women’s rights and empowerment, social justice, child welfare of differently abled individuals, equitable development have brought about a sharp increase in the quality of life, life expectancy, peace and happiness in Sikkim. 

                                </p>
                                
                                <p style={{color: '#000'}}>
                                His administration has gone far beyond fulfilling all the basic needs of the people of Sikkim. Sikkim has erected a crucial milestone in the history of identity politics. No State Government has been as proactive as the Chamling led SDF Government in the protection of ethnic, religious and cultural minorities. The recognition of different communities and groups for reservation, recognition of local languages as official state language, providing political rights at all costs, all was done without a single incident of violent social unrest.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                Since 1994, the SDF government has introduced several legislations and amendments to strengthen grassroots democracy, increase popular participation in the decision-making process, conduct regular elections and maintain activity mapping. Chamling believes that in the ethnically heterogeneous state, local government can create non-violent platforms for interethnic and intergroup discussion relating to local issues and allocation of resources.
Maintenance of social harmony among the three major ethnic groups-Nepali, Bhutia and Lepcha-has been one of the biggest achievements of Pawan Chamling. Chamling, who comes from a modest Nepali background, came to power on the slogan of “Anuhar haina, ragat milau, Jaat jaina, niti milau” (Don’t compare faces but compare blood, dont compare community, but compare policy), and has been able to provide leadership to diverse sections of society.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                The exemplary ethnic and interfaith harmony is one of Sikkim’s greatest strengths. The same is a result of Chamling Government’s sensitivity to public sentiments. There are 109 insurgency groups in India and none of them belong to Sikkim. This speaks volumes for the rule of law established in the state as just next door, the north eastern states are mired in the hotbed of insurgency politics. It has been the trust of the people of Sikkim in Pawan Chamling’s Government that kept the fragile harmony intact despite the best efforts of political and non-political bodies to hamper the same.

                                </p>
                                
                                
                                <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>RESERVATIONS</h3>
                                 <p style={{color: '#000'}}>
                                Keeping in mind the disadvantaged condition of different categories of people and individuals, a conscious effort has been made by the government to give children, senior citizens, differently abled, SCs/STs, OBCs, transgender and other communities equal access to basic needs, education, healthcare and economic opportunities. 

                                </p>
                                
                                <p style={{color: '#000'}}>
                                Reservation in government services, higher education, equal opportunities for minorities, marginalized and disadvantaged groups is a worthwhile way of ensuring representation of all sections of society and promoting social justice. The Sikkim Government during the chief ministership of Pawan Chamling made specific reservation quotas for each reserved category in government services, higher studies, Panchayati Raj Institutions and other areas. Significantly enough, one of the key factors behind the exemplary socio-economic advancement of Sikkim is the access to government jobs. Nearly 10 percent of the state population holds government jobs, which is a record in the country. As of 2014, about 3.55 percent of the Indian population had government jobs. 

                                </p>
                                
                                 <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>WOMEN EMPOWERMENT AND HEALTH</h3>
                                 <p style={{color: '#000'}}>
                                Social justice is not possible without women empowerment. Since ancient times, women have been deprived of education and forced to adapt to a patriarchal lifestyle. The SDF Government has fought hard to rectify educational inequality, especially between the sexes. Today women in Sikkim have some of the highest educational attainments in the country. Female literacy rate in Sikkim tripled from 22 percent in 1981 to 76 percent in 2011 which is much higher than the national female literacy rate of 65.46 percent. 

                                </p>
                                
                                <p style={{color: '#000'}}>
                                There is unparalleled reservation of 50 percent for women in the Panchayati Raj Institution and Urban local bodies and 30 percent reservation in government services and higher education. We can see a growing number of women in government jobs. The Sikkim Government has recognised the importance of widow remarriage and has implemented a Widow Remarriage Scheme in 1994 which ensures the social security of widows by giving them an incentive of Rs 10,000/- per couple upon remarriage. Sikkim also does an exceptional job of ensuring personal rights and safety. Sikkim has been reported to be the safest place for women in India as per NCRB survey 2015 with lowest crime rate against women in the country.

                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                Critics argue that most of his schemes have been populist, but the numbers reflect that these schemes have helped in improving social indicators. The SDF government implemented a number of pro-poor policies in health sector as well. With the arrival of the SDF Government in December 1994, staffing in healthcare increased. The government has offered free medical treatment to all within the state. When a patient is referred to hospitals outside the state, they receive Rs 2 lakh as assistance from the government. If the patient belongs to the BPL category, the government will bear the entire expense.
                                </p>
                                
                                <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>EDUCATION</h3>
                                 <p style={{color: '#000'}}>
                                The Pawan Chamling Government strongly believed that education is the most powerful weapon for individuals and society to liberate themselves from the shackles of backwardness. That is why his governance gave such a tremendous impetus to the educational development in the state.  The Chamling led government made some unprecedented decisions to promote education in the state right from its emergence. More and more schools were opened and older schools were upgraded. New government colleges were established, nearly 20 percent budget of the state was allocated towards education and uniforms, textbooks, exercise copies, books, shoes, socks, bags, raincoats and meals were provided free of cost to the students. Scholarships were instituted which enabled students from government schools to access education in the best private schools of the country. The state witnessed a massive institutional growth during the two decades of SDF Government in power. 
                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                               Also, the subsequent National Awards that the state won both validate and appreciate the efforts of the government. The Chamling led government has gone far beyond the level that the Indian Constitution has prescribed. The Parliament of India enacted the ‘Right of Children to Free and Compulsory Education Act’ (RTE) which provides for free and compulsory education for children between the ages of 6 and 14 under Article 21A of the Indian Constitution. The Chamling administration has stretched the limits of this act by providing free education up to graduation level and up to PhD level for students who come under BPL category. Such free education in Government Educational Institutes right up to graduation level is rarely available elsewhere in the world. Sikkim ranked number one nationally in the state’s per capita spending in education. Sikkim has a per capita spending of Rs 12,364 on education which is the highest in the country. The rest of the states spend nearly or less than half of what Sikkim spends on education. 
                                </p>
                                
                                <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>SOCIAL JUSTICE UNDER SDF</h3>
                                 <p style={{color: '#000'}}>
                                Despite allegations of corruption and nepotism levelled against his government by the Sikkim Krantikari Morcha (SKM), formed mostly by rebels of the Sikkim Democratic Front (SDF), Chamling has managed to keep his vote base intact, especially among the rural population which constitutes 75 per cent of the total population. In 2019, even though SDF failed to form the government due to loss in overall seats at the Legislative Assembly, the mandate given to Pawan Chamling and his party is reflected by the popular vote in which SDF secured 47.63% votes as opposed to 47.17% votes by the SKM party. Perhaps this is a testament to the decades of result oriented work that the Pawan Chamling led government has done to ensure social justice to each and every section of Sikkimese society. From engineering the boom in the education and health sector, providing reservations to each and every community in Sikkim, to pulling up those at the bottom of the economic ladder with the sharpest fall in poverty that India has seen, there is much to be said for the SDF government’s performance in the last few decades. All these reasons truly make Sikkim a land of Social Justice.

                                </p>
                              

                            </div>
                        </div>




                    </div>


                </div>








                                    </div>
                       

                                <Footer2 />

                        </div>
                    </div>
                </main>

            </>
    );

};

export default SikkimAlandOfSocialJustice;