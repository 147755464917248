import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr31Oct20212 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/31OCT21.jpg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>31 Oct, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and President of the SDF party Shri Pawan Chamling has reiterated his claims that the SKM government is selling all the prime Sikkimese assets and giving loans from SBS to outsiders. He has also alleged the state government of seeking 7 more functional hydropower projects which belonged to the Sikkim government. He has challenged the SKM government to share all the MoUs and agreements of all the sales of Sikkimese properties to outsiders with people.
                           </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                    “Q. Chief Minister PS Tamang has been giving justification after justification over your allegation of his government selling off government assets. What would you say about his statements?


                                </p>

                                

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> I am watching his growth as a liar. He is growing as a liar from strength to strength. The Emperor of Lies is here with us and he is heading our state government. The SKM won the 2019 election on lies, he became CM by telling lies, he won the by-poll elections by telling lies and he is now running the government on lies.



                                </p>

                                <img class="w-100" src="assets/img/pressrelease/31OCT21.jpg" alt="" />
                                <br />
                                <br />

                                <p style={{color: '#000'}}>
                                    Let me begin by responding to his lies about the sale of the Old STNM hospital. The SDF government had planned a project to construct a Central Taxi Stand on the lower floors, a children’s park on the top floor and an open public park on the terrace for people to socialize, relax and enjoy breathing space. It was an 82 crore rupees project to be undertaken by the state government under the Smart City Project. We had not sold it to a private company. However, what the SKM government did was to sell it to a private company to construct a shopping mall. Their project is designed to benefit a private company. There is an utter lack of interest in the welfare of the people.

                                </p>
                                 <p style={{color: '#000'}}>
                                    The taxi stand at Old West Point School in the heart of Gangtok has been sold to a man from Siliguri. The SDF government had built a Taxi Stand for the people of Sikkim. It was fully under the State Government. In the second stage, we had a plan to upgrade it as a Main Line Taxi stand. Now it has become the private property of a Siliguri man. Only 25% of the total space will be used as a car park and 75% of the space will be used for his five-star hotel and mall. He is saying that the property will be returned to the Sikkim government after 26 years. He doesn’t seem to know the fundamental difference between PPP (Public-Private-partnership) and BOT (Build-Operate-Transfer). Why does he not show the MoU and agreement to the people for them to see and decide?  

                                </p>
                                 <p style={{color: '#000'}}>
                                  The 60.08 per cent of Hydro Power equity share is the property of the people of Sikkim. This does not belong to any private company or central government undertakings. No amount of false justification will satisfy the people and compensate their loss. Let him show proof that he hasn’t sold our equity share and come clean of it. Also should he not tell us how he sold it? He should know that Sikkim's power projects are the property of the Sikkimese people, not his private property. He should bring the full details of the procedure, amount and parties involved in the share sale into the public domain.



                                </p>
                                
                                <p style={{color: '#000'}}>
                                  Sikkim’s Chief Minister must also explain why he sold the other seven hydro power projects which belonged to the Sikkim Government? Everything about those hydropower must be brought to the people’s notice. The people of Sikkim are the owners of Sikkim’s property. Did he ask the people before taking these decisions to sell Sikkim’s property?




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Another shocking decision by the SKM government is to build a Centre for Excellence, a private educational institute, with 90% funding by the Sikkim government. A private company will run its administration and business. It involves a total cost of 892 crore rupees. People have the right to know on what condition the government is funding 90% of its cost? Furthermore, what is the admission quota for our Sikkimese students? Who will get jobs? The MoU must be made public for the people to see.



                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                 He has made a desperate attempt to justify the unfortunate sale of government land in Karfektar by saying that all the jobs in the cancer hospital will be given to Sikkimese people, including 20% of the revenue annually. I challenge him to show the MoU and the agreement to the Sikkimese people. What will be the direct benefits for Sikkim? Let me remind you that the cancer hospital in Karfectar is a corporate house. They are here, not to carry out welfare schemes, but to do serious business. Shri PS Tamang says that the land has been given to the company without any cost. What was the compulsion on the Sikkim government to give government land to a private company freely for their business? What kind of administrative decision is this? He gave away the biggest flat land Sikkim has ever had. It was a prime piece of land for Sikkim. What will happen to our historic existent Mother Dairy Farm and India’s first-ever Capacity Building Directorate? Where are these institutes being shifted?



                                </p>
                                
                                <p style={{color: '#000'}}>
                                    Now let me remind the people of the mess in the SBS under the SKM government. Chief Minister PS Tamang says that no outsider has been given a loan. Let me ask him – when did Sanjay Mittal, a resident of Siliguri, become Sikkimese? He has been given a loan of rupees 70 crores from the SBS. I dare him to show the list of all the individuals who took loans from the SBS under the previous government and the present government. Let the people see. The owners of the SBS are the people of Sikkim. The bank is just a custodian of public money. Do not disburse public money arbitrarily.



                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                     
I want to remind him that he is not the PS Tamang of the pre-polls campaigns when he used to openly tell lie after lie. He is now the Chief Minister of Sikkim. Every lie he speaks taints Sikkimese image, our pride and our dignity. Most importantly, every lie he speaks darkens Sikkimese's future. He is selling Sikkimese assets piece by piece as if the entire state was his inheritance. Only the Sikkimese people have the right to Sikkim’s assets. No one has the authority to sell their assets, rights and future.  I once again demand that the state government make all the MoUs and agreements public because these are the people’s assets. Transparency cannot be proven by giving shallow speeches and press interviews. Transparency must be there on paper and in intention.  

                                </p>
                                <p style={{color: '#000'}}>
                                   While he keeps telling lies, I am ringing the warning bell for the people to know what’s going on and asking them to step up to prevent this ruinous sale of our Sikkimese assets. I hope people will give much-needed heed to this warning and take necessary action before it is too late.”
                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr31Oct20212;