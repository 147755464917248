import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import Header2 from "../components/nav/Header2";


import Footer2 from "../components/nav/Footer2";





const Achievements = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">
                        <div class="wrapper">

                                <div class="root-contact">
                                    
                                    {/* Header */}
                        <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                            <div class="container" style={{paddingBottom: '50px'}}>
                                <div class="row ">
                                    <div class="col-lg-12">
                                        <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                            <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                                data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                                Achievements 
                                            </h2>

                                            <p class="infographic-page-header-desc" style={{fontSize: '1rem', fontWeight: '500', maxWidth:'100%', color:'#000', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px'}}>
                                            A snapshot of some of the major achievements of the SDF government.

                                            </p>


                                        </div>
                                    </div>

                                </div>
                            </div>

                        </section>


                                    {/* Body */}

   
                                    <section class="our-work work-under-header  section-margin" data-dsn-col="3">
                   
                 
                 
                 <div class="container" style={{maxWidth: '1400px'}}>
                     <div class="row">
                         <div class="col-lg-9 offset-lg-3">
                                <div class="work-container">
                                    <div class="slick-slider">
                                        
                                        <div class="work-item slick-slide" style={{height: '50vh', borderRadius: '12px'}}>
                                            <img class="has-top-bottom" src="assets/img/achivements/organic-state.png" alt="" />
                                            <div class="item-border"></div>
                                            <div class="item-info" style={{width: '400px'}}>
                                                <Link to="#" data-dsn-grid="move-up" class="effect-ajax" style={{color:'#000'}}>

                                                    <h4>100% Organic State</h4>
                                                    <p style={{fontSize: '16px', fontWeight:'700', lineHeight: '18px', marginTop:'10px', color:'#fff'}}>
                                                        Sikkim is the first fully organic state in the world. After a journey of 12 years starting from 2003, Sikkim was declared a fully organic state on 18th Jan 2016 by Narendra Modi, Prime Minister of India. Sikkim received the Future Policy Gold Award from FAO United Nations in 2018 in recognition of the organic policy. Today, Pawan Chamling is seen as a brand ambassador for organic farming as it was his vision which led to Sikkim becoming the first organic state in the entire globe.

                                                    </p>
                                                </Link>

                                            </div>
                                        </div>
                                        
                                        
                                        <div class="work-item slick-slide" style={{height: '50vh', borderRadius: '12px'}}>
                                            <img class="has-top-bottom" src="assets/img/achivements/Nirmal Rajyaa.png" alt="" />
                                            <div class="item-border"></div>
                                            <div class="item-info" style={{width: '400px'}}>
                                                <Link to="#" data-dsn-grid="move-up" class="effect-ajax" style={{color:'#000'}}>

                                                   <h4>1st Nirmal Rajya</h4>
                                                    <p style={{fontSize: '16px', fontWeight:'700', lineHeight: '18px', marginTop:'10px', color:'#fff'}}>
                                                        Sikkim became the first Nirmal Rajya of the country, achieving 100% sanitation and setting a benchmark for other states to follow. Former President Pratibha Devisingh Patil conferred the Nirmal Rajya Puraskar on Pawan Chamling in 2008. It was as early as 1999 when Pawan Chamling launched a community led sanitation campaign in Sikkim.

                                                    </p>
                                                </Link>

                                            </div>
                                        </div>
                                        
                                        
                                        <div class="work-item slick-slide" style={{height: '50vh', borderRadius: '12px'}}>
                                            <img class="has-top-bottom" src="assets/img/achivements/Sikkim-Richest-State.png" alt="" />
                                            <div class="item-border"></div>
                                            <div class="item-info" style={{width: '400px'}}>
                                                <Link to="#" data-dsn-grid="move-up" class="effect-ajax" style={{color:'#000'}}>

                                                     <h4>Second richest state</h4>
                                                    <p style={{fontSize: '16px', fontWeight:'700', lineHeight: '18px', marginTop:'10px', color:'#fff'}}>
                                                        Sikkim recorded the second highest per-capita income in 2019 at Rs 3,80,000. The per capita income has increased steadily since 1994. In comparison, in 2012, the percapita income was Rs.1,58,000. Sikkim is the second richest state in the country today, following Goa. 
                                                    </p>
                                                </Link>

                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                        </div>
                     </div>
                     
                     
                     <div class="row">
                         <div class="col-lg-9 offset-lg-3">
                                <div class="work-container">
                                    <div class="slick-slider">
                                        
                                            <div class="work-item slick-slide" style={{height: '50vh', borderRadius: '12px'}}>
                                            <img class="has-top-bottom" src="assets/img/achivements/poverty.png" alt="" />
                                            <div class="item-border"></div>
                                            <div class="item-info" style={{width: '400px'}}>
                                                <Link to="#" data-dsn-grid="move-up" class="effect-ajax" style={{color:'#000'}}>

                                                     <h4>Steepest fall in poverty</h4>
                                                    <p style={{fontSize: '16px', fontWeight:'700', lineHeight: '18px', marginTop:'10px', color:'#fff'}}>
                                                        Under the leadership of Pawan Chamling, Sikkim has seen the steepest fall in poverty with the percentage of BPL at 41.43% in 1993 to 3.85% in 2019. This is a historic and sharpest fall in poverty seen in any country or state post World War 2 as stated by the World Bank.

                                                    </p>
                                                </Link>

                                            </div>
                                        </div>
                                        
                                        
                                        <div class="work-item slick-slide" style={{height: '50vh', borderRadius: '12px'}}>
                                            <img class="has-top-bottom" src="assets/img/achivements/Basic-needs-minium-fullfiled.png" alt="" />
                                            <div class="item-border"></div>
                                            <div class="item-info" style={{width: '400px'}}>
                                                <Link to="#" data-dsn-grid="move-up" class="effect-ajax" style={{color:'#000'}}>

                                                   <h4>Basic Minimum Needs fulfilled
</h4>
                                                    <p style={{fontSize: '16px', fontWeight:'700', lineHeight: '18px', marginTop:'10px', color:'#fff'}}>
                                                        Sikkim is the first state in the country to fulfil basic minimum needs including shelter, food, water, education, health and electricity. Under the SDF government, the people of Sikkim were provided with a free pucca house, free or subsidised rice, free education till university, water in every household, free healthcare and free electricity for BPL.

                                                    </p>
                                                </Link>

                                            </div>
                                        </div>
                                        
                                        
                                        <div class="work-item slick-slide" style={{height: '50vh', borderRadius: '12px'}}>
                                            <img class="has-top-bottom" src="assets/img/achivements/first wave of industrialization4.png" alt="" />
                                            <div class="item-border"></div>
                                            <div class="item-info" style={{width: '400px'}}>
                                                <Link to="#" data-dsn-grid="move-up" class="effect-ajax" style={{color:'#000'}}>

                                                  <h4>First wave of Industrialization
</h4>
                                                    <p style={{fontSize: '16px', fontWeight:'700', lineHeight: '18px', marginTop:'10px', color:'#fff'}}>
                                                        The SDF government brought about a period of industrialization in Sikkim. With the establishment of manufacturing units in the pharma sector to hydro power projects and education institutions, the thrust on industrialization positively impacted the economy, expanding the job market and increasing GSDP and GSVA exponentially. A sudden rise in GSDP was noted in 2009-10 with an astounding growth rate of 73.6% which was driven by this wave of industrialization.

                                                    </p>
                                                </Link>

                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                        </div>
                     </div>
                     
                     
                     
                     
                     
                     <div class="row">
                         <div class="col-lg-9 offset-lg-3">
                                <div class="work-container">
                                    <div class="slick-slider">
                                        
                                            <div class="work-item slick-slide" style={{height: '50vh', borderRadius: '12px'}}>
                                            <img class="has-top-bottom" src="assets/img/achivements/adding-11-years.png" alt="" />
                                            <div class="item-border"></div>
                                            <div class="item-info" style={{width: '400px'}}>
                                                <Link to="#" data-dsn-grid="move-up" class="effect-ajax" style={{color:'#000'}}>

                                                   <h4>Adding 11 years to Sikkimese life
</h4>
                                                    <p style={{fontSize: '16px', fontWeight:'700', lineHeight: '18px', marginTop:'10px', color:'#fff'}}>
                                                        An average Sikkimese person’s life under SDF increased by 11 years from 62.7 years in 1993 to 73.15 years in 2016. This has been a result of improvements and expansion in healthcare services and facilities, economic growth, improved sanitation and pre-emptive healthcare interventions. The life expectancy of the Sikkimese people is one of the highest in the country.

                                                    </p>
                                                </Link>

                                            </div>
                                        </div>
                                        
                                        
                                        <div class="work-item slick-slide" style={{height: '50vh', borderRadius: '12px'}}>
                                            <img class="has-top-bottom" src="assets/img/achivements/Kacha-House.png" alt="" />
                                            <div class="item-border"></div>
                                            <div class="item-info" style={{width: '400px'}}>
                                                <Link to="#" data-dsn-grid="move-up" class="effect-ajax" style={{color:'#000'}}>

                                                   
                                                   <h4>First kutcha house free state</h4>
                                                    <p style={{fontSize: '16px', fontWeight:'700', lineHeight: '18px', marginTop:'10px', color:'#fff'}}>
                                                        Sikkim is India’s first kutcha house free state. The SDF party began its mission to provide free housing to the Sikkimese people right in 1994. Starting from distributing Rs 20,000 for house upgradations, free GCI sheets to free one story building under the CMRHM scheme, SDF has been successful in transforming Sikkim into the first kutcha house free state in the country.

                                                    </p>
                                                </Link>

                                            </div>
                                        </div>
                                        
                                        
                                        <div class="work-item slick-slide" style={{height: '50vh', borderRadius: '12px'}}>
                                            <img class="has-top-bottom" src="assets/img/achivements/free Education.png" alt="" />
                                            <div class="item-border"></div>
                                            <div class="item-info" style={{width: '400px'}}>
                                                <Link to="#" data-dsn-grid="move-up" class="effect-ajax" style={{color:'#000'}}>

                                                   <h4>Free education till University</h4>
                                                    <p style={{fontSize: '16px', fontWeight:'700', lineHeight: '18px', marginTop:'10px', color:'#fff'}}>
                                                        Education has been a top priority of the SDF government. From spending 20% of the budget on education to expanding the number of schools and higher education institutions across the state, SDF has made education accessible to all. In Sikkim, the policies set by the SDF government makes education free right up to PhD level for BPL students. The SDF government also provided free uniforms, books, raincoats and so on to students in school.

                                                    </p>
                                                </Link>

                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                        </div>
                     </div>
                     
                     
                     <div class="row">
                         <div class="col-lg-9 offset-lg-3">
                                <div class="work-container">
                                    <div class="slick-slider">
                                        
                                            <div class="work-item slick-slide" style={{height: '50vh', borderRadius: '12px'}}>
                                            <img class="has-top-bottom" src="assets/img/achivements/Peaceful-border.png" alt="" />
                                            <div class="item-border"></div>
                                            <div class="item-info" style={{width: '400px'}}>
                                                <Link to="#" data-dsn-grid="move-up" class="effect-ajax" style={{color:'#000'}}>

                                                    <h4>Peaceful border state</h4>
                                                    <p style={{fontSize: '16px', fontWeight:'700', lineHeight: '18px', marginTop:'10px', color:'#fff'}}>
                                                       Under the leadership of Pawan Chamling, Sikkim was synonymous with peace. It is an astounding feat that despite being a geopolitically sensitive border state, Sikkim was able to maintain not years but decades of peace. This was due to a strong system of law and order under SDF. Unlike neighbouring states in the northeast, Sikkim does not have even one insurgency group within its borders. This is one of the biggest achievements of the SDF government. 

                                                    </p>
                                                </Link>

                                            </div>
                                        </div>
                                        
                                        
                                        <div class="work-item slick-slide" style={{height: '50vh', borderRadius: '12px'}}>
                                            <img class="has-top-bottom" src="assets/img/achivements/First-state-of-capacity-building.png" alt="" />
                                            <div class="item-border"></div>
                                            <div class="item-info" style={{width: '400px'}}>
                                                <Link to="#" data-dsn-grid="move-up" class="effect-ajax" style={{color:'#000'}}>

                                                    <h4>First state to begin capacity building</h4>
                                                    <p style={{fontSize: '16px', fontWeight:'700', lineHeight: '18px', marginTop:'10px', color:'#fff'}}>
                                                       Sikkim has created another benchmark by institutionalizing capacity building within the education framework for those seeking to build vocational skills for gainful employment. A Directorate of Capacity Building was established in 2007 and a State Institute for Capacity Building was established at Karfectar. 41 livelihood schools have been established across the state in order to facilitate capacity building of unemployed youth.

                                                    </p>
                                                </Link>

                                            </div>
                                        </div>
                                        
                                        
                                        <div class="work-item slick-slide" style={{height: '50vh', borderRadius: '12px'}}>
                                            <img class="has-top-bottom" src="assets/img/achivements/hospital.png" alt="" />
                                            <div class="item-border"></div>
                                            <div class="item-info" style={{width: '400px'}}>
                                                <Link to="#" data-dsn-grid="move-up" class="effect-ajax" style={{color:'#000'}}>

                                                    <h4>Second largest government hospital in India</h4>
                                                    <p style={{fontSize: '16px', fontWeight:'700', lineHeight: '18px', marginTop:'10px', color:'#fff'}}>
                                                       Sikkim is now home to the second largest government multi-specialty hospital in India, second only to AIIMS, Delhi. Because of Pawan Chamling’s vision, the people of Sikkim can access world class healthcare right in their home state in the 1002 bedded STNM Hospital. The hospital was a boon for Sikkim as it was inaugurated in 2019, just in time to provide relief to the people of Sikkim in the COVID-19 pandemic. Today, a large number of patients from beyond Sikkim’s borders also travel to the state to avail of medical facilities at STNM and this has boosted medical tourism in the state.
                                                    </p>
                                                </Link>

                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                        </div>
                     </div>
                     
                     
                     
                     <div class="row">
                         <div class="col-lg-9 offset-lg-3">
                                <div class="work-container">
                                    <div class="slick-slider">
                                        
                                        <div class="work-item slick-slide" style={{height: '50vh', borderRadius: '12px'}}>
                                            <img class="has-top-bottom" src="assets/img/achivements/Tourismm.png" alt="" />
                                            <div class="item-border"></div>
                                            <div class="item-info" style={{width: '400px'}}>
                                                <Link to="#" data-dsn-grid="move-up" class="effect-ajax" style={{color:'#000'}}>

                                                    <h4>India’s best emerging tourism state</h4>
                                                    <p style={{fontSize: '16px', fontWeight:'700', lineHeight: '18px', marginTop:'10px', color:'#fff'}}>
                                                       Under SDF, Sikkim has emerged as a prime tourism destination with the development of  a plethora of tourism infrastructure from the Char Dham, Namchi, Buddha Park, Ravangla to the highest Sky walk in Asia at Bhaley Dhunga, Yangang. Due to the rapid development of innovative tourist attractions and the expansion and strengthening of connectivity via roads and airport, Sikkim was ranked number 17 in the “52 places to go in 2017” by the New York Times and named the best region to visit in 2014 by Lonely Planet.

                                                    </p>
                                                </Link>

                                            </div>
                                        </div>
                                        
                                        
                                        <div class="work-item slick-slide" style={{height: '50vh', borderRadius: '12px'}}>
                                            <img class="has-top-bottom" src="assets/img/achivements/Leader-in-field-of-environment.png" alt="" />
                                            <div class="item-border"></div>
                                            <div class="item-info" style={{width: '400px'}}>
                                                <Link to="#" data-dsn-grid="move-up" class="effect-ajax" style={{color:'#000'}}>

                                                    <h4>A Leader in the field of Environment</h4>
                                                    <p style={{fontSize: '16px', fontWeight:'700', lineHeight: '18px', marginTop:'10px', color:'#fff'}}>
                                                      Sikkim has become a leader in the environment field with a massive number of programs and schemes catered towards the protection and conservation of the environment. 
                                                      From the Ten Minutes to Earth program which is a mass plantation program to the banning of plastic all the way in 1998 ( the first state in the country to do so), 
                                                      setting up of the Glacier and Climate Change Commission to making Sikkim fully organic, the environment driven policies and programs under SDF are many. 
                                                      This has brought several recognitions to Sikkim from the Greenest Chief Minister Award (1999), One World Award (2017) and Future Policy Gold Award (2018) to name a few. SDF’s green policies and programs has led to Sikkim’s forest cover increasing from 43.95 per cent in 1993, 47.34 per cent in 2013, perhaps the only state to achieve such increase.

                                                    </p>
                                                </Link>

                                            </div>
                                        </div>
                                        
                                        
                                        <div class="work-item slick-slide" style={{height: '50vh', borderRadius: '12px'}}>
                                            <img class="has-top-bottom" src="assets/img/achivements/women-emporwment.png" alt="" />
                                            <div class="item-border"></div>
                                            <div class="item-info" style={{width: '400px'}}>
                                                <Link to="#" data-dsn-grid="move-up" class="effect-ajax" style={{color:'#000'}}>

                                                    <h4>50% reservation for women in Panchayati Raj


</h4>
                                                    <p style={{fontSize: '16px', fontWeight:'700', lineHeight: '18px', marginTop:'10px', color:'#fff'}}>
                                                      SDF’s mission has been to empower women as equal partners in Sikkim’s developmental, cultural and societal trajectory. Under Pawan Chamling’s visions, Sikkim has instituted 50% reservation for women in the Panchayati Raj and 33% reservation in employment and education. 

                                                    </p>
                                                </Link>

                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                        </div>
                     </div>
                     
                     
                     
                     
                     <div class="row">
                         <div class="col-lg-9 offset-lg-3">
                                <div class="work-container">
                                    <div class="slick-slider">
                                        
                                            <div class="work-item slick-slide" style={{height: '50vh', borderRadius: '12px'}}>
                                            <img class="has-top-bottom" src="assets/img/achivements/Connecting-to-the-world.png" alt="" />
                                            <div class="item-border"></div>
                                            <div class="item-info" style={{width: '400px'}}>
                                                <Link to="#" data-dsn-grid="move-up" class="effect-ajax" style={{color:'#000'}}>

                                                    <h4>Connecting Sikkim to the World

</h4>
                                                    <p style={{fontSize: '16px', fontWeight:'700', lineHeight: '18px', marginTop:'10px', color:'#fff'}}>
                                                     Sikkim’s connectivity issues has been a top priority for the Pawan Chamling government. Under SDF, Sikkim’s road network increased from 1800 km to 10,000 km and massive road widening was done as well. The very first airport of Sikkim – Pakyong Greenfield Airport – was inaugurated in 2018. The mission to connect Sikkim to the railway network began in 2017 and soon, Rangpo Railway Station will connect Sikkim to the Sevoke-Rangpo Railway line, expanding travel options for tourists and Sikkimese people alike. 

                                                    </p>
                                                </Link>

                                            </div>
                                        </div>
                                        
                                        
                                        
                                        <div class="work-item slick-slide" style={{height: '50vh', borderRadius: '12px'}}>
                                            <img class="has-top-bottom" src="assets/img/achivements/SDA.png" alt="" />
                                            <div class="item-border"></div>
                                            <div class="item-info" style={{width: '400px'}}>
                                                <Link to="#" data-dsn-grid="move-up" class="effect-ajax" style={{color:'#000'}}>

                                                     <h4>Decentralization and Devolution

</h4>
                                                    <p style={{fontSize: '16px', fontWeight:'700', lineHeight: '18px', marginTop:'10px', color:'#fff'}}>
                                                    With an aim to strengthen grassroots governance, a process of decentralization and devolution of power was undertaken and 31 Gram Vikas Kendras (Village Development Centres) and 176 Gram Prashashan Kendras (Village Administration Centres) and 32 BDO offices were built. The president at the GPU has administrative control and discretion of all government institutions including primary schools, primary health centres, libraries, irrigation work etc. The SDF government has handed over power to the grassroot for governance and decision making.

                                                    </p>
                                                </Link>

                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                        </div>
                     </div>
                 </div>
             
                
                </section>








                                    </div>
                       

                                <Footer2 />

                        </div>
                    </div>
                </main>

            </>
    );

};

export default Achievements;