import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr28Nov2020 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/28Nov.jpg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>28 Nov, 2020</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and the President of the SDF party Shri Pawan Chamling says that he hasn’t budged an inch 
                            from the political field. The SDF party has been going slow in terms of holding public meetings in the public interest.
                             The Covid pandemic stricken Sikkim needed a break for people to heal. The SKM government needed time to focus on improving 
                             administration. But the government failed miserably. The SDF party is fully connected to the people and will soon intensify 
                             its programs. The SDF will continue to raise voice against misgovernance, loss of law and order and utter lack of development.
                           </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                               “Q. When will the SDF party go out to the people? SKM workers are spreading rumours that Pawan Chamling will not come back to active politics. They are challenging the SDF party to come out into the field. Chief Minister Goley has said that you should hold meetings openly rather than holding meetings in the SDF Bhawan. What would you like to say in response?
Ans: Those who are saying that Pawan Chamling will exit the arena of active politics are only expressing their wishful thinking. They know that the SDF is the most popular party, the centre of hope and the aspiration of the Sikkimese people. They know that Pawan Chamling is the most trusted and time-tested leader. They know that if Pawan Chamling steps down, they can take Sikkim for granted. So the rumour is nothing but the expression of their fear and lies. Let me tell them that I have not budged one inch from the field. I am as much with Sikkim and the Sikkimese people as I ever was. The SDF party and all the sahayoddhas are fully ready to make any sacrifice. We have the same josh and hosh. This party has the power to face the deadliest beasts, not just a few rabid puppies. The curse of a housefly doesn’t kill a bull. To underestimate the SDF party is to dig the grave of one’s political career. My advice to the local Baba Bhangis and Nostradamuses is that instead of worrying about my future and fortunetelling about the SDF and me, use your fortunetelling on yourselves.


                                </p>


                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> First of all, these accusations were not heard so much while we were in the government but it is after handing over the reins of the government that we hear more about them. Second, no one has made these accusations formally, whether within the party or outside. Some people in their political interest may say these things in passing but this too, it is not for sure. However, since these questions have been placed before me now, it is my democratic responsibility to answer them since I am a public representative. 
I cannot believe that people ask such foolish questions so seriously. These questions show that they have still not been able to truly accept the democratic system and are still undergoing a hangover of the old feudalistic mindset. That is why they are confused in politics even today. This is symptomatic of a belief system which views politics simply as a means to get elected and to rule. This thinking itself is undemocratic and anti-people. I believe those who pose these accusatory questions themselves don’t know what they are asking.


                                </p>

                                <img class="w-100" src="assets/img/pressrelease/28Nov.jpg" alt="" />
                                <br />
                                <br />

                                <p style={{color: '#000'}}>
                                   Yes, we have gone slow for the last two years or so due to various reasons. Covid was one. We also needed to give the new government time to focus on their administration and development and to prove themselves. But they have failed miserably again and again. Now they can’t say that we disturbed them. They had their time and chance and they have squandered it. We are aware that the people of Sikkim are desperately waiting for us to come into the field. We are missing them. We will go to them sooner rather than later. We are in touch with hundreds of our well-wishers. A huge majority of people are with us. Government employees, businesspeople and youth are with us. Every day, I receive hundreds of calls and messages from people from all walks of life.


                                </p>
                                 <p style={{color: '#000'}}>
                                   The SKM government has been victimizing the poor since its inception. People are going through terrible economic and mental stress. When we go to the people, they will intensify that victimization and persecution. We know that people need some rest. This cruel government has a brutal weapon called persecution. Their family members and relatives who have been victimized are already going through trauma. People have been shown all kinds of intimidation. People have been waiting for their old age pensions, bills, medicines and even salaries from this government. Many are chasing the payments of their construction and other bills. We do not want the people to go through such harassment, persecution and losses, especially when they had already borne such irreparable loss during the Covid pandemic. They all need a little time to heal. So our decision to go slow was in the public interest.
 

                                </p>
                                 <p style={{color: '#000'}}>
                                   It is a shameful reality that we are governed by leaders who have a Stone Age mentality. Sikkim had never been under such heartless, soulless and shameless leaders before.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                  You all have been witnessing the SKM goondagiri against innocent people. The years 2019-2021 have been the bloodiest years in Sikkimese history. The government has been using the police force to harass citizens. When we go out to the field, these desperate SKM goons will go mad and create mayhem. We are aware of that. Sikkim has lost its peace, law and order, image, economy and assets under the SKM governance. What else can Sikkim expect from a Chief Minister who openly issues threats in the name of “Goley’s Team.”  Let him threaten away- I am not scared. But I am grieved that many are weeping and mourning the foolishness of the CM. Let him realize that for the sake of Sikkim and its dignity, we are ready to face Yamaraj’s Team - for us, Goley’s Team is a mere joke.



                                </p>
                                
                                <p style={{color: '#000'}}>
                                   
We are not a bit concerned about their harassment, bullying and goodnagiri against us. We can battle against any power to save Sikkim. We are hoping that the government will own up to their rampant selling of Sikkim, gross misgovernance and change their mind. The time is running out for them. We will soon come out on the field and they will realize once again which party is the actual power of the people.




                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                We are taking serious note of their frustration and anger. They are still speaking the same foul language, carrying the same attitude of street fighters, using the same cheap talk, displaying the same mannerisms of a villain and braving as the same goondagiri they are. They have no answer to our ideological stance. There is not one person in the SKM party that has realised that politics is an ideological battle. Every single SKM member has one common ability which is to haul like a fewra. Their mind and brain are rusting for lack of use. They think muscle power is everything. The SDF’s greatest plight is that we don’t even have worthy political rivals who can think and talk sensibly. Talking to them about ideology is like reciting scriptures to a bear.


                                </p>
                                
                                <p style={{color: '#000'}}>
                                    Finally, the SDF party will continue to oppose the government’s anti-Sikkim, anti-people decisions. We will continue to fight against their conspiracy to misguide, divide the people and sell Sikkim. We are very much in the field and will not back out even an inch. When we eventually declare a final war against these anti-Sikkim elements, everyone will see. No one can stop time and its impact.




                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr28Nov2020;