import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr21Feb2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/21Feb.jpg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>21 Feb, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and the SDF president Shri Pawan Chamling shares his thoughts on the speeches by the SKM leaders and Chief Minister Shri PS Tamang on the occasion of SKM foundation day.
                           </h3>
                           
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#">
                                            <span>#OFOJ</span>
                                        </a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                    “ Q. In the last foundation day program of the SKM party, almost every leader who spoke targeted you and your 25 years of governance. The Chief Minister, Shri PS Tamang also devoted much of his time to criticising you. Some of his remarks were controversial and are now doing the rounds on social media. What are your thoughts?


                                </p>

                                

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> Let me begin with Hon’ble Chief Minister Shri PS Tamang’s shocking speech. When an incumbent Chief Minister issues an open threat in public like that, the only thing I can do is to pray for the people. Such a speech is unimaginable in a civilized country.  No one even in their wildest dreams would think that the chief minister of a democratic state would say such a thing. It was perhaps acceptable in the 17th century. Not today. Was he in a sound frame of mind or is he under some kind of mental imbalance?



                                </p>

                                <img class="w-100" src="assets/img/pressrelease/21Feb.jpg" alt="" />
                                <br/>
                                <br/>

                                <p style={{color: '#000'}}>
                                    What worries me, even more, is the fact that there was total silence all over the state. There was no one who displayed the courage to condemn it. This is a dangerous sign which shows that democracy is on the wane.  Sikkim silently accepted his savage threats. Sikkim failed to stand up to raise a voice for democracy. It is true that we do not get better leadership than we deserve and the quality of politics is determined by public awareness and courage. When people make a wrong choice and put the wrong people in the place of authority, the results will all be wrong. 


                                </p>
                                 <p style={{color: '#000'}}>
                                    Does Chief Minister Shri PS Tamang not know that his primary duty is to ensure the security of Sikkimese people including me? His primary duty is to protect the fundamental rights of the Sikkimese people, the people’s aspirations, peace in the state, Sikkimese interests and the environment. Standing on the platform as CM and issuing such threats against people is such a disgrace, shameful and barbaric. I, as a Sikkimese citizen, hang my head in shame. I, as a responsible citizen, totally condemn such a statement.
 

                                </p>
                                 <p style={{color: '#000'}}>
                                   When the SKM chief Shri PS Tamang himself does not know the dignity of such a responsible position of Chief Minister, what else can one expect from those who follow him? Not only in their foundation day speech but everywhere m, when these SKM leaders get a chance to open their mouths, they chant Chamling mantras. I challenge them to finish a five-minute talk without uttering my name and the SDF party. What does that mean? It means that they know nothing except scolding me and my party. It means that their mind is blind. Their mind is filled with envy and anger against Pawan Chamling and the SDF party. Their passion and enthusiasm come from their anger against us. Their mission is to hate me and my colleagues and conspire to finish us. The SKM government’s achievements in the last two years so far have been telling lies, conspiring against opponents to force them to join SKM, intimidating people, character assassinations and taking revenge. Chief Minister PS Tamang in public speeches says “If I find out that some people are going to Ghurpisey, I will be compelled to write ‘dismiss’ on their petition letters”. What does that mean? They are openly threatening people directly or indirectly saying “Be aware - you may lose your job, you or your wife or your children or your family members may get transferred, etc”. This is the tactic of cowardly leadership. 




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   I, as a senior politician, would like to tell them - understand that you have been given the public mandate to serve - not to use your platform to shout, scold, abuse and threaten me, my party and the people of Sikkim. Remember the promises you made to the people and fulfill them. Your job is not to assassinate our character. People did not vote for you to conspire to finish the SDF party. You are there to serve the people, to protect their rights and to ensure the peace and prosperity of Sikkim. Enough of your anger and hatred.



                                </p>
                                
                                <p style={{color: '#000'}}>
                                    They are shamelessly and irresponsibly thinking that they are distracting people from the promises they made during their election campaign by constantly scolding me.
I seriously think that the SKM party must do some soul searching, retract the shameless threats that they have given the people, mature and make a wholehearted attempt to bring about the change that they campaigned for. They must rack their brains, draw up a road map, design programs and formulate policies. Running the government is not child’s play. It is not as simple as organizing a picnic and wildly scolding me and my party. That was fine when they were in the opposition but now they have been voted into the government by the people. They now have the reins of administration, not me. Why waste time scolding me? The sooner they realize it the better.”




                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr21Feb2021;