import React from 'react';
import {Link} from 'react-router-dom';

const AwardForStateBody = () => {
    return(
        <>
                                 <div class="row">
                
               
                <div class="col-md-6">
   
                 <div class="table_wrapper">
   
                    <h4 style={{color: '#000', marginBottom: '40px'}}>Awards Received By The State From Ministry Of Panchayati Raj :</h4>
               
           <table id="table_medals" >
             <thead style={{padding: '10px'}}>
                 <tr >
                     <th id="medal_rank" class=" active" style={{padding: '15px'}}>Year</th>
                     <th id="medal_team" class="">Name of Award</th>
                     <th id="medal_gold">Awarded By</th>
                    
               </tr>
           </thead>
             <tbody>
                 <tr class="">
                     <td class="medal_rank">2006-07</td>
                     <td class="medal_team">Third Best Performing State in Panchayati Raj</td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India</td>
                     
                  </tr>
                  
                  
                  <tr class="">
                     <td class="medal_rank">2008-09</td>
                     <td class="medal_team">Second Best Performing State</td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India</td>
                    
                  </tr>
                  
                   <tr class="">
                     <td class="medal_rank">1998</td>
                     <td class="medal_team">Man Of The Year</td>
                     <td class="medal_gold">Biographical Institute, USA</td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2009-10</td>
                     <td class="medal_team">Third Best Performing State in Panchayati Raj</td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India</td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2010-11</td>
                     <td class="medal_team">Third Best Performing State in Panchayati Raj</td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India </td>
                    
                  </tr>
                  
                   <tr class="">
                     <td class="medal_rank">2010-15</td>
                     <td class="medal_team">First Place on Cumulative Devolution Index in NE Region</td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India </td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2014-15</td>
                     <td class="medal_team">Second Place on the Incremental Devolution Index</td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India </td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2015-16</td>
                     <td class="medal_team">First in e-Puraskar</td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India </td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2017</td>
                     <td class="medal_team">Best Achiever Sarpanch Award to Panchayat President of 28 Maniram Phalidara GPU</td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India</td>
                    
                  </tr>
                  
                   <tr class="">
                     <td class="medal_rank">2009-10</td>
                     <td class="medal_team">Rashtriya Gaurav Gram Sabha Puraskar for Best GPU - Mellidara Paiyong GPU </td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India </td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2010-11</td>
                     <td class="medal_team">Rashtriya Gaurav Gram Sabha Puraskar 
   for Best GPU - Sangdorjee GPU
   </td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India </td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2012-13</td>
                     <td class="medal_team">Rashtriya Gaurav Gram Sabha Puraskar for Best GPU - Samdong GPU</td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India </td>
                  </tr>
                  
                   <tr class="">
                     <td class="medal_rank">2014-15</td>
                     <td class="medal_team">Rashtriya Gaurav Gram Sabha Puraskar for Best GPU - Kolthang Tokdey GPU</td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India</td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2014-15</td>
                     <td class="medal_team">Rashtriya Gaurav Gram Sabha Puraskar for Best GPU - Tingvong GPU</td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India </td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2015-16</td>
                     <td class="medal_team">Nanaji Deshmukh Rashtriya Gaurav Gram Sabha Puraskar for Best GPU - Rey Mendu, East District</td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India </td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2010-11</td>
                     <td class="medal_team">Panchayat Sashaktikaran Puraskar for Best GPU - Aritar GPU</td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India</td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2011-12</td>
                     <td class="medal_team">Panchayat Sashaktikaran Puraskar for Best Performing District Panchayat - South District </td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India </td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2011-12</td>
                     <td class="medal_team">Panchayat Sashaktikaran Puraskar for Best Performing GPU - Mellidara Paiyong</td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India  </td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2017</td>
                     <td class="medal_team">Panchayat Sashaktikaran Puraskar for Best Performing GPU -  Gereythang GPU </td>
                     <td class="medal_gold">Sikkim Central University </td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2011-12</td>
                     <td class="medal_team">Ambassador of Organic Himalaya and Organic World </td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India </td>
                    
                  </tr>
                  
                   <tr class="">
                     <td class="medal_rank">2012-13</td>
                     <td class="medal_team">Panchayat Sashaktikaran Puraskar for Best Performing District Panchayat - South District</td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India  </td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2012-13</td>
                     <td class="medal_team">Panchayat Sashaktikaran Puraskar for Best Performing GPU - Mellidara Paiyong </td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India </td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2012-13</td>
                     <td class="medal_team">Panchayat Sashaktikaran Puraskar for Best Performing GPU - Karmatar Gyaten GPU </td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India </td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2013-14</td>
                     <td class="medal_team">Panchayat Sashaktikaran Puraskar for Best Performing District Panchayat - West District </td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India  </td>
                    
                  </tr>
                  
                   <tr class="">
                     <td class="medal_rank">2012-13</td>
                     <td class="medal_team">Panchayat Sashaktikaran Puraskar for Best Performing GPU - Namphing GPU </td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India</td>
                    
                  </tr>
                  
                   <tr class="">
                     <td class="medal_rank">2013-14</td>
                     <td class="medal_team">Panchayat Sashaktikaran Puraskar for Best Performing GPU - Sankhu Radhu Khandu GPU </td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India</td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2013-14</td>
                     <td class="medal_team">Panchayat Sashaktikaran Puraskar for Best Performing GPU - Namphing GPU </td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India </td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2014-15</td>
                     <td class="medal_team">Panchayat Sashaktikaran Puraskar for Best GPU - Rongay Tathangchen GPU</td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India</td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2015-16</td>
                     <td class="medal_team">Deendayal Panchayat Sashaktikaran Puraskar for Best Performing District-East District Zilla Panchayat  </td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India </td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2015-16</td>
                     <td class="medal_team">Deendayal Panchayat Sashaktikaran Puraskar for Best Performing GPU - Dolepchen</td>
                     <td class="medal_gold">Ministry of Panchayati Raj, Govt. of India</td>
                    
                  </tr>
                  
                   <tr class="">
                     <td class="medal_rank">2012</td>
                     <td class="medal_team">National Award for Sikkim’s Dhara Vikas Initiative</td>
                     <td class="medal_gold">Ministry of Rural Development, Govt. of India </td>
                    
                  </tr>
                  
                  <tr class="">
                     <td class="medal_rank">2018</td>
                     <td class="medal_team">Skoch Gold Award and Skoch Award of Merit for Dhara Vikas</td>
                     <td class="medal_gold">Ministry of Rural Development, Govt. of India </td>
                    
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2008</td>
                     <td class="medal_team">Nirmal Rajya Puraskar</td>
                     <td class="medal_gold">Ministry of Rural Development, Govt. of India </td>
                    
                  </tr>
                  
                  </tbody>
             <tfoot>
                 
               
             
             </tfoot>
           </table>
   
               
               
                <h4 style={{color: '#000', marginTop:'40px', marginBottom: '40px', textTransform: 'capitalize'}}> SIKKIM TOURISM AWARDS ( Awarded by Ministry Of Tourism GOI and other National and International organization)  
    :</h4>
           
            <table id="table_medals" >
             <thead style={{padding: '10px'}}>
                 <tr >
                     <th id="medal_rank" class=" active" style={{padding: '15px'}}>Year</th>
                     <th id="medal_team" class="">Name of Award</th>
                     <th id="medal_gold">Awarded By</th>
                    
               </tr>
           </thead>
             <tbody>
                 <tr class="">
                     <td class="medal_rank">1998-99</td>
                     <td class="medal_team">Best Tourism Performing State in NE</td>
                     <td class="medal_gold">Ministry of Tourism, Govt. of India</td>
                     
                  </tr>
                  
                  
                  <tr class="">
                     <td class="medal_rank">1999-2000</td>
                     <td class="medal_team">Best Tourism Performing State in NE</td>
                     <td class="medal_gold">Ministry of Tourism, Govt. of India</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2000-01</td>
                     <td class="medal_team">Best Tourism Performing State in NE</td>
                     <td class="medal_gold">Ministry of Tourism, Govt. of India</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2001-02</td>
                     <td class="medal_team">Best State in Citizen Security</td>
                     <td class="medal_gold">Ministry of Tourism, Govt. of India</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2002-03</td>
                     <td class="medal_team">Best State in Environment</td>
                     <td class="medal_gold">Ministry of Tourism, Govt. of India</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2005-06</td>
                     <td class="medal_team">Best State in Water and Sanitation</td>
                     <td class="medal_gold">Ministry of Tourism, Govt. of India</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2006-07</td>
                     <td class="medal_team">Best State in Overall Performance </td>
                     <td class="medal_gold">Ministry of Tourism, Govt. of India</td>
                     
                  </tr>
   
   
                   <tr class="">
                     <td class="medal_rank">2012</td>
                     <td class="medal_team">Comprehensive Development of Tourism </td>
                     <td class="medal_gold">Ministry of Tourism, Govt. of India</td>
                     
                  </tr>
   
                   <tr class="">
                     <td class="medal_rank">2012</td>
                     <td class="medal_team">Most Innovative and Unique Tourism Project to Char Dham </td>
                     <td class="medal_gold">Ministry of Tourism, Govt. of India</td>
                     
                  </tr>
   
                   <tr class="">
                     <td class="medal_rank">2012</td>
                     <td class="medal_team">Best State - Clean India Campaign </td>
                     <td class="medal_gold">Ministry of Tourism, Govt. of India</td>
                     
                  </tr>
   
                   <tr class="">
                     <td class="medal_rank">2014</td>
                     <td class="medal_team">National Tourism Award 12-13 </td>
                     <td class="medal_gold">Ministry of Tourism, Govt. of India</td>
                     
                  </tr>
   
                   <tr class="">
                     <td class="medal_rank">2014</td>
                     <td class="medal_team">National Tourism Award 12-13 </td>
                     <td class="medal_gold">Ministry of Tourism, Govt. of India</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2016</td>
                     <td class="medal_team">Gangtok - Cleanest Tourism Destination </td>
                     <td class="medal_gold">Ministry of Tourism, Govt. of India</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2018</td>
                     <td class="medal_team">National Award as the Best State/Union Territory for Comprehensive Development of Tourism amongst J&K, NE States </td>
                     <td class="medal_gold">Ministry of Tourism, Govt. of India</td>
                     
                  </tr>
   
                  
   
                 
                  
                 
                  
                  
             <tfoot>
                 
               
             
             </tfoot>
             </tbody>
           </table>
               
               
               
           
             </div>
   
           </div>
   
           <div class="col-md-6">
             <div class="table_wrapper">
                <h4 style={{color: '#000', marginBottom: '40px' }}>Awards Received By The State From The Government Of India :</h4>
           
            <table id="table_medals" >
             <thead style={{padding: '10px'}}>
                 <tr >
                     <th id="medal_rank" class=" active" style={{padding: '15px'}}>Year</th>
                     <th id="medal_team" class="">Name of Award</th>
                     <th id="medal_gold">Awarded By</th>
                    
               </tr>
           </thead>
             <tbody>
                 <tr class="">
                     <td class="medal_rank">2016</td>
                     <td class="medal_team">1st Open Defecation Free State</td>
                     <td class="medal_gold">Government of India</td>
                     
                  </tr>
                  
                  
                  <tr class="">
                     <td class="medal_rank">2016</td>
                     <td class="medal_team">PM’s Award for Excellence in Public Administration in Swachh Bharat (G)</td>
                     <td class="medal_gold">Government of India</td>
                    
                  </tr>
                  
                   <tr class="">
                     <td class="medal_rank">2007</td>
                     <td class="medal_team">Nirmal Gram Puraskar</td>
                     <td class="medal_gold">Government of India</td>
                    
                  </tr>
   
                   <tr class="">
                     <td class="medal_rank">2016</td>
                     <td class="medal_team">Gangtok- Cleanest City in North Eastern India</td>
                     <td class="medal_gold">Government of India</td>
                    
                  </tr>
                  
                 
                  
                  
             <tfoot>
                 
               
             
             </tfoot>

             </tbody>
           </table>
   
   
   
           <h4 style={{color: '#000', marginTop:'40px', marginBottom: '40px', textTransform: 'capitalize'}}> INDIA TODAY STATE OF THE STATES AWARDS ( Awarded By India Today Group)
    Abysmal to Excellence
    :</h4>
           
            <table id="table_medals" >
             <thead style={{padding: '10px'}}>
                 <tr >
                     <th id="medal_rank" class=" active" style={{padding: '10px'}}>Year</th>
                     <th id="medal_team" class="">Name of Award</th>
                     <th id="medal_gold">Awarded By</th>
                    
               </tr>
           </thead>
             <tbody>
                 <tr class="">
                     <td class="medal_rank">2004</td>
                     <td class="medal_team">Best State in Education</td>
                     <td class="medal_gold">India Today Group</td>
                     
                  </tr>
                  
                  
                  <tr class="">
                     <td class="medal_rank">2005-12</td>
                     <td class="medal_team">Best State in Investment Environment</td>
                     <td class="medal_gold">India Today Group</td>
                    
                  </tr>
                  
                   <tr class="">
                     <td class="medal_rank">2005</td>
                     <td class="medal_team">Best State in Education</td>
                     <td class="medal_gold">India Today Group</td>
                    
                  </tr>
   
                   <tr class="">
                     <td class="medal_rank">2008</td>
                     <td class="medal_team">Best State in Primary Education</td>
                     <td class="medal_gold">India Today Group</td>
                    
                  </tr>
   
                   <tr class="">
                     <td class="medal_rank">2009</td>
                     <td class="medal_team">Best State in Education</td>
                     <td class="medal_gold">India Today Group</td>
                    
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2009</td>
                     <td class="medal_team">Bharat Nirman Awards e-Governance</td>
                     <td class="medal_gold">India Today Group</td>
                    
                  </tr>
   
                   <tr class="">
                     <td class="medal_rank">2009</td>
                     <td class="medal_team">Bharat Nirman Awards - Electrification</td>
                     <td class="medal_gold">India Today Group</td>
                    
                  </tr>
   
                   <tr class="">
                     <td class="medal_rank">2009</td>
                     <td class="medal_team">Bharat Nirman Awards - Rural Roads</td>
                     <td class="medal_gold">India Today Group</td>
                    
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2010</td>
                     <td class="medal_team">Best State in Education</td>
                     <td class="medal_gold">India Today Group</td>
                    
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2010</td>
                     <td class="medal_team">Best State in Investment Environment</td>
                     <td class="medal_gold">India Today Group</td>
                    
                  </tr>
   
                   <tr class="">
                     <td class="medal_rank">2012</td>
                     <td class="medal_team">Best Small State in Education </td>
                     <td class="medal_gold">India Today Group</td>
                    
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2014</td>
                     <td class="medal_team">Best Infrastructure Award - Tourism</td>
                     <td class="medal_gold">India Today Group</td>
                    
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2015</td>
                     <td class="medal_team">Cleanest Hill Station ‘Safaigiri Award’</td>
                     <td class="medal_gold">India Today Group</td>
                    
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2016</td>
                     <td class="medal_team">Best State Award on Education in the category of ‘Best Small States’</td>
                     <td class="medal_gold">India Today Group</td>
                    
                  </tr>
   
                   <tr class="">
                     <td class="medal_rank">2018</td>
                     <td class="medal_team">Best State in Education (Small State Category)</td>
                     <td class="medal_gold">India Today Group</td>
                    
                  </tr>
   
                   <tr class="">
                     <td class="medal_rank">2018</td>
                     <td class="medal_team">Best State in Cleanliness (Small State Category)</td>
                     <td class="medal_gold">India Today Group</td>
                    
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2018</td>
                     <td class="medal_team">Most Improved State in Governance (Small State Category)</td>
                     <td class="medal_gold">India Today Group</td>
                    
                  </tr>
   
   
                  
                 
                  
                  
             <tfoot>
                 
               
             
             </tfoot>

             </tbody>
           </table>
   
   
   
   
           <h4 style={{color: '#000', marginTop:'40px', marginBottom: '40px', textTransform: 'capitalize'}}> IBN7 DIAMOND STATE AWARDS ( Awarded by IBN7)  
    :</h4>
           
            <table id="table_medals" >
             <thead style={{padding: '10px'}}>
                 <tr >
                     <th id="medal_rank" class=" active" style={{padding: '15px'}}>Year</th>
                     <th id="medal_team" class="">Name of Award</th>
                     <th id="medal_gold">Awarded By</th>
                    
               </tr>
           </thead>
             <tbody>
                 <tr class="">
                     <td class="medal_rank">2008</td>
                     <td class="medal_team">Best State in Citizen Security, Justice</td>
                     <td class="medal_gold">IBN7</td>
                     
                  </tr>
                  
                  
                  <tr class="">
                     <td class="medal_rank">2009</td>
                     <td class="medal_team">Special Top Five States</td>
                     <td class="medal_gold">IBN7</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2011</td>
                     <td class="medal_team">Best State in CItizen Security</td>
                     <td class="medal_gold">IBN7</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2012</td>
                     <td class="medal_team">Best State in Citizen Security</td>
                     <td class="medal_gold">IBN7</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2012</td>
                     <td class="medal_team">Best State in Environment</td>
                     <td class="medal_gold">IBN7</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2012</td>
                     <td class="medal_team">Best State in Water and Sanitation</td>
                     <td class="medal_gold">IBN7</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2012</td>
                     <td class="medal_team">Best State in Overall Performance </td>
                     <td class="medal_gold">IBN7</td>
                     
                  </tr>
   
                  
   
                 
                  
                 
                  
                  
             <tfoot>
                 
               
             
             </tfoot>

             </tbody>
           </table>
   
               
               
                <h4 style={{color: '#000', marginTop:'40px', marginBottom: '40px', textTransform: 'capitalize'}}> Other Awards Received By The State
    :</h4>
           
            <table id="table_medals" >
             <thead style={{padding: '10px'}}>
                 <tr >
                     <th id="medal_rank" class=" active" style={{padding: '15px'}}>Year</th>
                     <th id="medal_team" class="">Name of Award</th>
                     <th id="medal_gold">Awarded By</th>
                    
               </tr>
           </thead>
             <tbody>
                 <tr class="">
                     <td class="medal_rank">2005</td>
                     <td class="medal_team">Best Ecotourism Destination</td>
                     <td class="medal_gold">Hospitality India</td>
                     
                  </tr>
                  
                  
                  <tr class="">
                     <td class="medal_rank">2007</td>
                     <td class="medal_team">Most Picturesque Destination</td>
                     <td class="medal_gold">Today’s Traveller</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2008</td>
                     <td class="medal_team">Emerging Tourism State</td>
                     <td class="medal_gold">Today’s Traveller</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2008</td>
                     <td class="medal_team">Best Example of Community-based Tourism</td>
                     <td class="medal_gold">CNBC AAWAZ</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2008</td>
                     <td class="medal_team">Best Tourism State for Adventure Tourism</td>
                     <td class="medal_gold">Safari India</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2008</td>
                     <td class="medal_team">Best Adventure Tourism Destination</td>
                     <td class="medal_gold">PATWA</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2008</td>
                     <td class="medal_team">National Tourism Award 2007-2008 </td>
                     <td class="medal_gold">Ministry of Tourism, Govt. of India</td>
                     
                  </tr>
   
   
                   <tr class="">
                     <td class="medal_rank">2009</td>
                     <td class="medal_team">Most Responsible Tourism State </td>
                     <td class="medal_gold">Today’s Traveller</td>
                     
                  </tr>
   
                   <tr class="">
                     <td class="medal_rank">2010</td>
                     <td class="medal_team">Best State for Adventure Tourism </td>
                     <td class="medal_gold">Safari India</td>
                     
                  </tr>
   
                   <tr class="">
                     <td class="medal_rank">2010</td>
                     <td class="medal_team">Best Performing State </td>
                     <td class="medal_gold">C’wealth Trade Mart</td>
                     
                  </tr>
   
                   <tr class="">
                     <td class="medal_rank">2010</td>
                     <td class="medal_team">India’s Most Progressive State </td>
                     <td class="medal_gold">Today’s Traveller</td>
                     
                  </tr>
   
                   <tr class="">
                     <td class="medal_rank">2014</td>
                     <td class="medal_team">The Best Region to Visit in 2014 </td>
                     <td class="medal_gold">Global Travel Guide, Lonely Planet</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2016</td>
                     <td class="medal_team">Khangchendzonga National Park inscribed as World Heritage Site under Mixed Criteria  </td>
                     <td class="medal_gold">UNESCO</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2017</td>
                     <td class="medal_team">‘52 Places To Go In 2017’ List </td>
                     <td class="medal_gold">New York Times</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2017</td>
                     <td class="medal_team">Khangchendzonga Biosphere Reserve in UNESCO World Network of Biosphere Reserve </td>
                     <td class="medal_gold">UNESCO</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2006-
   2008
   </td>
                     <td class="medal_team">Cooperative Excellence Award</td>
                     <td class="medal_gold">National Cooperative Development Cooperation</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2010-18</td>
                     <td class="medal_team">Cooperative Excellence Award </td>
                     <td class="medal_gold">National Cooperative Development Cooperation</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2005-06</td>
                     <td class="medal_team">Skoch Challenger Award</td>
                     <td class="medal_gold">Skoch Company</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2005-06</td>
                     <td class="medal_team">National Road Safety Award</td>
                     <td class="medal_gold">Transport Ministry, Govt. of India</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2006-07</td>
                     <td class="medal_team">National Road Safety Award</td>
                     <td class="medal_gold">Transport Ministry, Govt. of India</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2006</td>
                     <td class="medal_team">2nd International Flora Expo</td>
                     <td class="medal_gold">Media Today Pvt Ltd</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2008</td>
                     <td class="medal_team">4th JRD Tata Memorial Award</td>
                     <td class="medal_gold">Population Foundation of India</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2008</td>
                     <td class="medal_team">First in NE Regional Horticulture Show</td>
                     <td class="medal_gold">Ministry of Horticulture, Govt. of India</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2009</td>
                     <td class="medal_team">Agriculture Leadership Award</td>
                     <td class="medal_gold">Agriculture Today Group</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2009</td>
                     <td class="medal_team">No.1 in Natural Resources Conservation</td>
                     <td class="medal_gold">CDF, Chennai</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2010</td>
                     <td class="medal_team">Cooperative Excellence Award</td>
                     <td class="medal_gold">National Cooperative Development cooperation</td>
                     
                  </tr>
                  <tr class="">
                     <td class="medal_rank">2011</td>
                     <td class="medal_team">Best Stall at BioFach India</td>
                     <td class="medal_gold">BioFach India </td>
                     
                  </tr>
                   <tr class="">
                     <td class="medal_rank">2012</td>
                     <td class="medal_team">National Award in Horticulture</td>
                     <td class="medal_gold">Ministry of Agriculture and Horticulture </td>
                     
                  </tr>
   
                   <tr class="">
                     <td class="medal_rank">2012-13</td>
                     <td class="medal_team">National Road Safety Award</td>
                     <td class="medal_gold">Transport Ministry, Govt. of India </td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2013</td>
                     <td class="medal_team">Agriculture Leadership Award</td>
                     <td class="medal_gold">Agriculture Today Group </td>
                     
                  </tr>
   
                   <tr class="">
                     <td class="medal_rank">2014</td>
                     <td class="medal_team">National Award for Best Electoral Practices</td>
                     <td class="medal_gold">The Election Commission of India </td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2016</td>
                     <td class="medal_team">Cleanest State in India</td>
                     <td class="medal_gold">National Sample Survey Office, Government of India </td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2016</td>
                     <td class="medal_team">Best Working Conditions for Women</td>
                     <td class="medal_gold">Centre for Strategic and International Studies (CSIS), a top America think-tank and Nathan Associates </td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2016</td>
                     <td class="medal_team">SIkkim: India’s First Fully Organic Farming State</td>
                     <td class="medal_gold">Ministry of Agriculture, Government of India</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2016</td>
                     <td class="medal_team">SIkkim: India’s First Fully Organic Farming State</td>
                     <td class="medal_gold">Ministry of Agriculture, Government of India</td>
                     
                  </tr>
   
                   <tr class="">
                     <td class="medal_rank">2017</td>
                     <td class="medal_team">Transform India Award in Government Category</td>
                     <td class="medal_gold">Zee Media Transform India Conclave & Awards</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2017</td>
                     <td class="medal_team">Indian Agribusiness Excellence Award 2017</td>
                     <td class="medal_gold">Indian Conventions Conferences & Award, Bengaluru</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2018</td>
                     <td class="medal_team">Kalam Innovation in Governance Award</td>
                     <td class="medal_gold">Dr.APJ Abdul Kalam Center</td>
                     
                  </tr>
   
                  <tr class="">
                     <td class="medal_rank">2018</td>
                     <td class="medal_team">6th JRD Tata Memorial Award</td>
                     <td class="medal_gold">The Population Foundation of India</td>
                     
                  </tr>
                  
   
                 
                  
                 
                  
                  
             <tfoot>
                 
               
             
             </tfoot>
             </tbody>
           </table>
   
   
             </div>
           </div>
   
   
   
                 
                 
                 
               </div>
        </>
    );
};

export default AwardForStateBody;