import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr15Dec2020 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/MassJoiningCovid.jpeg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>15 Dec, 2020</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and the SDF President Shri Pawan Chamling shares his take on the mass joining organised by the SKM  during the Covid pandemic
                           </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                    Former  “There have been a series of joining programs organized by the ruling SKM party all over the state. The party has been reporting that hundreds of people are joining the party right now. What are your thoughts on this?

                                </p>

                                

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> The mass joining that is supposedly going on shows the fear and intimidation faced by the people. The government, under which people could freely conduct the funeral of the Chief Minister, is no longer there. The Chief Minister, against whom people could say whatever they feel, is not there. Now Sikkim has a government that is intolerant and biased. During the bi-poll, I was attacked and my vehicle was pelted with stones. They didn’t allow me and my party supporters to campaign. That is the kind of government that Sikkim has right now. How many people who the government considers to be their threat, have been arrested and physically attacked in the last one and a half years? Can common people really live peacefully under such intimidating circumstances? If you do not join the ruling party, you will lose peace of mind, you will be tagged as anti-government. So how will people survive under such a regime? The answer is simple - go and join the SKM and earn their favour. In doing so, hapless people have to risk contracting coronavirus and attend these events of “mass surrender”.

                                </p>
                                
                                <div>
                                    
                                    <img class="w-100" src="assets/img/pressrelease/MassJoiningCovid.jpeg" alt=""/>
                                    <p style={{color: '#000', fontSize:'14px'}}>Mass joinings and public gathering by SKM despite the COVID pandemic and against all protocols set by the government itself. Photo shows mass joining at Zoom Salghari on 2nd Dec 2020.	
</p>
                                </div>
                                
                                <p style={{color: '#000'}}>
                                    These current mass surrenders are a reflection of the insecurity felt by the people. But more importantly, it is a measure of the level of the government at the helm in Sikkim. People are surrendering to the ruling party not because they are happy doing so, but because they fear the consequences of not giving in.

                                </p>
                                 <p style={{color: '#000'}}>
                                    People are not impressed with what the government has done FOR them but they are deeply scared by what the government can do AGAINST them.
                                        <br/>
                                        In history, the Jews had no choice but to surrender to the fascist regime of Mussolini in Italy. They surrendered to the fascists with the hopes that they would have a chance of survival working in the labour camps. Their fate was sealed in these camps, barring a minuscule number who survived. I worry for those who are surrendering for jobs, promotions, fear of transfer in Sikkim. Will their fate not be unlike that of the Jews giving in to the fascist regime?

                                </p>
                                 <p style={{color: '#000'}}>
                                   So this is what I have to say. People are surrendering to SKM to prevent the transfer of their family members. They are submitting themselves to the ruling party to save their rights and privileges. To save their Ad Hoc and MR jobs. To protect their jobs under OFOJ. This proves that there is no democracy in Sikkim today.


                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Ironically, people are enjoying their right to leave their old party and join a new party but they have lost the right to stand for their fundamental rights. There is an undeclared emergency in the state. I fully understand the compulsion and helpless situation of the people.


                                </p>
                                
                                <p style={{color: '#000'}}>
                                    In the end, all I can say is this – what is the urgency of such risky and uncalled for drama at a time when coronavirus is spreading in Sikkim like wildfire? What kind of political party would force people to gather in such huge mass rallies with no masks or social distancing, just to fulfil their political interests? That shows what is more important- the public and their lives or the political advantage of the ruling party. The ruling government is holding these programs for their benefit at the cost of the people’s lives. They are killing people for their political gains.


                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                   And as for the thought that SDF is waning, I say this: The SDF is an idea. It is an idea that has been inseparably intertwined with Sikkim’s progressive thought; an idea that is interwoven with a peaceful and prosperous Sikkimese life. The SDF is an idea that has founded the substructure of the development of modern Sikkim. The SDF idea has been immortalised in the modern history of Sikkim. To destroy the SDF idea is to destroy that substructure. The SDF idea has been manifested in the modern history of Sikkim. The unprecedented heights of development and fame Sikkim enjoyed in the last 25 years are an extension of the idea that SDF has been. Can anyone destroy that indestructible idea? To destroy the SDF is to destroy this glorious history of Sikkim. No amount of fear-mongering can challenge the idea of SDF.”  


                                </p>
                                
                                <p style={{color: '#000'}}>
                                    Finally, if anyone thinks that the case filed against OFOJ is a conspiracy hatched by the SDF, he/she must be advised to refrain from stooping so low in the name of dirty politics. It is laughable. The three petitioners know who is behind the case. They only know the truth. I respect the court and I will be saying nothing as the matter is sub-judice. The SDF will never risk the future of our poor people for political gain.  Before the election, the SKM was saying that if the SDF formed the government, the OFOJ beneficiaries would lose their jobs. They also promised to regularise the OFOJ within 100 days of their governance. I think people are more interested to know why the SKM government failed to deliver on that promise.”


                                </p>

                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr15Dec2020;