import React from 'react';

const GovernanceBody = () => {
    return(
        <>
           
          <div class="container section-margin">
            <div class="row">
                <div class="good-gov-inner-page-container">
                    
                    
                    <ul>
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Janata Mela: </span></h4>
                            <p>The Janta Mela was a one of a kind program and probably first in the world in which benefits were given directly en masse to the people from across the state. The mela was envisioned to enable transparency in the distribution of benefits. During the Janta Mela, people were given benefits such as CMRHM, CM Self Employment Scheme, Start-up loan, house upgradation grants under Rural Housing Scheme, GCI sheets, Old age pensions, Antodaya Yoja and Swarojgar yojana, piglets,  and milch cows, etc. In 2017 itself, the government planned to spend 700 crores in these schemes by the end of the year.
</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Sikkim Bhraman:  </span></h4>
                            <p>Pawan Chamling during his tenure as Chief Minister conducted several village-to-village tours which were famously called Sikkim Bhraman. During the tours, all cabinet ministers, MLAs, the heads of all departments, and district officers accompanied him and public grievances and requisitions were received on the spot and actions taken immediately. This was his way of taking government to the doorsteps of the people. Through the Sikkim Brahman, the administrative machinery went to all Gram Panchayat Wards, revenue blocks and villages. This is the first of its kind program in the world where the entire government literally was at the doorsteps of the public. The Sikkim Brahman was undertaken two times – First from 17th May - 30th July 2011 and 25th October -1st November 2011 and second from 2nd April – 30th June 2018. Over 20% of the people of Sikkim came in direct contact through this program.
</p>


                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Decentralization and Devolution of Power: </span></h4>
                            <p>As part of the process of decentralization and devolution of power, 31 Gram Vikas Kendras (Village Development Centres) and 176 Gram Prashashan Kendras (Village Administration Centres) and 32 BDO offices were built. The president at the GPU has administrative control and discretion of all government institutions including primary schools, primary health centres, libraries, irrigation work etc. Tenders of up to 25 lakhs get sanctioned at the panchayat offices, 50 lakhs at BDO and 2 crores at the District offices. The Panchayats of Sikkim were the first panchayats in the country to receive a salary as well as discretionary funds. All benefits which were to be received by the public in the villages were distributed by the Panchayati Rai institutions. Furthermore, bills, tenders, grants were all facilitated through the BDO office. Land, property certificates, other certificates, marking orders were all issued through the BDO offices, making it easier for the public to avail of government services.  

</p>


                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>GOVT EMPLOYEE BENEFITS: </span></h4>
                            <ul>
                                <p>Govt employees salary enhanced by 300% in 1994, right after government formation.</p>
                            </ul>
                            
                            <ul>
                                <p>Salary enhanced 2412% in 25 years. Pension enhanced 2101% </p>
                            </ul>
                            
                            <ul>
                                <p><span style={{fontWeight: '700'}}>Die-in-harness Scheme:</span> Under this scheme, employment is provided to the next of kin of the deceased to sustain their livelihood on compassionate grounds - to the legal wife or any one of the surviving children of the deceased government employee. </p>
                            </ul>

                        </li>
                        
                      
                        
                        
                    </ul>
                </div>
            </div>
          </div>
        </>
    );
};

export default GovernanceBody;