import React from 'react';

const EnvironmentHeader = () => {
    return(
        <>
            <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                    <div class="container" style={{paddingBottom: '50px'}}>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                    <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                        data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                       Environment
                                    </h2>

                                    <p class="economy-page-header-desc" style={{fontSize: '1rem', fontWeight: '500', maxWidth:'100%', color:'#000', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px'}}>
                                    Pawan Chamling led SDF government in Sikkim has created green milestones that has catapulted Sikkim into a global leadership position on the environment front. Becoming a fully organic farming state and winning Future Policy Gold Award will be the dream for many other state governments. Sikkim government made achieved both amazing feats – becoming first organic state in the entire world in 2016 and winning the Future Policy Gold Award in 2018. Shri Pawan Chamling was declared as the Greenest Chief Minister of India in 1998 by the Centre for Science and Environment. He has been the only Chief Minister who was one of the Signatories of Global Climate Solutions Declaration. He was invited to participate in the Governor’s Global Summit in California, USA on 19 February 2008.

                                   
                                    </p>


                                </div>
                            </div>

                        </div>
                    </div>

                </section>

                <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/good-governance/inside-page/Environment.png" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                  

                </div>
            </header>
        </>
    );
};

export default EnvironmentHeader;