import React, {useEffect} from 'react';

import {Link} from 'react-router-dom';

import Header2 from "../components/nav/Header2";


import Footer2 from "../components/nav/Footer2";

// import "../blog.css";
import "../style.css";
import "../widgets.css";
import "../responsive.css";





const PressRelease = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">
                        <div class="wrapper">

                                <div class="root-contact">
                                    
                                    {/* Header */}
                                    <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                                        <div class="container" style={{paddingBottom: '50px'}}>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                                        <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                                            data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                                        Press Releases
                                                        </h2>

                                                        <p class="economy-page-header-desc" style={{fontSize: '1rem', fontWeight: '500', maxWidth:'100%', color:'#000', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px'}}>
                                                        An archive of press releases from the office of Pawan Chamling. The press releases are part of the “Weekly QnA” series launched on 5th November 2020.
                                                        </p>


                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </section>


                                    {/* Body */}

<section class="trending pt-65 pb-65 position-relative">
            <div class="container">
                <div class="row">
                    
                    
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/27Feb.jpeg)' }}>
                                <Link class="img-link" href="/pr27Feb2022"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2022</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">27 Feb</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr27Feb2022">
Former Chief Minister and the President of the SDF party Shri Pawan Chamling ...
</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    
                    
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/Feb20.jpeg)' }} >
                                <Link class="img-link" href="/pr20Feb2022"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2022</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">20 Feb</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr20Feb2022">
Former Chief Minister and President of the SDF party Shri Pawan Chamling condemns the vandalism at the State Secretariat calling it an attack on national and Sikkimese dignity.
</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/6Feb.jpg)' }} >
                                <Link class="img-link" href="/pr6Feb2022"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2022</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">06 Feb</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr6Feb2022">
Former Chief Minister and the President of the SDF party Shri Pawan Chamling has expressed his anguish over acute shortage of teachers in government schools due to the discontinuation of the services of the ad hoc teachers. 
</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/13Feb.jpeg)' }} >
                                <Link class="img-link" href="/pr13Feb2022"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2022</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">13 Feb</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr13Feb2022">
                                            Former Chief Minister and the President of the SDF party Shri Pawan Chamling talks about some of the milestones policy decisions taken by the SDF government in the field of education and the achievements, national awards and accolades received by the Sikkim HRDD
</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    
                    
                    
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/28Nov.jpg)' }} >
                                <Link class="img-link" href="/pr28Nov2020"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2020</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">28 Nov</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr28Nov2020">
                                            Former Chief Minister and the President of the SDF party Shri Pawan Chamling says that he hasn’t budged an inch from the political field. The SDF party has been going slow in terms of holding public meetings...

</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    
                    
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/31oCT.jpeg)' }} >
                                <Link class="img-link" href="/pr31Oct2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">31 Oct</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr31Oct2021">Former Chief Minister and the President of the SDF party Shri Pawan Chamling has answered a question asked to him on his birthday. Responding to a question about one good thing the SKM government has done in the last two years...

</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    
                    
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/31OCT21.jpg)' }} >
                                <Link class="img-link" href="/pr31Oct20212"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">31 Oct</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr31Oct20212">Former Chief Minister and President of the SDF party Shri Pawan Chamling has reiterated his claims that the SKM government is selling all the prime Sikkimese assets and giving loans from SBS to outsiders...

</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    
                    
                     <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/Sep26.jpeg)' }} >
                                <Link class="img-link" href="/pr26Sep2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">26 Sep</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr26Sep2021">Former Chief Minister and the President of the SDF party has answered the question about his silence before the media on his birthday...

</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    
                    
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/20Sep.jpeg)' }} >
                                <Link class="img-link" href="/pr20Sep2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">20 Sep</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr20Sep2021">Former Chief Minister and the SDF President Shri Pawan Chamling says that the Ram Rajya the SKM party has promised during the last election campaign never ushered in...

</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    
                    
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/8Aug.jpg)' }} >
                                <Link class="img-link" href="/pr8Aug2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">8 Aug</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr8Aug2021">Former Chief Minister and President of the SDF party has reminded the SKM government of its endless number of unfulfilled party and has condemned the politics of lies adopted by the SKM party...

</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    
                    
                     <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/Aug1.jpg)' }} >
                                <Link class="img-link" href="/pr1Aug2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">1 Aug</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr1Aug2021">Former Chief Minister and the President of the SDF party Shri Pawan Chamling has responded to those who are saying that the SDF government did nothing in 25 years. He says that three kinds of people say that...

</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    
                    
                     <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/25July.jpeg)' }} >
                                <Link class="img-link" href="/pr25Jully2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">25 July</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr25Jully2021">Former Chief Minister and the President of the SDF party Shri Pawan Chamling has expressed his concern about the Sikkim Chief Minister still taking the Sikkim Covid situation lightly and saying that the situation is under control...

</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    
                    
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/11July.jpeg)' }} >
                                <Link class="img-link" href="/pr11Jully2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">11 July</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr11Jully2021">Former Chief Minister and the President of the SDF party, Shri Pawan Chamling, has condemned the violent attack on student Shri Arun Limboo in the strongest words possible...

</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    
                    
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/4July.jpeg)' }} >
                                <Link class="img-link" href="/pr4July2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">4 July</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr4July2021">Former Chief Minister and President of the SDF party responded to the allegation of his prolonged absence in the Sikkim Legislative Assembly. He said that the SKM government is using hired goons to heckle him which has brought an indelible disgrace to the Sikkim Legislative Assembly that he holds in the greatest esteem....

</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    
                    
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/20June.JPG)' }} >
                                <Link class="img-link" href="/pr20June2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">20 June</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr20June2021">Former Chief Minister and the President of the SDF party Shri Pawan Chamling expressed his solidarity with Shri Bhaichung Bhutia’s concerns and suggestions given to the state government concerning the 300 bedded hospital in Namchi...

</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/13June.jpeg)' }} >
                                <Link class="img-link" href="/pr13June2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">13 June</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr13June2021">Former Chief Minister and President of the SDF party, Shri Pawan Chamling, applauds Dr Mechung Bhutia and Dr Bina Basnet, terming their endeavours to provide voluntary medical service door to door a “global example”...

</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    
                    
                    
                    
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/May16.jpeg)' }} >
                                <Link class="img-link" href="/pr16May2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">16 May</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr16May2021">Former Chief Minister and the President of SDF party Shri Pawan Chamling advice the Sikkim Government to aim to become India’s first Covid Free State by way of massive vaccination programme across the State. He also asked the Government to make all the necessary preparation massively against the impending third wave of the Corona Virus.

</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/9May.jpeg)' }} >
                                <Link class="img-link" href="/pr9May2021p"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">9 May</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr9May2021">Former Chief Minister and the President of the SDF party Shri Pawan Chamling offers three important suggestions to the government and the SDF party office at Jorethang for use by the state government as Covid facilities.
</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/13Sep.png)' }} >
                                <Link class="img-link" href="/pr13Sep2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">13 Sep</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr13Sep2021">Former Chief Minister and the President of the SDF party Shri Pawan Chamling says that the SKM has delivered on PARIWARTAN promise and lists some of the changes brought about by the SKM government.
</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    
                    
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/5Sep.jpeg)' }}  >
                                <Link class="img-link" href="/pr5Sep2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">5 Sep</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr5Sep2021">Former Chief Minister and President of the SDF party Shri Pawan Chamling talks about the SKM government repeatedly failing students and teachers....
</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    
                    
                    
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/TeachersDay.jpeg)' }} >
                                <Link class="img-link" href="/pr4Sep2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">4 Sep</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr4Sep2021">Former Chief Minister and President of the SDF party Shri Pawan Chamling has conveyed his good wishes and congratulations to the teachers for their unparalleled contribution for the overall development of Sikkim....
                                        </Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    

                    {/* <!-- 1 --> */}
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/27June.jpeg)' }} >
                                <Link class="img-link" to="/pr27June2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">27 June</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr27June2021">Former Former Chief Minister and the SDF President Shri Pawan Chamling explained how the SDF party’s inherent green policies and the politics of ecology gave rise to a number of innovative initiatives like Green Revolution Year,...
</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>

                    {/* <!-- 2 --> */}
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/EmptyClassroom.jpeg)' }} >
                                <Link class="img-link" href="/pr30May2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">30 May</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr30May2021">Former Chief Minister and the SDF President Shri Pawan Chamling asks the government to form a committee consisting of professionals to study losses and grievances of students and plan to vaccinate students on a priority basis with a goal to open schools for students in a phased manner.
</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>

                    {/* <!-- 3 --> */}

                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/18April.jpeg)' }} >
                                <Link class="img-link" href="/pr18April2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">18 April</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr18April2021">Former Chief Minister and the SDF president Shri Pawan Chamling condemns the objectionable speech given by the Chief Minister Shri PS Tamang on Ambedkar Jayanti. He maintains that Chief Minster must refrain from abusing his constitutional post.
</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>

                    {/* <!-- 4 --> */}
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/GeyzingStudents.jpeg)' }} >
                                <Link class="img-link" href="/pr11April2021"></Link>
                                    <div class="post-meta-1 mb-20">
                                       <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                    </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">11 April</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr11April2021">Former Chief Minister and SDF President Shri Pawan Chamling 
                                        admonishes the administration for being insensitive to the students in general and four Geyzing 
                                        college students in particular. He appeals to the students and people to never give up and ensure justice.
                                        </Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>

                    {/* <!-- 5 --> */}
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/RamRajya.jpeg)' }} >
                                <Link class="img-link" href="/pr25April2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">25 April</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr25April2021">Former Chief Minister and SDF President Shri Pawan Chamling replying to a question about the quality of governance in the state says that the SKM government is not only inefficient but it is all cruel which is more dangerous.
</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>

                    {/* <!-- 6 --> */}
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/SDF.jpeg)' }} >
                                <Link class="img-link" href="/pr28Feb2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">28 Feb</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr28Feb2021">Former Chief Minister and the SDF President Shri Pawan Chamling explains why some leaders and workers leaving the SDF party will not have real impact and reiterates that the state’s longest serving party will grow into a better and stronger party with renewed energy.
</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>

                    {/* <!-- 7 --> */}
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/21Feb.jpg)' }} >
                                <Link class="img-link" href="/pr21Feb2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">21 Feb</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr21Feb2021">Former Chief Minister and the SDF president Shri Pawan Chamling shares his thoughts on the speeches by the SKM leaders and Chief Minister Shri PS Tamang on the occasion of SKM foundation day.
</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>

                    {/* <!-- 8 --> */}
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/14Feb.jpeg)' }} >
                                <Link class="img-link" href="/pr14Feb2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">14 Feb</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr14Feb2021">Former Chief Minister and SDF President responds to questions about the arrest of two students of Geyzing colleges who were later called anti-nationals by the SKM spokesperson Mr Jacob Khaling
</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>


                    {/* <!-- 9 --> */}
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/ULBelections.jpeg)' }} >
                                <Link class="img-link" href="/pr7Feb2022"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">7 Feb</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr7Feb2021">Former Chief Minister and the SDF president Shri Pawan Chamling speaks on the upcoming ULB elections, sharing his thoughts on the “non-partisan” election process to be implemented after the amendment of the Sikkim Municipalities Act and Panchayat Act by the SKM government.
                                        </Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>


                    {/* <!-- 10 --> */}
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/Literature.jpeg)' }} >
                                <Link class="img-link" href="/pr31Jan2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">31 Jan</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr31Jan2021">Former Chief Minister and the SDF president Shri Pawan Chamling reflects on the role literature has played in his life as well as how he has used the time during the pandemic to delve into some of his writing work.</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>


                    {/* <!-- 11 --> */}
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/CapitolHill.jpeg)' }} >
                                <Link class="img-link" href="/pr24Jan2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2021</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">24 Jan</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr24Jan2021">
Former Chief Minister and the SDF president Shri Pawan Chamling shares his opinion about the change of guard in the USA and takeaways for politicians in Sikkim
</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>


                    {/* <!-- 12 --> */}
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/Dec1.JPG)' }} >
                                <Link class="img-link" href="/pr1Dec2021"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2020</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">1 Dec</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr1Dec2021">Former Chief Minister and the President of the SDF party Shri Pawan Chamling answers the question about his failure to prepare alternative leadership</Link>
                                    </h5>
                                </div>
                            </div> 
                        </div>
                    </article>


                    {/* <!-- 13 --> */}
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/MassJoiningCovid.jpeg)' }} >
                                <Link class="img-link" href="/pr15Dec2020"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2020</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">15 Dec</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr15Dec2020">Former Chief Minister and the SDF President Shri Pawan Chamling shares his take on the mass joining organised by the SKM  during the Covid pandemic</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>



                    {/* <!-- 14 --> */}
                    <article class="col-lg-4 col-md-6 mb-40 wow fadeIn animated">
                        <div class="post-card-1 border-radius-10 hover-up">
                            <div class="post-thumb thumb-overlay img-hover-slide position-relative" style={{backgroundImage: 'url(assets/img/pressrelease/OFOJ.jpeg)' }} >
                                <Link class="img-link" href="/pr21Dec2020"></Link>
                                <div class="post-meta-1 mb-20">
                                    <Link to="#" class="tag-category bg-brand-5 shadown-1 text-light button-shadow hover-up-3">2020</Link>
                                </div>
                            </div>
                            <div class="post-content p-30" style={{background: '#fff',
    borderRadius: '0 0 10px 10px'}}>
                                <div class="post-card-content">
                                    <div class="entry-meta meta-1 float-left font-md mb-10">
                                        <span class="post-on has-dot">21 Dec</span>
                                    </div>
                                    <h5 class="post-title font-md" style={{color: '#000', fontSize:'14px'}}>
                                        <Link to="/pr21Dec2020">Former Chief Minister and the SDF President answers a question about the controversies that currently surround the ONE FAMILY ONE JOB SCHEME which was a brainchild of the SDF chief that benefited around 20 thousand members of poor jobless families across the state.</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </article>
                    
                    
                    


                   
                </div>
            </div>
        </section>





                                    </div>
                                    
                       




                                <Footer2 />

                        </div>
                    </div>
                </main>

            </>


    );

};

export default PressRelease;