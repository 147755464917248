import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import Header2 from "../components/nav/Header2";


import Footer2 from "../components/nav/Footer2";





const Contact = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">
                        <div class="wrapper">

                                <div class="root-contact">
                                    
                    {/* Header */}
                    <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                    <div class="container contact-us-container" style={{paddingBottom: '50px'}}>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                    <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                        data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                        Contact Us
                                    </h2>

                                    


                                </div>
                            </div>

                        </div>
                    </div>

                </section>


        {/* Body */}

   
                                    
          <div class="container section-margin">
            <div class="row">
              <div class="col-lg-6">
                <div class="box-info-contact" style={{backgroundColor: '#153493'}}>
                  <h3 style={{color: '#fff'}}>CONTACT US</h3>
                  <p style={{color: '#fff', fontSize: '1rem'}}>To get in touch with Pawan Chamling, you can reach out in the following ways</p>

                  <ul style={{paddingLeft: '0px'}}>
                    <li>
                      <span style={{color: '#fff'}}>Phone</span>
                      <a href="#" style={{color: '#fff', fontSize: '1rem'}}>03592-281586</a>
                    </li>
                    <li>
                      <span style={{color: '#fff'}}>Email</span>
                      <a href="#" style={{color: '#fff', fontSize: '1rem'}}>pawanchamling50@gmail.com</a>
                    </li>
                    <li>
                      <span style={{color: '#fff'}}>Address</span>
                      <a href="#" style={{color: '#fff', fontSize: '1rem'}}>SDF Bhavan Indira Bypass, Gangtok, East Sikkim, 737101.</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-box">
                  <h3 style={{color: '#000'}}>Write A Comment</h3>
                  <form id="contact-form" class="form" method="post" action="#" data-toggle="validator">
                    <div class="messages"></div>
                    <div class="input__wrap controls">
                      <div class="form-group">
                        <div class="entry">
                          <label style={{color: '#000'}}>What's your name?</label>
                          <input id="form_name" type="text" name="name" placeholder="Type your name" required="required"
                            data-error="name is required." style={{border:'none', padding: '0px'}} />
                        </div>
                        <div class="help-block with-errors"></div>
                      </div>

                      <div class="form-group">
                        <div class="entry">
                          <label style={{color: '#000'}}>What's your email address?</label>
                          <input id="form_email" type="email" name="email" placeholder="Type your Email Address"
                            required="required" data-error="Valid email is required." style={{border:'none', padding: '0px'}} />
                        </div>
                        <div class="help-block with-errors"></div>
                      </div>
                      
                      <div class="form-group">
                        <div class="entry">
                          <label style={{color: '#000'}}>What’s your phone number?</label>
                          <input id="form_email" type="number" name="phone" placeholder="Type your Phone Number"
                            required="required" data-error="Valid phone no. is required." style={{border:'none', padding: '0px'}} />
                        </div>
                        <div class="help-block with-errors"></div>
                      </div>

                      <div class="form-group">
                        <div class="entry">
                          <label style={{color: '#000'}}>What is your message?</label>
                          <textarea id="form_message" class="form-control" name="message"
                            placeholder="Tell us about you and the world" required="required"
                            data-error="Please,leave us a message." style={{border:'none', padding: '0px'}}></textarea>
                        </div>
                        <div class="help-block with-errors"></div>
                      </div>

                      <div class="image-zoom" data-dsn="parallax">
                        <button style={{backgroundColor: '#153493'}}>Send Message</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>








                                    </div>
                       

                                <Footer2 />

                        </div>
                    </div>
                </main>

            </>
    );

};

export default Contact;