import React from 'react';

const PoliticalAchievementHeader = () => {
    return(
        <>
            <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                    <div class="container" style={{paddingBottom: '50px'}}>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                    <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                        data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                       Political Achievements
                                    </h2>

                                    <p class="economy-page-header-desc" style={{fontSize: '1rem', fontWeight: '500', maxWidth:'100%', color:'#000', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px'}}>
                                    Under Pawan Chamling’s leadership, Sikkim has made several major political achievements including implementation of OBC, Income tax exemption, Scheduled Tribe status for the Limboo Tamang community, State OBC status for Bahun, Chettri and Newar community, recognition of state languages and inclusion of Sikkim in the North Eastern Council.

                                    </p>


                                </div>
                            </div>

                        </div>
                    </div>

                </section>

                <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/good-governance/inside-page/Political Achievement.png" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                </div>
                </header>
        </>
    );
};

export default PoliticalAchievementHeader;