import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr25Jully2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/25July.jpeg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>25 Jully, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and the President of the SDF party Shri Pawan Chamling has expressed his concern about the Sikkim Chief Minister still taking the Sikkim Covid situation lightly and saying that the situation is under control.
                            <br/><br/>He has also sternly asked the Sikkim Government to stop harassing people in the name of Covid guidelines and blaming people for their administrative failure. He has condemned the government recklessly organising in-person programs and spreading Covid infection. Shri Chamling has asked the government to read Sikkim health reports carefully and learn to value people’s lives.
                           </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                    “ Question: Are you worried about the prolonged second wave in Sikkim? Chief Minister Shri PS Tamang told journalists in Assam that the second wave in Sikkim is under control and there is no need to panic. What would you say in response to this statement?

                                </p>

                               

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> I request the Sikkim government to read the Covid-19 reports in Sikkim carefully with a humanitarian touch. Let me tell them what is happening right now: As many as 87 Covid-19 deaths in Sikkim were of those without comorbidities, which means that nearly 50 per cent of those who died of Covid-19 so far, did not have comorbidities. These dear people lost their lives during the second wave. Is that a sign of a situation “under control”?



                                </p>

                                <img class="w-100" src="assets/img/pressrelease/25July.jpeg" alt="" />
                                <br/>
                                <br/>

                                <p style={{color: '#000'}}>
                                   When we compare the number of deaths in the first and second wave, 33 per cent more people died due to Covid-19 during the second wave. Is this a sign of a situation “under control”?
More and more young people are falling victim to Covid-19. The health report says that 61 of the 97 Covid-19 cases of Delta variant in Sikkim were below 35 years of age. Is there “no need to panic”?


                                </p>
                                 <p style={{color: '#000'}}>
                                   Now the state administration is once again so mercilessly putting the blame of Covid-19 deaths on the people themselves. They are saying that many patients died because they did not come to the hospital in time. But what they must understand is that many people do not want to go to the hospital for various reasons. The Honourable Sikkim High Court had asked the state government to intensify the preparation of hospitals for Covid-19 some time ago. The government has to win the people’s confidence, generate awareness and convince them. People are not medical experts. They need to have full confidence based on the government’s performance, information and communication. If Covid patients are not coming to the hospitals, it is because they are afraid and do not have confidence in the health care system. What does that show? It shows that the government needs to upgrade its Covid facilities, intensify hospital preparation for Covid treatment, employ more manpower as per the need, ensure enough rest for fatigued Covid warriors and educate and convince the people - not blame them.
 

                                </p>
                                 <p style={{color: '#000'}}>
                                   Now with some random guidelines released by the government out of desperation, the situation is worsening, making the people’s lives even more difficult. The idea of containing an entire area or ward merely because of one Covid positive case is not a scientific or socially helpful step. It is just an expression of governmental frustration. It is ludicrous. What is worse, the government is imposing mass challans to harass and terrorise the people. This state government knows only one way of working, which is to terrorize and inconvenience the people. They should know that this foolish tactic is not working. They are containing village after village, causing further economic and emotional distress and the number of coronavirus cases is not decreasing at all.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                  Another distressing news came of two individuals who attended the Azadi ka Amrut Mahotsav program held at Manan Kendra in Gangtok on Wednesday now testing positive for Covid-19. The Culture Department headquarter is now a containment zone. The culture department also organised a storytelling event as a part of the same program which was attended by students. When schools are running online, what a foolish and reckless idea to gather students physically!
Remember, the government has been organising many programs under Dandi March in various departments. As if organising a joining meeting in Rangli at the start of the spread of Covid-19 in Sikkim was not bad enough, the SKM party has now been using the government machinery to ensure that Covid-19 is spread in every possible way. Chief Minister PS Tamang is spearheading the “SIKKIM CORONAVIRUS DISTRIBUTION MISSION”. He is touring recklessly with hundreds of people with him.   It will not be a bad idea to first want and then I mass challan his ever touring group. 




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   I sternly ask the government not to play with people’s lives anymore. The present administration has been given enough time to fight the Covid battle but it has miserably failed. The government must constitute a high powered committee with epidemiologists, renowned healthcare and disaster professionals and bring the situation under control. Enough of whimsical and silly notifications. It is now or never.”




                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr25Jully2021;