import React, {useEffect} from 'react';

import {Link} from 'react-router-dom';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr16May2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/May16.jpeg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>16 May, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <Link to="#" style={{color: '#000'}}>Uncategorized</Link>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and the President of SDF party Shri Pawan Chamling advice the Sikkim Government to aim to become India’s first Covid Free State by way of massive vaccination programme across the State. He also asked the Government to make all the necessary preparation massively against the impending third wave of the Corona Virus.
                           </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                    Q. The second wave of COVID-19 caught us off guard. We are losing our dear ones like never before. The lives of those who are not battling this critical disease have been crippled. Now a third wave is inevitable. Things look too distressingly dangerous ahead. What would you like to say on these matters?

                                </p>

                                

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> I would like to draw the attention of the government and people to the dangerous third wave of Covid which looks ominous. The second wave has been far more menacing than the first and experts are saying that the third wave will be far more disastrous than the second. At this critical juncture, as a senior leader and former Chief Minister, I am deeply compelled to put forth a few highly important facts for us to ponder.


                                </p>

                                <img class="w-100" src="assets/img/pressrelease/May16.jpeg" alt="" />
                                <br/>
                                <br/>

                                <p style={{color: '#000'}}>
                                   1. The sudden and alarming rate at which Covid infections and deaths have increased are signs that the virus is becoming deadlier and we are becoming less alert and more vulnerable. Some international experts are saying that the NE are on the verge of seeing a peak in Covid-19 infections.


                                </p>
                                 <p style={{color: '#000'}}>
                                    2. The State High Court’s observation about the poor preparation on the part of the State Administration is frightening. If that is the situation of our hospitals at this critical hour, I shudder to think of where we are heading. I ask the state administration to scale up their preparations and release a press statement saying what improvements have been made. If the government respects people’s rights and values people’s lives, it is their foremost duty to swallow their ego and act sensibly in light of the observation made by the Honourable High Court.
 

                                </p>
                                 <p style={{color: '#000'}}>
                                   3. The preparation for the third wave has to begin on a war footing, knowing that we are possibly going to fight the deadliest war ever. It is very important to listen to what the experts are saying and implement their suggestions at any cost. If we save lives now, we can do other things later in a more meaningful and purposeful manner. Here are a few suggestions.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   1) Prepare a massive well-trained healthcare force for the third wave. Provide them with every facility possible so that they remain safe and inspired while serving others.
                                   <br/><br/>
2)Aim to become the first Covid-free (zero Covid) state in India. Since ours is a small population, we can implement a strict lockdown and vaccinate eligible people within two weeks. It is the government that has to dream, envision and plan to become India’s first Covid-free country.
<br/><br/>
3) Since the third wave is going to affect children and the younger population, I appeal to the government to generate massive awareness among young people. Now experts are saying that the Pfizer vaccine is the safest vaccine among children above 12. The Sikkim government must make serious attempts and lobby at the right places to make Pfizer available for this age group of the population. Where there is a will, there is a way. We cannot afford to lose a single child in our state.
<br/><br/>
4) Children and adolescents with poor health must be identified immediately and provided extra safety through governmental intervention.
We need to prepare special Covid wards for small children.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                    Sikkim must learn from Bhutanese example. Bhutan saw only one Covid death and they vaccinated 93% of adults in just 16 days. Our population is slightly smaller than that of Bhutan. I had suggested the Sikkim government to vaccinate all the eligible Sikkimese adults before 16th May. 




                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                 If the State Government had listened to our suggestions, we could have minimised loss and increased safety. When I look at the situation of my beloved state and people feeling insecure and scared, I feel pain and sadness deep within me.  I really feel that the value of the lives of the Sikkim people has been grossly disregarded. They now face a situation where they have to fight for their lives themselves. 



                                </p>
                                
                                <p style={{color: '#000'}}>
                                   I am deeply anguished to see the Chief Minister openly defying Covid protocols and roaming in public places with hundreds of people around him. This is conveying a wrong and demoralizing message. When the head of the government doesn’t realize the criticality of the issue, what hope does this state have? I appeal to him and his supporters to organize all events virtually. At this technologically advanced time, there is hardly anything that we can’t do in a virtual mode. Save Sikkim, Save the Sikkimese and the rest will fall into place.”




                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr16May2021;