import React from 'react';

const EducationHeader = () => {
    return(
        <>
            <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                    <div class="container" style={{paddingBottom: '50px'}}>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                    <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                        data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                       Education
                                    </h2>

                                    <p class="economy-page-header-desc" style={{fontSize: '1rem', fontWeight: '500', maxWidth:'100%', color:'#000', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px'}}>
                                    In a state where education was for the few and wealthy, the SDF government has made it a reality for all sections of society. From making education free upto college level and increasing the number of schools and higher education institutions at the rate of 15%, education is now accessible for all students of Sikkim. A number of innovative scholarship programs enabled students from government schools to go to the best schools in the country and to the best universities in the world. As education is the great leveller in society, the pro-poor SDF government has focused on harnessing its power ensure equal opportunity for all.
                                    </p>


                                </div>
                            </div>

                        </div>
                    </div>

                </section>

                <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/good-governance/inside-page/Education.png" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                  

                </div>
                </header>
        </>
    );
};

export default EducationHeader;