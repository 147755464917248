import React, {useEffect} from 'react';

import Header2 from "../components/nav/Header2";
import BiographyHeader from "../components/biography/BiographyHeader";
import BiographyLife from "../components/biography/BiographyLife";
import BiographyBrand from "../components/biography/BiographyBrand";
import BiographyDidYouKnow from "../components/biography/BiographyDidYouKnow";
import BiographyBanner from "../components/biography/BiographyBanner";

import Footer2 from "../components/nav/Footer2";




const Biography = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">
                        <div class="wrapper">

                                

                                <div class="root-contact">
                                    <BiographyHeader />
                                    <BiographyLife />   
                                    <BiographyBrand /> 
                                    <BiographyDidYouKnow />
                                    <BiographyBanner />
                                </div>

                                <Footer2 />

                        </div>
                    </div>
                </main>

            </>
    );

};

export default Biography;