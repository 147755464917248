import React from 'react';
import {Link} from 'react-router-dom';

const HomeSocial = () => {
    return(
        <>
            <section style={{background: '#eee'}}>
    <div class="container" style={{padding: '100px 0 150px'}}>
        <div class="title pc-social-link" style=
    {{textAlign: 'center',
    color: '#000',
    fontSize: '42px',
    fontWeight: '700',
    lineHeight: '74px',
    marginBottom: '80px'}}>Pawan Chamling on Social Media</div>
     
    
    

    
    
    <div class="row pc-social-link-row" >
        
            <div class="col-md-6">
                <Link to="https://www.instagram.com/pawankchamling/?hl=en" class="" link="_blank" style={{display: 'block'}}>
                <div class="row link pc-social-link-item" style={{color: '#05090e',
                    backgroundColor: '#ffffff',
                    padding: '20px 20px',
                    fontWeight: '700',
                    fontSize: '18px',
                    lineHeight: '33px',
                    margin: '10px 0',
                    borderRadius: '15px'}}>
                    
                    <div class="col-md-2">
                        <img src="assets/img/icons/instagram.png" 
                        alt="Photo" style={{height: '45px', width: '45px', borderRadius: '45px', marginRight: '15px'}} />
                    </div>
                    <div class="col-md-10" style={{padding: '10px 10px'}}>
                        <h4 class="h3" style={{color:'#05090e'}}>PC on Instagram</h4>
                    </div>
                </div>
                </Link>
                
                
                <a href="https://www.facebook.com/BeautifulSikkim" link="_blank" style={{display: 'block'}}>
                <div class="row link pc-social-link-item" style={{color: '#05090e',
                    backgroundColor: '#ffffff',
                    padding: '20px 20px',
                    fontWeight: '700',
                    fontSize: '18px',
                    lineHeight: '33px',
                    margin: '10px 0',
                    borderRadius: '15px'}}>
                    
                    <div class="col-md-2">
                        <img src="assets/img/icons/facebook.png" 
                        alt="Alexandria Ocasio-Cortez Photo" style={{height: '45px', width: '45px', borderRadius: '45px', marginRight: '15px'}} />
                    </div> 
                    <div class="col-md-10" style={{padding: '10px 10px'}}>
                        <h4 class="h3" style={{color:'#05090e'}}>PC on Facebook</h4>
                    </div>
                </div>
                </a>
                
                
            </div>
            <div class="col-md-6">
                 
                <a href="https://twitter.com/pawanchamling5" link="_blank" style={{display: 'block'}}>
                <div class="row link pc-social-link-item" style={{color: '#05090e',
                    backgroundColor: '#ffffff',
                    padding: '20px 20px',
                    fontWeight: '700',
                    fontSize: '18px',
                    lineHeight: '33px',
                    margin: '10px 0',
                    borderRadius: '15px'}}>
                    
                    <div class="col-md-2">
                        <img src="assets/img/icons/twitter.png" 
                        alt="Alexandria Ocasio-Cortez Photo" style={{height: '45px', width: '45px', borderRadius: '45px', marginRight: '15px'}} />
                    </div>
                    <div class="col-md-10" style={{padding: '10px 10px'}}>
                        <h4 class="h3" style={{color:'#05090e'}}>PC on Twitter</h4>
                    </div>
                    
                </div>
                </a>
                
                <a href="https://www.youtube.com/channel/UCbWxZ1g9vWLm16cuXXql-bA" link="_blank" style={{display: 'block'}}>
                <div class="row link pc-social-link-item" style={{color: '#05090e',
                    backgroundColor: '#ffffff',
                    padding: '20px 20px',
                    fontWeight: '700',
                    fontSize: '18px',
                    lineHeight: '33px',
                    margin: '10px 0',
                    borderRadius: '15px'}}>
                    
                    <div class="col-md-2"> 
                        <img src="assets/img/icons/youtube.png" 
                        alt="Alexandria Ocasio-Cortez Photo"style={{height: '45px', width: '45px', borderRadius: '45px', marginRight: '15px'}} />
                    </div>
                    <div class="col-md-10" style={{padding: '10px 10px'}}>
                        <h4 class="h3" style={{color:'#05090e'}}>PC on Youtube</h4>
                    </div>
                    
                </div>
                </a>
                
            </div>
            
        
    </div> 
    
    </div>
</section>





        </>
    );
};

export default HomeSocial;