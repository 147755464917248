import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr4July2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/4July.jpeg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>4 Jully, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and President of the SDF party responded to the allegation of his prolonged absence in the Sikkim Legislative Assembly. He said that the SKM government is using hired goons to heckle him which has brought an indelible disgrace to the Sikkim Legislative Assembly that he holds in the greatest esteem. The government refused to confess the unfortunate incident and Chief Minister PS Tamang shamelessly said, “Tyo malai thaha bhaena”. The state government also uses the entire treasury bench to heckle him and the Speaker and Chief Minister watch the show mutely. He said that he will enter the Assembly when he sees fit and he will continue to raise the people’s voices and concerns in every possible way.
                           </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                    “ One allegation made against you has been that you are avoiding the assembly sessions. You have not been able to represent the opposition party in the assembly. Also, the SKM party is saying that you should have attended the last assembly and shared your suggestions for this pandemic. How would you address these questions?


                                </p>

                               

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> First of all, let me make it absolutely clear that I have been raising my voice and concerns about the misrule and administrative failures under the SKM government. From complete mismanagement of Covid-19 to rampant de-employment of government employees, from the blatant deception about the 100 day promises made by the SKM party to the mindless cutting down of trees, from coldblooded threats made by Chief Minister Shri PS Tamang in open to the slowing down of the developmental pace - I have condemned and exposed everything and will keep doing so.



                                </p>

                                <img class="w-100" src="assets/img/pressrelease/4July.jpeg" alt=""/>
                                <br/>
                                <br/>

                                <p style={{color: '#000'}}>
                                    I have attended the assembly sessions twice since the SKM party formed the government. The SDF party initially boycotted the session as Mr PS Tamang, who was ineligible to become a public representative and contest the election came from the backdoor to become Sikkim’s Chief Minister. It was extremely unfortunate and will remain as an indelible blotch in the history of Sikkim’s democracy. The nation knows how he became Chief Minister and I do not want to repeat the whole story all over again. All I can say is that Sikkim is bearing the consequence of a wrong person heading up the government. The lessons came in the form of endless suffering, humiliation, insecurity and intimidation.


                                </p>
                                 <p style={{color: '#000'}}>
                                    Now let me tell you how the Sikkim Legislative Assembly has been made to function under this government. The SKM government keeps hiring goons in the gallery whose job is to heckle me. They use the meanest words in the ugliest tone in the most barbarous manner. It feels as if you are in a den of dacoits. The assembly is sacrosanct and I find it unbearably painful to see the SKM government going so low to destroy the dignity and sanctity of the Sikkim Legislative Assembly. As a Sikkimese citizen and an avid believer in democracy, I cannot continue to see the SKM and its hired goons make a mockery of the SLA. Despite the clear evidence, the PS Tamang-led government didn’t even confess that such an unfortunate event took place right under their nose, let alone arrest the goons. For one thing, it pains me to see them bringing such disgrace to the Sikkim Legislative Assembly, a place I revere with all my heart. For the last 36 years, I entered this house with utmost respect and behaved reverentially. And then, these goons entered and behaved as though they were in the street. But it doesn’t stop there. These goons, who have gone scot free, could even physically attack me. There is an unrestrained and unchecked hatred and anger against me and anyone who speaks for Sikkim. This party is openly stirring up sentiments and using that hatred and violence and these goons are hired to do so.
 

                                </p>
                                 <p style={{color: '#000'}}>
                                   The Sikkim media kept quiet about it. Sikkim civil society didn’t utter a word.  What does it show? It shows that the people’s freedom of speech and press freedom has been curtailed. There is no democracy inside the Assembly and outside the Assembly. People are afraid to speak. They are losing confidence. The happiness index is now worst in the country where Sikkim was the happiest state in India. It was called the Sukhistan of Hindustan. Now it has become Dukhistan. Later, when Chief Minister PS Tamang was asked about the goons heckling me, he shamelessly said, “Tyo malai thaha bhaena” (I don’t know). What can you expect from a government run by a Chief Minister who pretends to be deaf and blind to the tamasha orchestrated by his party? The people have seen the deception of their 10 and 100 day promises, injustices and people being removed from government jobs. People now know how much this “tyo malai thaha bhaena Sarkar” (I don’t know government) can do for them. 



                                </p>
                                
                                <p style={{color: '#000'}}>
                                  As if hiring goons and keeping them in the gallery was not enough, the SKM government has strategized to disallow my voice to be heard by keeping a dozen SKM MLAs ready to heckle me. In an assembly session, I stood up to speak and wanted to share my concerns and suggestions but the entire treasury bench stood up to heckle me. What was worse, the Speaker and Chief Minster watched the show mutely. In my 36 years in the Sikkim Legislative Assembly, I saw such anarchy in the SLA for the first time. I am not surprised that a section of Sikkim police and government employees are not working for the government but for the SKM party. Remember, government employees must be loyal to the government, not the ruling party.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   If they value my suggestions in this pandemic, they should have listened to some of my suggestions. I shared my suggestions every week. I offered the SDF party office in Jorethang. They arrogantly ignored all my suggestions and the suggestions given by others. Now, look at our situation. The lockdown has been implemented for two months and our positivity rate is the worst in the world. Every day people are dying.




                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                 In the end, let me say this, the SKM party questioning my absence in the Assembly is like a man inviting people to his house with unchained rabid dogs loitering in the compound. I am more worried about the sanctity of the house than about my personal safety. I ask the government to recognise the sacrosanct status of the SLA and keep it away from the reach of goons. They don’t have to worry much about my absence now. I will be there when I see fit.”



                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr4July2021;