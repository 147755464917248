import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr11April2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/GeyzingStudents.jpeg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>11 April, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and SDF President Shri Pawan Chamling admonishes the administration for being insensitive to the students in general and four Geyzing college students in particular. He appeals to the students and people to never give up and ensure justice.
                           </h3>
                           
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#">
                                            <span>#OFOJ</span>
                                        </a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                  “ Question: The unfortunate expulsion of four students from Geyzing College has brought Sikkim education into terrible disrepute. There was widespread criticism against the college administration from different organizations and individuals from Sikkim and even from outside Sikkim. In a shocking move, Chief Minister Shri PS Tamang went on to justify the expulsion, terming it a disciplinary case. The case is slowly going into oblivion. It is feared that citizens in Sikkim will slowly lose the courage to question the government. Some are saying that this will have a lasting impact on the minds of students. What are your thoughts?

                                </p>

                               

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> There can be a huge gap between students and the people who run the administration as they come from different generations. Their ambitions, ideas and outlook may differ. Young students have their own dreams, perspectives and aspirations. It is only natural for students to want what the modern world can offer. What wrong did those students commit when they wanted to meet the HRDD Minister and request him to expedite the construction work of the college infrastructure?



                                </p>
                                
                                <div>
                                <img class="w-100" src="assets/img/pressrelease/GeyzingStudents.jpeg" alt="" />
                                <p style={{color: '#000', fontSize: '14px'}}>
                                    The four Geyzing College students who were expelled for demanding the completion of their college campus.	

                                </p>
                                </div>
                                
                                <p style={{color: '#000'}}>
                                    Instead of listening to them, this cruel government tortured them, arrested them and finally expelled them from the college for which they were fighting. It is as brutal as snatching food from a client who has complained to the restaurant manager about the quality of the food. Even worse, it is as cruel as pushing away a patient who asked the reluctant pharmacist to give him his prescription. Can anything be crueller and harsher than robbing children of the very dreams for which they sacrifice everything?

                                </p>
                                 <p style={{color: '#000'}}>
                                    What kind of leadership takes students’ demands in such a repressive manner? I am shocked that this leadership could become so immature and cold. They must know that they are leaders entrusted with the responsibility of nurturing young people and nourishing their dreams. Who gave them the power to play with their emotions and ruin their future? Who gave them the power to snatch away the fundamental and natural rights of our students to question authority? Did they inherit a monarchy or were they elected to run the government?
 

                                </p>
                                 <p style={{color: '#000'}}>
                                   I do admit that there was a student’s agitation in 2014 in which the Tadong college (Present-day NBD College) students demanded a rollback of their examination fee. There was an oversight on the concerned administration which was extremely unfortunate. But we did our utmost to rectify that mistake by giving much more in the end than what they demanded. We not only instructed the college authorities to roll back the fee but we also declared that education would be free for Sikkimese students right up to the college level.



                                </p>
                                
                                <p style={{color: '#000'}}>
                                   I cannot fathom the immaturity of this government. They react like ill-tempered children. When they first formed the government, they were not even interested to continue with our nationally acclaimed innovative Scheme called the Chief Minister Meritorious Scholarship. It is this scheme which has given unheard of opportunities to the children of poor parents across Sikkim to study in some of India’s best schools.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                    I do not know what this government is doing with another scheme launched by the SDF government called the Chief Minister’s Free Scholarship Scheme (20 best universities in the world),  Free Coaching for UPSC examinations, Comprehensive Education Loans, Small Family Scheme, Prerna Yojana, Fellowship for Research Scholars, free BEd for in-service teachers, etc.



                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                 I hope that the present government will appreciate the value and significance of these wonderful schemes aimed at making Sikkim education the best and unique in the country and even the world.



                                </p>
                                
                                <p style={{color: '#000'}}>
                                    I am completely aghast to see an insensitive government who would treat students as their enemy and rob them of their right to study. My heartfelt sympathy is with those students and their parents. I encourage them and all other students that our lives are much more important than the pettiness of these immature so-called leaders. Never give up. Never say ‘never’. We are with you. The more they suppress us, the louder our voices should be. I appeal to the people of Sikkim to support students in their cause and let us not allow their dreams to wither under this repressive leadership. We will fight for students’ rights and make this government realize that our students’ futures are more important than their egos. Justice will prevail and the truth will prevail.”




                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr11April2021;