import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr20Sep2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                        <div class="header-single-post" data-dsn-header="project">
                            <div class="post-parallax-wrapper" data-overlay="3">
                                <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/20Sep.jpeg" alt=""
                                    data-dsn-header="blog" data-dsn-ajax="img" />
                            </div>
                            <div class="container">

                                <div class="inner-box m-section">
                                    <div class="post-info">
                                        <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>20 Sep, 2021</a>
                                        <div class="blog-post-cat dsn-link">
                                            <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                        </div>
                                    </div>

                                    <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                                    Former Chief Minister and the SDF President Shri Pawan Chamling says that the Ram Rajya the SKM party has promised during the last election campaign never ushered in. “Golay’s Team” has been running amok enjoying their own version of perverted Ram Rajya and the people are lamenting crying “Hey Ram” and “Ram, Ram”.  He says the worse is yet to come under such a cruel and heartless government.
                                </h3>
                                </div>

                            </div>

                        </div>
                    </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                  “Q. Mr PS Tamang had promised a Ram Rajya during his election campaign. How would you describe his 'Ram Rajya' over the last two years of SKM governance?
Ans: No doubt the SKM government has been a Ram Rajya for Mr Golay and “Golay’s Team”. But in Golay’s Ram Rajya, the common people have not been able to find solace for their pain and suffering. The government is supposed to be a source of security and help for the people but the Golay government has been a source of fear, terror and insecurity for the people. Therefore, the SKM regime has become “Hey Ram, Rajya”. People are saying “Hey Ram” to express their shock, agony, pain and loss under this cruel regime. Close your eyes and think about the most significant events that have become media headlines over the last two years. 


                                </p>

                                

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> They are –
                                    <br/><br/>
1.              An attack on SDF cadres immediately after forming the government. There was violence, arson and open verbal threats. Many were severely injured and some houses were severely damaged. When these citizens had to face such violence, would they say, “Hey Ram” or “Ram Rajya”?



                                </p>

                                <img class="w-100" src="assets/img/pressrelease/20Sep.jpeg" alt=""/>
                                <br/>
                                <br/>

                                <p style={{color: '#000'}}>
                                    2.      The illegal appointment of over 2000 individuals in government jobs through the backdoor. All of them are either relatives of the CM, Ministers, MLAs, Advisors, Chairpersons or Top Bureaucrats. They should have been given those jobs openly under a OFOJ kind of scheme. There are around 30,000 families in Sikkim with no job.  Moreover, the SKM government removed hundreds of government employees, mainly from SNT and started threatening the employees of OFOJ. They had to protest for several days at Tashiling Secretariat against the government’s cruelty. It was only then that the government had to kneel down before the transparency of OFOJ. OFOJ is a fair public welfare scheme and we gave out jobs in public. The SKM government is giving jobs to their cadres secretly. 


                                </p>
                                 <p style={{color: '#000'}}>
                                    3.       The expulsion of four students from Geyzing college who were merely wanting to meet the HRDD Minister and CM. It was the most regressive, oppressive and anti-education act one can imagine. I can’t believe that it happened to us, the Sikkimese people, during our time. The world is laughing at our government’s foolishness. Even a former Supreme Court judge, Mr Markandey Katju asked the SKM government to repeal the expulsion order - but to no avail.
When students from all over Sikkim started condemning the expulsion, this anti-education government stirred up a few students to praise the government. Such is the SKM’s Ram Rajya.
 

                                </p>
                                 <p style={{color: '#000'}}>
                                  4.       The SPYF members staged a dharna to ask the state government to reconsider the hike in taxi fares during the pandemic. Instead of listening to them, the arrogant, divisive and heartless government instigated the taxi drivers to stage a counter-dharna. Such is the SKM’s Ram Rajya.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                  5.       Sikkim’s young Adhoc teachers had to repeatedly come to the streets to ask the government to listen to their grievances. They wanted to meet the HRDD Minister and CM. The government imposed section 144 to restrict their entry into the HRDD office. They had to stage a hunger strike. A democratic government must respect any form of protest and establish  dialogue. But this SKM government stirred up another set of Adhoc teachers to go to the dharna site and disrupt their peaceful dharna. There was a shameless commotion and abuse. What a Ram Rajya! They must be reminded that independent India was the outcome of numerous dharna and protests by Gandhi and hundreds of freedom fighters. Now, Sikkimese citizens have lost their very right to sit for dharna.
 <br/><br/>
Remember, section 144 is imposed to prevent terrorist activity. Why impose it upon teachers? Because the SKM government is anti-education and a pro-goondaism party. There is no respect for teachers. There can’t be a worse case of misuse of power and humiliation of education.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   6.       When Shri Bhaichung Bhutia questioned the government’s decision to build a hospital on a public playground and divert money from the Covid crisis to infrastructure building, Golay’s Team burned his effigy in the middle of Namchi bazaar, violating Covid protocols. A bunch of angry women were singing the praises of SKM’s Ram Rajya and the onlookers were saying “Hey Ram”.




                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                 7.       Sikkim witnessed another shocking spectacle of SKM’s typically angry workers mobbing, harassing, shaming and even threatening a BJP MLA and Former Minister Shri Ugen Bhutia in his own constituency. Such a stone age, pre-historic, barbaric act is possible in the 21st century, only in Golay’s Ram Rajya.



                                </p>
                                
                                <p style={{color: '#000'}}>
                                    8.       They do not spare their own party workers and leaders. Health Minister Shri MK Sharma was beaten up in public by SKM workers. The minister’s brother shared his shock and anguish on social media and then he was arm-twisted to change his statement.



                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                     
9.       There are rumours that the Rhenock MLA has also been threatened and even beaten. A SKM woman leader used to repeatedly abuse the MLA on social media and she was never disciplined by the party. Ram Rajya!


                                </p>
                                <p style={{color: '#000'}}>
                                   10.   Former Minister, the SIBLAC Convener and  SNS Chief Patron Shri Tseten Tashi Bhutia were dared by one of the SKM leaders to walk in Gandhi Marg. Then there was a peace walk conducted by him with his supporters. Even ordinary citizens have to prove that they can walk freely despite the government’s threats. That is their Ram Rajya.
                                   
                                   <br/><br/>
                                   
                                   These are only a few examples. The reality is much worse and darker than this tip of the iceberg. Many have joined the SKM as their contract bills have been held up or their family members have been given transfer orders. The image of Sikkim police is being tarnished under this Ram Rajya.
                                   
                                   <br/><br/>
                                   
                                   This is a disgrace to Ram Rajya. If you want to abuse Ram Rajya and humiliate Ram, then call the SKM rule Ram Rajya. Keep watching. More and more unidentified dead bodies are being found these days. There has been a rapid increase in drug peddling, rape cases, theft and suicide. Even cases of pick pocketing  have begun to surface. The worse is yet to come. SKM anger, hatred and frustration are on the rise. Wait till 2024 for the climax. The people’s “Hey Ram” a cry will only become louder. Looking at the Ram Rajya of Golay’s team, the people are saying, “Ram, Ram”.
 <br/><br/>
 
To call the SKM government a Ram Rajya is to say that Ram was good at telling lies and punishing those who exposed his lies. What we have in Sikkim is a Badnam Rajya for Sikkim lovers,  a Dyamki Dyam Rajya for SKM-sponsored goons, a Development fund Haam Rajya for some SKM leaders, a Business Jam Rajya for entrepreneurs, a Chakka Jam Rajya for drivers, a bill jam for contractors, a studies Thaam Rajya for students, and an Aapasi-Sangram Rajya for Adhoc teachers.”


   
                                </p>
                                
                            
                        </div>
                        </div>
                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr20Sep2021;