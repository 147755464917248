import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr13Feb2022 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/13Feb.jpeg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>13 Feb, 2022</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  Former Chief Minister and the President of the SDF party Shri Pawan Chamling talks about some of the milestones policy decisions taken by the SDF government in the field of education and the achievements, national awards and accolades received by the Sikkim HRDD. He says that the SDF innovated some unique educational schemes of which CMMSS is closest to his heart. It gives him immense sense of pride to see Sikkim as the most literate state in India and hundreds of highly educated youth all across the state.
                            
                            

                            
                            </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                    “ Q. How would you look back at the performance of the SDF government in the field of education?
                                </p>

                                

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span>First of all, we must all realize that quality of education is a complex subject. It involves diligence and hard work from all stakeholders of education-namely administration, teachers, parents and students. However, the government or the administration has a key role to play in providing hardware and software infrastructure and framing policies.

                                </p>

                                <img class="w-100" src="assets/img/pressrelease/13Feb.jpeg" alt="" />
                                <br />
                                <br />

                                <p style={{color: '#000'}}>
                                  We took some of the most remarkable policy decisions in the field of education. Almost 99 percent of schools have pucca buildings. We provided our students with India’s best teacher pupil ratio of 9:1. We built schools within walking distance (max 2 km) for every child. We made education free up to the college level. We distributed free school uniforms, books, exercise copies, raincoats, school bags, shoes and socks. We allocated 20% of the state budget to education. We expanded higher (college) education to all the districts and rural areas of Sikkim. Sikkim had two government colleges of which one was a night law college in 1994. We now have our own BEd college today. We started university education in the state.
                                </p>
                                 <p style={{color: '#000'}}>
                                   We also innovated unique, pro-poor and inclusive educational schemes of which the Chief Minister Meritorious Scholarship Scheme (CMSSS) is very close to my heart. Under the Chief Minister Meritorious Scholarship Scheme, all the class V toppers who have studied in government schools alone are selected through a state-wide test to study in India’s top-rated schools like Scindia School, Gwalior, Pinegrove School, Dharampur, Aryan School, Dehradun, Delhi Public School, Scindia Kanya Vidhyalaya, Shigally Hill International Academy, Dehradun outside Sikkim and TNA, St. Xaviers, Don Bosco, Namchi Public School, St.Joseph, St. Merry schools within the state. During my tenures as CM, I painfully discovered that even the reservations provided for different categories such as BL, ST, SC, OBC were mostly availed of by candidates who studied in good schools. Most of the professional courses like doctors, engineers, architects, etc. were bagged by students from good top-rated schools. I began to wonder if we could help meritorious students in government schools to find admission in some of the best schools in India and Sikkim. That gave birth to the CMMSS. It gives me immense joy to see thousands of Sikkimese students from poor families studying in those schools under this scheme.

                                </p>
                                 <p style={{color: '#000'}}>
                                   We started another scheme called the Chief Minister’s Free Scholarship Scheme under which any Sikkimese student who gets admission in one of the world’s top 20 universities will get a full scholarship from the Sikkim government. This is also to benefit Sikkimese children with governmental intervention.


                                </p>
                                
                                <p style={{color: '#000'}}>
                                  We started free coaching for students preparing for UPSC exams. Under this scheme, the state government paid a coaching fee of ₹2 lakh per student.
<br />
A quick glance at the following facts and figures will give any thinking citizen an idea of what the Sikkim Education Department was doing during the SDF governance:


                                </p>
                                
                                <p style={{color: '#000'}}>
                                    <ul>
                                        <li style={{paddingBottom: '10px'}}>
                                            1) Sikkim became the state that had the highest per capita spending of ₹12, 364.06 on education whereas other states with massive budgets like Kerala, Gujarat, Delhi and West Bengal had ₹4245.875, ₹3012.005,  4185.809 and 2223.26 respectively.
                                        </li>
                                        
                                        <li style={{paddingBottom: '10px'}}>
                                            2) Nearly 20% of the state government’s budget was allocated to education.
                                        </li>
                                        
                                        <li style={{paddingBottom: '10px'}}>
                                            3) The distribution of free uniforms, shoes, socks, bags, raincoats, textbooks, exercise copies, books, and meals in all the government schools of Sikkim
                                        </li>
                                        
                                        <li style={{paddingBottom: '10px'}}>
                                            4) CM’s Meritorious Scholarship Scheme - 2010 (for class V students of government schools).

                                        </li>
                                        
                                        <li style={{paddingBottom: '10px'}}>
                                            5) CM’s Special Merit Scholarship (For Sikkimese students in 20 best universities in the world).
                                        </li>
                                        
                                        <li style={{paddingBottom: '10px'}}>
                                            6) Financial aid to SC, ST and OBC students.

                                        </li>
                                        
                                        <li style={{paddingBottom: '10px'}}>
                                           7) Reward for class X and XII toppers in the state
                                        </li>
                                        
                                        <li style={{paddingBottom: '10px'}}>
                                            8) Sikkim had the best teacher–student ratio in India: Elementary -1: 8; Primary -1:5; and Secondary -1:6 (as per the Unified District Information for Education data).
                                        </li>
                                        
                                        <li style={{paddingBottom: '10px'}}>
                                            9) The Chief Minister’s Educational Loan Scheme.
                                        </li>
                                        
                                        <li style={{paddingBottom: '10px'}}>
                                            10) Out of 65 senior secondary schools in Sikkim 42 have science and 64 have commerce and 44 have a Vocational Education Programme.
                                        </li>
                                        
                                        <li style={{paddingBottom: '10px'}}>
                                           11) Sikkim Government Colleges have been opened all over Sikkim like Renock, Geyzing, Namchi, Chakung, Burtuk, and Soreng (BEd).

                                        </li>
                                        
                                        <li style={{paddingBottom: '10px'}}>
                                           12) The annual growth rate of higher educational institutions in Sikkim is 15.27 per cent as against the national average of 7 per cent.
                                        </li>
                                        
                                        <li style={{paddingBottom: '10px'}}>
                                           13) A full-fledged central university in Sikkim was established by an act of Parliament in 2007.
                                        </li>
                                        
                                        <li style={{paddingBottom: '10px'}}>
                                           14) The Sikkim State University was established.
                                        </li>
                                        
                                        <li style={{paddingBottom: '10px'}}>
                                            15) Free education for Sikkimese students up to the college level and up to the post-doctoral level for BPL students has been provided.
                                        </li>
                                        
                                        <li style={{paddingBottom: '10px'}}>
                                           16) Free education for Sikkimese students in polytechnics such as ATTC, Bardang and CCCT, Chisopani under which hundreds of our children have benefited.
                                        </li>
                                        
                                        <li style={{paddingBottom: '10px'}}>
                                            17) A kindergarten system was introduced in government schools for the first time.
                                        </li>
                                        
                                        <li style={{paddingBottom: '10px'}}>
                                            18) The literacy rate has increased from 56.94 per cent in 1991 to 96.72 per cent in 2018.
                                        </li>
                                        
                                        <li style={{paddingBottom: '10px'}}>
                                            19) Sikkim has 13 government colleges, 5 private colleges, 3 government universities including NIT, Ravangla, 4 private universities, 3 District Institutes of Educational Training and 2 polytechnics
                                        </li>
                                        
                                        
                                    </ul>
                                </p>
                                
                                <p style={{color: '#000'}}>
                                    Awards for Sikkim in the field of education:
                                </p>
                                
                                <ul>
                                    <li style={{paddingBottom: '10px'}}>
                                        1) In 2012–13, Sikkim was ranked fourth among all states of India on the Composite Educational Development Index. The education index measures the access, infrastructure and quality of teachers. The index is calculated by the National University of Educational Planning and Administration (NUEPA) based on the data available with the District Information of School Education (DISE). It was the Best Performing State in Education by India Today, 2004.
                                    </li>
                                    
                                    <li style={{paddingBottom: '10px'}}>
                                        2) Sikkim won the Best Small State for Investment in Education by India Today, 2005.
                                    </li>
                                    
                                    <li style={{paddingBottom: '10px'}}>
                                       3) Sikkim won the Best State in Primary Education by India Today Group, 2008.
                                    </li>
                                    
                                    <li style={{paddingBottom: '10px'}}>
                                        4) Sikkim won the Bharat Nirman Award in Education, 2009.
                                    </li>
                                    
                                    <li style={{paddingBottom: '10px'}}>
                                       5) Sikkim won the India Today Small State Awards in Education, 2010.
                                    </li>
                                    
                                    <li style={{paddingBottom: '10px'}}>
                                       6) Sikkim won the India Today State of States Award in Education, 2012.
                                    </li>
                                    
                                    <li style={{paddingBottom: '10px'}}>
                                       7) In 2016, Sikkim won the overall best state award in Education, 2016, in the 16th India Today State of the States Award.
                                    </li>
                                    
                                </ul>
                                
                                <p style={{color: '#000'}}>
                                    We also made teaching positions the highest paid job in the country. We took all possible steps to make teaching a special and dignified job. 

Today I take pride in the fact that Sikkim, the youngest state in the country, has become the most literate state in India. I take pride in the fact that most of our young people are educated, capable and smart. There are doctors, engineers, skilled professionals, professors and young people with Master’s degrees and PhDs in the villages of Silkkim. 

If anyone wants to say that the SDF government manipulated these facts and figures and bought the awards, I have nothing to say except, “Grow up”. 

Finally I appeal to the people of Sikkim and particularly education enthusiasts to keep doing our best in whatever way we can to contribute towards creating public awareness about the significance of education in human civilization.”
                                </p>

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr13Feb2022;