import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr13FJan2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                        <div class="header-single-post" data-dsn-header="project">
                            <div class="post-parallax-wrapper" data-overlay="3">
                                <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/Literature.jpeg" alt=""
                                    data-dsn-header="blog" data-dsn-ajax="img" />
                            </div>
                            <div class="container">

                                <div class="inner-box m-section">
                                    <div class="post-info">
                                        <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>31 Jan, 2021</a>
                                            <div class="blog-post-cat dsn-link">
                                                <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                            </div>
                                    </div>

                                    <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                                        Former Chief Minister and the SDF president Shri Pawan Chamling reflects on the role literature has played in his life as well as how he has used the time during the pandemic to delve into some of his writing work.
                            
                                    </h3>
                                
                                </div>

                            </div>

                        </div>
                    </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#">
                                            <span>#OFOJ</span>
                                        </a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                    “ Question: Despite being a full-time politician and the longest-serving Chief Minister in India, you have been able to give tremendous time to literature. Literature is very close to your heart. How would you like to remember your journey as a poet and songwriter? Have you been doing any literature work right now?


                                </p>


                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> I have been known as a hardcore politician and the longest-serving Chief Minister in India. I have given my life over to the wholehearted service of the people through politics. However, throughout the rigours of my life as Chief Minister, while entrusted with the highest administrative responsibility, deep down I lived as Pawan Chamling Kiran - ever sensitive to life’s deeper questions, angst and existential struggles. I kept my mind engrossed in literary exercises, seeking answers to these questions. Literature has always dwelt within my heart.  Personally, I love literature. As an ardent lover of Sikkim and the Sikkimese people, I take politics as my first priority. However, as busy I am, literature will always find a central place in my heart.



                                </p>
                                
                                <div>
                                    <img class="w-100" src="assets/img/pressrelease/Literature.jpeg" alt="" />
                                    <p style={{color: '#000', fontSize: '14px'}}>
                                        Pawan Chamling is also known as a poet, writer and publisher other than being a politician. Literature has always been a big part of his life.	

                                    </p>
                                </div>

                                <p style={{color: '#000'}}>
                                   During my 25 years of Chief Ministership, I published the entire works of many of our renowned poets and writers like IB Rai, Parijat, Agam Singh Giri and their like. I have also helped budding talents by publishing their works. We started the Sikkim Sahitya Academy and instituted awards in literature. It was during our governance that journalism thrived in Sikkim both qualitatively and quantitively.
During the pandemic, I wrote a book entitled “Rajnitik Abhyasle Janmaaeka Vicharharu” (Thoughts Birthed by Political Excercise) and a poem collection entitled Achetan Mannko Abhiwyakti. I hope to publish these works soon. I also wrote a few songs, essays and other poems. 


                                </p>
                                 <p style={{color: '#000'}}>
                                    I used my days during the pandemic to study politics globally. I read a few books. I have been listening to people and mulling over these things.” 

                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr13FJan2021;