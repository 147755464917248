import React, {useEffect} from 'react';

import Header2 from "../components/nav/Header2";

import AwardForStateHeader from "../components/award/AwardForStateHeader";
import AwardForStateBody from "../components/award/AwardForStateBody";



import Footer2 from "../components/nav/Footer2";

import "../award.css";
import "../awardascm.css";



const AwardForState = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">
                        <div class="wrapper">

                                <AwardForStateHeader />

                                <div class="root-contact">

                                    <div class="container section-margin">
                                        <AwardForStateBody />
                                    </div>
                                    
                                </div>

                                <Footer2 />

                        </div>
                    </div>
                </main>

            </>
    );

};

export default AwardForState;