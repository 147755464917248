import React, {useEffect} from 'react';
import Header from "../components/nav/Header";
import HomeHeader from "../components/home/HomeHeader";
import HomeAbout from "../components/home/HomeAbout";
import HomeResource from "../components/home/HomeResource";
import HomeBanner from "../components/home/HomeBanner";
import HomeAwards from "../components/home/HomeAwards";
import HomeReadingList from "../components/home/HomeReadingList";
import HomeVideo from "../components/home/HomeVideo";
import HomeUpcomingBook from "../components/home/HomeUpcomingBook";
import HomeSocial from "../components/home/HomeSocial";
import Footer from "../components/nav/Footer";
import HomeAwardMobile from "../components/home/HomeAwardMobile";

const Home = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
        <>

        <div >

            <Header />


            <main class="main-root">
                <div id="dsn-scrollbar">

                    <HomeHeader />

                    <div class="wrapper">
                        <HomeAbout />
                        <HomeResource />
                        <HomeBanner />
                        <HomeAwards />
                        <HomeAwardMobile />
                        <HomeReadingList />
                        <HomeVideo />
                        <HomeUpcomingBook />
                        <HomeSocial />
                        <Footer />

                    </div>

                </div>
            </main>

            </div>

        </>
    );

};

export default Home;

