import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr1Aug2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/Aug1.jpg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>1 Aug, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and the President of the SDF party Shri Pawan Chamling has responded to those who are saying that the SDF government did nothing in 25 years. He says that three kinds of people say that (1) the desperate SKM supporters who are distracting people’s attention from the utter failure of the SKM govt, (2) the opportunists who milked the SDF government and now want to sell their souls to the SKM govt so that they can milk it again or (3) the self-righteous self-proclaimed analysts who refuse to accept the good works of the SDF govt. He has shared some of the major facts and figures as evidences of the SDF government’s achievements.
                           </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                    “Q. What would you like to say to those who say that the SDF government did nothing good in 25 years?

                                </p>


                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span>  First of all, we must know who those people are who can speak such lies so confidently. They are one of the following.


                                </p>

                                <img class="w-100" src="assets/img/pressrelease/Aug1.jpg" alt="" />
                                <br/>
                                <br/>

                                <p style={{color: '#000'}}>
                                    1.       Frustrated SKM supporters who have realized that since the SKM government failed to keep a single promise, the only way to score brownie points is by lying about the SDF government. It is like a cactus calling a marigold thorny. It is like a barren fox calling a milking cow unproductive.
 

                                </p>
                                 <p style={{color: '#000'}}>
                                    2.       Those who took every benefit possible from the SDF government and when the SDF party was voted out, now feel desperate to milk the SKM government. It is the cry of opportunistic souls who live and die for personal benefits. They are soul sellers. When you criticize the SDF government, the SKM will buy you. 

                                </p>
                                 <p style={{color: '#000'}}>
                                   3.       Self-righteous, self-styled analysts who deliberately refuse to accept reality. If anyone who claims to be politically neutral and yet fails to see such a massive heap of evidence of the SDF government’s achievements, I have only one thing to say, “Get your eyes checked”.



                                </p>
                                
                                <p style={{color: '#000'}}>
                                  Let me remind them of a few of our historic achievements:
The extreme poverty level decreased from 41 per cent to 8 per cent way back in 2011. According to the World Bank, this was a record for the fastest poverty reduction in the world after World War II. Cuba achieved the second-fastest decrease in poverty. Sikkim’s extreme poverty was 8 per cent in 2011 and I am sure, by 2019, it must have reduced to nearly 2 per cent.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   The little-known Sikkim became a globally acclaimed tourist destination. Sikkim is now not only one of India’s best tourist destinations, but it was also recognized by Lonely Planet. The tourists footfall increased from 92,000 (domestic) and 6888(foreign) in 1994 to nearly 20 lakh (domestic) and 71,172 (foreign) in 2018. Rural tourism (homestays) in Sikkim has become a national example.




                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                 Sikkim is the only organic state in the whole world. Sikkim won the Future Policy Gold Award. But right now, Sikkim is ruled by leaders who think that such a prestigious prize can be bought with money.


                                </p>
                                
                                <p style={{color: '#000'}}>
                                    Sikkim is India’s first Nirmal Rajya (2008) and First Open Defecation Free and Cleanest State.
Sikkim became India’s greenest state way back in 1998.
Sikkim’s per capita income rose from ₹8457 to ₹ 3,41,000 which was the third-highest in the country.




                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                     
Our GSDP increased from a mere ₹377.43 lakh to ₹ 23000 crore. The GSDP grew 5159 times. In 2010-11, Sikkim recorded a GSDP growth of 26%.
 

                                </p>
                                <p style={{color: '#000'}}>
                                   Sikkim’s internal Revenue generation increased from ₹42.72 crore to ₹1500.71 crores.
Sikkim’s power production increased from a mere 32 MW to 2743 MW during our governance.
Our literacy rate rose from 56.94% to 96.72% and life expectancy increased from 62.7 years to 73.15 years, which is the highest in India. There were 2 colleges in 1994 and now we have 28 colleges. The number of universities went from 0 to 7.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                    There were only a few industries till 1994 such as Temi Tea, Sikkim Distilleries, Yuksom Brewaries, SITCO and Sikkim Jewels. Now there are 56 pharmaceutical factories and 3 distilleries in Sikkim. What is more significant is that there are hundreds of agro-based entrepreneurial ventures like poultries, piggeries, fisheries, dairy farms as well as cottage industries of many kinds started by Sikkimese people with varying levels of success. Trout fishery in Sikkim has been a huge success.

                                </p>
                                
                                
                                 <p style={{color: '#000'}}>
                                   Many promising, talented and educated Sikkimese youth were returning to Sikkim to seek their fortune leaving their jobs and business outside Sikkim. They saw hope, prospects and opportunities in Sikkim. It all changed overnight after 2019. 
                                   
                                   <br/><br/>
                                   
                                   Many Sikkimese people became crore-pati and many more became lakh-pati during the last 25 years before 2019. 
                                   
                                   <br/>
                                   <br/>
                                   
                                   The road network increased from 1889 km to 10,000 km in 2018-19. Electrification increased from 65 to 100 per cent. Drinking water from 60 to 100 per cent.  Nearly 50 per cent of houses were kutcha with thatched roofs before we formed the government. Under CMRHM, we converted almost all kutcha houses to pucca houses. We could have reached the 100 percent mark by 2020. The number of government employees increased from 20,000 (Adhoc and MR included) to one lakh 10 thousand under our governance. We made Sikkim a tax (state and central) free state. We granted OBC (State List) to Bahun, Newar and Chettri communities. We secured Scheduled Tribe status for the Limboo and Tamang Communities. We successfully lobbied with the Centre to include Sikkim in the North East Council in 2002. The SDF government granted recognition of state official languages to all the left out ethnic languages in Sikkim and introduced them in government schools.
                                    
                                    <br/><br/>
                                    
                                    Sikkim was the first Indian state to have opened a Directorate for Capacity building and livelihood schools, way back in 2003.
                                    
                                    <br/><br/>
                                    
                                    We created an example of a green economy with several eco-friendly initiatives. Our forest cover increased by 4 per cent. Sikkim is the only state to have achieved such a feat. Khangchendzonga National Park was recognized as an UNESCO World Heritage Site in 2016.
                                    
                                    <br/><br/>
                                    
                                    It was during our time that we lobbied with the Centre and opened the Nathula pass for trade with China. Route to Kailash-Mansarovar from Sikkim opened in June 2015.
                                    
                                    <br/><br/>
 
Sikkim was the poorest state till 1994 and became India’s third richest state. Sikkim became India’s number one state in peace, social justice, cleanliness and Panchyati Raj Institution. Sikkim won several national awards in Good Governance, Environment Protection, institution building in higher education, innovations and all kinds of tourism. Sikkim is the best among NE states in most of the development indicators. A least known Sikkim became a nationally and even globally well-known state during our 25 years of governance. If that’s nothing, we have nothing else to say to convince the desperate nay-sayers.

<br/><br/>
 
During our 25 years of governance, we fully protected Article 371 F. Sikkim’s image, prestige, dignity and local protection had been our highest priority. The same can’t be said of the present govt today. Drug smuggling, suicide and depression is increasing. The national media is reporting about drug smugglers and children traffickers arrested on Sikkim. The noise of the Sikkim-Darjeeling merger is becoming louder. Some leaders from outside Sikkim are openly saying that Article 371 F has to be abrogated. Some are demanding all the special privileges reserved for Sikkim Subject Certificate and COI holders to be extended to all Aadhar cardholders. 

<br/><br/>
 
Sadly, the federal system has experienced a shocking and disturbing change in Sikkim. Never had such a ridiculous thing happened in Sikkim. 

<br/><br/>
 
These figures and facts have not been generated by the SDF party. They have been generated by national and international organizations. Moreover, it is not possible to include all the achievements of the SDF government. Finally, let me add that the most significant thing was the overall quality of life in Sikkim. People were much happier, securer and more satisfied. Sikkim was one of the fastest developing states with some of the best indicators. Sikkim enjoyed a win situation during 25 years under the SDF government. Peace, prosperity, security, happiness and freedom marked Sikkim. Everything changed since the last election. Sikkim is experiencing a nightmarish pariwartan change since 2019.”




                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr1Aug2021;