import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr20June2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/20June.JPG" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>20 June, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and the President of the SDF party Shri Pawan Chamling expressed his solidarity with Shri Bhaichung Bhutia’s concerns and suggestions given to the state government concerning the 300 bedded hospital in Namchi. He strongly condemned the act of burning Shri Bhutia’s effigy and said that it was the well-orchestrated act executed on the behalf of the SKM party with the full blessing of the government. The ladies were used by the party leadership to execute the evil plan and the party withdrew from the act once completed.
                           </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                    “Q. After a group of some young women torched the effigy of Bhaichung Bhutia in Namchi, several organizations and political parties condemned the act and blamed the state government. What would you like to say about the whole event and the subsequent developments? 

                                </p>

                                

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> First of all, this unfortunate event is the external manifestation of the mentality of the SKM leadership. Even after receiving the mandate to run the state government, they are protesting. When a party that used hostile hate campaign to win an election runs the state government, what better can one expect? I firmly believe that those ladies were used by the party leadership. After using them, now the party is saying that it was their individual decision, not the party’s. Burning Shri Bhaichung Bhutia’s effigy, demonstrating placards, shouting slogans, calling names and playing football with the effigy in the open market area during a complete lockdown right under the nose of the Sikkim police can never be the individual decision of any right thinking person. This was an orchestrated act, planned and executed on the behest of the SKM party with the full blessing of the state government. In exchange, the SKM government delayed the arrest, gave bail within minutes  and will probably dismiss the case once the public interest dies down. If anyone is expecting better governance from this government, I am sorry, your expectation is going to be crushed again and again. Just keep watching. There is too much anger in the SKM party and it will one day burst to their own destruction. Their press conferences and speeches are nothing but outbursts of anger, hatred and frustration. Some people are rightly saying, ‘They are burning effigies two years after forming the government - they will probably be burning people alive after four years.’



                                </p>

                                <img class="w-100" src="assets/img/pressrelease/20June.JPG" alt=""/>
                                <br/>
                                <br/>

                                <p style={{color: '#000'}}>
                                    You may recall, the SDF government faced opposition from Sikkim’s then senior most politician and former Chief Minister, the late Shri NB Bhandari who staged a dharna in Delhi. Shri Bhaichung Bhutia also conducted protests in Delhi. There used to be a series of protests and rallies in Sikkim. Some even foolishly conducted my last rites also. The SKM party flooded Facebook with their personal attacks and abusive posts against me and my colleagues. Their main hate campaign was run on social media. Character assassinations, hitting below the belt  and using unconstitutional language comes naturally to them. We knew who were doing all these and yet never arrested them.  They fuelled the fire of rumours about my Nepalese citizenship. A case was filed against me in the Delhi High Court. Was any effigy burnt? Was anyone arrested for speaking against the government? 

                                </p>
                                 <p style={{color: '#000'}}>
                                    Secondly, I believe that the party’s major decisions are made by some anti-Sikkim, anti-people and anti-peace elements who are probably living somewhere outside Sikkim. In their press conference, they said that they will file a defamation case against Mr Bhaichung Bhutia and soon after that, his effigy was burnt. If there were thinking leaders in the party, they would have either listened to him or responded to him. Instead, they threatened to file a defamation case against him, so typical of the SKM thinking. When somebody asks a tough question - don’t think, don’t engage intellectually - just threaten.
 

                                </p>
                                 <p style={{color: '#000'}}>
                                   I fully stand by the opinion of Mr. Bhaichung Bhutia and all others who say that there is no harm in mobilizing government attention on strengthening our preparation for the Covid-19 pandemic which has gripped Sikkim right now. Let us save lives now, equip all the district hospitals for Covid treatment and provide economic relief to the people who lost their livelihoods - buildings will wait.  The hospital will take another 4-5 years to be completed but Covid-19 and the economic crisis people are facing need urgent intervention. It is now or never. Mr. Bhutia said nothing illegal or unconstitutional. He expressed public concerns and desires in a most civilized and articulate manner.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Finally, I am shocked to see the government’s ill-thought out priorities. The 300 bedded hospital in Namchi was planned, designed and approved by the SDF government. It was a part of a statewide upgradation of district hospitals. After Socheythang, Namchi was the next planned up gradation and was to be followed by the Geyzing and Mangan hospitals. The cabinet passed this proposal in 2018. The hospital was to be constructed at an estimated cost of ₹375 crores. But when the SKM party formed the government in 2019, the work order was cancelled. The cost was revalued and increased to ₹585 crores. It should have started two years ago. By now it would have probably been completed and ready for use if they had acted in a timely fashion. What was the government doing for the last two years?




                                </p>
                                
                                <p style={{color: '#000'}}>
                                    Moreover, the proposed site of the new building was adjacent to the existing Namchi hospital. The administrative building and TB ward were to be demolished for the new building. It was decided so, keeping in mind the convenience of people and hospital staff. When the same district hospital is situated at two different locations, there will be a lot of hassle, both for the people and the staff.  Furthermore, the veterinary playground was built on public demand. It is the main open space for the people of Namchi and provides a much-needed breathing space for people and a training space for sports and fitness lovers. The Bhaichung Bhutia stadium will not be so easily accessible to the general public as this veterinary playground.”




                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr20June2021;