import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr4Sep2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/TeachersDay.jpeg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>4 sep, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and President of the SDF party Shri Pawan Chamling has conveyed his good wishes and congratulations to the teachers for their unparalleled contribution for the overall development of Sikkim. He has also demanded the state government’s apology to the teachers of the state for mistreating and letting them down. Until the government fulfils their demand and gives them justice, the SKM government has no moral ground to celebrate Teachers’ Day.
                            
                           </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                    Teachers’ Day Message
 <br/><br/>
“ The history of education in Sikkim goes a long way back to when the Lepchas developed a traditional system of learning before the establishment of the Chogyal monarchy. Then, centuries later, three lamas came to Sikkim and initiated a monastic education. It was strictly a religious education imparted to Buddhist monks. The medium of learning was the Tibetan language. The formal system of education in Sikkim started in 1906.


                                </p>

                                

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> It would be heartening to note that in 2018 Sikkim achieved the status of the highest literacy rate in India at 96.72%. Sikkim achieving nearly 100 per cent literacy within 112 years since the initiation of formal education could possibly be the fastest rate of literacy growth in Indian history. When the SDF party took the reins of the Sikkim administration, the literacy rate was 56.94%. Our government did a tremendous work to scale up to higher education, increasing the number of colleges from two to twenty-eight. Sikkim had no university in 1994 and we established 7 universities including Central, Buddhist, Law, State and Private universities. We also made education free right up to the college level. We successfully achieved an Education Free for All scheme and many UN goals.


                                </p>
                                
                                <div>
                                <img class="w-100" src="assets/img/pressrelease/TeachersDay.jpeg" alt=""/>
                                <p style={{color: '#000', fontSize: '14px'}}>
                                    Protesting Ad Hoc teachers being dragged away by the police in the middle of the night from Education Department	

                                </p>
                                </div>

                                <p style={{color: '#000'}}>
                                    The development of knowledge precedes all other development and it cannot be reversed. There is no greater wealth than knowledge. To see Sikkim becoming the most literate state in India has therefore been deeply satisfying. Sikkim has also achieved extraordinary success in economic development. When we formed the government in 1994, Sikkim was the third poorest state after Bihar and Odisha and in 2018 Sikkim became the third richest state in India.
 

                                </p>
                                 <p style={{color: '#000'}}>
                                    Behind all the great feats and achievements of Sikkim, the contribution of teachers is the most significant one. Sikkim’s development is the outcome of its teachers’ hard work, dedication, perseverance and commitment. They are the people behind Sikkim becoming the most literate (intellectual development) and third richest state (economic development).  The same can be said about the country and the world at large. Teachers prepare our doctors, engineers, scientists, leaders, professionals and above all good citizens. Isaac Newton, Albert Einstein, AJP Abdul Kalam, Stephen Hawking and all the great legends were taught and inspired by teachers. They say behind every successful man there is a woman. I would go on to add that behind every successful individual, there is an inspiring teacher. Mothers give birth to a child from the physical womb and teachers give the child a second birth through the womb of knowledge.
Today, on the auspicious occasion of Teachers’ Day, I would like to convey my heartfelt gratitude and congratulations to all our teachers in Sikkim and beyond!
 

                                </p>
                                 <p style={{color: '#000'}}>
                                   Unfortunately, I feel profoundly ashamed to witness how the Sikkim government is humiliating teachers of our state right now. Hundreds of young teachers of our state are standing outside government offices asking for justice. They have been dragged by the police like animals. The government has been refusing to listen to them, let alone meet them. The SKM government does not have any moral ground to celebrate Teachers’ Day. Their anti-knowledge, anti-teacher and anti-intellectualism stance reveals their mindset. A government that treats teachers like criminals and drags them into police vans in the night when they were simply asking for an interview to be conducted for the continuance of their service does not deserve to celebrate Teachers’ Day. A government that mercilessly expels students who want to meet the Education Minister has no moral worth to observe Teachers’ Day. When such a government celebrates teachers’ day, it is a mockery of the dignity and prestige of the teaching profession. How can the state government celebrate Teachers’ Day when so many teachers are sitting for hunger strike seeking justice? Let the government know that the humiliation of teachers will have a far-reaching adverse impact on the progressive journey of our civilisation m, affecting many future generations.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                  To honour and respect is both our responsibility and privilege. The present Sikkim government failed to fulfil the responsibility and squandered the privilege to honour teachers. It is unfortunate that we are living in a state where the government takes it upon itself to demean and disgrace teachers. 




                                </p>
                                
                                <p style={{color: '#000'}}>
                                    I demand that the Sikkim Government submit a sincere apology to those teachers who have been mistreated and humiliated. Let their genuine demands be fulfilled unconditionally and submit an unconditional apology for treating them as though they were convicted criminals. Then only can the government consider celebrating Teachers’ Day?”




                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr4Sep2021;