import React from 'react';

const InfrastructureHeader = () => {
    return(
        <>
            <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                    <div class="container" style={{paddingBottom: '50px'}}>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                    <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                        data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                       Infrastructure
                                    </h2>

                                    <p class="economy-page-header-desc" style={{fontSize: '1rem', fontWeight: '500', maxWidth:'100%', color:'#000', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px'}}>
                                    Infrastructural development has soared under SDF. In 25 years of governance, state of the art administrative buildings have been established and towns across the state beautified and upgraded. A massive achievement of the Chamling led SDF government has been to develop a dense network of roads in Sikkim and to connect the landlocked state to the rest of the world through Sikkim’s first airport and the upcoming railway network via Rangpo. 
                                   
                                    </p>


                                </div>
                            </div>

                        </div>
                    </div>

                </section>

                <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/good-governance/inside-page/infrastructure .png" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                </div>
                </header>
        </>
    );
};

export default InfrastructureHeader;