
import React, {useEffect} from 'react';
import {Switch, Route} from 'react-router-dom';


import Home from "./pages/Home";
import Biography from "./pages/Biography";
import Award from "./pages/Award";
import AwardAsCm from "./pages/AwardAsCm";
import AwardForState from "./pages/AwardForState";
import OnPawanChamling from "./pages/OnPawanChamling";
import Constituency from "./pages/Constituency";
import Timeline from "./pages/Timeline";
import GoodGovernance from "./pages/GoodGovernance";
import Economy from "./pages/Economy";
import Health from "./pages/Health";
import PoliticalAchievement from "./pages/PoliticalAchievement";
import Welfare from "./pages/Welfare";
import Infrastructure from "./pages/Infrastructure";
import Governance from "./pages/Governance";
import Education from "./pages/Education";
import Tourism from "./pages/Tourism";
import Women from "./pages/Women";
import Youth from "./pages/Youth";
import Environment from "./pages/Environment";
import Institution from "./pages/Institution";
import Gallery from "./pages/Gallery";
import Speeches from "./pages/Speeches";
import Interviews from "./pages/Interviews";
import PressRelease from "./pages/PressRelease";
import Pr27Feb2022 from "./pages/pressrelease/Pr27Feb2022";
import Pr20Feb2022 from "./pages/pressrelease/Pr20Feb2022";
import Pr6Feb2022 from "./pages/pressrelease/Pr6Feb2022";
import Pr13Feb2022 from "./pages/pressrelease/Pr13Feb2022";
import Pr28Nov2020 from "./pages/pressrelease/Pr28Nov2020";
import Pr31Oct2021 from "./pages/pressrelease/Pr31Oct2021";
import Pr31Oct20212 from "./pages/pressrelease/Pr31Oct20212";
import Pr26Sep2021 from "./pages/pressrelease/Pr26Sep2021";
import Pr20Sep2021 from "./pages/pressrelease/Pr20Sep2021";
import Pr8Aug2021 from "./pages/pressrelease/Pr8Aug2021";
import Pr1Aug2021 from "./pages/pressrelease/Pr1Aug2021";
import Pr25Jully2021 from "./pages/pressrelease/Pr25Jully2021";
import Pr11Jully2021 from "./pages/pressrelease/Pr11Jully2021";
import Pr4July2021 from "./pages/pressrelease/Pr4July2021";
import Pr20June2021 from "./pages/pressrelease/Pr20June2021";
import Pr13June2021 from "./pages/pressrelease/Pr13June2021";
import Pr16May2021 from "./pages/pressrelease/Pr16May2021";
import Pr9May2021 from "./pages/pressrelease/Pr9May2021";
import Pr13Sep2021 from "./pages/pressrelease/Pr13Sep2021";
import Pr5Sep2021 from "./pages/pressrelease/Pr5Sep2021";
import Pr4Sep2021 from "./pages/pressrelease/Pr4Sep2021";
import Infographic from "./pages/Infographic";
import Achievements from "./pages/Achievements";
import Books from "./pages/Books";
import Music from "./pages/Music";
import Quotes from "./pages/Quotes";
import Blog from "./pages/Blog";
import SikkimAlandOfSocialJustice from "./pages/blogs/SikkimAlandOfSocialJustice";
import TextSpeeches from "./pages/TextSpeeches";
import IndependenceDay from "./pages/textspeeches/IndependenceDay";
import RepublicDay from "./pages/textspeeches/RepublicDay";
import StateDay from "./pages/textspeeches/StateDay";
import PlanningCommission from "./pages/textspeeches/PlanningCommission";
import Contact from "./pages/Contact";
import RequestAMeeting from "./pages/RequestAMeeting";
import Meeting from "./pages/RequestAMeeting";
import Pr27June2021 from "./pages/pressrelease/Pr27June2021";
import Pr30May2021 from "./pages/pressrelease/Pr30May2021";
import Pr18April2021 from "./pages/pressrelease/Pr18April2021";
import Pr11April2021 from "./pages/pressrelease/Pr11April2021";
import Pr25April2021 from "./pages/pressrelease/Pr25April2021";
import Pr28Feb2021 from "./pages/pressrelease/Pr28Feb2021";
import Pr21Feb2021 from "./pages/pressrelease/Pr21Feb2021";
import Pr14Feb2021 from "./pages/pressrelease/Pr14Feb2021";
import Pr31Jan2021 from "./pages/pressrelease/Pr31Jan2021";
import Pr7Feb2021 from "./pages/pressrelease/Pr7Feb2021";
import Pr24Jan2021 from "./pages/pressrelease/Pr24Jan2021";
import Pr1Dec2021 from "./pages/pressrelease/Pr1Dec2021";
import Pr15Dec2020 from "./pages/pressrelease/Pr15Dec2020";
import Pr21Dec2020 from "./pages/pressrelease/Pr21Dec2020";





const App = () => {
  return (
    
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/biography" component={Biography} />
      <Route exact path="/award" component={Award} />
      <Route exact path="/award-As-CM" component={AwardAsCm} />
      <Route exact path="/award-For-State" component={AwardForState} />
      <Route exact path="/on-pawan-chamling" component={OnPawanChamling} />
      <Route exact path="/constituency" component={Constituency} />
      <Route exact path="/timeline2" component={Timeline} />
      <Route exact path="/good-governance" component={GoodGovernance} />
      <Route exact path="/economy" component={Economy} />
      <Route exact path="/health" component={Health} />
      <Route exact path="/political-achievements" component={PoliticalAchievement} />
      <Route exact path="/welfare" component={Welfare} />
      <Route exact path="/infrastructure" component={Infrastructure} />
      <Route exact path="/education" component={Education} />
      <Route exact path="/tourism" component={Tourism} />
      <Route exact path="/women-empowerment" component={Women} />
      <Route exact path="/youth" component={Youth} />
      <Route exact path="/governance" component={Governance} />
      <Route exact path="/environment" component={Environment} />
      <Route exact path="/institution" component={Institution} />
      <Route exact path="/gallery" component={Gallery} />
      <Route exact path="/speeches" component={Speeches} />
      <Route exact path="/interviews" component={Interviews} />
      <Route exact path="/press-releases" component={PressRelease} />
      <Route exact path="/pr27Feb2022" component={Pr27Feb2022} />
      <Route exact path="/pr20Feb2022" component={Pr20Feb2022} />
      <Route exact path="/pr6Feb2022" component={Pr6Feb2022} />
      <Route exact path="/pr13Feb2022" component={Pr13Feb2022} />
      <Route exact path="/pr28Nov2020" component={Pr28Nov2020} />
      <Route exact path="/pr31Oct2021" component={Pr31Oct2021} />
      <Route exact path="/pr31Oct20212" component={Pr31Oct20212} />
      <Route exact path="/pr26Sep2021" component={Pr26Sep2021} />
      <Route exact path="/pr20Sep2021" component={Pr20Sep2021} />
      <Route exact path="/pr8Aug2021" component={Pr8Aug2021} />
      <Route exact path="/pr1Aug2021" component={Pr1Aug2021} />
      <Route exact path="/pr25Jully2021" component={Pr25Jully2021} />
      <Route exact path="/pr11Jully2021" component={Pr11Jully2021} />
      <Route exact path="/pr4July2021" component={Pr4July2021} />
      <Route exact path="/pr20June2021" component={Pr20June2021} />
      <Route exact path="/pr13June2021" component={Pr13June2021} />
      <Route exact path="/pr16May2021" component={Pr16May2021} />
      <Route exact path="/pr9May2021" component={Pr9May2021} />
      <Route exact path="/pr13Sep2021" component={Pr13Sep2021} />
      <Route exact path="/pr5Sep2021" component={Pr5Sep2021} />
      <Route exact path="/pr4Sep2021" component={Pr4Sep2021} />
      <Route exact path="/infographic" component={Infographic} />
      <Route exact path="/achievements" component={Achievements} />
      <Route exact path="/books" component={Books} />
      <Route exact path="/music" component={Music} />
      <Route exact path="/quotes" component={Quotes} />
      <Route exact path="/blogs" component={Blog} />
      <Route exact path="/sikkim-a-land-of-social-justice" component={SikkimAlandOfSocialJustice} />
      <Route exact path="/text-speeches" component={TextSpeeches} />
      <Route exact path="/independence-day" component={IndependenceDay} />
      <Route exact path="/republic-day" component={RepublicDay} />
      <Route exact path="/state-day" component={StateDay} />
      <Route exact path="/planning-commission-meeting" component={PlanningCommission} />
      <Route exact path="/contacts" component={Contact} />
      <Route exact path="/request-a-meeting" component={RequestAMeeting} />
      <Route exact path="/meeting" component={Meeting} />
      <Route exact path="/pr27June2021" component={Pr27June2021} />
      <Route exact path="/pr30May2021" component={Pr30May2021} />
      <Route exact path="/pr18April2021" component={Pr18April2021} />
      <Route exact path="/pr11April2021" component={Pr11April2021} />
      <Route exact path="/pr25April2021" component={Pr25April2021} />
      <Route exact path="/pr28Feb2021" component={Pr28Feb2021} />
      <Route exact path="/pr21Feb2021" component={Pr21Feb2021} />
      <Route exact path="/pr14Feb2021" component={Pr14Feb2021} />
      <Route exact path="/pr31Jan2021" component={Pr31Jan2021} />
      <Route exact path="/pr7Feb2021" component={Pr7Feb2021} />
      <Route exact path="/pr24Jan2021" component={Pr24Jan2021} />
      <Route exact path="/pr1Dec2021" component={Pr1Dec2021} />
      <Route exact path="/pr15Dec2020" component={Pr15Dec2020} />
      <Route exact path="/pr21Dec2020" component={Pr21Dec2020} />
      
      
    </Switch>
  );
}

export default App;
