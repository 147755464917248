import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import Header2 from "../components/nav/Header2";


import Footer2 from "../components/nav/Footer2";

// import "../blog.css";



const Blog = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">
                        <div class="wrapper">

                                <div class="root-contact">
                                    
                                    {/* Header */}
                <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                    <div class="container" style={{paddingBottom: '50px'}}>
                        <div class="row ">
                            <div class="col-lg-12">
                                <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                    <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                        data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                        Blog 
                                    </h2>

                                    {/* <p class="infographic-page-header-desc" style={{fontSize: '1rem', fontWeight: '500', maxWidth:'100%', color:'#000', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px'}}>
                                    	From a little known Himalayan state and one of the poorest states in India in 1994, Sikkim has grown leaps and bounds in various sectors under Pawan Chamling’s governance. From unparalleled poverty reduction to an exponential rise in GSDP, many indicators point to the strong trajectory of growth and development under the SDF government.

                                    </p> */}


                                </div>
                            </div>

                        </div>
                    </div>

                </section>


                                    {/* Body */}

   
                                    <div class="container" style={{marginTop: '0px', paddingBottom: '80px'}}>

                                        <div class="section-15 col-md-4">
                                        
                                        <div class="w-dyn-list">
                                            <div role="list" class="_4grid-responsive w-dyn-items">

                                                <div role="listitem" class="collection-item-19 w-dyn-item" 
                                                    style={{transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', 
                                                    transformStyle: 'preserve-3d'}}>
                                                    <Link to="/sikkim-a-land-of-social-justice" class="link-block-20 w-inline-block">
                                                        <img src="assets/img/sikkim.jpg" alt="" sizes="100vw" srcset="assets/img/sikkim.jpg, assets/img/sikkim.jpg, assets/img/sikkim.jpg" 
                                                        class="image-27" />
                                                    </Link>
                                                    <div class="div-block-57">
                                                    <Link to="/sikkim-a-land-of-social-justice" class="div-block-58 w-inline-block">
                                                        <h4 class="heading-23">SIKKIM: A LAND OF SOCIAL JUSTICE</h4>
                                                    </Link>
                                                    
                                                    </div>
                                                </div>
                                        

                                        
                                        
                                            </div>
                                        </div>
                                        </div>

                                        

                                        </div>








                                    </div>
                       

                                <Footer2 />

                        </div>
                    </div>
                </main>

            </>
    );

};

export default Blog;