import React from 'react';
import {Link} from 'react-router-dom';

const AwardOfState = () => {
    return(
        <>
                    <div class="header home" style={{marginBottom: '20px', marginTop: '40px'}}>
                    <Link to="#" class="link-block w-inline-block">
                    <h1 class="heading-15 inline heading-underline" style={{fontSize: '32px',
                        fontWeight: '700', borderBottom: '1px dashed rgba(0, 0, 0, 0.5)',
                        color: '#333',
                        textDecoration: 'none'}}> Awards For The State</h1>
                    </Link>
            </div>

             <div class="row">

                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="">
                            <img src="assets/img/awards/OFFICE-DISTRICT-MAGISTRATE-2011.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/OFFICE-DISTRICT-MAGISTRATE-2011.jpg 500w, assets/img/awards/OFFICE-DISTRICT-MAGISTRATE-2011.jpg 800w, assets/img/awards/OFFICE-DISTRICT-MAGISTRATE-2011.jpg 1080w" 
                             class="image-27" />
                        </Link>
                       
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Certificate Of Quality Management System</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="">
                            <img src="assets/img/awards/TRIPADVISOR-WINNER-2015.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/TRIPADVISOR-WINNER-2015.jpg 500w, assets/img/awards/TRIPADVISOR-WINNER-2015.jpg 800w, assets/img/awards/TRIPADVISOR-WINNER-2015.jpg 1080w" 
                             class="image-27" />
                        </Link>
                      
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Winner Certificate Of Excellence To Buddha Park</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/WORLD-HERITAGE-AWAD-2016.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/WORLD-HERITAGE-AWAD-2016.jpg 500w, assets/img/awards/WORLD-HERITAGE-AWAD-2016.jpg 800w, assets/img/awards/WORLD-HERITAGE-AWAD-2016.jpg 1080w" 
                             class="image-27" />
                        </Link>
                       
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >World Heritage Award</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/NORTH-EAST-LEADS-THE-WAY--2016.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/NORTH-EAST-LEADS-THE-WAY--2016.jpg 500w, assets/img/awards/NORTH-EAST-LEADS-THE-WAY--2016.jpg 800w, assets/img/awards/NORTH-EAST-LEADS-THE-WAY--2016.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Certificate On Being Recognised As The First Open Defecation Free State</h4>
                     </Link>
                    
                  </div>
                </div>
               
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/MINISTRY-OF-HEALTH-AND-FAMILY-2016.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/MINISTRY-OF-HEALTH-AND-FAMILY-2016.jpg 500w, assets/img/awards/MINISTRY-OF-HEALTH-AND-FAMILY-2016.jpg 800w, assets/img/awards/MINISTRY-OF-HEALTH-AND-FAMILY-2016.jpg 1080w" 
                             class="image-27" />
                        </Link>
                     
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Certificate For Securing First Rank For Maximum Improvement In IPD Service</h4>
                     </Link>
                    
                  </div>
                </div>
               
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/SAFARI-INDIA--SIKKIM-AS-BEST-STATE-FOR-TOURISM.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/SAFARI-INDIA--SIKKIM-AS-BEST-STATE-FOR-TOURISM.jpg 500w, assets/img/awards/SAFARI-INDIA--SIKKIM-AS-BEST-STATE-FOR-TOURISM.jpg 800w, assets/img/awards/SAFARI-INDIA--SIKKIM-AS-BEST-STATE-FOR-TOURISM.jpg 1080w" 
                             class="image-27" />
                        </Link>
                    
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Best State For Adventure Tourism</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/INDIA-TODAY-TOURISM-AWARDS.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/INDIA-TODAY-TOURISM-AWARDS.jpg 500w, assets/img/awards/INDIA-TODAY-TOURISM-AWARDS.jpg 800w, assets/img/awards/INDIA-TODAY-TOURISM-AWARDS.jpg 1080w" 
                             class="image-27" />
                        </Link>
                     
                    </div>


                    <div class="div-block-57" >
                     <Link to="" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Runners Up In Adventure Category</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/Diamond-state-award,-best-small-state-for-citizen-security.JPG" alt=""
                             sizes="100vw" srcset="assets/img/awards/Diamond-state-award,-best-small-state-for-citizen-security.JPG 500w, assets/img/awards/Diamond-state-award,-best-small-state-for-citizen-security.JPG 800w, assets/img/awards/Diamond-state-award,-best-small-state-for-citizen-security.JPG 1080w" 
                             class="image-27" />
                        </Link>
                       
                    </div>


                    <div class="div-block-57" >
                     <Link to="" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Diamond State Award</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/State-of-states-award-for-best-small-state-in-education-2009.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/State-of-states-award-for-best-small-state-in-education-2009.jpg 500w, assets/img/awards/State-of-states-award-for-best-small-state-in-education-2009.jpg 800w, assets/img/awards/State-of-states-award-for-best-small-state-in-education-2009.jpg 1080w" 
                             class="image-27" />
                        </Link>
                       
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Best Small State In Education</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/nirmal-gram-puraskar-award.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/nirmal-gram-puraskar-award.jpg 500w, assets/img/awards/nirmal-gram-puraskar-award.jpg 800w, assets/img/awards/nirmal-gram-puraskar-award.jpg 1080w" 
                             class="image-27" />
                        </Link>
                        
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Nirmal Gram Puraskar Award</h4>
                     </Link>
                    
                  </div>
                </div>
               
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/National-Tourism-award-2010.JPG" alt=""
                             sizes="100vw" srcset="assets/img/awards/National-Tourism-award-2010.JPG 500w, assets/img/awards/National-Tourism-award-2010.JPGg 800w, assets/img/awards/National-Tourism-award-2010.JPG 1080w" 
                             class="image-27" />
                        </Link>
                     
                    </div>


                    <div class="div-block-57" >
                     <Link to="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >National Tourism Award</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/Little-Miracle-State-Award-To-Sikkim.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/Little-Miracle-State-Award-To-Sikkim.jpg 500w, assets/img/awards/Little-Miracle-State-Award-To-Sikkim.jpg 800w, assets/img/awards/Little-Miracle-State-Award-To-Sikkim.jpg 1080w" 
                             class="image-27" />
                        </Link>
                       
                    </div>


                    <div class="div-block-57" >
                     <Link to="" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >Little Miracle State Award</h4>
                     </Link>
                    
                  </div>
                </div>
                
                <div class="col-md-3 col-xs-6 single-award">
                    <div class="date" style={{position: 'relative'}}>
                        <Link to="#awards">
                            <img src="assets/img/awards/JRD-TATA-memorial-awawd-for-health-sector-2018.jpg" alt=""
                             sizes="100vw" srcset="assets/img/awards/JRD-TATA-memorial-awawd-for-health-sector-2018.jpg 500w, assets/img/awards/JRD-TATA-memorial-awawd-for-health-sector-2018.jpg 800w, assets/img/awards/JRD-TATA-memorial-awawd-for-health-sector-2018.jpg 1080w" 
                             class="image-27" />
                        </Link>
                       
                    </div>


                    <div class="div-block-57" >
                     <Link to="" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                        
                        <h4 class="heading-23" >JRD TATA MEMORIAL AWARD FOF HEALTH SECTION</h4>
                     </Link>
                    
                  </div>
                </div>
               
                
              
                
                
                
                
                
                
                
                
                
                 
                
                  
                


                
              
            </div>
            
            
             <div class="row" >
                <div class="col-md-12" style={{textAlign: 'right'}}>
                <div class="link-custom">
                                <Link class="image-zoom" to="/award-For-State" tyle={{borderColor: 'rgb(238, 238, 238)',
                                transformOrigin: '0px 0px 0px', transform: 'matrix(1, 0, 0, 1, 0, 0)' }}>
                                    <span style={{color: '#000'}}>Show More</span>
                                <div class="icon-circle" style={{transformOrigin: '0px 0px 0px', transform: 'matrix(1, 0, 0, 1, 0, 0)'}}></div></Link>
                            </div>
                </div>
            </div>
        </>
    );
};

export default AwardOfState;