import React from 'react';
import {Link} from 'react-router-dom';

const HealthBody = () => {
    return(
        <>
                      <div class="container section-margin">
            <div class="row">
                <div class="good-gov-inner-page-container">
                    
                    
                    <ul>
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Second largest government hospital in India - STNM Hospital, Sochakgang: </span></h4>
                            <p>The 1002 bedded STNM hospital, second only to AIIMS, Delhi, is a multispecialty hospital built with the vision to provide all people of Sikkim with quality healthcare services right in the home state. The hospital has 23 operating theatres that can function at the same time. The structure has been built so that it can withstand earthquakes upto 8 richter scale. The hospital was inaugurated in 2019.
</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Sikkim State Illness Assistance Fund (SSIAF):  </span></h4>
                            <p>The SSIAF scheme provides financial assistance to bonafide Sikkimese BPL patients who are suffering from life threatening diseases and who have been referred outside the state for further treatment. According to this scheme, patients can receive upto Rs 1.5 lakh as medical assistance.
</p>


                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Mukhya Mantri Jeevan Raksha Kosh (MMJRK): </span></h4>
                            <p>Under this scheme, all Sikkimese people excluding government servants and their dependents are eligible for cash/cashless treatment benefits upto Rs 2 lakhs. To ensure quality healthcare, a number of reputed hospitals have been empanelled for services in Delhi, Siligrui, Kolkata and Chennai. For government employees who fall under the Sikkim Service (medical facility) rules, there is a provision of reimbursement of upto 5 lakhs and 2 lakhs for dependents.

</p>


                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Mukhya Mantri Shravan Shakti Samridhi Yojana: </span></h4>
                            <p>: Introduced on 14th October 2010, the scheme provided Behind the Ear (BTE) hearing aids free of charge on the recommendation of ENT specialists for people suffering from congenital and acquired hearing impairments.

</p>

                        </li>
                        
                        <br /><br />
                        
                        <li>
                             <div class="header-single-post" data-dsn-header="project">
                                <div class="post-parallax-wrapper" data-overlay="3">
                                    <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/good-governance/inside-page/Health second photo.png" alt=""
                                        data-dsn-header="blog" data-dsn-ajax="img" />
                                </div>
                            </div>
                        </li>
                        
                        <br /><br />
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>CATCH:  </span></h4>
                            <p>The Chief Minister's Comprehensive Annual and Total Check-Up for Healthy Sikkim (CATCH) is a unique all-inclusive public health program. This is an annual health check-up conducted across the state so that every individual can have a medical check-up available at their doorstep. This is the first of its kind scheme implemented in India and it prioritizes preventive action in healthcare. The program was launched on 26th August 2010 and the program covered 5,19,969 people with 11,28,801 lab investigations in the first round. The data of 4,24,310 individuals has been entered into the CATCH software prepared by the National Informatics Centre for analysis and 1,30,723 health cards have been distributed.


</p>


                        </li>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Hepatitis Vaccine Programme:  </span></h4>
                            <p>The Hepatitis Vaccine program was launched by the Sikkim government on 14th August 2001 from the State Plan Fund. In the beginning the vaccination program covered infants upto the age of one year but later it was expanded to cover children upto the age of five. The vaccines were provided free of cost.

</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>MMR Vaccine Program: </span></h4>
                            <p>The Measles, Mumps and Rubella (MMR) Vaccine Programme was also launched from the State Plan Funds to provide vaccines free of cost. The program was launched on 15th August 2009.
</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>HPV Vaccine Program:  </span></h4>
                            <p>Sikkim is the only state where girl children aged between 9-14 are given the Human Papilloma Virus (HPV) vaccination free of cost to prevent cervical cancer.
</p>

                        </li>
                        
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Sharp Decrease in Infant Mortality Rate:   </span></h4>
                            <p>In 1994 IMR was 46 per 1000 live births and this fell to 19 per 1000 live births in 2014. Sikkim’s IMR was already well below the goal of 25 by 2017 set by the National Health Mission. The IMR puts Sikkim among the top five states in the country after Kerela, Goa, Manipur and Nagaland.
</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Increase in life expectancy:  </span></h4>
                            <p>The life expectancy of the people of Sikkim increased from 62.7 years in 1990 to73.15 years in 2016, which is much higher as compared to the national average of 62.7 years.

</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Increase in Immunization rates:  </span></h4>
                            <p>Complete immunization coverage increased from 48% in 1998-99 to 93% in 2013-14. Sikkim was awarded the “Best Performance in Immunization” award in the North East zone in 2008. 

</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Highest government per capita spending on healthcare: </span></h4>
                            <p>Sikkim government’s per capita outlay has been among the highest in the country at Rs 1983 per capita in comparison to Rs392 per capita national average.
</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>ASHA workers honorarium increased to 3000 since April 2011 </span></h4>
                          
                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Guest Houses for Sikkimese patients:  </span></h4>
                            <p>Under the SDF government, the Denzong Kunga Khansang (DKK) Bhawan and Sewa Bhawan at Delhi were purchased by the Government of Sikkim in October 2010. Similarly, Sikkim House at Salt Lake City was set up Kolkata. These properties were set up by the SDF government as guest houses that the people of Sikkimese patients can stay in at minimal cost while availing of medical services in Delhi and Kolkata.
</p>

                        </li>
                        
                        
                    </ul>
                </div>
            </div>
          </div>
       

        </>

    );

}

export default HealthBody;