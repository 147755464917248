import React from 'react';

const TimelineBody = () => {
    return(
        <>
        <div class="timeline timeline__section">


<div class="timeline-item" data-text="September 22,1950 " >
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/1950.jpeg" />
    <h2 class="timeline__content-title milestone">1950</h2>
    <p style={{fontWeight: '700', fontSize:'22px', color: '#fff'}}>22nd Sept.1950	</p>
    <p class="timeline__content-desc">
        Pawan Chamling was born at Yangang, South Sikkim, to Ashbahadur Chamling and Asharani Chamling. He is the eldest son.
        <br/> 
        Wrote the poem "veer ko parichay"	


    </p>
  </div>
</div>
<div class="timeline-item" data-text="1967" >
  <div class="timeline__content" style={{marginTop: '150px'}}>
    <img class="timeline__img" src="assets/img/timeline/1967.jpeg"/>
    <h2 class="timeline__content-title milestone">1967</h2>
    <p class="timeline__content-desc">
       Wrote the poem "veer ko parichay" and published it in the fortnightly magazine Kanchenjunga. He started writing poems from his school days when he was at Yangang Secondary School. 	

    </p>
  </div>
</div>
<div class="timeline-item" data-text="3rd April 1973">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/1973.JPG"/>
    <h2 class="timeline__content-title milestone">1973</h2>
    <p class="timeline__content-desc">In March 31, 1973, the late L.D. Kazi, who was leading the democratic movement personally wrote to Pawan Chamling saying, "…in Sikkim there is a movement for the establishment of one-man one-vote system. Please reach Gangtok on 3rd April along with people from South district...". He headed to Gangtok with a group of people from South district of Sikkim only to find himself in the thick of things in the democratic People's Revolution. During the movement, he led his group chanting slogans of democracy. He was only 23 years old. 	

</p>
  </div>
</div>
<div class="timeline-item" data-text="1976">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/1976.jpeg"/>
    <h2 class="timeline__content-title milestone">1976</h2>
    <p class="timeline__content-desc">Established Yangang Multipurpose Cooperative Society. Pawan Chamling was the first Secretary of the cooperative society and was later elected as President. He resigned from the MPCS once he was elected as MLA in 1985.	
    </p>
    
    <p class="timeline__content-desc">Pawan Chamling was sent to Pune by the Cooperative Department, Govt of Sikkim, where he underwent training and received a diploma in multipurpose coorperatives.	
</p>

<p class="timeline__content-desc">Became a First Class government contractor and did many projects across Sikkim. He stopped doing contractual work once he was elected as MLA in 1985.	
</p>
    
    
  </div>
</div>
<div class="timeline-item" data-text="1977">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/1977.jpeg"/>
    <h2 class="timeline__content-title milestone">1977</h2>
    <p class="timeline__content-desc">Became a founding member and Vice President of Sikkim Prajatantra Congress	

    </p>
    
    <p class="timeline__content-desc">
        Founded a monthly literary magazine called Nirman which is still being published till date. He also founded Nirman Publications which has published more than 400 books so far including the complete works of Parijat, Agam Singh Giri, Indra Bahadur Rai, Gopal Gaule, Bhim Nidhi Tiwari, Mahananda Paudyal and Acha Rai Rasik.	
    </p>
  </div>
</div>
<div class="timeline-item" data-text="1982">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/1982.jpeg"/>
    <h2 class="timeline__content-title milestone">1982</h2>
    <p class="timeline__content-desc">Elected uncontested as the President of the Yangang Gram Panchayat	

</p>

  </div>
</div>
<div class="timeline-item" data-text="1985">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/1985.jpeg"/>
    <h2 class="timeline__content-title milestone">1985</h2>
    <p class="timeline__content-desc">Elected MLA from Damthang Constituency and was appointed as the Chairman of Sikkim Distilleries Ltd.	

</p>
  </div>
</div>
<div class="timeline-item" data-text="1987">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/1987.jpeg"/>
    <h2 class="timeline__content-title milestone">1987</h2>
    <p class="timeline__content-desc">
      Awarded the Chintan Puraskar by Sikkim Sahitya Parishad for the publication of his poem, "Maile nasamjheko samjhana ma kati kati", published in Chintan, an annual literary magazine.	


    </p>
    
    <p class="timeline__content-desc">
        Became a member of Bharatiya Nepali Rashtriya Parishad. He was a member until 1993.	

    </p>
   
    
  </div>
</div>
<div class="timeline-item" data-text="1989">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/1989.jpeg"/>
    <h2 class="timeline__content-title milestone">1989</h2>
    <p class="timeline__content-desc">
       Re-elected from Damthang with a record 96.6% votes. He was inducted into the Council of Ministers and was given charge of three Departments  - Industry, Information and Public Relations and Printing. 	
    </p>
   
  </div>
</div>

<div class="timeline-item" data-text="1992">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/1992.png"/>
    <h2 class="timeline__content-title milestone">1992</h2>
    <p style={{fontWeight: '700', fontSize:'22px', color: '#fff'}}>16th June 1992</p>
    <p class="timeline__content-desc">
     He was removed from the Ministry upon his disagreements over various polices and criticisms regarding the Sikkim Sangram Parishad government's failures. 	

    </p>
    <p style={{fontWeight: '700', fontSize:'22px', color: '#fff'}}>19th July 1992	</p>
   <p class="timeline__content-desc">
     He was removed from the Sikkim Sangram Parishad.	

    </p>
    
    <p style={{fontWeight: '700', fontSize:'22px', color: '#fff'}}>9th September 1992</p>
   <p class="timeline__content-desc">
     On this day, Pawan Chamling lit a candle at the Sikkim Legislative Assembly asking "Where is democracy in Sikkim?" "Where is the democracy of the people?". The flame that was lit that fateful day would usher in an era of democracy and progress under the leadership of Pawan Chamling.	


    </p>
  </div>
</div>

<div class="timeline-item" data-text="1993">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/1993.jpeg"/>
    <h2 class="timeline__content-title milestone">1993</h2>
     <p style={{fontWeight: '700', fontSize:'22px', color: '#fff'}}>4th March 1993</p>
    <p class="timeline__content-desc">
      The Sikkim Democratic Front was founded in Namchi and Pawan Chamling was unanimously elected as the President of the party.
    </p>
    
    
    <p style={{fontWeight: '700', fontSize:'22px', color: '#fff'}}>18th April 1993</p>
    <p class="timeline__content-desc">
      Pawan Chamling made his maiden speech as the President of the Sikkim Democratic Front at Jorethang. The themes of his speech were "vaicharik kranti" or an ideological revolution and "New Sikkim, Happy Sikkim".	

    </p>
    
    <p style={{fontWeight: '700', fontSize:'22px', color: '#fff'}}>18th June 1993	</p>
    <p class="timeline__content-desc">
      Since 9th June, around 200 hired goons of the Sikkim Sangram Parishad were camped out in tents at Alay Ground, Namchi. The goons chanted slogans about the ruling party and threatened people. They travelled around South and West districts spreading fear, threatening the public and harassing the girls from Namchi Girls School adjacent to Alay Ground. There was a sense of fear in Namchi and students had stopped walking the Alay ground route to their school. On 16th June, after finishing the SDF meeting at Temi Tarku that day,  Pawan Chamling went to Alay ground with around 3000 supporters and drove away the goons of the ruling party and dismantled their tents.  Around 5000 people gathered at Ghurpishey that day after the incident and the people stayed there over night. That very same day, Pawan Chamling was booked under the Terrorist and Disruptive Activities (Prevention) Act (TADA) by the government.	

    </p>
    
    <p style={{fontWeight: '700', fontSize:'22px', color: '#fff'}}>22th June 1993	

</p>
    <p class="timeline__content-desc">
      Thousands of SDF supporters gathered all the way from the SDF party office at Deorali to  Metro Point, Gangtok. The police used tear gas on the crowd and lathi charged the people. Thousands of people were arrested and since there were so many of them that were arrested, they had to be sent to Sikkim Armed Police Camp at Pangthang. 	

    </p>
    
    
    <p style={{fontWeight: '700', fontSize:'22px', color: '#fff'}}>23rd June 1993	
</p>
    <p class="timeline__content-desc">
      Pawan Chamling had to flee for safety due to the manhunt in his name unleashed by Sikkim Sangram Parishad in Sikkim. He left on a scooter disguised in a long coat, shawl and hat to outmaneuvor the police at Ranipool, Pakyong, Rhenock stations and Reshi police checkpost. They reached the home of Former Chief Minister of Sikkim, Shri L.D. Kazi at Kalimpong in the wee hours of the morning and stayed there for safety.	

    </p>
    
    <p style={{fontWeight: '700', fontSize:'22px', color: '#fff'}}>28th June 1993	

</p>
    <p class="timeline__content-desc">
     Pawan Chamling left Shri L.D. Kazi's home just in time before the Kalimpong and Sikkim police arrived for his arrest. He shifted his base to a home in a nearby hill belonging to a Bengali gentleman and then to Chibo Busty at Mr MB Bhattarai's house.	

    </p>
    
    
    <p style={{fontWeight: '700', fontSize:'22px', color: '#fff'}}>30th June	

</p>
    <p class="timeline__content-desc">
    Fleeing from Chibo Busty due to the ensuing manhunt, Pawan Chamling and his team went through the Amphitaar forest to the banks of the river Teesta for safety. Spending several nights in the forest, Pawan Chamling and his companions were hungry and exhausted, weathering rain and the lashes of the Teesta. After several days, they met some poeple in the forest from the nearby Kamsi village who gave them shelter.	

    </p>
    
    <p style={{fontWeight: '700', fontSize:'22px', color: '#fff'}}>5th July 1993	

</p>
    <p class="timeline__content-desc">
   While underground, Pawan Chamling wrote a letter on this day to his colleagues encouraging them to move ahead and carry on with the mission they had started. In the letter, he wrote, "We have shared people's sorrows and misery together and have come into politics to bring success in the lives of the people. It has nothing to do with our individual failure and success. In fact, in individual we had won while the people had always been losers. Considering people's loss as our personal grief, we have come on the street to win people's victory. The victory of the people is a real victory and also it secures the overall interest of the majority of people. My friends we will win only when the people of Sikkim win - when the peole will live as masters and not as slaves." 	

    </p>
    
    
    <p style={{fontWeight: '700', fontSize:'22px', color: '#fff'}}>10th July 1993	

</p>
    <p class="timeline__content-desc">
   Pawan Chamling went to Delhi incognito under the assumed name of BS Thapa. He met Central Ministesr in Delhi along with MPs from different political parties, apprising them of the situation of terror in Sikkim. After a weeklong stay, he went back to Darjeeling	

    </p>
    
    
     <p style={{fontWeight: '700', fontSize:'22px', color: '#fff'}}>24th August 1993	

</p>
    <p class="timeline__content-desc">
  After a long stay at Ilam, Nepal, he left for Darjeeling again to hold a meeting with party members which lasted 11 hours. During this meeting, they discussed strategy and futher actions of the party. However, soon his identity was known and he left for Heelaytaar, Nepal, for 15 days after which he returned to Darjeeling again. 	

    </p>
    
    
     <p style={{fontWeight: '700', fontSize:'22px', color: '#fff'}}>12th Sept. 1993	

</p>
    <p class="timeline__content-desc">
  Pawan Chamling left for Delhi and this time, he was able to obtain an anticipatory bail from the court. 	

    </p>
    
    
     <p style={{fontWeight: '700', fontSize:'22px', color: '#fff'}}>22nd Sep 1993	

</p>
    <p class="timeline__content-desc">
On this day, Pawan Chamling made a triumphant return to Sikkim after many months of being hunted by the Sangram Government. After a long awaited return by the people, Pawan Chamling entered Sikkim through Rangpo  around 9am on an open jeep. The roads were filled with thousands of people who welcomed Pawan Chamling with fervant slogans. He was escorted by his supporters all the way to Palzor Stadium, Gangtok where a massive public meeting had been organized in the name of Garib Utthan Diwas (Day of upliftment of the poor). It was here that he made the historic declaration that "I would rather die a thousand deaths than betray the poor".	

    </p>
    
    


   
  </div>
</div>



<div class="timeline-item" data-text="1994">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/1994.jpg"/>
    <h2 class="timeline__content-title milestone">1994</h2>
    
     <p style={{fontWeight: '700', fontSize:'22px', color: '#fff'}}>9th December 1994	</p>
    <p class="timeline__content-desc">
    The voting for general elections took place on 16th Nov 1993 and on 9th December, the mandate was clear: SDF had won the elections with 19 seats. Pawan Chamling had contested from Damthang Constituency winning with a 70.27% vote margin. 	
    </p>
    
    <p style={{fontWeight: '700', fontSize:'22px', color: '#fff'}}>12th Dec 1994	
</p>
    <p class="timeline__content-desc">
        Pawan Chamling was sworn in as the 5th Chief Minister of Sikkim. He was 44 years old. 	

    </p>
    
     <p style={{fontWeight: '700', fontSize:'22px', color: '#fff'}}>1994	
</p>
    <p class="timeline__content-desc">
       Became a member of the National Development Council. He was member for 25 years until 2019.	

    </p>
   
    
  </div>
</div>




<div class="timeline-item" data-text="5th March 1997">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/1997.jpeg"/>
    <h2 class="timeline__content-title milestone">1997</h2>
    <p class="timeline__content-desc">
     Pawan Chamling was awarded the Bharat Shiromani Award by the Shiromani Foundation, New Delhi for "his outstanding contribution to integrate the people fo Sikkim with the rest of the country, his endeavours to bring them into the national mainstream and for his efforts to reinforce the democratic system of Sikkim". Shri K.R. Narayanan said, " I have the greatest respect for Mr Pawan Chamling, not only as the Chief Minister but as a great human being. Mr Chamling is a remarkable personality who has been in politics from his young age. I would not look upon him as a politician but rather as a poet, writer, editor, and on who has espoused great causes like democracy, national integration, economic development, and progressive causes."	

    </p>
   
    
  </div>
</div>


 <div class="timeline-item" data-text="1998">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/1998.png"/>
    <h2 class="timeline__content-title milestone">1998</h2>
    <p class="timeline__content-desc">
     Became a member of the Rashtriya Hindi Parishad or National Hindi Committee, a committee under the Chairmanship of the 10th Prime Minister of India Atal Bihari Vajpayee. Pawan Chamling was a member until 2014.	

    </p>
    
    <p class="timeline__content-desc">
        Became a member of the United Progressive Alliance (UPA)	

    </p>
   
  </div>
</div>


<div class="timeline-item" data-text="1999">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/1999.jpeg"/>
    <h2 class="timeline__content-title milestone">1999</h2>
   <p style={{fontWeight: '700', fontSize:'22px', color: '#fff'}}>8th Feb 1999	

    </p>
    
       <p class="timeline__content-desc">
       He was conferred the Greenest Chief Minister of India award by Centre for Science and Environment, New Delhi for his outstanding contribution towards nature conservation measures undertaken in Sikkim.	

       </p>
       
       <p class="timeline__content-desc">
    He was awarded the Manav Seva Purashkar, 1999 presented by the Institute of Economic Studies, New Delhi for his “rare vision in bringing about far-reaching changes in quality of life for the poor…championing the cause of the Sikkimese people and downtrodden, especially the upliftment of women’s rights and child welfare...” 	

       </p>
       
       
       <p class="timeline__content-desc">
    He was awarded Man of Dedication Award, 1999 conferred by Manav Uthan Sewa Samiti in recognition of his dedication and commitment to the welfare of the people of Sikkim as Chief Minister. The Award was presented by Shri Satpalji Maharaj at a national function in Ramlila Maidan, New Delhi.	

       </p>
       
        <p class="timeline__content-desc">
   Sikkim Democratic Front, under Pawan Chamling's leadership won the general elections of 1999, winning 24 out of 32 seats. Pawan Chamling won from the Damthang constituency.	

       </p>
   
    
  </div>
</div>


<div class="timeline-item" data-text="2001">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/2001.jpeg"/>
    <h2 class="timeline__content-title milestone">2001</h2>
    <p class="timeline__content-desc">
     He was awarded the Lifetime Achievement award in Literature by the Poets Foundation at Kolkata in 2001. 	

    </p>
    
   
   
  </div>
</div>


  <div class="timeline-item" data-text="2002">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/2002.jpeg"/>
    <h2 class="timeline__content-title milestone">2002</h2>
    <p class="timeline__content-desc">
    On 23.12.2022 became a member of the North Eastern Council and remained so until 2019. 	

    </p>
    
    <p class="timeline__content-desc">
    Became a member of the Eastern Zonal Council and also served as Vice Chairman of the Council. The council was set up vide Part-III of the States Re-organization Act, 1956 and the then Eastern Zonal Council comprised of Bihar, Jharkhand, Orissa, Sikkim and West Bengal. 	

    </p>
   
    
  </div>
</div>

<div class="timeline-item" data-text="2003">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/2003.jpeg"/>
    <h2 class="timeline__content-title milestone">2003</h2>
    <p class="timeline__content-desc">
     Conferred a Doctor of Philosophy (AC) by Sikkim Manipal University in 2003 in recognition of “his vision, leadership and contribution in the field of social upliftment of weaker sections, women, backward classes and all round development of Sikkim”.	

    </p>
    
    <p class="timeline__content-desc">
    Declared that Sikkim would begin its journey to become a fully organic state by the passing of a resolution in the Sikkim Legislative Assembly.	

    </p>
   
  </div>
</div>

 <div class="timeline-item" data-text="2004">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/2004.jpeg"/>
    <h2 class="timeline__content-title milestone">2004</h2>
    <p class="timeline__content-desc">
     Sikkim Democratic Front, under Pawan Chamling's leadership won the general elections of 2004 as well, winning a whopping 31 out of 32 seats. Pawan Chamling was elected uncontested from the Damthang constituency.	

    </p>
    
   
  </div>
</div>


 <div class="timeline-item" data-text="2008">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/2008.jpeg"/>
    <h2 class="timeline__content-title milestone">2008</h2>
  
    <p class="timeline__content-desc">
    Awarded the prestigious 4th J.R.D Tata Memorial Award, which was conferred by His Excellency the Vice President of India, Shri Hamid Ansari.	

    </p>
    
     <p class="timeline__content-desc">
    Invited by Governor, California, Arnold Schwarzenegger to the Global Climate Summit held at the Beverly Hills California to promote international action and partership in fighting climate change. 	

    </p>
    
   
    
  </div>
</div>


<div class="timeline-item" data-text="2009">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/2009.jpeg"/>
    <h2 class="timeline__content-title milestone">2009</h2>
    <p class="timeline__content-desc">
     Sikkim Democratic Front, under Pawan Chamling's leadership won the general elections of 2009 and formed a new record of winning every single constitutency in the state. SDF won 32 of all 32 seats. In the general elections of 2009, Pawan Chamling contested from Poklok-Kamrang and Namchi-Singithang and won both constituencies.	

    </p>
    
    <p class="timeline__content-desc">
    Awarded the Leadership and Good Governance Award 2009 by Universal Peace Foundation and the Interreligious and International Federation on 6th July 2009 for manifesting “the highest standards of excellence in leadership, integrating professional excellence and practical experience with moral and spiritual wisdom”.	

    </p>
    
    <p class="timeline__content-desc">
    Awarded the Mother Teresa Lifetime Achievement Award in 2009 as recognition of his renowned administrative skills by the Mother Teresa’s International & Millennium Awards committee.	

    </p>
    
    <p class="timeline__content-desc">
    Awarded the Agriculture Leadership Award 2009 by Ministry of Agriculture, Government of India at New Delhi on 4th September 2009.	

    </p>
    
    <p class="timeline__content-desc">
        On July 6th, 2009, the United Peace Federation awarded Shri Pawan Chamling with the Leadership and Good Governance Award 2009. During the award ceremony, Dr Chung Sik Yong, Chairman, UPF-Asia, said, "Through our representative in Sikkim, we came to know of your Honor’s selfless and unchanging efforts over many decades exemplifying UPF’s motto of ‘Living for the Sake of Others.' Thus it was easy for us to select you for this prestigious award. Your accomplishments for the sake of the Sikkimese people, especially the upliftment of the poor, downtrodden, and marginalized, are truly remarkable. I am confident that you will achieve your aspiration for the people of Sikkim to be poverty-free and 100 percent literate, two very important goals out of the eight UN Millennium Development Goals."	

    </p>
   
  </div>
</div>


<div class="timeline-item" data-text="2010">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/bhanu-puraskar-2010.png"/>
    <h2 class="timeline__content-title milestone">2010</h2>
     
    <p class="timeline__content-desc">
   Conferred the Bhanu Puruskar (in Literature) by His Excellency the Governor of Sikkim, Shri B.P. Singh on 13th July 2010. This award was given by Sikkim Sahitya Parishad for his long time “special contribution towards the preservation and development of literature, arts and culture”.	

    </p>
    
     <p class="timeline__content-desc">
    Conferred the Thekong Ambassador of Unity Award 2010 by the Pang Lhabsol Committee for “his inspiring efforts to unite the bonds of peace and harmony between the different communities of Sikkim”. The award is given only once every 10 years. 	

    </p>
    
   
   
    
  </div>
</div>


<div class="timeline-item" data-text="2011">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/2011.jpeg"/>
    <h2 class="timeline__content-title milestone">2011</h2>
     
    <p class="timeline__content-desc">
   Between 17th May 2011 - 30th July 2011, Pawan Chamling embarked on a Sikkim Brahman where he travelled with all secretaries and relevant governmen officials from various departments to each and every Gram Panchayat Unit of Sikkim. The idea of the Sikkim Brahman was to "take the government to the doorsteps of the people". The tour began with a puja at the Sirdi Saibaba mandir, Assangthang, and ended at Phodong Gumpa.	

    </p>
    
   
   
    
  </div>
</div>


<div class="timeline-item" data-text="2012">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/2012.jpeg"/>
    <h2 class="timeline__content-title milestone">2012</h2>
    <p class="timeline__content-desc">
    Awarded the Bharat Asmita Jeevan Gaurav Puraskar 2012 by the MIT Group of Institutions, recognizing his longstanding, excellent and selfless service to the State and nation. The citation records Shri Chamling as “a staunch nationalist, who dreams of transforming Sikkim into one of the most prosperous, progressive, and self-reliant States of India”. The award is given to prominent luminaries in various fields like education, social welfare and politics. 	



    </p>
   
  </div>
</div>


<div class="timeline-item" data-text="2013">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/2013.jpeg"/>
    <h2 class="timeline__content-title milestone">2013</h2>
     
    <p class="timeline__content-desc">
   Conferred the Agriculture Leadership Award, 2013. The award was given at the Agriculture Leadership Summit and Agriculture Leadership Awards held at Taj Palace, New Delhi on the 19-20th of September, 2013.  	

    </p>
   
    
  </div>
</div>

 <div class="timeline-item" data-text="2014">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/2014.jpeg"/>
    <h2 class="timeline__content-title milestone">2014</h2>
     
    <p class="timeline__content-desc">
   Sikkim Democratic Front, under Pawan Chamling's leadership won the general elections of 2014 as well winning 22 out of 32 seats. Pawan Chamling contested from Namchi Singithang and Yangang Rangang and won both seats.	

    </p>
    
    <p class="timeline__content-desc">
        Pawan Chamling was sworn in as the Chief Minister of Sikkim on May 	

    </p>
    
    <p class="timeline__content-desc">
        Pawan Chamling became a member of the National Democratic Alliance (NDA) in 2014.	

    </p>
   
    
  </div>
</div>

<div class="timeline-item" data-text="2016">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/2016.jpeg"/>
    <h2 class="timeline__content-title milestone">2016</h2>
    <p class="timeline__content-desc">
    Became a member of North East Democratic Alliance (NEDA)	

    </p>
    
    <p class="timeline__content-desc">
   Conferred the Yug Kavi Siddhicharan Award 2016 bestowed upon him by Nepal’s Prime Minister, K.P. Sharma Oli for his commendable contributions in promoting Nepali literature in India. The award carried a certificate of appreciation and a sum of NPR 100,000/-. The award was organized by the Siddhicharan Shrestha Academy on the occasion of the 105th birth anniversary of the late poet Siddhicharan Shrestha, who was popularly known as Yug Kavi. 	

    </p>
    
    <p class="timeline__content-desc">
   Conferred the Sustainable Development Leadership Award - 2016 by The Energy and Resources Institute (TERI) on 6th October 2016 in recognition of his vision and leadership in Environment and sustainable development of Sikkim as the first and only Organic State in the Country. This prestigious award was bestowed on him by the President of India, Shri Pranab Mukherjee at the World Sustainable Development Summit, organised by The Energy and Resources Institute (TERI) at Vigyan Bhawan. The award has, so far, been given to global leaders including HE Ms Tarja Halonen, the President of Finland, HE Mr. Ban-ki-Moon Former Secretary-General, United Nations, HE Mr. Arnold Schwarzenegger Former Governor, State of California, USA, etc.  	

    </p>
    
     <p class="timeline__content-desc">
   Conferred the Bishwa Byaktityo Samman by Hon’ble Minister, Nepal, Shri Surendra Karki on 9th November 2016.	

    </p>
   
  </div>
</div>


<div class="timeline-item" data-text="2017">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/2017.jpeg"/>
    <h2 class="timeline__content-title milestone">2017</h2>
     
    <p class="timeline__content-desc">
   Awarded a Doctor of Philosophy (Honoris Causa) degree by Central University Sikkim on 22nd July 2017 at the 4th Convocation Ceremony of the University held at Chintan Bhawan. The honour was given to him in appreciation of his achievements as a distinguished leader who made Sikkim the first Organic State in the Country. The degree of Doctorate of Philosophy was presented by Sikkim Governor Shriniwas Patil in the presence of the Chancellor Justice Ruma Pal, Vice Chancellor T. B.  Subba.	

    </p>
    
     <p class="timeline__content-desc">
   Awarded a Doctor of Literature (Honoris Causa) degree by S.R.M. University, Sikkim during its 1st Convocation on 6 December, 12017 at Chintan Bhawan, Gangtok in appreciation of his visionary outlook, single minded devotion, selfless service and unequivocal commitment in transforming Sikkim into a fully literate, equitable, harmonious, environment friendly and prosperous modern state.	

    </p>
    
    <p class="timeline__content-desc">
   Conferred the first Bhairon Singh Shekhawat Lifetime Achievement Honour for his dedicated service to the people of India by His Excellency the President of India, Shri Pranab Mukherjee on 15th May 2017 in Jaipur. The award was conferred during the first Bhairon Singh Shekhawat memorial lecture delivered by the President. Present on the occasion were the Governor of Rajasthan Kalyan Singh, the Governor of Punjab VP Singh Badnore, Chief Minister of Rajasthan Vasundhara Raje and former Chief Minister of Rajasthan Ashok Gehlot. 	

    </p>
    
    <p class="timeline__content-desc">
   Conferred the prestigious One World Award (OWA) Grand Prix Award by Rapunzel on 8th September 2017 for his exemplary work in the field of Organic farming at Legau, Germany on the occasion of the One World Festival. The award is presented every three years by Rapunzel and IFOAM - Organics International to people and initiatives that have an outstanding impact on the international organic movement and its ideas. This year, the 5 winners were from India, Palestine, Azerbaijan, Zimbabwe, Ethiopia and Germany. The festive gala was attended by nearly 700 guests who had travelled into the Allgäu region from different countries from all over the world. They were honoured for their achievements in the ecological, economic, social and cultural issues that stands for a positive and fair aspect of globalization. 	

    </p>
    
    <p class="timeline__content-desc">
   Recognized as the "Ambassador of an Organic Himalaya & Organic World 2017". This recognition and honour was given by Dr. Vandana Shiva on 1st October 2017 during the Bhoomi Festival in New Delhi for transforming Sikkim into a 100% organic state.  	

    </p>
    
  </div>
</div>


 <div class="timeline-item" data-text="2018">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/2018.jpeg"/>
    <h2 class="timeline__content-title milestone">2018</h2>
    
    <p style={{fontWeight: '700', fontSize:'22px', color: '#fff'}}>28th April 2018	
    </p>
    <p class="timeline__content-desc">
    Completed 25 uninterrupted years of governance in Sikkim as the Chief Minister to break the record of Jyoti Basu as the longest serving Chief Minsiter in India ever.	

    </p>
    
    <p class="timeline__content-desc">
  Conferred a Doctor of Literature (Honoris Causa) degree by ICFAI University on 15th June 2018. 	

    </p>
    
    <p class="timeline__content-desc">
   Conferred the Future Policy Gold Award 2018  by Food and Agriculture Organization of the United Nations (FAO), the World Future Council, and IFOAM-Organics International on 15th October 2018 in Italy.	

    </p>
    
    <p class="timeline__content-desc">
   Recognized as the Longest Serving Chief Minister of any State of India by World Books of Records, London on 11th July 2018. 	

    </p>
    
    <p class="timeline__content-desc">
  Awarded the Dr. A.P.J. Abdul Kalam Memorial Summit and Award to Sikkim Organic Mission for Pawan Chamling’s contribution towards innovation in Governance. This prestigious award was presented at Vigyan Bhawan, New Delhi on 20th January 2018	

    </p>
   
  </div>
</div>


 <div class="timeline-item" data-text="2019">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/2019.jpeg"/>
    <h2 class="timeline__content-title milestone">2019</h2>
     
    <p class="timeline__content-desc">
  Conferred the Doctor of Philosophy (Honoris Causa) degree by Desh Bhagat University, Mandi Gobindgarh, Punjab on 14th February 2019. The honour was conferred on Pawan Chamling for his "Exceptional vision, deep commitment and continuous efforts to uplift the weaker sections, backward class and women, for his leadership and good governance with moral and spiritual wisdom".	

    </p>
    
    <br/>
    
     <p class="timeline__content-desc">
  Pawan Chamling won both constituencies he had contested from during the General Elections of 2019, namely Namchi Singithang and Poklok Kamrang. The Sikkim Democratic Front under his leadership also won the popular vote with 47.63% votes as opposed to 47.17% votes won by the SKM party. However, due to the loss in number of seats, SDF was unable to form the government in 2019 general elections. SDF won 15 seats and SKM won 17 seats. The 2019 elections was a new record for Pawan Chamling as in his 37 year long experience in electoral politics, he had personally won each and every election as the candidate.	

    </p>
    
    <br/>
    
    <p class="timeline__content-desc">
  Pawan Chamling won both constituencies he had contested from during the General Elections of 2019, namely Namchi Singithang and Poklok Kamrang. The Sikkim Democratic Front under his leadership also won the popular vote with 47.63% votes as opposed to 47.17% votes won by the SKM party. However, due to the loss in number of seats, SDF was unable to form the government in 2019 general elections. SDF won 15 seats and SKM won 17 seats. The 2019 elections was a new record for Pawan Chamling as in his 37 year long experience in electoral politics, he had personally won each and every election as the candidate.	

    </p>
    
    <br/>
    
    <p class="timeline__content-desc">
  Invited to speak at the COP14 United Nations Convention to Combat Desertification, Land Degradation and Drought on 6th September 2019. He was a part of a panel including L. Yeshey Penjor, Minister of Agriculture and Forest, Bhutan and Dr Ashok Khosla, Chairman Development Alternatives. At the convention, Pawan Chamling spoke about his experience as a policy maker in making Sikkim the first organic state in the world.
    </p>
    
  </div>
</div>


  <div class="timeline-item" data-text="2020">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/2020.jpeg"/>
    <h2 class="timeline__content-title milestone">2020</h2>
    <p class="timeline__content-desc">
   Conferred the Kirati Yalamber Smriti Samman by Kirat Rai Yayokha on 17th Jan 2020 in Kathmandu, Nepal. The dignitaries present at the programme were Chief Minister of Pradesh No. 1, Sher Dhan Rai, former ministers and members of the Federal Parliament of Nepal, among others. 	

    </p>
    
    <br/>
    
    <p class="timeline__content-desc">
 Recognized by the Lyricist Association of Nepal in Kathmandu on 22nd Jan 2020 for his immense contribution to the field of Nepali music and literature. Those present at the award ceremony were eminent personalities including Chudamani Deokota, Kiran Kharel, Rajendra Thapa, SP Koirala, Premdhoj Pradhan, Kumar Basnet, Deepak Jangam among other musicians, writers and lyricists. 	

    </p>
    
    <br/>
    
    <p class="timeline__content-desc">
  Felicitated by Geetkar Sangh, Nepal on 22nd Jan 2020 for his immense contribution to the field of Nepali music and literature. He was felicitated in the presence of eminent personalities including Chudamani Deokota, Kiran Kharel, Rajendra Thapa, SP Koirala, Premdhoj Pradhan, Kumar Basnet, Deepak Jangam among other musicians, writers and lyricists. 

    </p>
    
    <br/>
    
     <p class="timeline__content-desc">
  On, 23rd March 2020 Pawan Chamling held a press meet at the SDF party headquarters to point out the critical need for preventive action by the Sikkimese people in the context of the imminent COVID-19 spread in Sikkim. The mass distribution of masks, sanitizers and pamphlets were flagged off that day. The SDF party was first to take action regarding the COVID-19 pandemic, long before the incumbent government awoke to the crisis at hand. The party has been vocal and critical of the incumbent government’s utter failure to tackle the crisis on the economic as well health front. 

    </p>
    
    <br/>
    
    <p class="timeline__content-desc">
  On 5th November 2020, Pawan Chamling launched a “Weekly QnA”, continuing till date, in which he comments on and responds to political issues in Sikkim. The Weekly QnA is published by Sikkim Express.


    </p>
    
    <br/>
    
    <p class="timeline__content-desc">
 The first Ten Minutes to Earth program post 2019 was celebrated by the SDF party on 25th June 2020. The program has been discontinued by the incumbent government but the SDF party has continued the program with vigor while in opposition. The Ten Minutes to Earth program is the brainchild of Pawan Chamling.

    </p>
  <div style={{height: '200px'}}>
      
  </div>
   
  </div>
</div>



   <div class="timeline-item" data-text="2021">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/2021.jpeg"/>
    <h2 class="timeline__content-title milestone">2021</h2>
     
  <p class="timeline__content-desc">
   In the year 2021, Pawan Chamling travelled widely across the state on social and political visits, at times visiting the funerals of party supporters and well-wishers and at other times touring the state for party functions.


    </p>
    
    <br/>
    
    <p class="timeline__content-desc">
Pawan Chamling made headlines on his birthday on 22nd September when he refused to respond to the press upon being asked to comment. In past years, his birthday celebrations always took on a political tone. This year, a clear departure from previous years, was a non-political celebration. The silence was a comment on the need to de-politicize personal events in Sikkim where everything seems to be seen from a political lens. 

    </p>
    
    <br/>
    
    <p class="timeline__content-desc">
A massive crowd received Pawan Chamling at Bagdogra airport where he also gave a press conference.

    </p>
    
  </div>
</div>


  <div class="timeline-item" data-text="2022">
  <div class="timeline__content" style={{marginTop: '150px'}}><img class="timeline__img" src="assets/img/timeline/2022.jpeg"/>
    <h2 class="timeline__content-title milestone">2022</h2>
    <p class="timeline__content-desc">
   During a grand New Year’s celebration at Ghurpishey, Namchi, Pawan Chamling called in the new year along with SDF party workers and well-wishers. The party calendar was released on that day. Since then, the social visits to constituencies across the state has been continuing.


    </p>
    
  
  <div style={{height: '200px'}}>
      
  </div>
   
  </div>
</div>




</div>
        </>
    );
};

export default TimelineBody;