import React, {useEffect} from 'react';

import Header2 from "../components/nav/Header2";
import OnPawanChamlingHeader from "../components/onpawanchamling/OnPawanChamlingHeader";
import OnPawanChamlingBody from "../components/onpawanchamling/OnPawanChamlingBody";


import Footer2 from "../components/nav/Footer2";

import "../onpawanchamling.css";




const OnPawanChamling = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grid.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">
                        <div class="wrapper">

                                {/* Header */}
                                <OnPawanChamlingHeader />

                                <div class="root-contact">
                                    <OnPawanChamlingBody />
                                </div>

                                <Footer2 />

                        </div>
                    </div>
                </main>

            </>
    );

};

export default OnPawanChamling;