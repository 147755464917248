import React from 'react';

const HomeHeader = () => {
    return(
        <>

<header>
                <div class="header-hero header-hero-2" data-dsn-header="project">
                    <div id="descover-holder" class="bg-circle-dotted"></div>

                    <div class="container h-100">
                        <div class="row align-items-center h-100 home-header-section-row">
                            <div class="col-lg-6">
                                <img class="home-header-section-img" src="assets/img/sir.png" />
                            </div>
                            <div class="col-lg-6">
                                <div class="contenet-hero" style={{paddingBottom: '60px'}}>
                                    <h1 class="home-header-section-title" style={{fontSize:'55px'}}>FOR SIKKIM AND THE SIKKIMESE PEOPLE </h1>
                                    <p style={{color: '#fff', fontSize: '0.7em'}}>A soldier of democracy, Pawan Chamling has been leading the revolution for Sikkim and the Sikkimese people since 1993.

</p>


                                       <div class="link-custom" >
                                <a class="" href="biography.php" data-dsn="parallax" style={{borderColor: '#eee'}}>
                                    <span style={{color: '#fff'}}>Learn More</span>
                                </a>
                            </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        
        </>
    );
}

export default HomeHeader;