import React from 'react';

const HomeAwardMobile = () => {
    return(
        <>
            
<section class="our-work work-under-header  section-margin mobile-sikkim-under-pc" data-dsn-col="3" 
style={{backgroundColor: '#eee', marginBottom:'0px', marginTop: '0px'}} >
                    <div class="container" >
                        <div class="one-title" style={{width:'100%', textAlign: 'center', marginTop: '70px'}}>
                          
                            <h2 class="title-main" style={{color: '#3559c7', paddingBottom:'20px'}}>Sikkim Under Pawan Chamling</h2>

                           <p data-dsn-animate="text" style={{color: '#000' }}>Under Pawan Chamling’s leadership, Sikkim has gone from a little 
                           known Himalayan state of India to a national and global leader on many fronts. Sikkim has become an exemplary state, 
                           achieving monumental feats previously thought of as impossible.  

 </p>


                        </div>
                    </div>









<div class="container-fluid my-4" style={{paddingBottom: '30px'}}>


  <div id="multi-item-example" class="carousel slide carousel-multi-item mobile-carousel" data-ride="carousel">

    <div class="controls-top" style={{marginBottom: '10px'}}>
      <a class="btn-floating" href="#multi-item-example" data-slide="prev" style={{padding: '12px', backgroundColor: '#fff', borderRadius: '10px'}}>
        <i class="fa fa-chevron-left"></i>
      </a>

      <a class="btn-floating" href="#multi-item-example" data-slide="next" style={{padding: '12px', backgroundColor: '#fff', borderRadius: '10px'}}>
        <i class="fa fa-chevron-right"></i>
      </a>
      
      
    </div>

    <ol class="carousel-indicators">
      <li data-target="#multi-item-example" data-slide-to="0" class="active"></li>
      <li data-target="#multi-item-example" data-slide-to="1"></li>
      <li data-target="#multi-item-example" data-slide-to="2"></li>
      <li data-target="#multi-item-example" data-slide-to="3"></li>
    </ol>

    <div class="carousel-inner" role="listbox">

      <div class="carousel-item active">

        <div class="row under-pc-list">
          <div class="col-xs-6 item">
        
            <div style={{width: '100%', height:'auto', borderRadius: '10px', backgroundColor: '#fff'}}>




              <div class="row" style={{margin:'0px', paddingTop:'20px', paddingBottom: '20px', paddingLeft: '10px'}}>

              
    
                  <div class="col-xs-12">
    
                    <h2 style={{fontFamily: 'Montserrat, sans-serif', fontSize: '16px',
                        fontWeight: '800', color: 'rgb(0, 0, 0)',
                        textTransform: 'uppercase'}}>100% Organic State</h2>
                        <br />
                        <p style={{fontFamily: 'Montserrat, sans-serif', fontSize: '14px', color: 'rgb(0, 0, 0)'}}>Sikkim is the first fully organic state in the world...</p>
                    
                  </div>
              </div>





           
          </div>
        </div>
        

          <div class="col-xs-6 item">
            <div style={{width: '100%', height:'auto', borderRadius: '10px', backgroundColor: '#fff'}}>




              <div class="row" style={{margin:'0px', paddingTop:'20px', paddingBottom: '20px', paddingLeft: '10px'}}>


              <div class="col-xs-12">

                <h2 style={{fontFamily: 'Montserrat, sans-serif', fontSize: '16px',
                        fontWeight: '800', color: 'rgb(0, 0, 0)',
                        textTransform: 'uppercase'}}>1st Nirmal Rajya</h2>
    <br />
    <p style={{fontFamily: 'Montserrat, sans-serif', fontSize: '14px', color: 'rgb(0, 0, 0)'}}>Sikkim became the first Nirmal Rajya of the country...</p>

              </div>
            </div>


    


             
          </div>
          </div>




         

         
        </div>

      </div>



      <div class="carousel-item">

        <div class="row under-pc-list">
            
            


          <div class="col-xs-6 item">
            <div style={{width: '100%', height:'auto', borderRadius: '10px', backgroundColor: '#fff'}}>




              <div class="row" style={{margin:'0px', paddingTop:'20px', paddingBottom: '20px', paddingLeft: '10px'}}>

        

              <div class="col-xs-12">

                <h2 style={{fontFamily: 'Montserrat, sans-serif', fontSize: '16px',
                        fontWeight: '800', color: 'rgb(0, 0, 0)',
                        textTransform: 'uppercase'}}>Second richest state</h2>
    <br />
    <p style={{fontFamily: 'Montserrat, sans-serif', fontSize: '14px', color: 'rgb(0, 0, 0)'}}>Sikkim recorded the...</p>
    
  
              </div>
            </div>





           
          </div>
          </div>
          
          
          
          
         <div class="col-xs-6 item">
           <div style={{width: '100%', height:'auto', borderRadius: '10px', backgroundColor: '#fff'}}>




              <div class="row" style={{margin:'0px', paddingTop:'20px', paddingBottom: '20px', paddingLeft: '10px'}}>

            

              <div class="col-xs-12">

                <h2 style={{fontFamily: 'Montserrat, sans-serif', fontSize: '16px',
                        fontWeight: '800', color: 'rgb(0, 0, 0)',
                        textTransform: 'uppercase'}}>Adding 11 years to Sikkimese life</h2>
    <br />
    <p style={{fontFamily: 'Montserrat, sans-serif', fontSize: '14px', color: 'rgb(0, 0, 0)'}}>An average Sikkimese...</p>

              </div>
            </div>





             
             
          </div>
          </div>

          
        
            </div>
      </div>
      
      
      <div class="carousel-item">

        <div class="row under-pc-list">
            
             <div class="col-xs-6 item">
            <div style={{width: '100%', height:'auto', borderRadius: '10px', backgroundColor: '#fff'}}>




              <div class="row" style={{margin:'0px', paddingTop:'20px', paddingBottom: '20px', paddingLeft: '10px'}}>

              

              <div class="col-xs-12">

                <h2 style={{fontFamily: 'Montserrat, sans-serif', fontSize: '16px',
                        fontWeight: '800', color: 'rgb(0, 0, 0)',
                        textTransform: 'uppercase'}}>First kutcha house free state</h2>
    <br />
    <p style={{fontFamily: 'Montserrat, sans-serif', fontSize: '14px', color: 'rgb(0, 0, 0)'}}>Sikkim is India’s first...</p>

              </div>
            </div>





        
          </div>
          </div>

          <div class="col-xs-6 item">
            <div style={{width: '100%', height:'auto', borderRadius: '10px', backgroundColor: '#fff'}}>




              <div class="row" style={{margin:'0px', paddingTop:'20px', paddingBottom: '20px', paddingLeft: '10px'}}>

        

              <div class="col-xs-12">

                <h2 style={{fontFamily: 'Montserrat, sans-serif', fontSize: '16px',
                        fontWeight: '800', color: 'rgb(0, 0, 0)',
                        textTransform: 'uppercase'}}>Free education till University</h2>
    <br />
    <p style={{fontFamily: 'Montserrat, sans-serif', fontSize: '14px', color: 'rgb(0, 0, 0)'}}>Education has been a...</p>

              </div>
            </div>





             
             
          </div>
        </div>
            
         </div>
            
      </div>
      
      
      
      <div class="carousel-item">

        <div class="row under-pc-list">
            
                 <div class="col-xs-6 item">
           <div style={{width: '100%', height:'auto', borderRadius: '10px', backgroundColor: '#fff'}}>




              <div class="row" style={{margin:'0px', paddingTop:'20px', paddingBottom: '20px', paddingLeft: '10px'}}>

            

              <div class="col-xs-12">

                <h2 style={{fontFamily: 'Montserrat, sans-serif', fontSize: '16px',
                        fontWeight: '800', color: 'rgb(0, 0, 0)',
                        textTransform: 'uppercase'}}>Peaceful border state</h2>
    <br />
    <p style={{fontFamily: 'Montserrat, sans-serif', fontSize: '14px', color: 'rgb(0, 0, 0)'}}>Under the leadership...</p>

              </div>
            </div>





             
             
          </div>
          </div>

          <div class="col-xs-6 item">
            <div style={{width: '100%', height:'auto', borderRadius: '10px', backgroundColor: '#fff'}}>




              <div class="row" style={{margin:'0px', paddingTop:'20px', paddingBottom: '20px', paddingLeft: '10px'}}>

             

              <div class="col-xs-12">

                <h2 style={{fontFamily: 'Montserrat, sans-serif', fontSize: '16px',
                        fontWeight: '800', color: 'rgb(0, 0, 0)',
                        textTransform: 'uppercase'}}>First state to begin capacity building</h2>
    <br />
    <p style={{fontFamily: 'Montserrat, sans-serif', fontSize: '14px', color: 'rgb(0, 0, 0)'}}>Sikkim has created...</p>

              </div>
            </div>





           
             
          </div>
          </div>
        
        
        
        
        
        
            
         </div>
            
      </div>



    </div>

  </div>


</div>

</section>
        </>
    );
};

export default HomeAwardMobile;