import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr28Feb2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/SDF.jpeg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>28 Feb, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and the SDF President Shri Pawan Chamling explains why some leaders and workers leaving the SDF party will not have real
                           </h3>
                           
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#">
                                            <span>#OFOJ</span>
                                        </a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                    “Question: How is the SDF party going to keep its flock together and march ahead when so many leaders and workers are leaving the party to join the ruling party? What will be the next steps ahead?


                                </p>


                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> Life never ceases to teach you lessons. As a senior politician, I have learnt that politics doesn’t depend on a large number of leaders. Nor does it depend on self-proclaimed leaders and workers joining a party. We have seen them all. Ours was the party with the largest number of leaders and the largest number of workers joining our party even in the last election. But we lost the election despite all of that.



                                </p>
    
                                <div>
                                <img class="w-100" src="assets/img/pressrelease/SDF.jpeg" alt="" />
                                <p style={{color: '#000', fontSize: '14px'}}>
                                    
                                </p>
                                </div>

                                <p style={{color: '#000'}}>
                                    Not only that, we have learnt that achievements also do not help politics. This has been a sad realization. As a state government, what did we not achieve? Some of our achievements were globally acclaimed. We became the world’s first Organic State. Sikkim won the Future Policy Gold Award. We achieved a world record-breaking poverty reduction. No other country has seen a rise in life expectancy of 11 years in 20 years. We banned grazing, crackers and took many novel initiatives for environment conservation. We achieved the best national feat in literacy. We were about to become India’s first kutcha house free state. We built the country’s second-largest hospital. We successfully implemented a one family one job policy - another first in the country. We provided reservations in various fields for every community in the state. We secured an income tax exemption for all Sikkim Subject Certificate holders. But despite these extraordinary feats, we lost the election. 

                                </p>
                                 <p style={{color: '#000'}}>
                                    Where did we fail? We failed to realize that the SDF party was becoming more like a corporate house where many leaders and workers were coming for personal gain – jobs, business, contracts, tickets, posts, positions, etc. There were plenty of so-called leaders and workers who personalized gains and socialized losses. The party was filled with a large number of hypocrites. We were looking at the large multitude of leaders but we failed to see the hypocrites in that multitude who were with us for the sole objective of building their own homes by destroying the party.
 

                                </p>
                                 <p style={{color: '#000'}}>
                                  We must all realize that mere materials and money are not adequate to build a house. We need an architect to give shape to the desire to build a house, then we need an engineer to interpret and implement the blueprint and skilled workers and equipment to act. But even these are not enough - we need a sense of belongingness to own and look after the house. If the construction of a house is so complicated, how difficult and serious is politics which makes a society, a state or a nation. How many of us in politics realise that politics involves significant decisions about collective human lives. Sikkim politics need leaders and people who are willing to struggle. Comfort builds physical bodies but struggles and sacrifices build inner character. Materials build physical structures but willpower and courage build human life.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   In fact, the past election highlighted more the defeat of SDF, not the victory of the SKM. People were compelled to vote for them because we failed to see the party’s internal problems. People wanted to support the SDF but they had seen hypocrites in the party who were behaving like saints in front of us and bullying people when they were in the field.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   All those so-called leaders who have left the SDF are now selling the post and position that they earned in the SDF. The former Ministers, former MLAs, former Chairperson, former this and former that - they are now merchandising the names and titles that the SDF party gave them.
It reminds me of TV shows on animals. It is interesting how animals always move to greener pastures. They live in a green pasture until they finish the grass there and then they move to another place with more grass. For the moment, the SDF has nothing to offer them. So they have moved to the ruling party. They will leave the SKM party when it loses the election in the future.




                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                 They remind me of dead fish that do not have their own direction but merely flow with the water.
To me, this is a time of political Great Depression in Sikkim. When first the USA and then the world had the Great Depression in the 1930s, the then US President, Franklin Roosevelt had initiated a New Deal (1933-1939) to fight the economic recession. Sikkim is in need of a political New Deal and, believe me, the SDF will initiate that political New Deal. The SDF has lost the election but not the battle. We have lost votes to form the government but not the vision to build Sikkim. Many power-hungry political brokers have left the party but the soldiers of democracy are in the party.



                                </p>
                                
                                <p style={{color: '#000'}}>
                                    I know many honest and sincere SDF supporters have also been misguided, intimidated and forced to join the ruling party by these political brokers. I have heartfelt sympathy for such dear people. They are physically with the ruling party but in their mind and heart, they are with us.




                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                     
Mark my words, the SDF will come back stronger with renewed energy and strength. The best is yet to come.”


                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr28Feb2021;