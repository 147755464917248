import React from 'react';

const BiographyLife = () => {
    return(
        <>
           <section class="biography-container" style={{background: '#cee5ff', padding: '60px'}}>
                    <div class="container" style={{marginBottom: '50px'}}>
                        <div class="row">
                            
                            <div class="col-md-6">
                                <h3 style={{color: '#153493', fontWeight: '800', paddingBottom: '20px', paddingTop: '20px'}}>Early Life</h3>
                                
                                <p style={{color: '#153493', fontSize:'21px', lineHeight: '33px', fontWeight: '600'}}> 
                                Pawan Chamling was born on 22 September 1950 in Yangang, a quiet village in South Sikkim, 
                                tucked in the lap of a majestic towering rocky hill - Bhaleydhunga. He was born to Ash Bahadur Chamling and Asharani Chamling, 
                                the eldest of six children. As he grew up in a farmer's family, he learned to push against the limitations of a rural life which 
                                was mired with poverty and oppression. Unlike an ordinary teen, he began to ponder whether those distresses and sufferings were 
                                overcomable or not. Deep down he wanted to believe that there must be a way to alleviate the hardships of the poor and the downtrodden. 
                                He found an creative outlet to give words to these early experiences in poetry. He began writing at a young age when he was a student at Yangang Secondary School. 


                                </p>

                                
                            </div>

                            <div class="col-md-6" style={{paddingTop:'100px'}}>
                                <img class="img-responsive" src="assets/img/biography/Early-lifee.png" style={{padding: '20px'}} />
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p style={{color: '#153493', fontSize:'21px', lineHeight: '33px', fontWeight: '600'}}> His creative pursuits would lead him in 1977 to establish the Nirman Prakashan publishing house as well as 
                                launch a monthly magazine entitled, ‘Nirman’ which he himself edited and which is still being printed today. 
                                Nirman would go onto be a household name in the Nepali literary world publishing complete works of towering literary figures like Parijat, Indra Bahadur Rai and Agam Singh Giri.

                                </p>
                                
                               
                                <h3 style={{color: '#153493', fontWeight: '800', paddingBottom: '20px', paddingTop: '20px'}}>SOCIAL WORKER, CONTRACTOR AND PLAYWRIGHT</h3>
                                <p style={{color: '#153493', fontSize:'21px', lineHeight: '33px', fontWeight: '600'}}>
                                    After his school days, he joined the government service and served for three years. Soon after, he left his job and started a Multipurpose Cooperative Society in 
                                    Yangang where he was the founder Secretary and later was elected as President. Around the same time, he became a Government enlisted First Class Contractor. 
                                    This was also the time when he began participating in politics. He was an active social worker in Yangang, helping people make domicile certificates, taking the 
                                    sick to the hospital and getting young children admitted in school. He worked hard to relieve the people of their problems and in this way, touched thousands of lives in Yangang. 
                                    In his free time he wrote, directed and acted in short dramas on social issues which would be staged at Yangang and Mangley.
                                    Some of his plays were titled ‘Hija Aja’ and ‘Soshak singh ko antya’. His earnings from contractual work would be used to stage these plays.
                                </p>
                                <img class="img-responsive" src="assets/img/biography/contractor.jpeg" style={{padding: '20px'}} />
                                
                                 <h3 style={{color: '#153493', fontWeight: '800', paddingBottom: '20px', paddingTop: '20px'}}>A YOUNG DEMOCRAT</h3>
                                 <p style={{color: '#153493', fontSize:'21px', lineHeight: '33px', fontWeight: '600'}}>
                                     Pawan Chamling’s first active political participation was as a youth leader in the democratic movement led by late L.D. Kazi in the 1970s. When he was 23, he received a personal letter from late L.D.
                                     Kazi who was leading the movement for ‘one man, one vote’, against the Chogyal establishment. In the letter, he was asked to join the movement with likeminded people from his village and surrounding places.
                                     Pawan Chamling, defying a fever that had left him weakened, left for Gangtok on 3 April 1973. With him were a large number of people from various places of South Sikkim. People from all over Sikkim gathered 
                                     in Gangtok to demand electoral reform. The 1973 people’s movement became a significant event in Sikkim history which led to the 8th May Agreement between the Chogyal (king) and the leaders of the political 
                                     parties representing the people of Sikkim and the Government of India. As a youth leader, 
                                     Chamling actively took part in this democratic movement that culminated in Sikkim becoming the 22nd state of India in 1975. The following year, the late LD Kazi became Sikkim’s first Chief Minister. 
                                </p>
                                
                                
                                <h3 style={{color: '#153493', fontWeight: '800', paddingBottom: '20px', paddingTop: '20px'}}>SIKKIM PADYATRI</h3>
                                <p style={{color: '#153493', fontSize:'21px', lineHeight: '33px', fontWeight: '600'}}>
                                     Between 1976-78, Pawan Chamling travelled widely across Sikkim crisscrossing through the entire state. He travelled by road till the roads
                                     ended and then onwards on foot to reach each and every village. The Sikkim he found during these travels was one where people were suffering 
                                     from the lack of basic minimum needs. There was no electricity, water, education, and it was rare to find a house with a GCI sheet roof and 
                                     people with shoes on their feet. Seeing this, a strong determination took root in his heart saying, “No, this is not it! People must not live like this!”. 
                                     This is when he became unshakable in his commitment to politics driven by the determination to secure a dignified life and a democratic future for the people of Sikkim.

                                </p>
                                <img class="img-responsive" src="assets/img/biography/padyatri.jpeg" style={{padding: '20px'}} />
                                
                                <h3 style={{color: '#153493', fontWeight: '800', paddingBottom: '20px', paddingTop: '20px'}}>FROM PANCHAYAT PRESIDENT TO MINISTER</h3>
                                <p style={{color: '#153493', fontSize:'21px', lineHeight: '33px', fontWeight: '600'}}>
                                     The public sought Pawan Chamling as the Panchayat President of the Yangang Gram Panchayat and hence he was elected unopposed in 1982. 
                                     His popularity soared and just about three years later in 1985, he was elected to the Sikkim Legislative Assembly as the MLA from Damthang constituency.
                                     After being elected as MLA, he visited each and every house in his constituency between 1986-87. On alternative months, he would travel to different villages under Damthang.
                                     His style of work and exemplary connection with the grassroots escalated his mass appeal to newer heights. He was elected for the second consecutive term to
                                     the Sikkim Legislative Assembly in the 1989 elections, with a record breaking 96.6 per cent of votes cast in his favour. He was given the portfolio of Minister for Industries, 
                                     Information and Public Relations in the late NB Bhandari’s Sikkim Sangram Parishad government.
                                </p>
                                <img class="img-responsive" src="assets/img/biography/panchayat.jpeg" style={{padding: '20px'}} />
                                
                                <h3 style={{color: '#153493', fontWeight: '800', paddingBottom: '20px', paddingTop: '20px'}}>LIGHTING THE FLAME OF DEMOCRACY</h3>
                                <p style={{color: '#153493', fontSize:'21px', lineHeight: '33px', fontWeight: '600'}}>
                                     Pawan Chamling became increasingly vocal on disagreements over policies of the Sangram government and went on to argue that the foundations of democracy had been seriously shaken 
                                     under Bhandari’s watch. Not surprisingly, Pawan Chamling was first removed from the council of ministers on 16 June 1992 and from the Sikkim Sangram Parishad Party on 19 July 1992. 
                                </p>
                                
                                <p style={{color: '#153493', fontSize:'21px', lineHeight: '33px', fontWeight: '600'}}>
                                    These removals neither deterred him nor did they discourage his spirit. On 9 September 1993, he shook the Sikkim Legislative Assembly with his symbolic act of searching for democracy with a 
                                    lit candle in his hand. Moving in the floor of the Legislative Assembly with a burning candle and walking to the Leader of the House’s chair, he thundered, “Where is democracy? I don’t see 
                                    it anywhere!” This incident is viewed as a momentous event in Sikkim’s political history. 
                                </p>
                                
                                <h3 style={{color: '#153493', fontWeight: '800', paddingBottom: '20px', paddingTop: '20px'}}>THE BIRTH OF SIKKIM DEMOCRATIC FRONT</h3>
                                <p style={{color: '#153493', fontSize:'21px', lineHeight: '33px', fontWeight: '600'}}>
                                     On 4 March 1993, the Sikkim Democratic Front was born and Pawan Chamling was elected its Party President. The massive public support that the party received instantly indicated the trust that they had reposed on him. Pawan Chamling became the name of the aspirations of the people in Sikkim. ‘Janta raj ma jantai raja’  (In a democracy, the people are the rulers!) became the new political slogan. 
                                </p>
                                <img class="img-responsive" src="assets/img/biography/Birth of SDF.jpg" style={{padding: '20px'}} />
                                
                                
                                <p style={{color: '#153493', fontSize:'21px', lineHeight: '33px', fontWeight: '600'}}>
                                    On 19 June 1993, hundreds of SDF supporters were arrested followed by the arrest of thousands a few days later. With his life in danger, Pawan Chamling went underground. This was the only way to escape the government’s conspiracy to strangulate the voice of the people of Sikkim he represented. A TADA case was slapped against Pawan Chamling. After three months of hiding and working underground, he came back to Sikkim on 22 September 1993 with multitudes of people receiving him at Rangpo, East Sikkim. The SDF party’s momentum only soared with his arrival. 

                                </p>
                                
                                <h3 style={{color: '#153493', fontWeight: '800', paddingBottom: '20px', paddingTop: '20px'}}>FIFTH CHIEF MINISTER OF SIKKIM</h3>
                                 <p style={{color: '#153493', fontSize:'21px', lineHeight: '33px', fontWeight: '600'}}>
                                     Eventually, on 16 November 1994 the SDF went to the polls. When the election results were declared on 10 December 1994, the SDF party won 19 of the 32 seats, thus unseating the Bhandari leadership that had taken the reins of the Sikkim administration for 14 long years. On 12 December, Pawan Chamling became the fifth Chief Minister of Sikkim. Little did the tiny Himalayan state know that a young 42 year old poet politician was going to become the longest serving Chief Minister in India, breaking hundreds of administration related records. In 2004, SDF won 31 out of 32 seats and in 2009, SDF won 32 out of 32 seats in the Sikkim Legislative Assembly. During his tenure, he also undertook two Sikkim Brahmans – in 2011 and 2018 - in which he travelled with the entire administrative machinery each and every panchayat ward in order to receive and address grievances of the public on the spot. He took the government to the doorsteps of the people.

                                </p>
                                <img class="img-responsive" src="assets/img/biography/WhatsApp Image 2022-03-02 at 11.02.12 AM.jpeg" style={{padding: '20px'}} />
                                
                                
                                <h3 style={{color: '#153493', fontWeight: '800', paddingBottom: '20px', paddingTop: '20px'}}>A RECORD SETTER</h3>
                                 <p style={{color: '#153493', fontSize:'21px', lineHeight: '33px', fontWeight: '600'}}>
                                    When he completed 25 years as Sikkim’s Chief Minister, Sikkim had become India’s greenest state, cleanest state, most literate state, the most popular tourist destination, the state with a world record breaking poverty reduction, the highest life expectancy in India and the world’s first organic farming state. He was the one to conceive a fully organic state, something that was previously thought of as an impossibility. He showed to the world that a completely organic state is indeed possible. He had become successful in transforming the Sikkim he had witnessed in 1976 to one where basic minimum needs were fulfilled and where each individual had access to education and health. Roads now reached villages to which Pawan Chamling walked on foot. Electricity lighted up villages that were shrouded by darkness then and water flowed to every household. Beyond fulfilling basic minimum needs, Sikkim had transformed from an unknown Himalayan state to a national and global leader in several fronts including economic growth, agriculture and inclusive politics.

                                </p>
                                <img class="img-responsive" src="assets/img/biography/A record setter.jpg" style={{padding: '20px'}} />
                                
                                 <p style={{color: '#153493', fontSize:'21px', lineHeight: '33px', fontWeight: '600'}}>
                                    He has been the recipient of several prestigious awards such as the Bharat Shiromani Award (1997) by the Shiromani Institute, 4th JRD Tata Memorial Award (2008), Agriculture Leadership Award (2009) by Govt. of India, Sustainable Development Leadership Award (2016) by TERI, One World Award Grand Prix (2017)  by Rapunzel and IFOAM and Future Policy Gold Award (2018) by FAO United Nations, and several awards by organizations like India Today and IBN7 in various sectors, to name a few.
                                </p>
                                
                                
                                <h3 style={{color: '#153493', fontWeight: '800', paddingBottom: '20px', paddingTop: '20px'}}>2019 ELECTIONS AND LONE MEMBER OF THE OPPOSITION</h3>
                                <p style={{color: '#153493', fontSize:'21px', lineHeight: '33px', fontWeight: '600'}}>
                                    In 2019, despite the loss of elections by the Sikkim Democratic Front, Pawan Chamling made a new personal record of winning every election he has fought since 1982. After 25 years in government, SDF still won the popular vote with 47.67% votes as opposed to 47.17% votes won by SKM. In a political career spanning more than 40 years, Pawan Chamling remains the tallest political figure in Sikkim. Today, he is the President of the SDF, the 29th senior most political party in India and the biggest opposition party in Sikkim, as well as the Member of Legislative Assembly, Namchi-Singithang. He remains the strongest voice of the opposition in Sikkim and the political waves continue to be centred around him, despite the fact that he is the lone member of the opposition and only member representing a regional party in a government with 31 of 32 MLAs belonging to the SKM-BJP alliance.

                                </p>
                                <img class="img-responsive" src="assets/img/biography/2019 elections.jpeg" style={{padding: '20px'}} />
                                
                                <h3 style={{color: '#153493', fontWeight: '800', paddingBottom: '20px', paddingTop: '20px'}}>SHOWING THE WAY OF A REAL OPPOSITION</h3>
                                <p style={{color: '#153493', fontSize:'21px', lineHeight: '33px', fontWeight: '600'}}>
                                   After the 2019 elections, Pawan Chamling declared that as a soldier of democracy, he respected the mandate of the people and that now he would show what an opposition is and what its role is. Keeping his word, the SDF party under Pawan Chamling’s leadership has raised its voice continuously against each and every act of injustice to the people of Sikkim. Several SDF member have been arrested in the process and Pawan Chamling himself has been in several life threatening physical attacks instigated by the ruling party. The SDF party took to the streets on 10th June 2019, barely two week after the formation of the SKM government, staging a dharna at District Court, Gangtok, to force the government to take back the circular disqualifying thousands of One Family One Job employees from their jobs. As the pandemic was just beginning to spread in India, the SDF party was the first to spring into action in the state as early as 23rd March 2020 by holding a press meet stressing the imminent crisis of the pandemic in Sikkim, distributing masks and sanitizers, long before the SKM government awoke to the crisis at hand. The SDF party has also fought numerous legal battles in the court pertaining to the rights of the people of Sikkim. Pawan Chamling has been vehemently vocal against the undemocratic and unjust government at hand through press conferences and weekly press releases. The SDF party is in politics not to rule the people but to protect the rule of the people and under the leadership of Pawan Chamling, the party will continue to fight against the anti-democratic forces in power. 
                                </p>
                                 <img class="img-responsive" src="assets/img/biography/WhatsApp Image 2022-03-02 at 2.26.16 PM.jpeg" style={{padding: '20px'}} />
                                
                            </div>
                        </div>

                        
                       
                    </div>
                    
                    
                </section>
        </>
    );
};

export default BiographyLife;