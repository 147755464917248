import React from 'react';

const HomeVideo = () => {
    return(
        <>
<section class="graphic-text-alternate">
        <div class="flex">
            


<div class="multimedia graphic">
    <iframe width="640" height="360" src="https://www.youtube.com/embed/MOLc18ExZOw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>



<div class="text-content">

    <div class=" has-inline-btn intro-about intro-content-text video-text" style={{width: '80%', paddingLeft: '50px'}}>
        <h2 style={{fontFamily: 'Montserrat, sans-serif', fontSize: '32px',
        fontWeight: '800', color: 'rgb(0, 0, 0)',
        textAlign: 'left',
        visibility: 'inherit',
        opacity: '1.12564',
        transform: 'translate3d(0px, -18.8457px, 0px)', textTransform: 'uppercase'}}>Pawan chamling ने Rajiv, Narasimha Rao, Manmohan, Atal से लेकर PM Modi से जुड़ी कौन सी बाते साझा की?</h2>



        <p data-dsn-animate="text" style={{color: '#000', maxWidth: '570px'}}>Watch the interview of Pawan Chamling with The Lallontop’s Editor, Saurabh Dwivedi as part of The Lallontop’s Neta Ji Ghere Mein.
        </p>

    </div>


    </div>
</div>


    </section>
        </>
    );
};

export default HomeVideo;
