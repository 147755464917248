import React from 'react';

const EducationBody = () => {
    return(
        <>
                     <div class="container section-margin">
            <div class="row">
                <div class="good-gov-inner-page-container">
                    
                    
                    <ul>
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Free Education: </span></h4>
                            <p>Sikkim is the only state in India where education is free, not only up to the school level, but right up to the college level. Free education is provided up to doctorate level for BPL students.
</p>
                            

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Education Loan 2003:  </span></h4>
                            <p>The SDF Government launched an education loan scheme to help low income group students to pursue higher/professional education outside the state. 

                            </p>


                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Comprehensive Education Loans (CELs) 2007: </span></h4>
                            <p>In order to help Sikkimese students pursue higher studies/professional courses in India or abroad, the Chamling government launched a Comprehensive Education Loan (CELs) scheme in July 2007. A total of 1406 students availed this loan by the end of  2017. The Sikkim Government sanctioned loans worth over 52.03 crore. 

</p>


                        </li>
                        
                        
                                                <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Chief Minister's Meritorious Scholarship Scheme 2009 :  </span></h4>
                            <p>This is Sikkim’s most popular education program envisioned by the Chamling administration in 2009. Under this Sikkim specific scheme, all the class V toppers from government schools across the state sit for a centrally conducted selection test annually. The selected students are allotted seats for free education in the country's best schools on merit basis. Some of the schools where over 1000 students are studying currently are Pinegrove School, Himachal Pradesh, Scindia School, Scindia Kanya Vidhalaya, Madhya Pradesh, Aryan School, Shigally Hill International Academy Dharampur , Delhi Public School, Haryana, Tashi Namgyal Academy, Gangtok, St. Xaviers, Pakyong, etc. 

</p>
</li>

                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Chief Minister's Free Scholarship Scheme (20 best universities in the world) 2011 : </span></h4>
                            <p>Under this scheme, any Sikkimese student who qualifies for admission to the world's top 20 universities will be given financial help of up to ₹ 22 lakh. The scheme was launched on 15th August 2011.

</p>

                        </li>
                        



                        
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Free Coaching:  </span></h4>
                            <p>The Chamling administration started cash awards of ₹ 2 lakhs and free coaching for UPSC examinations. The cash award of ₹2 lakhs is given to Sikkimese students who qualify in the Union Public Service commission (UPSC) exams.

</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Free Laptops: </span></h4>
                            <p>Free laptops were distributed starting in 2013 for students in 9th grade and above. This was a first of its kind policy in India. 
</p>                            
         

                        </li>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Most Literate State:  </span></h4>
                            <p>The literacy rate was 56.94% in 1993-94 which rose to 96.72%. in 2018.

</p>

                        </li>
                        
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Free textbooks, bags, school uniforms:  </span></h4>
                            <p>24.7.1995 - started the free distribution of books, texts books, rain coats, uniforms, socks, shoes, bags etc to all students up till class 8 in government schools.

</p>

                        </li>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Highest per capita spending on education:  </span></h4>
                            <p>Sikkim has a per capita spending of Rs 12.364.06 on education which is the highest in the country. As a point of comparison, other states with massive budgets like Kerela, Gujarat, Delhi and West Bengal have a per capita spending of Rs 4245.87, Rs 3012.00, Rs 4285.81 and Rs 2223.26 respectively.

</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Highest budget allocation towards education:  </span></h4>
                            <p>Sikkim has the highest budget allocation towards education in the country at 20%.

</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Best Teacher-Student Ration in India:  </span></h4>
                            <p>Elementary level – 1:8; Primary level- 1:5 and Secondary level – 1:6. (UDISE)

</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Growth in the number of schools:  </span></h4>
                            <p>: The number of Junior High Schools increased from 118 in 1994-95 to 182 in 2016-17. Similarly, the number of Secondary Schools increased from 66 in 1994-95 to 114 in 2016-17. Today, there are over 780 government schools today and the growth in the number of schools largely took place under SDF.


</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Growth rate of higher education institutions double that of the national average:  </span></h4>
                            <p>Growth rate of higher education institutions in Sikkim is over 15% which is double that of the national average at 7%. In 1994, there was only one government college and one evening law college, both located in Gangtok. Due to this, students from other districts did not have ready opportunity available to access education in higher education institutions.

</p>

                        </li>
                        
                         
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Sports Infrastructure:  </span></h4>
                            <p>
                                Barely seven months after forming the government in 1994, Pawan Chamling created a new department – Department of Sports and Youth Affairs. Under this department, several leaps have been made in the sports sector. In particular, the sports infrastructure has proliferated across the state.

                            </p>
                            
                            
                           

                        </li>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                                    <h4 style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>List of government colleges established by SDF: </h4>
                                    
                                    <ul>
                                         <li>
                                            <p>Sikkim Government College Namchi (1995) </p>
                                        
                                        </li>
                                        
                                        <li>
                                            <p>Sikkim Government College Rhenock (2005)</p>
                                        </li>
                                        
                                        <li>
                                            <p>Sikkim Government College Gyalshing (2011)</p>
                                        </li>
                                        
                                        <li>
                                            <p>Sikkim Government B.Ed College, Soreng (2010)</p>
                                        </li>
                                        
                                        <li>
                                            <p>Government Sansnkrit Mahavidyalaya, Samdong (1997)</p>
                                        </li>
                                        
                                        <li>
                                            <p>Skkim Government College, 2ND Campus, Burtuk (2013)</p>
                                        </li>
                                        
                                        <li>
                                            <p>Science College, Chyakhung (2016)</p>
                                        </li>
                                        
                                        <li>
                                            <p>Vocational College, Dentam (2017)</p>
                                        </li>
                                        
                                        <li>
                                            <p>Arts College, Mangshila (2017)</p>
                                        </li>
                                        
                                        <li>
                                            <p>Women’s Arts College, Khamdong (under construction)</p>
                                        </li>
                                    </ul>
                                </li>
                        
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Government Universities:   </span></h4>
                            <ul>
                                <li>
                                    <p>Sikkim Central University (2007)</p>
                                </li>
                                
                                <li>
                                    <p>National Institute of Technology, Ravangla (2009)</p>
                                </li>
                                
                                <li>
                                    <p>Sikkim State University 
</p>
                                </li>
                                
                                
                            </ul>
                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Private Universities
:  </span></h4>
                            <ul>
                                <li>
                                    <p>Sikkim Manipal University, Tadong (2006)

</p>
                                </li>
                                
                                <li>
                                    <p>ICFAI University, Sichey ( 2005)
</p>
                                </li>
                                
                                <li>
                                    <p>EIILM University, Jorethang (2006) closed</p>
                                </li>
                                
                                <li>
                                    <p>Vinayaka Missions Sikkim University (2008)
</p>
                                </li>
                                
                                <li>
                                    <p>
                                        SRM University, Sikkim (2014)

                                    </p>
                                </li>
                            </ul>

                        </li>
                        
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Private Colleges
:  </span></h4>
                            <ul>
                                <li>
                                    <p>Damber Singh College, Gangtok


</p>
                                </li>
                                
                                <li>
                                    <p>Harka Maya College of Education, Gangtok

</p>
                                </li>
                                
                                <li>
                                    <p>Loyola College of Education, Namchi
</p>
                                </li>
                                
                                <li>
                                    <p>Palatine College, Pakyong
</p>
                                </li>
                                
                                <li>
                                    <p>
                                        Himalayan Pharmacy College, Majitar

                                    </p>
                                </li>
                            </ul>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Polytechnics
:  </span></h4>
                            <ul>
                                <li>
                                    <p>Advanced Technical Training Centre, Bardang (2005)
</p>
                                </li>
                                
                                <li>
                                    <p>Centre for Computers and Communication Technology, Chisopani, South Sikkim (2005)

</p>
                                </li>
                                
                            </ul>

                        </li>
                        
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>DIET
:  </span></h4>
                            <ul>
                                <li>
                                    <p>DIET, Gangtok (2003)
</p>
                                </li>
                                
                                <li>
                                    <p>DIET, Namchi (2010)


</p>
                                </li>
                                
                                <li>
                                    <p>DIET, Gyalshing (2010)
</p>
                                </li>
                                
                            </ul>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Central university established:  </span></h4>
                            <p>A fully fledged central university in Sikkim was established by an act of Parliament in 2007. 
</p>

                        </li>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Free education in polytechnic colleges:  </span></h4>
                            <p>The SDF government took another bold decision of making education free for all Sikkimese students in polytechnic colleges like ATTC, Bardang and CCCT at Chisopani Namchi. Education is also free at the Institute of Hotel Management at Sajong Rumtek. </p>

                        </li>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Teacher Training Institutes:  </span></h4>
                            <p>Only one Teacher Training Institute was established in Sikkim in 1955 and not a single teacher training institute was added until the time SDF came to power. Under SDF, DIETs were set up in Gangtok, Namchi and Gyalshing to train teachers for the schools of Sikkim.

 </p>

                        </li>
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Teacher salaries:  </span></h4>
                            <p>Teacher salaries were increased making Sikkimese teachers the highest paid in the country. The Primary School Teacher scale is higher than the equivalent LDC post in other departments. Similarly, Graduate teachers, Post Graduate Teachers, Headmasters and Principals receive a salary higher than their equivalent posts in other departments. This was done to make teaching an attractive profession for young Sikkimese people embarking on their careers.

 </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>State Teacher Recruitment Board (STRB):  </span></h4>
                            <p>The STRB was set up in order to expedite teacher recruitment due to the existing pressure on Sikkim Public Service Commission.  

 </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Other Scholarships:  </span></h4>
                            <ul>
                                <li>
                                    <p>
                                        Post Matriculation Scholarship and General Scholarship

                                    </p>
                                </li>
                                
                                <li>
                                    <p>Merit Scholarship for students securing 70% and above</p>
                                </li>
                                
                                 <li>
                                    <p>Rewards for class 10 and 12 toppers</p>
                                </li>
                                
                                 <li>
                                    <p>Financial aid to SC, ST, OBC students</p>
                                </li>
                            </ul>

                        </li>
                        
                         
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Yoga and Organic Farming in the curriculum:  </span></h4>
                            <p>Sikkim became the first state in the country to introduce yoga classes as well as organic farming in the curriculum. 

 </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Capacity Building and Livelihood Schools:  </span></h4>
                            <p>On 26th March 2007, Pawan Chamling in his budget speech at the State Legislative Assembly announced the establishment of a Directorate of Capacity Building. Alongside, it was also announced that all Panchayati Raj institutions were to set aside 2% of fund allocation towards capacity building. A State Level Capacity Building Institute was also built at Karfectar. On 31st Oct 2009, the state government took a Cabinet decision to set up livelihood schools in all 31 territorial assembly constituencies. The Directorate of Capacity Building and livelihood schools were set up to build capacity amongst the unemployed youth and skill them to take up income generating activities and professions thereafter. Sikkim is the first state in the country to institutionalise capacity building across the state.

 </p>

                        </li>
                        
                        
                      
                        
                        
                    </ul>
                </div>
            </div>
          </div>
        </>
    );
};

export default EducationBody;