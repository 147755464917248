import React from 'react';

const HealthHeader = () => {
    return(
        <>
            <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                    <div class="container" style={{paddingBottom: '50px'}}>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                    <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                        data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                       Health
                                    </h2>

                                    <p class="economy-page-header-desc" style={{fontSize: '1rem', fontWeight: '500', maxWidth:'100%', color:'#000', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px'}}>
                                    Expansion in access to health services, instituting preventive action in healthcare,  and building of world class infrastructure has been the highlight of SDF’s thrust in the health sector. The SDF government has created a strong support system for patients – from treatment benefits to guest houses outside the state – that will benefit the people of Sikkim for time to come. Today, Sikkim is home to the second largest government hospital in India which was a boon for the state during the time of the COVID19 pandemic.

                                    </p>


                                </div>
                            </div>

                        </div>
                    </div>

                </section>

                <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/good-governance/inside-page/Health.png" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                  

                </div>
            </header>
        </>
    );
};

export default HealthHeader;