import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr27Feb2022 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/OFOJ.jpeg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>21 Dec, 2020</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>Former Chief Minister and the SDF President answers a question about the controversies that currently surround the ONE FAMILY ONE JOB SCHEME which was a brainchild of the SDF chief that benefited around 20 thousand members of poor jobless families across the state. 
                            
                            

                            
                            </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                                <p>
                                    Former  “The One Family One Job scheme designed and implemented 
                                    by your government last year has now become controversial as a case has been filed against it, 
                                    making the jobs of around 20,000 beneficiaries uncertain. Critics are saying that it was an
                                     illegal scheme. Also, some are saying that, even if the scheme was good, the implementation
                                      process was wrong as multiple members from single families have been benefitted and many
                                      undeserving people have gotten jobs, etc. Now some in the government are saying that the
                                       case against OFOJ is the SDF party’s conspiracy. What would you like to say on this matter?

                                </p>

                               

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> The One Family One Job scheme was designed to benefit the weakest section of our society. The central government reservations like ST, ST(BL), SC and Women mostly benefit the elite of the creamy layers. The OBC also often fails to benefit people in the lowest strata of society. And remember, our reservations are caste, community and gender-based. But there is a section that has been deprived of any opportunities for ages despite these reservations. When we realized that caste, community and gender-based reservations failed to reach down to that level, we formulated this scheme which I call a “humanitarian-based ultra-reservation” for the weakest section of society.  We realized that roughly 40,000 Sikkimese families did not have a single government job. And so we made a criterion that would benefit such people.

                                </p>
                                
                                
                                <div>
                                  
                                    <img class="w-100" src="assets/img/pressrelease/OFOJ.jpeg" alt="" />
                                    <p style={{color: '#000', fontSize: '14px'}}>
                                        Pawan Chamling speaking at the Sikkim Legislative Assembly on 20.12.18 to approve the creation of 16,000 temporary jobs as part of the FOJ scheme announced earlier in the year.	
    
                                    </p>
                                </div>

                                <p style={{color: '#000'}}>
                                    The jobs are on an ad-hoc basis and are to be duly regularized after five years. That period of five years would have helped them to get 'on the job training' and to learn needed skills. I do admit that no matter what well-intentioned schemes the government formulates, some public representatives and influential bureaucrats with vested interests will always take undue advantage. The five year period would have given us ample time to weed out such cases.

                                </p>
                                 <p style={{color: '#000'}}>
                                    This scheme was also designed with a view to increasing rural employment opportunities. We created village centric posts such as Village Police Guards, Environment Protection Guards, Village Marketing Assistants, Village Health Assistants, etc. The Panchayats were empowered to supervise the implementation. This scheme has helped to check the movement of villagers into towns and cities.
                                </p>
                                 <p style={{color: '#000'}}>
                                   This scheme was implemented before the election with the sole intention of benefiting jobless poor families. If we had any electoral gain in mind, we would have included this scheme in our Election Manifesto and said – “If you vote for us, we will give this job within 10 days or 100 days”. Around 20,000 families who benefited under the scheme had complete liberty to support the parties they liked. Politics must be raised higher than the ugly game of forcing people to support the government or a party by blackmailing, intimidation and allurement.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                    With regards to multiple members belonging to single families benefiting, what I do say is that lapses may have happened for the reasons I cited above or other reasons which can be investigated. We must also keep in mind that there are many joint families with married children living under the same roof. Each of those children with their spouses and children is actually a family who have their own responsibility to feed and educate their children and meet various other needs.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                    I am delighted to note that even the Honourable Prime Minister Shri Narendra Modi is launching One Family One Job at the national level. I proudly feel that this has amply justified our scheme.

                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                   If we were voted to form the government, my administration would have initiated a 'One Person, One Profession' program. It is high time that Sikkimese youth beyond a certain age (20-25) become independent financially by earning their livelihood. In the Western world, after children attain the age of 18 many no longer depend on their parents. Sikkim must lead the way here and a One Person One Profession program could be helpful.


                                </p>
                                
                                <p style={{color: '#000'}}>
                                    Finally, if anyone thinks that the case filed against OFOJ is a conspiracy hatched by the SDF, he/she must be advised to refrain from stooping so low in the name of dirty politics. It is laughable. The three petitioners know who is behind the case. They only know the truth. I respect the court and I will be saying nothing as the matter is sub-judice. The SDF will never risk the future of our poor people for political gain.  Before the election, the SKM was saying that if the SDF formed the government, the OFOJ beneficiaries would lose their jobs. They also promised to regularise the OFOJ within 100 days of their governance. I think people are more interested to know why the SKM government failed to deliver on that promise.”


                                </p>

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr27Feb2022;