import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr5Sep2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/5Sep.jpeg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>5 Sep, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and President of the SDF party Shri Pawan Chamling talks about the SKM government repeatedly failing students and teachers. He condemns the ill-treatment of the teachers and students by the HRDD and demands an unconditional apology and immediate justice.
                           </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                    “Question: Since the formation of the SKM government in 2019, the human resource development department has been facing endless protests from teachers and students. The department seems to have no idea how the issues need to be addressed. Teachers have been experiencing a humiliating approach as police have dragged some of them and the Minister and ACS are not meeting them. What are your thoughts? 

                                </p>

                                

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> I am not surprised by the SKM government’s obstinate and arbitrary ways but I am surprised by the fact that the people themselves are surprised by the SKM government’s way of functioning. I hope that the public memory is not so short as to forget the repeated warnings that I gave during the last election campaign. I had said that the 2019 elections were for SAVING SIKKIM. The stakes were that high. I had said that, knowing full well the mindset and intentions of those who were leading the SKM party. I did not expect better than this and we have not experienced better than that. They are the party that is driven by anger, hatred, violence and an empty hunger for power. Look at their shamelessly false promises, arrogant statements, open threats, silly justifications and foolish ways. People were initially shell-shocked by their highhandedness and they became mute spectators. The SKM government took the public silence as acceptance. They have begun to behave like a mad man who finds a loaded gun and starts shooting wildly-nilly. They are haughtily behaving like someone who rides a tiger. But now people have begun to speak out. Their tiger-ride show is about to end.


                                </p>

                                <img class="w-100" src="assets/img/pressrelease/5Sep.jpeg" alt="" />
                                <br/>
                                <br/>

                                <p style={{color: '#000'}}>
                                    The HRDD’s ways of functioning and dealing with issues aptly reflects the mindset that governs the SKM party and their government. Look at the pattern of their functioning. It is quite distressing:
 <br/>
1.       First, they mishandle matters - that shows their incompetence.
 <br/>
2.       Then they do whatever benefits them - that shows their insensitivity to the people’s welfare.
 <br/>
3.       Then they avoid meeting people who have been wronged – that shows their arrogance.
 <br/>
4.       When victims continue to ask for justice, they use police force to frighten them – that shows their autocratic and undemocratic mentality.


                                </p>
                                 <p style={{color: '#000'}}>
                                    This is exactly how they are running the government. Never was the Sikkim Police so badly misused by the government to quell the public voice, suppress the truth and spread government propaganda. The SKM government is bereft of leadership vision and ideas. All they have is muscle power. Threatening, assaulting, forcefully dragging and dispersing people who demand justice, filing cases against people and asking them to eventually join their party is how “Golay’s Team” works. Mark my words, this evil trend is worsening day by day. 
 

                                </p>
                                 <p style={{color: '#000'}}>
                                   If the HRDD Minister had any moral sense, he wouldn’t shamelessly continue like this. The HRDD officials who are at the helm of crucial decision making should have been suspended by now. How can the government silently watch a department make a series of seriously wrong decisions that are failing teachers and students? But what can we expect from a government that expels students from college for the crime of wanting to meet the Education Minister?



                                </p>
                                
                                <p style={{color: '#000'}}>
                                   The Sikkim Government has been ignoring the fact that many schools across villages of Sikkim do not have several subject teachers, headmasters, principals because they have been either transferred, deputed or their jobs have been discontinued. The Covid pandemic has already weakened the school system. Poor network connectivity to no network connectivity has been another problem. This is the time when the HRDD should have intensified its functioning, going out of its way to help schools to fortify their infrastructure. But sadly, the HRDD is busy expelling students and sacking teachers. To add insult to injury, the HRDD Minister and top officials are too arrogant to meet these victims.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   This is the SKM government’s way of prioritizing education in Sikkim. They have completely destroyed the education and academic prospects of an entire generation by utter apathy, dictatorship and administrative inefficiency. The future of this generation of students looms dark. Let the SKM government realize that the damage done to the education of one generation can have impacts up till the fifth generation.




                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                 I was deeply disturbed by a video of policemen dragging teachers from the HRDD  headquarters to a police van. This dreadfully ugly video reminded me of similar incidents of Geyzing college students being ill-treated by the custodians of law - namely the police. Even a convicted criminal should not be treated like that, let alone innocent students and teachers. A government that uses police force and bureaucracy to humiliate and disgrace its citizen is a shame on democracy. Sikkim is facing an unprecedented humanitarian crisis. A bunch of angry administrators are mercilessly trampling upon the innocent citizens’ human rights.



                                </p>
                                
                                <p style={{color: '#000'}}>
                                    I strongly condemn such an undemocratic functioning of government. I would also like to condemn the act of the Sikkim police dragging the protesting ad hoc teachers on 2 September. Such an atrocity has become the order of the day. Sikkim will eventually make such a ruthless leadership pay for all their evils. I demand that the government, particularly the HRDD, submit a public apology to the teachers for mistreating them and taking their service for granted and to the students for failing them repeatedly.



                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                     
Finally, I ask the concerned department to extend the services of all ad hoc teachers till interviews are conducted and results are declared. Let the jobs of the teachers and classes for students continue uninterrupted.”


                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr5Sep2021;