import React from 'react';

const TimelineHeader = () => {
    return(
        <>
       <div class="timeline-header">
    <h2 class="timeline-header__title">Journey of Pawan Chamling</h2>
    <h3 class="timeline-header__subtitle">LEADER OF THE SIKKIM</h3>
    
  </div>
  <article>
    <nav class="timeline__nav">
    <ul>
      <li><span>1950</span></li>
      <li><span>1967</span></li>
      <li><span>1973</span></li>
      <li><span>1976</span></li>
      <li><span>1977</span></li>
      <li><span>1982</span></li>
      <li><span>1985</span></li>
      <li><span>1987</span></li>
      <li><span>1989</span></li>
      <li><span>1992</span></li>
      <li><span>1993</span></li>
      <li><span>1994</span></li>
      <li><span>1997</span></li>
      <li><span>1998</span></li>
      <li><span>1999</span></li>
      <li><span>2001</span></li>
      <li><span>2002</span></li>
      <li><span>2003</span></li>
      <li><span>2004</span></li>
      <li><span>2008</span></li>
      <li><span>2009</span></li>
      <li><span>2010</span></li>
      <li><span>2011</span></li>
      <li><span>2012</span></li>
      <li><span>2013</span></li>
      <li><span>2014</span></li>
      <li><span>2016</span></li>
      <li><span>2017</span></li>
      <li><span>2018</span></li>
      <li><span>2019</span></li>
      <li><span>2020</span></li>
      <li><span>2021</span></li>
      <li><span>2022</span></li>
    </ul>
  </nav>

</article>
        </>
    );
};

export default TimelineHeader;