import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr26Sep2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/Sep26.jpeg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>26 Sep, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and the President of the SDF party has answered the question about his silence before the media on his birthday. It was his expression of anguish over deeply politicised Sikkimese society and Sikkimese mindset and ever-increasing meaningless noise that is damaging Sikkimese soul.
                           </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                    “ Q. Your silence before the media on your birthday has been interpreted in various ways. What did your silence signify?


                                </p>

                               

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span>My silence before the media was the expression of my deep anguish over our deeply politicized Sikkimese society and Sikkimese mindset. I was asked some political questions and I decided to keep silent and address those questions from a political platform later. I had made a well thought through decision to start de-politicizing personal and social celebrations by keeping aside political activities. I realized that this process needed to begin someday by someone. On my birthday, I decided to be the man to start the de-politicization process.


                                </p>

                                <img class="w-100" src="assets/img/pressrelease/Sep26.jpeg" alt="" />
                                <br/>
                                <br/>

                                <p style={{color: '#000'}}>
                                    It is high time that Sikkim separates politics from apolitical institutions and matters. All concerned Sikkimese citizens have begun to notice that every single social and cultural activity has become politicized. In many funerals, some wait for the CM, Minister, MLA, Advisor or Chairman to arrive before taking the body out. In many weddings, people wait for such political leaders to arrive before solemnising the marriage. Things have come to such a pass that even in a wedding invitation card, the Chief Minister’s name is printed under RSVP. What is worse, even religious ceremonies are being conducted under the patronage and presence of political leaders. Our festivals - be it Dussehra, Deepawali, Losar, Lochar, Christmas, and every other festival have been politically influenced. Our education and health systems are deeply politically divided. Games and sports have a political colour. Our family identity is now defined by our political affiliation and flags. Society has been divided into the youth party and the old party. We will soon divide ourselves into the male party and the female party.


                                </p>
                                 <p style={{color: '#000'}}>
                                   The SDF party has long been celebrating my birthday as Garib Utthan Diwas. But from now on it will not be celebrated thus. From this year onward, I will celebrate my birthday as a purely personal event so that my friends and well-wishers from across the political parties can come and meet me. This is my effort to start breaking political barriers that have kept us isolated. I am hopeful if all of us take the necessary steps towards de-politicizing our personal, family, social, cultural and religious events and celebrate them apolitically, we will be able to break the political boxes that have confined our real identity.

                                </p>
                                 <p style={{color: '#000'}}>
                                   Furthermore, my silence was also a desire to register my concern about our society becoming increasingly noisier. There is noise everywhere. But when we carefully listen to the noise, there is sadly nothing of substance. There is the noise of rumours, anger, deception, frustration and lies. We need to step back and self-introspect in silence. I appeal to my dear fellow citizens to be wary of noise. Noise has done a lot of damage to the Sikkimese soul. We need meaningful silence to heal our ailing souls. Mother Teresa had once said, "We need silence to be able to touch souls". Gautam Buddha said, “Silence is better than the nonsense”. Right now there  are too much nonsense in Sikkim.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   This year, I had a wonderfully refreshing and really delightful time with all my dear family, friends, colleagues and well-wishers. To be able to share mutual love, respect, warmth and good wishes without relating to our political affiliation was marvellously pleasurable.  I felt much closer to humanity than I would have been if I had allowed my political identity to overshadow the event. That is why I didn’t give a speech, message or any public statement, answer media questions which could have conveyed a political message or could have been looked at or interpreted through a political lens.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   I have the highest respect and deep affection for the media fraternity. I heartily appreciate their presence on my birthday. I also deeply respect their effort to reach out to me and get my views on the current political scenario. I convey my heartfelt regret and apologies if my gesture has offended any of them. It was never a blunt refusal to interact with the media. As I explained earlier at length, it was a long-meditated decision to initiate a process to disentangle our apolitical activities from political entanglements.




                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                  I will certainly answer all of their questions from a political platform. I take this opportunity to once again thank all those who attended my birthday and conveyed their good wishes to me. My heart has been deeply touched. Thank you all very much! 



                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Finally, because I have been India’s longest-serving Chief Minister, it is natural for people to associate me with politics. But I have taken a great interest in literature, music, social service, and other apolitical spheres of life as well. It is therefore my responsibility to help others to see me as an apolitical being also. Would you not agree with me? Do I not have a natural right to enjoy a purely apolitical moment with my family and friends? I hope you will understand this desire and be able to take my silence in the context that it was meant. 




                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                     
I hope that the esteemed people will appreciate that my Pavitra Kranti is far more and far higher than a political revolution. Humanity deserves more than just politics. My silence was an appeal to people to mull the vastness of apolitical truths  that constitute humanity.” 



                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr26Sep2021;