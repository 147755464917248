import React from 'react';

const PoliticalAchievementBody = () => {
    return(
        <>
            
          <div class="container section-margin">
            <div class="row">
                <div class="good-gov-inner-page-container">
                    
                    
                    <ul>
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Central OBC recognition: </span></h4>
                            <p>The Mandal Commission report was implemented in Sikkim in 1995 soon after the formation of the SDF government on 12.12.1994. According to this, Central OBC recognition was given to Manger, Gurung, Rai, Yakha, Sunwar, Bhujel, Limbu and Tamang. Earlier, the OBC status of these communities had been stopped by the previous govt in the Legislative Assembly. The OBC status which was denied by the previous government was reinstated by the SDF government.
</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Official state languages: </span></h4>
                            <p>On 21.3.1995, Newari, Rai, Gurung, Manger, Sherpa, Sunwar, Mukhia, Thami, Bhujel, and Tamang were recognized as official state languages and introduced into the school curriculum.  </p>


                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>OBC recognition for Thamis and Sanyasis   </span></h4>
                            <p>On 6.12.1999, Thamis and Sanyasis were accorded OBC recognition by the Centre Government.

</p>


                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>OBC recognition for Jogis and Dewans   </span></h4>
                            <p>On 4.4.2000, Jogis and Dewans were accorded OBC recognition by the Centre Government.
</p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Sikkim became a member of NEC:    </span></h4>
                            <p>On 23.12.2002, Sikkim became a member of the North Eastern Council.


</p>
                        </li>
                        
                        
                       
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Sikkim became a member of NEDA: </span></h4>
                            <p>Sikkim became a member of North East Democratic Alliance in 2002.

</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Hydro Power hub:    </span></h4>
                            <p>In 1994-95, the total capacity of power generation in Sikkim was only 32 MW. In 2018-19 this increased to 2800 MW. During the SDF government, 9 hydro power projects were completed. Hydro power became the major source of revenue for Sikkim.
</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Scheduled Tribe Status for the Limboo Tamang:  </span></h4>
                            <p>On 7.1.2003, the Limboos and Tamangs were recognized as Scheduled Tribe.
 </p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Nathula Trade Route opening:   </span></h4>
                            <p>Nathula Trade Route reopened after 44 years of being closed since the Indo-China war of 1962 on 6.7.2006. On the historical day of the opening, Pawan Chamling, Chief Minister of Sikkim, represented the Government of India and on behalf of the Chinese Government was C. Phuntsok, Chairman, Tibetan Autonomous Region.
 </p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Primitive Tribe Status for Lepchas:   </span></h4>
                            <p>Lepchas were accorded primitive tribe status by the state government on 18.11.2006. </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Income Tax Exemption:
   </span></h4>
   
                            <ul>
                                <li>
                                    <p>10.5.2008 - Central Income Tax exemption for the people of Sikkim who have Sikkim Subject Certificates.
</p>
                                </li>
                                
                                <li>
                                    <p>10.5.2008 – State Income Tax also exempted for the people of Sikkim. The benefit for the Sikkim Subject holders, especially for government employees is that they are exempted from Indian income tax. Earlier, they were paying income tax according to the Sikkim Income Tax manual 1948. Today, they are completely exempted from paying income tax and hence the minimum tax savings per year for various government functionaries are as given below:

</p>
                                </li>
                            </ul>
                            
                            
                            <table id="table_medals">
                                <thead style={{padding: '10px'}}>
                                    <tr>
                                          <th id="medal_rank" class=" active" style={{padding: '15px'}}>Designation</th>
                                          <th id="medal_team" class="">Tax saving /benefit  per year</th>
                                         
                                    </tr>
                                </thead>
                                
                                <tbody>
                                    <tr>
                                        <td class="medal_rank">Chief Secretary</td>
                                        <td class="medal_team">5.97 lakhs </td>
                                    </tr>
                                    
                                    <tr>
                                        <td class="medal_rank">Principle Secretary</td>
                                        <td class="medal_team">5.68 lakhs</td>
                                    </tr>
                                    
                                    <tr>
                                        <td class="medal_rank">Secretary</td>
                                        <td class="medal_team">2.90 lakhs</td>
                                    </tr>
                                    
                                    <tr>
                                        <td class="medal_rank">Special secretary</td>
                                        <td class="medal_team">2.30 lakhs</td>
                                    </tr>
                                    
                                    <tr>
                                        <td class="medal_rank">Additional secretary</td>
                                        <td class="medal_team">1.40 lakhs</td>
                                    </tr>
                                    
                                    <tr>
                                        <td class="medal_rank">Joint Secretary</td>
                                        <td class="medal_team">1.7 lakhs</td>
                                    </tr>
                                </tbody>
                            </table>
                            

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Kailash Mansarovar Yatra:    </span></h4>
                             <p> 18 June 2015 – The Kailash Mansarovar Yatra through Nathula commenced. The Kailash Mansarovar Yatra has religious and cultural significance for the people of India, in particular to Hindus, and is undertaken by hundreds of people each year.
 </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>State OBC recognition:   </span></h4>
                             <p> Way back in 22.9.2003, the SDF government took a bold step to include upper castes like Brahmin, Chettri and Newar in the Other Backward Classes State list. No Indian state governments had ever taken a policy decision such as this. Pawan Chamling justifies his government’s unique social engineering strategy by saying that backward classes are in every community and that the categorization of high or low caste does not reflect people's socio-economic condition. Only about 18 years later, on 9 August 2021, the central government passed a bill to empower states and UTs to make their own separate list of OBCs.  Pawan Chamling led SDF government was 18 years ahead in seeing the possibility of such an inclusive policy. 
 </p>

                        </li>
                        
                        
                        
                      
                        
                    </ul>
                </div>
            </div>
          </div>
        </>
    );
};

export default PoliticalAchievementBody;