import React from 'react';

const AwardHeader = () => {
    return(
        <>
            <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                    <div class="container" style={{paddingBottom: '50px'}}>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                    <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                        data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                        Awards
                                    </h2>

                                    <p class="economy-page-header-desc" style={{maxWidth:'100%', color:'#000', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px'}}>
                                    For a state once unknown to the outside world, Sikkim has received over 500 national and international awards during the Sikkim Democratic Front government in various areas of governance and policy. Sikkim has achieved such recognition despite being a landlocked and geopolitically sensitive state with mountainous and inaccessible terrain, surrounded by three international borders. These awards and recognitions have helped boost the brand of the state. They are a validation of the effectiveness of governance and commitment of Pawan Chamling as he continues to step forward to fulfil a bigger legacy for Sikkim.
                                    </p>


                                </div>
                            </div>

                        </div>
                    </div>

                </section>
        </>
    );
};

export default AwardHeader;