import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr7Feb2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/ULBelections.jpeg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>7 Feb, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and the President of the SDF party Shri Pawan Chamling has responded to those who are saying that the SDF government did nothing in 25 years. He says that three kinds of people say that (1) the desperate SKM supporters who are distracting people’s attention from the utter failure of the SKM govt, (2) the opportunists who milked the SDF government and now want to sell their souls to the SKM govt so that they can milk it again or (3) the self-righteous self-proclaimed analysts who refuse to accept the good works of the SDF govt. He has shared some of the major facts and figures as evidences of the SDF government’s achievements.
                           </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                                <p>
                                    “Question: The SKM government amended the Sikkim Municipalities Act and the Panchayat Act and made these elections non-partisan based. The state government is saying that the candidates will contest the ULB and Panchayat election without direct and indirect support of any political parties. How do you look at this amendment?
                                </p>

                              

                                <p style={{color: '#000'}}>
                                    <span style={{color: '#000', fontSize:'14px'}}>Ans:</span> The non-partisan based ULB and Panchayat elections are a reversal of a process to institutionalize democratic governance at the grassroots level.  In the name of “change”, the SKM government is going about changing every good thing that we initiated. Removing people from their jobs in various departments including Sanskrit teachers, ridiculing the ban on crackers, cutting down trees mindlessly, burning and dumping government books, calling India’s secondlargest New STNM hospital “bakhrako khor” (goat shed) and even grossly ignoring the organic state status that Sikkim earned after such hard labour and effort for almost one and a half decades - all these have been the changes Sikkim saw under this government.


                                </p>
                                
                                <div>
                                <img class="w-100" src="assets/img/pressrelease/ULBelections.jpeg" alt="" />
                                <p style={{color: '#000', fontSize:'14px'}}>
                                    SKM declared the municipal and panchayat elections as non-partisan which is just an eyewash. SKM will still push their candidates secretly and silence other aspirants.	

                                </p>
                                </div>
                                
                                
                                <p style={{color: '#000'}}>
                                    Ideologies, principles, programs and philosophies make the foundation for politics and a firm commitment to them strengthens democracy. Candidates must be committed to their parties’ ideological stance. But the SKM government, with this amendment, has belittled the significance of the ULB and Panchayat elections. Now, these elections are not ideology and principle-based. Anyone who has no particular commitment to any ideology can claim a stake in these elections. This will weaken local self-governance in the long run.


                                </p>
                                 <p style={{color: '#000'}}>
                                   This amendment shows the SKM party’s understanding of democracy and commitment to it. This I’ll-conceived amendment will eventually destroy the very foundation of democratic local governance at the grassroots level. 

                                </p>
                                 <p style={{color: '#000'}}>
                                   It is not hard to realise that this amendment is a subtle attempt to prove that the ULBs and Panchayats are not constitutional bodies. Can we make our assembly and parliamentary elections party-less? Think of the potential situation of a country when these elections are held like that.
When the ULBs and Panchayats are formed, there will be rampant bargaining as the candidates will not be bound to a party’s high command. Individuals with might or money power will easily be able to dominate the process of body formation. The age-old system of socially influential individuals grabbing power at the grassroots will be institutionalised soon.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   In a dangerous move, the state government on 2/2/2021 notified the constitution of a Block Development Committee which will eventually transfer the local governance and authority from the Panchayat to the bureaucracy.



                                </p>
                                
                                <p style={{color: '#000'}}>
                                    This shows the regressive mentality of the SKM leadership. When the rest of India is moving away from the old system of nonpartisan elections to party-based elections, Sikkim is moving towards it. Remember, party-less panchayat elections were held in the past when democracy was non-existent. People rejected that system and imposing the same system upon people shows the insensitivity of the government to people’s aspirations.



                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                 But the matter is worse than this. This amendment is basically an eyewash. The SKM will still push their won candidates secretly and silence other aspirants.



                                </p>
                                
                                <p style={{color: '#000'}}>
                                    Another interesting thing is that they have retained the reservation system that we implemented. You may recall that the SKM was totally opposed to our reservation policy in these elections. This shows that their decisions are not governed by any ideology or principle. They say and do as their whims and fancies.



                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                     
Finally, let me reiterate that people are the most powerful entity in democracy. What they want, not what the politicians want, will ultimately prevail. The people’s mandate, not the whims and fancies of politicians, will ultimately prevail. I appeal to the people to never give up.” 

                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr7Feb2021;