import React from 'react';
import {Link} from 'react-router-dom';

const HomeResource = () =>{
    return(
        <>
 <section class="text-cards bgcolor-red">
        


        <div class="text-align-center" style={{padding: '100px 20px'}}>
            
            
                 
                 
                     <h3 style={{fontSize:'14px', color: '#000', marginBottom: '20px'}}>More Resources</h3>
                     <h1 class="story-section-title" style={{fontSize:'42px', fontWeight: '800', color: '#000', padding: '0px 200px'}}>My aim is to always ensure the victory of Sikkim and the Sikkimese people – Pawan Chamling.</h1>
                 
                 
        
        </div>
        
        
                
                <div class="card-grid">
                    <div class="card-grid__item card-grid__item--flex">
                        <article class="card-grid__section">
                            <header class="card-grid__header">
                                <h3 class="card-grid__heading" style={{color: '#000'}}>Blogs</h3>
                                <Link class="card-grid__header-link" to="/blogs">See All</Link>
                            </header>
                            <ul class="card-list" style={{paddingLeft: '0px'}}>
                            
                                <li class="card-list__item">
                                    <a class="card-list__link" href="sikkim-a-land-of-social-justice.php" style={{color: '#000', textTransform: 'capitalize'}}>
                                        Sikkim: A Land Of Social Justice
                                    </a>
                                </li>
                            
                               
                            
                            </ul>
                        </article>
        
                        <article class="card-grid__section card-list">
                            <header class="card-grid__header">
                                <h3 class="card-grid__heading" style={{color: '#000'}}>Q&As</h3>
                                <Link class="card-grid__header-link" to="/">See All</Link>
                            </header>
                            <ul style={{paddingLeft: '0px'}}>
                            
                                <li class="card-list__item">
                                    <Link class="card-list__link card-list__link--faq" to="/single-interview32">
                                        <span style={{color: '#000 !important'}}>Q:</span>
                                        How would you look back at the performance of the SDF government in the field of education?
                                    </Link>
                                </li>
                            
                                <li class="card-list__item">
                                    <Link class="card-list__link card-list__link--faq" to="/single-interview31">
                                        <span style={{color: '#000 !important'}}>Q:</span>
                                         When will the SDF party go out to the people? SKM workers are spreading rumours that Pawan Chamling will not come back to active politics. 
                                    </Link>
                                </li>
                            
                            </ul>
                        </article>
                    </div>
                    <div class="card-grid__item card-grid__item--wide">
                        <header class="card-grid__header">
                            <h3 class="card-grid__heading" style={{color: '#000 '}}>Resources</h3>
                            <Link class="card-grid__header-link" to="/">See All</Link>
                        </header>
                        <ul class="card-grid--alt" style={{paddingLeft: '0px'}}>
                            
                                <li class="card-grid--alt__item">
                                    <Link class="card card--alt" to="/economy">
                                        <div class="card__image card__image--alt" style={{backgroundImage: 'url(assets/img/economy.jpeg)' }}></div>
                                        <div class="card__text">
                                            <h3 class="card__title" style={{color: '#000'}}>Economy</h3>
                                            <p class="card__excerpt">Under Pawan Chamling’s leadership, Sikkim saw the steepest fall in poverty...</p>
                                            <div class="card__type card__type--alt card__type--flex-between">
                                                
                                                <p class="card-grid__header-link" style={{color:'#3559c7'}}>Read more</p>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            
                                <li class="card-grid--alt__item">
                                    <Link class="card card--alt" to="/health">
                                        <div class="card__image card__image--alt" style={{backgroundImage: 'url(assets/img/health.jpeg)' }}></div>
                                        <div class="card__text">
                                            <h3 class="card__title" style={{color: '#000'}}>Health</h3>
                                            <p class="card__excerpt">The 1002 bedded STNM hospital, second only to AIIMS, Delhi, is a multispecialty hospital built... </p>
                                            <div class="card__type card__type--alt card__type--flex-between">
                                               
                                                <p class="card-grid__header-link" style={{color:'#3559c7'}}>Read more</p>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            
                                <li class="card-grid--alt__item">
                                    <Link class="card card--alt" to="/political-achievement">
                                        <div class="card__image card__image--alt" style={{backgroundImage: 'url(assets/img/political.jpeg)' }}></div>
                                        <div class="card__text">
                                            <h3 class="card__title" style={{color: '#000'}}>Political Achievements</h3>
                                            <p class="card__excerpt">Under Pawan Chamling’s leadership, Sikkim has made several major political achievements...  </p>
                                            <div class="card__type card__type--alt card__type--flex-between">
                                           
                                                <p class="card-grid__header-link" style={{color:'#3559c7'}}>Read more</p>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            
                        </ul>
                    </div>
                </div>
            </section>
        
        
        </>
    );
}

export default HomeResource;