import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import Header2 from "../components/nav/Header2";


import Footer2 from "../components/nav/Footer2";





const RequestAMeeting = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">
                        <div class="wrapper">

                                <div class="root-contact">
                                    
                                    {/* Header */}
                                    <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                    <div class="container contact-us-container" style={{paddingBottom: '50px'}}>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                    <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                        data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                        Request a Meeting
                                    </h2>

                                </div>
                            </div>

                        </div>
                    </div>

                </section>


        {/* Body */}

   
                                    
        <div class="container section-margin" style={{marginTop: '0px'}}>
            <div class="row">


              <div class="col-lg-12 ">
                <div class="form-box">
                  <h3 style={{color:'#000'}}>To request a meeting, please fill out the form below.</h3>
                  <form id="contact-form" class="form" method="post" action="http://theme.dsngrid.com/droow/contact.php" data-toggle="validator">
                    <div class="messages"></div>
                    <div class="input__wrap controls">


                  
<div class="row">
<div class="col-md-6">
                       <div class="form-group">
                        <div class="entry">
                          <label style={{color:'#000'}}>NAME *
</label>
                          <input id="form_email" type="text" name="name" placeholder="Type Contact name"
                            required="required" data-error="Valid name is required." style={{border:'none', padding: '0px'}} />
                        </div>
                        <div class="help-block with-errors"></div>
                      </div>
</div>

<div class="col-md-6">
                       <div class="form-group">
                        <div class="entry">
                          <label style={{color:'#000'}}>CONTACT PHONE NUMBER *
</label>
                          <input id="form_email" type="number" name="phone" placeholder="Type Contact phone"
                            required="required" data-error="Valid phone is required." style={{border:'none', padding: '0px'}} />
                        </div>
                        <div class="help-block with-errors"></div>
                      </div>
</div>

</div>


<div class="row">
<div class="col-md-6">
                       <div class="form-group">
                        <div class="entry">
                          <label style={{color:'#000'}}>EMAIL ADDRESS *
</label>
                          <input id="form_email" type="email" name="email" placeholder="Type Email Address"
                            required="required" data-error="Valid name is required." style={{border:'none', padding: '0px'}} />
                        </div>
                        <div class="help-block with-errors"></div>
                      </div>
</div>

<div class="col-md-6">
                       <div class="form-group">
                        <div class="entry">
                          <label style={{color:'#000'}}>WARD NUMBER *
</label>
                          <input id="form_email" type="number" name="ward" placeholder="Type Ward number"
                            required="required" data-error="Valid phone is required." style={{border:'none', padding: '0px'}} />
                        </div>
                        <div class="help-block with-errors"></div>
                      </div>
</div>

</div>




 <div class="form-group">
                        <div class="entry">
                          <label style={{color:'#000'}}>CONSTITUENCY *
                            </label>
                          <input id="form_email" type="text" name="constituency" placeholder="Type constituency"
                            required="required" data-error="Valid constituency is required." style={{border:'none', padding: '0px'}} />
                        </div>
                        <div class="help-block with-errors"></div>
</div>






 <div class="form-group">
                        <div class="entry">
                          <label style={{color:'#000'}}>ANY MESSAGE *</label>
                          <textarea id="form_message" class="form-control" name="message"
                            placeholder="" required="required"
                            data-error="Please,leave us a message." style={{border:'none', padding: '0px'}}></textarea>
                        </div>
                        <div class="help-block with-errors"></div>
                      </div>

                     



                      <div class="image-zoom" data-dsn="parallax">
                        <button style={{backgroundColor: '#153493'}}>Send Message</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>








                                    </div>
                       

                                <Footer2 />

                        </div>
                    </div>
                </main>

            </>
    );

};

export default RequestAMeeting;