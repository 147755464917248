import React from 'react';
import {Link} from 'react-router-dom';

const Header2 = () => {

    return(
        <div class="dsn-nav-bar">
        <div class="site-header" style={{background: '#3559c7'}}>
            <div class="extend-container">
                <div class="inner-header">
                    <div class="main-logo" style={{width: 'auto'}}>
                        <Link to="/">
                            
                            <p style={{fontWeight: '700', fontSize: '18PX', color: '#fff'}}>Pawan Chamling</p>
                        </Link>
                    </div>
                </div>
                <nav class=" accent-menu main-navigation" style={{width: '80%'}}>
                    <ul class="extend-container">
                        
                        <li class="custom-drop-down">
                            <Link to="/about">About</Link>
                            <ul>
                                <li><Link to="/biography">Biography</Link></li>
                                <li><a href="/timeline2">Timeline</a></li>
                                <li><Link to="/award">Awards</Link></li>
                                <li><Link to="/constituency">Consitituency</Link></li>
                                <li><Link to="/on-pawan-chamling">ON PAWAN CHAMLING</Link></li>
                            </ul>
                        </li>
                        <li class="">
                            <Link to="/good-governance">Good Governance</Link>
                            
                        </li>
                        <li class="custom-drop-down">
                            <Link to="/media">Media</Link>
                            <ul>
                                <li><Link to="/gallery">Gallery</Link></li>
                                <li><Link to="/speeches">Speeches</Link></li>
                                <li><Link to="/interviews">Interviews</Link></li>
                                <li><Link to="/press-releases">Press releases</Link></li>
                                
                            </ul>
                        </li>
                        <li class="custom-drop-down">
                            <Link to="#">Sikkim First</Link>
                            <ul>
                                <li><Link to="/infographic">Infographics</Link></li>
                                <li><Link to="/achievements">Achievements</Link></li>
                                
                            </ul>
                        </li>

                        <li class="custom-drop-down">
                            <Link to="#">PC Library</Link>
                            <ul>
                                <li><Link to="/books">Books</Link></li>
                                <li><Link to="/music">Music</Link></li>
                                <li><Link to="/quotes">Quotes</Link></li>
                                <li><Link to="/blogs">Blog</Link></li>
                                <li><Link to="/text-speeches">Text Speeches</Link></li>
                            </ul>
                        </li>

                        <li class="custom-drop-down">
                            <Link to="#">Connect</Link>
                            <ul>
                                <li><Link to="/contacts">Contact</Link></li>
                                <li><Link to="/request-a-meeting">Request A Meeting</Link></li>
                                
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <div class="header-top header-top-hamburger">
            <div class="header-container">
                <div class="logo main-logo">
                    <Link to="/">
                        <p style={{fontWeight: '700', fontSize: '14PX', color: '#fff'}}>Pawan Chamling</p>

                    </Link>
                </div>

                <div class="menu-icon" data-dsn="parallax" data-dsn-move="5">
                    <div class="icon-m">
                        <i class="menu-icon-close fas fa-times"></i>
                        <span class="menu-icon__line menu-icon__line-left"></span>
                        <span class="menu-icon__line"></span>
                        <span class="menu-icon__line menu-icon__line-right"></span>
                    </div>

                    <div class="text-menu">
                        <div class="text-button">Menu</div>
                        <div class="text-open">Open</div>
                        <div class="text-close">Close</div>
                    </div>
                </div>

                <div class="nav">
                    <div class="inner">
                        <div class="nav__content">

                        </div>
                    </div>
                </div>
                <div class="nav-content">
                    <div class="inner-content">
                        <address class="v-middle">
                            <span>SDF BHAWAN</span> 
                            <span>INDIRA BYPASS</span>
                            <span>GANGTOK EAST SIKKIM</span>
                        </address>
                    </div>

                </div>
            </div>
        </div>
    </div>
    );

};

export default Header2;