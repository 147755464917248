import React, {useEffect} from 'react';

import {Link} from 'react-router-dom';

import Header2 from "../components/nav/Header2";


import Footer2 from "../components/nav/Footer2";


import "../music.css";




const Music = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">
                        <div class="wrapper">

                                <div class="root-contact">
                                    
                                    {/* Header */}
                                    <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                                        <div class="container" style={{paddingBottom: '50px'}}>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                                        <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                                            data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                                        Music
                                                        </h2>

                                                        <p class="economy-page-header-desc" style={{fontSize: '1rem', fontWeight: '500', maxWidth:'100%', color:'#000', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px'}}>
                                                        Besides being a renowned poet, Pawan Chamling is also a celebrated lyricist. In 2020, Pawan Chamling was facilitated by the Geetkar Sangh, Nepal, at Kathmandu for his contribution to the field of Nepali music. Along with lyrics to contemporary songs, he is also known for writing revolutionary and political lyrics. Here is an archive of songs written by Pawan Chamling.
 
                                                        </p>


                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </section>


                                    {/* Body */}

                                    <div class="box-seat">
                    <div class="dsn-v-text" style={{ background: '#000' }}>
                        <div class="container-fluid full-width">

                            <div class="row">
                            <div class="col-md-12 " style={{padding: '0px !important'}}>
                            <div class="box-middle-text" >
                                <p data-dsn-animate="text">Music that reflects the humanitarian and revolutionary thoughts of Pawan Chamling. </p>
                                <a href="https://youtu.be/e-bCpVlMJPU" class="vid">
                                    <div class="play-button">
                                        <div class="play-btn">
                                            <i class="fas fa-play"></i>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="inner-img" data-dsn-grid="move-up" data-overlay="9">
                                <img class="has-top-bottom" src="assets/img/musics/music-banner-3.jpg" alt="" />
                            </div>

                            </div>


                              <div class="col-md-12 " style={{padding: '0px !important'}}>
                                <div class="box-middle-text" >
                                    <p data-dsn-animate="text">Music that reflects the humanitarian and revolutionary thoughts of Pawan Chamling. </p>
                                    <a href="https://youtu.be/1zlRLDglA28" class="vid">
                                        <div class="play-button">
                                            <div class="play-btn">
                                                <i class="fas fa-play"></i>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="inner-img" data-dsn-grid="move-up" data-overlay="9">
                                    <img class="has-top-bottom" src="assets/img/musics/music-banner-3.jpg" alt="" />
                                </div>
                              </div>
                            
                            
                            <div class="col-md-12 " style={{padding: '0px !important'}}>
                                <div class="box-middle-text" >
                                    <p data-dsn-animate="text">Music that reflects the humanitarian and revolutionary thoughts of Pawan Chamling. </p>
                                    <a href="https://youtu.be/TTF5mjc390M" class="vid">
                                        <div class="play-button">
                                            <div class="play-btn">
                                                <i class="fas fa-play"></i>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="inner-img" data-dsn-grid="move-up" data-overlay="9">
                                    <img class="has-top-bottom" src="assets/img/musics/music-banner-3.jpg" alt="" />
                                </div>

                            </div>
                            
                            

                            </div>



                        </div>
                    </div>
                </div>






                                    </div>
                                    
                       




                                <Footer2 />

                        </div>
                    </div>
                </main>

            </>


    );

};

export default Music;