import React from 'react';

const HomeUpcomingBook = () => {
    return(
        <>

<section style={{paddingTop:'70px', paddingBottom:'70px', backgroundColor: '#3559c7'}}>
	

	<div class="container">
	<div class="row upcoming-book-row">


			<div class="col-md-6 ">
				<div class="polocy-1" style={{width: '100%', height: '100%',  padding: '40px'}}>
					
					<div class="polycy-title" style={{textAlign: 'left'}}>
						<div class="col-md-12" style={{paddingBottom: '20px'}}>
              			<h2 class="upcoming-book-title" style={{color: '#fff'}}>Pawan Chamling : Champion Of Social Justice 
                          <br /> 
                          <span style={{fontSize:'18px'}}>Howard A Barnes And Jiwan Rai</span></h2>
            		</div>
            			<div class="col-md-12" style={{textAlign: 'left'}}>
               					<p data-dsn-animate="text" style={{color: '#fff', marginBottom: '0px'}}>A Story Of Establishing A Just Society
                                        
                                        </p>

                                        <div class="link-custom" style={{textAlign: 'left'}}>
                                    <a class="image-zoom effect-ajax" 
                                    href="https://www.amazon.in/Pawan-Chamling-Howard-Barnes-Jiwan/dp/0143443240/ref=sr_1_1?crid=1OHBB64G6X9GO&keywords=pawan+chamling&qid=1646293286&sprefix=pawan+chamling+%2Caps%2C342&sr=8-1" link="_blank" data-dsn="parallax" 
                                    style={{color: '#fff', borderColor: '#ddd', textAlign: 'left'}}>
                                        <span style={{color: '#fff'}}>Buy Now</span>
                                    </a>
                                </div>
            			</div>
					</div>

				</div>
			</div>
			



			<div class="col-md-6 ">
		<div class="upcoming-book-img" style={{paddingLeft: '200px'}}>			
				
<img class="img-responsive" src="assets/img/champion-of-social-jusctice-book-banner-mockup.png" style={{width: '300px', height: '420px'}} />
				

			</div>
			</div>


	</div>





</div>


</section>



        
        </>
    );
};

export default HomeUpcomingBook;

