import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr13June2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/13June.jpeg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>13 June, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and President of the SDF party, Shri Pawan Chamling, applauds Dr Mechung Bhutia and Dr Bina Basnet, terming their endeavours to provide voluntary medical service door to door a “global example” not seen anywhere else in the Covid world. He condemns the utter lack of wisdom of the state government who, instead of supporting these duos is using the police to harass them.
                           </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                   “Q. The state government, instead of encouraging private doctors, Dr Mechung Bhutia and Dr Bina Basnet, who are so generously providing voluntary home service to people during the Covid pandemic, is humiliating them. What message would you like to give to these two doctors, the people and the government?

                                </p>

                               

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> I do not have words to express my shock and disappointment at the arrogance and foolishness of the present state government. We have been battling a global pandemic of the rarest kind. The world has been doing everything possible to unite forces to fight against Covid-19. Far from refusing to take the help of our own people, we should be willing to take even an enemy’s help if that will save lives. But our government in Sikkim, on the other hand, has been so arrogantly harassing the volunteering medical doctors who have been providing free door to door service in Sikkim.


                                </p>

                                <img class="w-100" src="assets/img/pressrelease/13June.jpeg" alt=""/>
                                <br/>
                                <br/>

                                <p style={{color: '#000'}}>
                                    First, let me state that what Dr Mechung Bhutia and Dr Bina Basnet are doing with their team is a global example. In what other part of the world will you see medical doctors visiting people in their homes with medicines, pulse oximeters and other necessary materials and providing free checkups and medical counselling during this Covid-19 pandemic? People have been going to the hospitals and doctors for help and here in our state, two private doctors have been going to people’s doorsteps. These doctors are not paid by the government. They are procuring the medicines and arranging logistics privately.


                                </p>
                                 <p style={{color: '#000'}}>
                                   What should the Sikkim government do for them? First, say thank you and then provide support and encourage them. It is beyond imagination what this state government is doing. Using the police to harass them. Dear people of Sikkim, it is not just a gross injustice and oppressive mentality at work. It is the outworking of an inhumane and sadistic mind.  

                                </p>
                                 <p style={{color: '#000'}}>
                                   Dr Mechung Bhutia, in his visits, found out that the government had been recklessly distributing Covid-kits containing medicines for Covid in the villages.  Medicines which are dangerous for lactating and pregnant women were given out randomly. To respond to his claims, the state government said that the medicines were distributed by ASHA workers, which was a half-truth. In many places, it is SKM workers who are distributing them. Moreover, even if it is so, ASHA workers are not trained or qualified to distribute medicines. Medicines cannot be distributed randomly without doctors physically seeing patients. Can you believe that this is what is happening in the state?




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Dr Mechung Bhutia and Dr Bina Basnet have been meeting people having low oxygen saturation, showing symptoms of Covid-19, with some needing immediate medical intervention. They came across Covid positive pregnant woman with fluctuating oxygen saturation levels. They called MS, STNM and arranged an ambulance immediately. They also consulted gynaecologist Dr Anup from Siliguri.  What a wonderful service these two young private doctors have been doing. I am proud of them. People are glad and proud of them. Only a few narrow-minded leaders in the government are disappointed with their free service. History will never forgive and forget this government’s inhuman attitude and abysmal pettiness. Dear Dr Mechung and Dr Bina, the people of the state are with you. Do not lose heart. Our support, best wishes and the people’s blessings are upon you.




                                </p>
                                
                                <p style={{color: '#000'}}>
                                   If there are qualified medical doctors and nurses who are willing to go out and serve people in these crisis–ridden times, please come out without hesitation. Our people are suffering and they need help. A medical personnel’s door to door visit can make a huge difference. People are anxious. They need counselling and checkups. They need to hear your voice. We can provide you with all the needed logistical support, equipment, medicines etc. that you need to perform this service.




                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                  
Some anti-Sikkimese foolish people are saying that we are politicizing the Covid pandemic. Let them know we have been sharing our heartfelt concerns about this reckless government’s disastrous management of the situation. We are sharing our sincere willingness to help. We are contributing what we can. We love Sikkim and the Sikkimese people more than anything and we can’t just be mute spectators when they are losing everything, including their lives. I appeal simply to the government to stop thinking that we are politicising the matter. We are not. We know what the supreme priority should be right now. We have no political brownie points to gain. Remove your focus from us and please focus all your mind and attention in the Covid battle. We are with you. When we start politics, you will know it, you will feel it and you will see it. Till then, utilise all the power the people have given you to serve them to the utmost and save their lives.



                                </p>
                                
                                <p style={{color: '#000'}}>
                                    Finally, to those perennially insecure ones who have devoted their lives to rallying against me and say “Chamling is politicising this and the SDF is politicising that”, I have an advise. Remove that narrow lens and look at what Sikkim achieved during our governance. If you remain politically dwarfed and myopic, you can never see the heights of our achievements. Unless you  come out of the pit of your misery, narrow-mindedness, jealousy and negativity, you can never see where my political journey has been. It is way above your narrow thoughts. Grow up and learn to give credit where it is due. Learn to value things that the world values.”



                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr13June2021;