import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import Header2 from "../../components/nav/Header2";


import Footer2 from "../../components/nav/Footer2";





const RepublicDay = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">
                    <header>
                        <div class="header-single-post" data-dsn-header="project">
                            <div class="post-parallax-wrapper" data-overlay="3">
                                <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pHOTO02.jpg" alt=""
                                    data-dsn-header="blog" data-dsn-ajax="img" />
                            </div>
                            <div class="container">

                                <div class="inner-box m-section">
                                    <div class="post-info">
                                        <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>Gangtok</a>
                                        <div class="blog-post-cat dsn-link">
                                            <a href="#" style={{color: '#000'}}>January 26, 2012</a>
                                        </div>
                                    </div>

                                    <h3 class="title-box mt-10" style={{color: '#000'}}>Republic Day </h3>
                                </div>

                            </div>

                        </div>
                    </header>


                        <div class="wrapper">

                        <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tagged with: </h5>
                                        <a href="#"><span>Lorem ipsum</span></a>
                                        <a href="#"><span>Lorem ipsum</span></a>
                                        <a href="#"><span>Lorem ipsum</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p style={{color: '#000'}}>
                                    <a href="#" style={{color: '#000'}}>My esteemed fellow Sikkimese,</a>
                                    <br/>
                                   On the auspicious occasion of the 63rd Republic Day of the Nation, I extend my warm greetings and good wishes to the people of Sikkim and to my fellow countrymen. The guiding principles of the founding fathers who drafted the Constitution has stood the test of time, providing us guidance in the sacred task of running the  government of the people. On this day in the year 1950, the people of India adopted the  Constitution which would govern the Country based on democratic, socialist and secular  principles.
</p>

                              

                                <p style={{color: '#000'}}>
                                    While we pay our deep respect and reverence to the people who sacrificed their lives for the republic, I reiterate my pledge to uphold the sanctity of the Constitution both in  letter and spirit and forever respect the public mandate as the ultimate power as enshrined in the Constitution.

                                </p>

                                <img class="w-100" src="assets/img/pHOTO02.jpg" alt="" />

                                <p style={{color: '#000'}}>
                                    <a href="#" style={{color: '#000'}}>Respected fellow citizens,</a>
                                    <br/>
                                    We are aware that when Sikkim was integrated into the larger federal structure of our Country, we the people entered into the protective fold of the Indian Constitution. From a limited space, we expanded into a  larger horizon, both in terms of productive thinking                    and a comprehensive development road map. I am proud to state that after 36  years of following the democratic principles in the State, we have achieved many milestones in the development process. Sikkim today stands tall as one of  the most progressive States in the Country with a mix of the traditional and the modern values and a development strategy that is one of the best in the Country. On this solemn occasion, I congratulate my fellow Sikkimese for the progress and  prosperity achieved today.

                                </p>
                                 <p style={{color: '#000'}}>
                                    Nevertheless, we still need to remember that the period between 1975 to the early 1990s was fraught with political duplicity threatening to tear apart our fledgling democracy     which was just taking root in Sikkimese society. Thanks to the formation of the Sikkim Democratic Front party and the growing sagacity of the public, the Sikkimese people quickly took serious responsibility against anti-national threats to salvage democracy and ensure the democratic rights of the Sikkimese people for all time to come. It has been an arduous journey to bring about emotional integration of our people with the Country.
                                </p>
                                 <p style={{color: '#000'}}>
                                   Today we are crippled by many problems as we have become a prisoner of our circumstance. Our country is free and led by self-governing citizens. However, even after achieving freedom for so many years now, we still need to struggle for social, economic and cultural freedom. Today the world is beset with formidable challenges for which we must find solutions.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Today, as we observe Republic Day, we acknowledge the countrywide appreciation showered upon us as a model state with the most progressive and peaceful  values, driven by the relentless yearning of our people to achieve higher and higher milestones.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                    
                                    <a href="#" style={{color: '#000'}}>Fellow citizens,</a>
                                    <br/>
                                    
                                   The impressive development scenario in Sikkim today is multi-faceted and based on     equity, fair play, greater access and affordability. Traditional bottlenecks including inadequate connectivity, socio-economic disparity, lack of infrastructure for various high end activities and institute building are being taken care of. Today, we have made achievements in all spheres of development. Important elements in the social sector like education, health, poverty eradication, human resource development, agriculture and horticulture, women empowerment and child protection have been adequately addressed. Infrastructure development and expansion including the construction of airport, Railway line, National Highway and State Highway have been effectively conceived and many of the works are in advanced stages of completion.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Activities aimed at generating local employment and additional revenue have been firmly put in place. The emphasis has been on promotion of eco-tourism, development of horticulture, floriculture, harnessing of water resources and the program to become a fully  organic State by the year 2015. People worldwide are becoming health conscious and  are ready to pay any price to stay healthy and fit. Under the scenario, our organic concept is well appreciated at the Planning Commission as well as abroad. While writing to congratulate the Sikkimese experiment, the General Consul of Argentina in India  wrote,

                                
                                </p>
                                
                                <blockquote class="wp-block-quote">
                                    <p style={{color: '#000'}}><strong><em>“I read about your state will become a fully organic state and I would like to congratulate you for this fantastic achievement!!” </em></strong></p>
                                </blockquote>
                                
                                <p style={{color: '#000'}}>
                                  In this way, we are trying to discover our own strengths which ultimately will also impact thinking worldwide.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   My government has consistently endeavored to provide the best of public services to the people with particular emphasis on  the weaker sections  and to women. The policy formulation allocating over 70 percent of plan budget for rural development has been testimony to our pro-poor stand. Strengthening of the social safety  net with increased allocation of resources for a wide range of welfare measures has provided great relief to our elderly citizens and to the differently-abled    citizens.
                                </p>
                                
                                <p style={{color: '#000'}}>
                                  Under the decentralized regime as provided under the 73rd & 74th amendments of our Constitution, our grass root level functionaries are well placed and well versed in democratic governance. Decentralization in the planning process has been fully achieved. Policies formulated and development priorities processed at the village level are representative of people’s hopes and aspirations. While taking up these schemes, we have been able to appropriately address concerns and needs felt at the grassroot.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   As part of our long-term goal, 29 Block Administrative Centres have been established and are operational. The BACs have proved to be a great boon for the villages as the administration has been brought to the doorsteps of the people, relieving them of                great hardships earlier.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                  The Kutcha House free campaign has been taken up in all seriousness to transform the kutcha dwellings into pucca houses. This program is new and the first of its kind in the entire country. Through this program, we seek to provide strong and lasting housing to all our people.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Successive plans and programmes initiated by my Government have enabled the State to remain among the fastest developing States in the Country. Sikkim today occupies the top  position in terms of key indicators of growth and development such as, per capita income, literacy rate, infant mortality rate, life expectancy and other socio-economic indicators.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                  There is a  total absence of gender discrimination and violence. Strict legal frameworks  have been put in place for controlling the misuse of drugs and prohibiting persons from  selling/serving liquor to children less than 18 years of age in hotels and restaurants. At the same time, my Government is committed to the rapid socio-economic development of weaker sections like SC/ST/OBC & MBCs.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   In order to update ourselves on ground realities and to see the shortcomings at  the village level, the entire machinery of my Government undertook a 42-day village-to- village tour of various parts of the State. This tour was unique and first of its kind in the State and probably in the Country. The demands placed by the public are being processed and sanctioned in two phases to be started immediately and completed within the next 3 years. The concerned departments have been alerted and directed to prioritize                 on-the-spot sanctions granted during the tour for speedy implementation. Based on the  great responsibility bestowed upon us in the Constitution, I urge all Government functionaries to walk the extra miles and accomplish this task without any delay. We have to live up to the expectations of the people and prove worthy of their support and acceptance.
                                </p>
                                
                               
                                
                                 <p style={{color: '#000'}}>
                                  While travelling  through villages across the state, we interacted  widely and freely with the public. This facilitated interactions at the very personal level leading to some important announcements and initiatives. This  has been done to provide assistance duly recognizing the contribution of people, many of them working silently to uplift our society. Some of the important initiatives in the social sector announced and approved by my Government during the 42-day tour of  the State are as follows:-
                                </p>
                                
                                <ul>
                                    
                                    <li>
                                        <p style={{color: '#000'}}>Old age pension has been increased from Rs. 400 to 600 for the persons in the age group 60-79 years and eligibility criteria has been reduced to 60 year of age;
</p>
                                    </li>
                                    
                                    <li>
                                        <p style={{color: '#000'}}>
                                            For persons above 80 years old, we have approved Old age pension at the rate of Rs. 1000/-per month

                                        </p>
                                    </li>
                                    
                                    <li>
                                        <p style={{color: '#000'}}>
                                           Honorarium being paid to Anganwadi workers and helpers have been increased to Rs. 5200/- and Rs. 3000/- respectively.

                                        </p>
                                    </li>
                                    
                                    <li>
                                        <p style={{color: '#000'}}>
                                            The disability criteria for availing disability pension has been reduced to 40%.

                                        </p>
                                    </li>
                                    
                                    <li>
                                        <p style={{color: '#000'}}>
                                           One time maternity allowance of Rs. 3700 to mothers at the time of delivery and Rs. 300 per month in the name of first new born child for a period of  6 years as Nutrition Allowance.

                                        </p>
                                    </li>
                                    
                                    <li>
                                        <p style={{color: '#000'}}>
                                            BPL family with more than 5 members entitled to receive 50 kg rice free. Distribution of 35 kgs rice free of cost  to continue for other BPL families with less                     than 4 members.

                                        </p>
                                    </li>
                                    
                                   
                                    
                                </ul>
                                
                                 <p style={{color: '#000'}}>
                                  Further, the State Government has assigned specific tasks to all Departments to be completed within 100 days and the progress made by the Departments is being monitored closely.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                  I cannot but recall the devastating earthquake of 18th Sept, 2011, which cost us precious lives and damage to property and infrastructure. 60 persons lost their  lives and more than 600 were injured. The prompt response of my Government provided help, assistance and comfort to our people. To a great extent, road, communication,                power and water supply could be restored. I toured all parts of  the State to oversee proper relief and rehabilitation work to assuage the sufferings of our people.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                  The State Government has made Detailed Project Reports for restoration works amounting to Rs. 6890.50 crores which have been submitted to the Ministry of Home Affairs Government of India for release of the same. The State Government so far has received communication for the release of the first installment of Rs. 200 crores (from the Prime Minister’s special relief package for Sikkim amounting to Rs. 1000 crores announced during his visit to Sikkim) and another Rs. 150.38 crores from the NDRF for instant calamity to the State Government.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                    
                                   
                                 Based on our detailed assessment of loss of properties, we are going to rebuild more than 7000 rural houses in all the four districts. This is in addition to proposals to reconstruct State Secretariat building and 13 departmental headquarters, retrofit and reconstruct the Police Headquarter building, reconstruct damaged school buildings,  restore damaged roads, bridges and water supply etc. We are starting                       the reconstruction work with the hope to finish them at the earliest.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                  I place on record, the gratitude of the people of the State for the visits of the central leaders and the Prime Minister as well as for their helping hand at this hour of crisis and need.  I am also thankful to many of my fellow Chief  Ministers from across the  country, voluntary organizations, and individuals for their support.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                    We are an eco-friendly State rich in natural resources and scenic beauty and we consider ourselves an oasis of       peace and tranquility where different communities live in harmony.
                                </p>
                                
                                <p style={{color: '#000'}}>
                                    The development saga of Sikkim during the last eighteen years has been remarkable both in terms of short term and long term implications. While we have tried to focus on the  immediate needs of people, providing for basic minimum needs, we have at the same time                                                 devised long term development programs. The Sikkimese people take pride in being  the citizens of this great Nation and we are consistently moving ahead to prove that                         under the democratic dispensation,  guided by our Constitution, it is possible to  dream big and to become the makers of our own destiny.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                    My fellow Sikkimese will remember that after we formed the government for the fourth term, we tried to upgrade our development strategies and communicated our programs through ambitious Mission Statements with a time bound commitment. As time unfolds with new challenges and  circumstances, we need to adopt appropriate measures and interventions. Today, I have a commitment to make every household a concrete building under the Kutcha House  free Sikkim campaign. The target is to cover over 6000 households within 2013.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   While many parts of the Country are continuously struggling against a development deficit, Sikkim is a happy exception. In addition to setting  high development benchmarks, we have also completed some major innovative projects to further promote  Sikkim as a prime tourism destination , which will further benefit large sections              of Sikkimese society. The consecration ceremony of our recently developed Siddheswar  Dham was solemnized recently on November 8, 2011 and has been opened for devotees  and the general public. We already have the world’s highest statue of Guru Rinpoche installed at Samdruptse and Buddha Park is being developed at Ravangla. With these projects and many  more including the Chenregiz statue at Pelling and construction of skywalk atop Bhaledhunga, Sikkim is inching towards creating pilgrimage centres of rare dimensions and this will  provide long-term economic prosperity to the Sikkimese people.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   At the national level though, we are passing through a difficult phase, facing  an economic winter owing to factors such as the euro-zone crisis, food inflation and  a slowdown in overall growth of the economy. The projected 9% growth of the country’s economy has not happened. Instead, growth is hovering much below that mark. However, as it stands,  India has always bounced back to attain high economic growth based on its rich resources                       and pool of world class human capital.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   The British Prime Minister, Harold Wilson once said ‘A week is a long time in politics’. My fellow Sikkimese, I am not for short-term political gain. Since the time the Sikkimese people  voted us to run the government, I have moved beyond petty political rivalry to stand firmly  by  the aim of securing the greatest happiness possible for the Sikkimese people, for all time to come.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Empowered by the law of the land and blessed by the power of the people, I  once again commit myself to the great task of State and Nation building. Let us dedicate                                ourselves to the cause of continued peace and harmony. Let us resolve to contribute towards the speedy development of Sikkim and the Country, as always and for all time to come.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Jai Hind.

                                </p>
                                

                            </div>
                        </div>

         


                    </div>


                </div>

          

               <div class="clearfix"></div>

               <Footer2 />


                        </div>
                    </div>
                </main>

            </>
    );

};

export default RepublicDay;