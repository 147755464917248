import React, {useEffect} from 'react';

import Header2 from "../components/nav/Header2";


import Footer2 from "../components/nav/Footer2";

// import "../speeches.css";




const Interviews = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">
                        <div class="wrapper">

                                <div class="root-contact">
                                    
                                    {/* Header */}
                                    <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                                        <div class="container" style={{paddingBottom: '50px'}}>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                                        <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                                            data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                                        Interviews
                                                        </h2>

                                                        <p class="economy-page-header-desc" style={{fontSize: '1rem', fontWeight: '500', maxWidth:'100%', color:'#000', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px'}}>
                                                        Interviews of Pawan Chamling by various national and international media houses
                                                        </p>


                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </section>


                                    {/* Body */}
                                    <div class="container" style={{marginTop: '0px'}}>


<div id="page-container" style={{padding: '0px'}}>
  
   <div id="main-container">
      <div class="post-list">
         <div class="inner-post-list">
            <div id="daily-dose-text" style={{color: '#000'}}>Latest Press Releases:</div>
            <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
               <div class="inner-post">
                  
                     <div class="feat-img-container">
                         
                         <iframe width="257" height="193" src="https://www.youtube.com/embed/H5y67w9igJM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                         </div>
                     <div class="post-content-container">
                        <div class="post-content">
                            <a href="https://youtu.be/H5y67w9igJM">
                                
                                <h3 class="small-post-title">
                                    सिक्किमको विलयदेखि विकाससम्मः पवन चाम्लिङसँग संवाद | PAWAN CHAMLING
                                    </h3>
                           </a>
                           
                        </div>
                     </div>
                 
               </div>
            </div>
            
            
            <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
               <div class="inner-post">
                  
                     <div class="feat-img-container">
                         
                        <iframe width="257" height="193" src="https://www.youtube.com/embed/Ideakmdiomw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                         </div>
                     <div class="post-content-container">
                        <div class="post-content">
                            <a href="https://youtu.be/Ideakmdiomw">
                                
                                <h3 class="small-post-title">
                                   Mr. Pawan Kumar Chamling - Interview - OMG Zindagi
                                    </h3>
                           </a>
                           
                        </div>
                     </div>
                 
               </div>
            </div>
            
            <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
               <div class="inner-post">
                  
                     <div class="feat-img-container">
                         
                        
                        <iframe width="257" height="193" src="https://www.youtube.com/embed/JOOCkUVVQq4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                         </div>
                     <div class="post-content-container">
                        <div class="post-content">
                            <a href="https://youtu.be/JOOCkUVVQq4">
                                
                                <h3 class="small-post-title">
                                   सिक्किमका भूतपूर्व मुख्य मन्त्रि Dr. Pawan Kumar Chamling || AAWAJ
                                    </h3>
                           </a>
                           
                        </div>
                     </div>
                 
               </div>
            </div>
            
            
            
            <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
               <div class="inner-post">
                  
                     <div class="feat-img-container">
                         
                        <iframe width="257" height="193" src="https://www.youtube.com/embed/MOLc18ExZOw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                         
                         </div>
                     <div class="post-content-container">
                        <div class="post-content">
                            <a href="https://youtu.be/MOLc18ExZOw">
                                
                                <h3 class="small-post-title">
                                   Pawan chamling ने Rajiv, Narasimha Rao, Manmohan, Atal से लेकर PM Modi से जुड़ी कौन सी बाते साझा की?
                                    </h3>
                           </a>
                           
                        </div>
                     </div>
                 
               </div>
            </div>
            
            
            <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
               <div class="inner-post">
                  
                     <div class="feat-img-container">
                         
                      <iframe width="257" height="193" src="https://www.youtube.com/embed/zzkPD06A8eA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
                         </div>
                     <div class="post-content-container">
                        <div class="post-content">
                            <a href="https://youtu.be/zzkPD06A8eA">
                                
                                <h3 class="small-post-title">
                                   Chief Minister Pawan Chamling on One family one job | interview | Sikkim Chronicleझा की?
                                    </h3>
                           </a>
                           
                        </div>
                     </div>
                 
               </div>
            </div>
            
            <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
               <div class="inner-post">
                  
                     <div class="feat-img-container">
                         
                     <iframe width="257" height="193" src="https://www.youtube.com/embed/nwZ3ypzbw9I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        
                         </div>
                     <div class="post-content-container">
                        <div class="post-content">
                            <a href="https://youtu.be/nwZ3ypzbw9I">
                                
                                <h3 class="small-post-title">
                                   Netaji Ka Naashta: Exclusive Interview Of Longest-Serving CM Pawan Kumar Chamling
                                    </h3>
                           </a>
                         
                        </div>
                     </div>
                 
               </div>
            </div>
            
            <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
               <div class="inner-post">
                  
                     <div class="feat-img-container">
                         
                     <iframe width="257" height="193" src="https://www.youtube.com/embed/aqkWc4XpvNc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  
                         </div>
                     <div class="post-content-container">
                        <div class="post-content">
                            <a href="https://youtu.be/aqkWc4XpvNc">
                                
                                <h3 class="small-post-title">
                                   Khas Mulakat- First Ever Interview Of Sikkim CM- Pawan Kumar Chamling
                                    </h3>
                           </a>
                          
                        </div>
                     </div>
                 
               </div>
            </div>
            
            <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
               <div class="inner-post">
                  
                     <div class="feat-img-container">
                         
                    <iframe width="257" height="193" src="https://www.youtube.com/embed/lMJFJ8536kc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                         </div>
                     <div class="post-content-container">
                        <div class="post-content">
                            <a href="https://youtu.be/lMJFJ8536kc">
                                
                                <h3 class="small-post-title">
                                   Exclusive Interview with CM Pawan Chamling
                                    </h3>
                           </a>
                         
                        </div>
                     </div>
                 
               </div>
            </div>
            
            
            <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
               <div class="inner-post">
                  
                     <div class="feat-img-container">
                         
                   <iframe width="257" height="193" src="https://www.youtube.com/embed/e-puUDQ2jnc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  
                       
                         </div>
                     <div class="post-content-container">
                        <div class="post-content">
                            <a href="https://youtu.be/e-puUDQ2jnc">
                                
                                <h3 class="small-post-title">
                                   I will work as strong opposition leader : Pawan Chamling
                                    </h3>
                           </a>
                          
                        </div>
                     </div>
                 
               </div>
            </div>
            
            
             <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
               <div class="inner-post">
                  
                     <div class="feat-img-container">
                         
                  <iframe width="257" height="193" src="https://www.youtube.com/embed/Ubfm0O_tNAU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>    
                         </div>
                     <div class="post-content-container">
                        <div class="post-content">
                            <a href="https://youtu.be/Ubfm0O_tNAU">
                                
                                <h3 class="small-post-title">
                                  "सम्बोधन''। 'प्रत्यक्ष बातचीत' (भाग १)।
                                    </h3>
                           </a>
                           
                        </div>
                     </div>
                 
               </div>
            </div>
            
             <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
               <div class="inner-post">
                  
                     <div class="feat-img-container">
                         
                  <iframe width="257" height="193" src="https://www.youtube.com/embed/z3uGI0Z4Ovo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>   
                        
                         </div>
                     <div class="post-content-container">
                        <div class="post-content">
                            <a href="https://youtu.be/z3uGI0Z4Ovo">
                                
                                <h3 class="small-post-title">
                                  Satyamev Jayate - Toxic Food- Sikkim, the organic state
                                    </h3>
                           </a>
                           
                        </div>
                     </div>
                 
               </div>
            </div>
            
            
            
            <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
               <div class="inner-post">
                  
                     <div class="feat-img-container">
                         
                  <iframe width="257" height="193" src="https://www.youtube.com/embed/ylOOAdGQ8zc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>     
                         </div>
                     <div class="post-content-container">
                        <div class="post-content">
                            <a href="https://youtu.be/ylOOAdGQ8zc">
                                
                                <h3 class="small-post-title">
                                  Speaking with SIKKIM CHRONICLE the chief Minister Pawan Chamling
                                    </h3>
                           </a>
                         
                        </div>
                     </div>
                 
               </div>
            </div>
            
            
            <div data-max-pages="6" data-next-page="" id="post-881" class="link-true post post-881 type-post status-publish format-link has-post-thumbnail hentry category-uncategorized post_format-post-format-link" data-post-id="881">
               <div class="inner-post">
                  
                     <div class="feat-img-container">
                         
                 <iframe width="257" height="193" src="https://www.youtube.com/embed/iSGdWIaVltg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                         
                         </div>
                     <div class="post-content-container">
                        <div class="post-content">
                            <a href="https://youtu.be/iSGdWIaVltg">
                                
                                <h3 class="small-post-title">
                                  "सम्बोधन''। 'प्रत्यक्ष बातचीत' (भाग 2)
                                    </h3>
                           </a>
                           
                        </div>
                     </div>
                 
               </div>
            </div>
            
            
           
         
           
           
         </div>
      </div>
   </div>
   
 
</div>



</div>
                                    




                                    </div>
                       




                                <Footer2 />

                        </div>
                    </div>
                </main>

            </>
    );

};

export default Interviews;