import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import Header2 from "../components/nav/Header2";


import Footer2 from "../components/nav/Footer2";





const Books = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">
                        <div class="wrapper">

                                <div class="root-contact">
                                    
                                    {/* Header */}
                <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                    <div class="container" style={{paddingBottom: '50px'}}>
                        <div class="row ">
                            <div class="col-lg-12">
                                <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                    <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                        data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                        Books 
                                    </h2>

                                    <p class="infographic-page-header-desc" style={{fontSize: '1rem', fontWeight: '500', maxWidth:'100%', color:'#000', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px'}}>
                                    A renowned poet, writer and publisher, Pawan Chamling is a celebrated figure in the Nepali literary world. Founder of Nirman Magazine (1977) and Nirman Publications, Pawan Chamling has published more than 400 books including the complete works of Parijat, Indra Bahadur Rai and Agam Singh Giri. He has written several books of poetry and his memoirs. Numerous books have been written by writers from Sikkim and beyond on the work, life and thought of Pawan Chamling.
                                    </p>


                                </div>
                            </div>

                        </div>
                    </div>

                </section>


                                    {/* Body */}

   
                                    <div class="container section-margin">
                      
                      
                      
                      
                      {/* <!-- Award for the state start --> */}
                     <div class="header home" style={{marginBottom: '20px', marginTop: '40px'}}>
                        <a href="#" class="link-block w-inline-block">
                           <h1 class="heading-15 inline heading-underline" style={{fontSize: '32px',
                              fontWeight: '700', borderBottom: '1px dashed rgba(0, 0, 0, 0.5)',
                              color: '#333',
                              textDecoration: 'none'}}> Written By Him</h1>
                        </a>
                     </div>
                     <div class="row" style={{marginBottom: '20px'}}>
                       
                        {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="">
                              <img src="assets/img/books/no-author-2.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/no-author-2.jpg 500w, assets/img/books/no-author-2.jpg 800w, assets/img/books/no-author-2.jpg 1080w" 
                                 class="image-27" />
                              </a>
                              <div>
                                 <p style={{color: '#000', fontSize: '12px'}}>
                                    पवन चचामलिङका उद् गारहरू नामक यो पुस्तकमा सङ्ग्रहित उद् गारहरू प्रत्येक नै अर्थपूर्ण भविष्यमार्गी, दर्शन, वैज्ञानिकता र ठोस अग्रगामी विचारका संक्षिप्त संदेशहरू हुन्। प्रत्येक उमेर रपाई प्रत्येक तप्काका प्रत्येक मानव समुहका निम्ति यी उदगारहरू विचारशील साथै व्यवहार योग्य मन्तव्यहरू सिद्ध हुनेछन् भन्ने विश्वास जाहेर गरेकााछन्।
                                  
                                 </p>
                              </div>
                           </div>
                           <div class="div-block-57" >
                              <a href="" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23" >पवन चामलिङका उद् गारहरू - २०१८</h4>
                              </a>
                           </div>
                        </div>
                        {/* <!-- Award End--> */}
                        {/* <!-- Award Start --> */}
                        
                        {/* <!-- Award End--> */}
                        {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="">
                              <img src="assets/img/books/by-him-5.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/by-him-5.jpg 500w, assets/img/books/by-him-5.jpg 800w, assets/img/books/by-him-5.jpg 1080w" 
                                 class="image-27" />
                              </a>
                             
                           </div>
                           <div class="div-block-57" >
                              <a href="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23" >Pawan Chamling : Kiran</h4>
                              </a>
                           </div>
                        </div>
                        {/* <!-- Award End--> */}
                        
                        {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="#awards">
                              <img src="assets/img/books/by-him-6.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/by-him-6.jpg 500w, assets/img/books/by-him-6.jpg 800w, assets/img/books/by-him-6.jpg 1080w" 
                                 class="image-27" />
                              </a>
                              <div>
                                 <p style={{color: '#000', fontSize: '12px'}}>
                                    आफ्नो हुनुप्रति समाज, संस्कृति, भाषा र साहित्यप्रति, देश, परिवेश र समग्र मानवप्रति सकारात्मक दृष्टिकोण र भावना राख्ने अति भावुक कविले पोखाएका उद्गगार हुन् यी कविताहरू। उहाँ मान्छेको शाश्वत मर्यादा र अस्तित्वको रक्षार्थ कलमको हतियार लिएर संघर्षरत हुनुहुन्छ भने रूढ़ीग्रस्त समाजमा
 मानिसले भोगिरहेका विसङ्गतिहरू प्रति उहाँको कोमल र संवेदनशील हृदय रोएको पाइन्छ। मान्छेलाई सही दिशा र सही मूल्यबोध दिनु नै रचनाकारको दायित्व हो। उहाँ लेखकीय स्वतन्त्रताका पक्षधर हुनुहुन्छ र भन्नुहुन्छ कि यसैबाट मात्र जन-मानसलाई न्याय दिन सकिन्छ।
                                 </p>
                              </div>
                           </div>
                           <div class="div-block-57" >
                              <a href="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23" style={{textTransform: 'capitalize'}}>वीरको परिचय र अन्य प्रारम्भिक कविताहरू:
                                    पवन चामलिङ 'किरण'
                                 </h4>
                              </a>
                           </div>
                        </div>
                        {/* <!-- Award End--> */}
                        
                        {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="#awards">
                              <img src="assets/img/books/by-him-2.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/by-him-2.jpg 500w, assets/img/books/by-him-2.jpg 800w, assets/img/books/by-him-2.jpg 1080w" 
                                 class="image-27" />
                              </a>
                              <div>
                                 <p style={{color: '#000', fontSize: '12px'}}>
                                    सिकिक्मको धार्मिक, सांस्कृतिक र शौर्य-वीर र रमणीयताको आफ्नै अतीत कथा छ। अनि आफ्नै खालको बर्तमान व्यथा छ। ती कथा र व्यथा पनि छ। ती कथा र व्यथालाई आत्मसात गरेका प्रस्तुत कविता संग्रहका कवि श्री पवन चामलिङज्यूले सिकिक्मेली जनताको सजीव चित्र उतारि तिनका इच्छा, आकांक्षा, आशा, विश्वासलाई पनि प्रभावशाली रूपमा अभिव्यक्ति दिएका छन्।
                                 </p>
                              </div>
                           </div>
                           <div class="div-block-57" >
                              <a href="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23" style={{textTransform: 'capitalize'}}>पवन चामलिङ 'किरण'
                                    अन्तहीन सपना: मेरो विपना
                                 </h4>
                              </a>
                           </div>
                        </div>
                        {/* <!-- Award End--> */}
                        
                        {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="#awards">
                              <img src="assets/img/books/by-him-7.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/by-him-7.jpg 500w, assets/img/books/by-him-7.jpg 800w, assets/img/books/by-him-7.jpg 1080w" 
                                 class="image-27" />
                              </a>
                              <div>
                                 <p style={{color: '#000', fontSize: '12px'}}>
                                    कवि श्री पवन चामलिङ 'किरण' का कविता संग्रह म को हुँ? आफ्नो स्वाभाविक गतिशील परिष्कृतिका साथ निक्कै विद्रोही, प्रगतिवादी र क्रान्तिकारी कवि अनि तीनै काव्य-प्रवृतिको अझ सुढ़ृढ़ता र परिमार्जन मात्र नभइ अभिव्यक्तिमा आफ्नै शैली पाएकाछौं।
                                 </p>
                              </div>
                           </div>
                           <div class="div-block-57" >
                              <a href="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23" style={{textTransform: 'capitalize'}}>म को हुँ? 
                                    - पवन चामलिङ  'किरण'
                                 </h4>
                              </a>
                           </div>
                        </div>
                        {/* <!-- Award End--> */}
                        {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="#awards">
                              <img src="assets/img/books/by-him-8.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/by-him-8.jpg 500w, assets/img/books/by-him-8.jpg 800w, assets/img/books/by-him-8.jpg 1080w" 
                                 class="image-27" />
                              </a>
                              <div>
                                 <p style={{color: '#000', fontSize: '12px'}}>
                                    सिकिक्ममा लामो समयसम्म रहेको राजा शासन र देशसंग साटेको प्रजातन्त्र पनि जनता र जन आकांक्ष बिरुद्ध भए पछि सारा सिकिम्मेले घृणा, जात-पात अनि पुंजीवादको शिकार हुनुपरेको थियो। त्यसतो प्रतिक्रियावादी, असंबैधानिक र सिकिक्मे बिरोधी शक्ति/शासकको बिरोधमा खडा हुंदा वहाँ ( लेखक/पवन चाम्लिङ) लाई पार्टी मात्रै नभएर मन्त्री पदबाट निस्कासित गरिएको थियो। सिकिक्मे माथि सत्ताको आड लिएर भएको अत्यचार, शोषण र दमनले सिकिक्ममा प्रजातन्त्र थिएन भन्ने बुझिरहेथ्यो। निस्कासन पछि वहां प्रति पटक-पटक पुलिस लगाइ रेड् गराइयो र फर्जी मुद्दा (टाढा) लगाइएका थिए।जसले गर्दा वहाँ भुमिगत हुनुपरेको थियो। त्यस बखत भएको सम्पूर्ण घटना र विवरण लिएर वहाँले यस किताबमा पेश गर्नु भएको छ।

                                 </p>
                              </div>
                           </div>
                           <div class="div-block-57" >
                              <a href="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23">प्रजातान्त्रिक आन्दोलनको आत्मा- संर्घष</h4>
                              </a>
                           </div>
                        </div>
                        {/* <!-- Award End--> */}
                        
                         {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="#awards">
                              <img src="assets/img/books/by-him-1.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/by-him-1.jpg 500w, assets/img/books/by-him-1.jpg 800w, assets/img/books/by-him-1.jpg 1080w" 
                                 class="image-27" />
                              </a>
                              
                           </div>
                           <div class="div-block-57" >
                              <a href="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23">Quest Of A Self</h4>
                              </a>
                           </div>
                        </div>
                        {/* <!-- Award End--> */}
                        
                        
                         {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="#awards">
                              <img src="assets/img/books/by-him-3.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/by-him-3.jpg 500w, assets/img/books/by-him-3.jpg 800w, assets/img/books/by-him-3.jpg 1080w" 
                                 class="image-27" />
                              </a>
                            
                           </div>
                           <div class="div-block-57" >
                              <a href="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23">Excavating Self</h4>
                              </a>
                           </div>
                        </div>
                        {/* <!-- Award End--> */}
                     </div>
                      
                      
                      
                      
                      
                      
                      
                      
                      
                      
                      {/* <!--Written On Him Section start--> */}
                      
                      
                     <div class="header home" style={{marginBottom: '20px'}}>
                        <a href="#" class="link-block w-inline-block">
                           <h1 class="heading-15 inline heading-underline" style={{fontSize: '32px',
                              fontWeight: '700', borderBottom: '1px dashed rgba(0, 0, 0, 0.5)',
                              color: '#333',
                              textDecoration: 'none'}}> Written On Him</h1>
                        </a>
                     </div>
                     <div class="row">
                        {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="#awards">
                              <img src="assets/img/books/sikhsa-sambandh-book.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/sikhsa-sambandh-book.jpg 500w, assets/img/books/sikhsa-sambandh-book.jpg 800w, assets/img/books/sikhsa-sambandh-book.jpg 1080w" 
                                 class="image-27" />
                              </a>
                              <div>
                                 <p style={{color: '#000', fontSize: '12px'}}>प्रस्तुत पुस्तक शिक्षा सम्बन्धमा पवन चाम्लिङ-उनले २०१५-२०१६ साल भित्रमा बिभिन्न स्कूल र कलेजहरूमा गएर बिद्यार्थीहरु सँग गरेका प्रत्येक्षा- संवाद र अन्तर्क्रियाहरूमा आधारित उनका प्रमुख सन्देशहरूको संगालो हो। यस पुस्तकमा उनले वर्तमान शिक्षा प्रणाली, विश्व परिप्रेक्ष्यमा शिक्षाको एैतिहासिक रूपरेखा र सिकिक्ममा शिक्षाको शुरूवात देखि अहिलेसम्मको सिंहावलोकन अनि रास्ट्रिय र अन्तराष्ट्रिय सन्दर्भमा शिक्षाको अवस्थाको तुलनात्मक विश्लेषण, शिक्षाको परिभाषा, शिक्षक र समाज, शिक्षा र शिष्य, शिक्षा र शिक्षक आदि विभिन्न आयामहरूमा आफ्ना समेत विचारहरू समेटेर सिकिक्मे विद्यार्थीहरूको अन्तर्चेतनालाई जगाउने काम गरेका छन्।</p>
                              </div>
                           </div>
                           <div class="div-block-57" >
                              <a href="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23 buttonhover" >शिक्षा सम्बन्धमा पवन चामलिङ </h4>
                              </a>
                           </div>
                        </div>
                        {/* <!-- Award End--> */}
                        
                        {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="#awards">
                              <img src="assets/img/books/on-him-5.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/on-him-5.jpg 500w, assets/img/books/on-him-5.jpg 800w, assets/img/books/on-him-5.jpg 1080w" 
                                 class="image-27" />
                              </a>
                              <div>
                                 <p style={{color: '#000', fontSize: '12px'}}>
                                    यह किताब उस आवाज की दास्तान है जिसने दबी,कुलीन, सहमी,लड्खडाती अवाज को उठाया अौर उन्हें रोशन किया।
                                 </p>
                              </div>
                           </div>
                           <div class="div-block-57" >
                              <a href="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23">अवाज की आवाज पवन चामलिङ</h4>
                              </a>
                           </div>
                        </div>
                        {/* <!-- Award End--> */}
                        {/* <!-- Award Start --> */}
                       


                        {/* <!-- Award End--> */}
                        {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="#awards">
                              <img src="assets/img/books/on-him-10.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/on-him-10.jpg 500w, assets/img/books/on-him-10.jpg 800w, assets/img/books/on-him-10.jpg 1080w" 
                                 class="image-27" />
                              </a>
                              <div>
                                 <p style={{color: '#000', fontSize: '12px'}}>
                                   डा. पवन चामलिङ- डम्बर कुमार गिरी
वास्तवमा डा. पवन चाम्लिङ एक दुर दर्शी विकास पुरूष हुनुहुन्छ। जनताको हितको खातिर मात्र क्रान्ति गर्ने क्रान्तिपुरूष हुनुहुन्छ। सानो राज्य र स्रोत र साधनको कमी हुँदाहुँदै पनि अकल्पनीय प्रगतिहरू हुनु एैतिहासिक छलांग मार्नु हो। तसर्थ डा. पवन चाम्लिङले राजनैतिक, आर्थिक, सामाजिक लगायत विविध क्षेत्रमा पुर्याउनु भएको योगदान चिरस्थायी बनोस् भन्ने उद्देश्य यस पुस्तकमा रहेको छ।
                                 </p>
                              </div>
                           </div>
                           <div class="div-block-57" >
                              <a href="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23">डा पवन चामलिङ</h4>
                              </a>
                           </div>
                        </div>
                        {/* <!-- Award End--> */}
                        
                       
                        {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="#awards">
                              <img src="assets/img/books/on-him-9.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/on-him-9.jpg 500w, assets/img/books/on-him-9.jpg 800w, assets/img/books/on-him-9.jpg 1080w" 
                                 class="image-27" />
                              </a>
                              <div>
                                 <p style={{color: '#000', fontSize: '12px'}}>
                                    यस सङ्कलबाट डा.पवन चामलिङ र उनको सिद्धान्त, नीति, दर्शन र सपनालाई प्रस्तुत गरिएको छ। 
                                
                                 </p>
                              </div>
                           </div>
                           <div class="div-block-57" >
                              <a href="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23">डा. पवन चामलिङ:
                                    विकल्प हीन नेताा
                                 </h4>
                              </a>
                           </div>
                        </div>
                        {/* <!-- Award End--> */}
                        {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="#awards">
                              <img src="assets/img/books/on-him-8.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/on-him-8.jpg 500w, assets/img/books/on-him-8.jpg 800w, assets/img/books/on-him-8.jpg 1080w" 
                                 class="image-27" />
                              </a>
                              <div>
                                 <p style={{color: '#000', fontSize: '12px'}}>
                                    राजनीतिलाई सफल रूपमा जनताको सेवा मान्ने अनि भारतीय नेपाली साहित्य र संस्कृतिमा पुर्याएको निरन्तर योगदान स्तुत्य  छ। यस्ता व्यक्तित्वका धनी पवन चामलिङसँग साहित्यकार म
                                    हेश प्रसाईंले विभिन्न समयमा गर्नुभएका अन्तरङग यस 'अन्तरङग:श्री पवन चामलिङमा' समावेश गरिएको छ।
                                 </p>
                              </div>
                           </div>
                           <div class="div-block-57" >
                              <a href="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23">अन्तरङग  | श्री पवन चामलिङ | सम्पादक- कृष्णा भुसाला
                                 </h4>
                              </a>
                           </div>
                        </div>
                        {/* <!-- Award End--> */}
                        
                        {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="#awards">
                              <img src="assets/img/books/on-him-15.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/on-him-15.jpg 500w, hassets/img/books/on-him-15.jpg 800w, assets/img/books/on-him-15.jpg 1080w" 
                                 class="image-27" />
                              </a>
                              <div>
                                 <p style={{color: '#000', fontSize: '12px'}}>
                                    एसडीएफ सरकारले समावेशी विकासको अवधारणा तथा उद्धेश्यलाई सबै योजना तथा कार्यक्रमहरूको केन्द्रमा राख्दै नवान्वेषी, दिगो तथा गुणात्मक विकासको सुसूचित गरिएको छ।
                                 </p>
                              </div>
                           </div>
                           <div class="div-block-57" >
                              <a href="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23">अथक संघर्ष
                                    सार्थक उपलब्धि
                                    रोबिन साम्पाङ, सन्तोष सुब्बा
                                 </h4>
                              </a>
                           </div>
                        </div>
                        {/* <!-- Award End--> */}
                        {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="#awards">
                              <img src="assets/img/books/on-him-20.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/on-him-20.jpg 500w, assets/img/books/on-him-20.jpg 800w, assets/img/books/on-him-20.jpg 1080w" 
                                 class="image-27" />
                              </a>
                              <div>
                                 <p style={{color: '#000', fontSize: '12px'}}>
                                    It is written on one of the most Visionary, compassionate and successful Chief Ministers India has ever produced but also on one of the fastest developing states of India with the least environmental cost. There is hardly any other state of India that can match the vision and action taken by Sikkim towards achieving the sustainable development Goals. The sustainable development Goals that Sikkim has set it's eyes on are viable because it is based on the principles of inclusive development led by visionary leadership.
                                 </p>
                              </div>
                           </div>
                           <div class="div-block-57" >
                              <a href="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23" style={{textTransform: 'capitalize'}}>Pawan Chamling Champion Of Social Justice</h4>
                              </a>
                           </div>
                        </div>
                        {/* <!-- Award End--> */}
                        
                       
                       
                       
                        
                       
                        
                        {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="#awards">
                              <img src="assets/img/books/no-author-7.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/no-author-7.jpg 500w, assets/img/books/no-author-7.jpg 800w, assets/img/books/no-author-7.jpg 1080w" 
                                 class="image-27" />
                              </a>
                              <div>
                                 <p style={{color: '#000', fontSize: '12px'}}>
                                    A brief history on SDF party and it's Leader Shri Pawan Kumar Chamling. Compilation of different press articles from 1992-2003 about the Chamling's underground movement for the restoration of freedom and democracy in Sikkim and to strive a political process with sole aim of helping the sikkimese people to attain their democratic rights as enshrined in the Constitution. For such, the Sikkim Democratic Front was founded and which form a new government in 1994. Critical steps taken by Shri Chamling on Sikkim developmental goals is recorded in this book
                                 </p>
                              </div>
                           </div>
                           <div class="div-block-57" >
                              <a href="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23" style={{textTransform: 'capitalize'}}>PAWAN CHAMLING
                                    In Print Media
                                 </h4>
                              </a>
                           </div>
                        </div>
                        {/* <!-- Award End--> */}
                       
                        {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="#awards">
                              <img src="assets/img/books/on-him-18.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/on-him-18.jpg 500w, assets/img/books/on-him-18.jpg 800w, assets/img/books/on-him-18.jpg 1080w" 
                                 class="image-27" />
                              </a>
                              <div>
                                 <p style={{color: '#000', fontSize: '12px'}}>
                                    युग-युगान्तरसम्म श्री पवन चामलिंङप्रतिको जनताको श्रद्धा र माया यिनै कविताबाट बाँचिरहनु भन्ने उद्देश्यले विभिन्न पत्र-पत्रिकामा प्रकाशित एक सय पैंतीसवटा कविताहरू सङ्कलन गरिएको पुस्तक हो।
                                 </p>
                              </div>
                           </div>
                           <div class="div-block-57" >
                              <a href="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23" style={{textTransform: 'capitalize'}}>
                                 पवन चामलिङ माथि अभिव्यक्त कविताहरू
                                 (कविता संग्रह) 
                                 </h4>
                              </a>
                           </div>
                        </div>
                        {/* <!-- Award End--> */}
                       
                        {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="">
                              <img src="assets/img/books/on-him-14.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/on-him-14.jpg 500w, assets/img/books/on-him-14.jpg 800w, assets/img/books/on-him-14.jpg 1080w" 
                                 class="image-27" />
                              </a>
                              <div>
                                 <p style={{color: '#000', fontSize: '12px'}}>
                                    भारतमा विलय पछि तीनवटा प्रजातान्त्रिक सरकारहरू चले। ती सरकारहरू कसरी चले, त्यो इतिहाससँग छ। ती सरकारहरूपछि सिक्किमको सरकार सञ्चालन गर्ने जिम्मेवारी एसडीएफ पार्टी अध्यक्ष तथा पूर्व मुख्यमन्त्री पवन चामलिंङले नेतृत्व दिएका थिए। अग्रवर्ती सरकारका नेतृत्वपंक्तिले सरकारलाई कसरी सञ्चालन गरे र उनीहरुबाट विरासतमा सरकार सञ्चालन गर्ने अभिभावक आफ्नो हातमा लिएपछि एसडीएफ पार्टी र यस पार्टीका प्रमुख पवन चामलिंङले सरकारलाई कसरी सञ्चालन गरे र उनले सरकार सञ्चालन गरेका दुइ दशकको अवधिभित्र सिक्किम र सिक्किमेली जनताले आफ्नाअघि के कस्ता मौलिक परिवर्तनहरू देख्न, भोग्नपाए सोहि कुरा यो पुस्तकमा लेखिएको छ।
                                 </p>
                              </div>
                           </div>
                           <div class="div-block-57" >
                              <a href="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23" style={{textTransform: 'capitalize'}}>
                                 सिकिम्मेली समाजको रूपान्तरण 
                                 र पवन चामलिङ
                                 </h4>
                              </a>
                           </div>
                        </div>
                        {/* <!-- Award End--> */}
                        
                        {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="">
                              <img src="assets/img/books/on-him-4.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/on-him-4.jpg 500w, assets/img/books/on-him-4.jpg 800w, assets/img/books/on-him-4.jpg 1080w" 
                                 class="image-27" />
                              </a>
                              
                           </div>
                           <div class="div-block-57" >
                              <a href="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23">पवन चामलिङ : ज़मीन से जुड़े एक राजनीतिज्ञ का सफ़रनामा</h4>
                              </a>
                           </div>
                        </div>
                        {/* <!-- Award End--> */}
                        
                        {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="">
                              <img src="assets/img/books/on-him-1.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/on-him-1.jpg 500w, assets/img/books/on-him-1.jpg 800w, assets/img/books/on-him-1.jpg 1080w" 
                                 class="image-27" />
                              </a>
                             
                           </div>
                           <div class="div-block-57" >
                              <a href="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23">पवन चामलिङ : व्यक्ती र कृति</h4>
                              </a>
                           </div>
                        </div>
                        {/* <!-- Award End--> */}
                        
                        
                        {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="">
                              <img src="assets/img/books/on-him-3.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/on-him-3.jpg 500w, assets/img/books/on-him-3.jpg 800w, assets/img/books/on-him-3.jpg 1080w" 
                                 class="image-27" />
                              </a>
                             
                           </div>
                           <div class="div-block-57" >
                              <a href="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23">सिक्किम : बिकास को चामलिङ मोडल कृति</h4>
                              </a>
                           </div>
                        </div>
                        {/* <!-- Award End--> */}
                        
                        
                        {/* <!-- Award Start --> */}
                        <div class="col-md-3 col-xs-6 single-award">
                           <div class="date" style={{position: 'relative'}}>
                              <a href="">
                              <img src="assets/img/books/on-him-6.jpg" alt=""
                                 sizes="100vw" srcset="assets/img/books/on-him-6.jpg 500w, assets/img/books/on-him-6.jpg 800w, assets/img/books/on-him-6.jpg 1080w" 
                                 class="image-27" />
                              </a>
                              
                           </div>
                           <div class="div-block-57" >
                              <a href="/u/booklets" class="div-block-58 w-inline-block" style={{textDecoration: 'none', alignItems: 'center'}}>
                                 <h4 class="heading-23">भूमिका भूमिकामा पवन चामलिङ</h4>
                              </a>
                           </div>
                        </div>
                        
                        
                     </div>
                     
                     
                  </div>








                                    </div>
                       

                                <Footer2 />

                        </div>
                    </div>
                    
                </main>

            </>
    );

};

export default Books;