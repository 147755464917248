import React from 'react';

const InfrastructureBody = () => {
    return(
        <>
          <div class="container section-margin">
            <div class="row">
                <div class="good-gov-inner-page-container">
                    
                    
                    <ul>
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Roads: </span></h4>
                            <p>In 1993, Sikkim had a road network of 1800 km which increased to 10,000 km by 2019.
</p>
                            <p>
                                The mission to provide housing for the people did not begin with CMRHM. In fact it started right in 1995. The rural housing scheme began in 1995 and 30 GCI sheets and Rs 20,000 were distributed to the public. These benefits were distributed to over 1 lakh homes. After this, a program began for building houses for the people with GCI roofs, totalling an amount of 3 lakhs each. The program then evolved to building houses with brick walls and GCI sheets at 5 lakhs. Over 30,000 houses were built under the 3 and 5 lakh schemes. The SDF party began the work of building pucca houses for the poor right since 1995.

                            </p>

                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Alternative national highway:  </span></h4>
                            <p>A national highway of 250 km which originates at NH-31C near Bagrakote and ends at Gangtok was to be built as an alternative highway to NH-10. The project was approved by the Centre government in 2015.

                            </p>


                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Railway: </span></h4>
                            <p>Rangpo Railway station is to be constructed at Rangpo as part of the Sevoke-Rangpo Railway Line. The railway station will consist of three platforms and four lines upon completion. The work for the project began in 2017. 

</p>


                        </li>
                        
                        
                                                <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Airport :  </span></h4>
                            <p>Sikkim’s first airport – Pakyong Greenfield Airport – was inaugurated on 24th September 2018 by Narendra Modi, Prime Minister of India. The commercial flight operations began on 4th October 2018. 

</p>
</li>

                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Power : </span></h4>
                            <p>The Sikkim government undertook an ambitious project of harnessing the potential for power generation through hydro power projects in Sikkim. The largest of the power projects is the Teesta Stage III project of 1200 MW. This is the largest power project in India commissioned in PPP mode. Under SDF Government, 100% electrification in rural as well as urban areas of Sikkim was accomplished.

</p>

                        </li>
                        



                        
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Beautification of Towns:  </span></h4>
                            <p>The SDF government undertook the beautification and upgradation of each and every town in Sikkim. Some well celebrated examples are the beautification of MG Marg, Jorethang with the adjoining Green Park which houses India’s biggest water fountain, and Namchi Central Park. 

</p>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Administrative Infrastructure: </span></h4>
                            <p>Several new administrative buildings have also been built under Pawan Chamling’s leadership to ensure delivery of services to the public. Some examples are:
</p>                            
                            <ul>
                                <li>
                                    <p style={{fontWeight: '700'}}>Tashiling Secretariat</p>
                                </li>
                                
                                <li>
                                    <p style={{fontWeight: '700'}}>Samman Bhawan</p>
                                </li>
                                
                                <li>
                                    <p style={{fontWeight: '700'}}>Manan Kendra</p>
                                </li>
                                
                                <li>
                                    <p style={{fontWeight: '700'}}>Chintan Bhawan</p>
                                </li>
                                
                                <li>
                                    <p style={{fontWeight: '700'}}>Tourism Office</p>
                                </li>
                                
                                <li>
                                    <p style={{fontWeight: '700'}}>Forest Department</p>
                                </li>
                                
                                <li>
                                    <p style={{fontWeight: '700'}}>Soochana Bhawan</p>
                                </li>
                                
                                <li>
                                    <p style={{fontWeight: '700'}}>Gram Vikas Bhawan</p>
                                </li>
                                
                                <li>
                                    <p style={{fontWeight: '700'}}>Udhyog Bhawan</p>
                                </li>
                                
                                <li>
                                    <p style={{fontWeight: '700'}}>New Raj Bhawan</p>
                                </li>
                                
                                <li>
                                    <p style={{fontWeight: '700'}}>Sadar Police Station</p>
                                </li>
                            </ul>

                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Sports Infrastructure:  </span></h4>
                            <p>
                                Barely seven months after forming the government in 1994, Pawan Chamling created a new department – Department of Sports and Youth Affairs. Under this department, several leaps have been made in the sports sector. In particular, the sports infrastructure has proliferated across the state.

                            </p>
                            <ul>
                                <li>
                                    <h4 style={{color: '#000'}}>District level stadiums: </h4>
                                    
                                    <ul>
                                         <li>
                                            <p><span style={{fontWeight: '700'}}>Palzor Stadium</span> was upgraded to an international turf football field and inaugurated by President of India Dr APJ Abdul Kalam on 22nd September 2005. The work was imitated on 24th July 1998 via a High Power State Committee constituted by the government. </p>
                                        </li>
                                        
                                        <li>
                                            <p><span style={{fontWeight: '700'}}>Bhaichung Stadium, Namchi</span></p>
                                        </li>
                                        
                                        <li>
                                            <p><span style={{fontWeight: '700'}}>Mangan Stadium</span></p>
                                        </li>
                                        
                                        <li>
                                            <p><span style={{fontWeight: '700'}}>Soreng Stadium </span> </p>
                                        </li>
                                    </ul>
                                </li>
                                
                               <li>
                                   <h4 style={{color: '#000'}}>Playgrounds at all sub division level</h4>
                               </li>
                               
                               <li>
                                   <h4 style={{color: '#000'}}>Sports Academy Namchi</h4>
                               </li>
                               
                               <li>
                                   <p><span style={{fontWeight: '700'}}>Namchi Indoor Stadium/Multipurpose Community hall - </span> inaugurated on 8th Sep 2005.

                                    </p>
                               </li>
                               
                               <li>
                                    <p><span style={{fontWeight: '700'}}>Sports Complex at Khel Gaon </span> Reshithang. Foundation stone laid on 22nd April 2008.
                                    </p>
                               </li>
                               
                               <li>
                                   <p>Multipurpose indoor stadiums in all four districts
</p>
                               </li>
                               
                               <li>
                                   <p>Archery complex, Gangtok</p>
                               </li>
                               
                               <li>
                                   <p>Under Panchayat Yuva Krida aur Khel Abhiyan (PYKKA), Sikkim government constructed and improved 261 playgrounds – 166 in Gram Panchayat Units and 95 in Zilla Panchayat Units. Of these, 239 were upgraded and 22 were new.
</p>
                               </li>
                                
                                
                            </ul>
                            
                           

                        </li>
                        
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Shopping Complexes and Taxi Stands:   </span></h4>
                            <ul>
                                <li>
                                    <p>Singtam Lal Bazaar Shopping Centre</p>
                                </li>
                                
                                <li>
                                    <p>Namchi Taxi stand cum shopping centre</p>
                                </li>
                                
                                <li>
                                    <p>Gangtok Lal Bazaar and Kangchenjunga complex
</p>
                                </li>
                                
                                <li>
                                    <p>Jorethang Taxi Plaza</p>
                                </li>
                                
                                <li>
                                    <p>Geyzing Taxi Plaza
</p>
                                </li>
                                
                                <li>
                                    <p>Mangan Taxi Plaza</p>
                                </li>
                                
                                <li>
                                    <p>Soreng Taxi Plaza</p>
                                </li>
                                
                                <li>
                                    <p>Rabongla Taxi Plaza</p>
                                </li>
                            </ul>
                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Ropeways:  </span></h4>
                            <ul>
                                <li>
                                    <p>Deorali to Tashiling Secretariat. This was the first ropeway in the Northeast
</p>
                                </li>
                                
                                <li>
                                    <p>Changu lake to Hilltop</p>
                                </li>
                                
                                <li>
                                    <p>Namchi to Samdruptse</p>
                                </li>
                                
                                <li>
                                    <p>Dhappar to Bhaley Dhunga, Yangang </p>
                                </li>
                                
                                <li>
                                    <p>
                                        Kyongsa to Sanga Choeling Gumpa, Pelling (under construction)
                                    </p>
                                </li>
                            </ul>

                        </li>
                        
                        
                      
                        
                        
                    </ul>
                </div>
            </div>
          </div>
        </>
    );
};

export default InfrastructureBody;