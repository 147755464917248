import React from 'react';

const InstitutionHeader = () => {
    return(
        <>
            <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                    <div class="container" style={{paddingBottom: '50px'}}>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                    <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                        data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                       Institution
                                    </h2>

                                    <p class="economy-page-header-desc" style={{fontSize: '1rem', fontWeight: '500', maxWidth:'100%', color:'#000', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px'}}>
                                    The Chamling led government has made a strong effort to preserve the culture and history of Sikkim through institutions like the Sikkim State Museum and the Nepali Literature and Cultural Research Centre, Geyzing. Each community’s culture has been preserved through such institutions of research and study. The upcoming Gyan Mandir, which will house a large collection of books and documents for research, will serve as an intellectual hub for generations to come. 

                                    </p>


                                </div>
                            </div>

                        </div>
                    </div>

                </section>

                <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/good-governance/inside-page/Institution.png" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                </div>
                </header>
        </>
    );
};

export default InstitutionHeader;