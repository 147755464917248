import React from 'react';

const EconomyHeader = () => {
    return(
        <>
            <section class="intro-about section-margin" style={{marginBottom: '0px'}}>
                    <div class="container" style={{paddingBottom: '50px'}}>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="intro-content-text" style={{paddingTop: '90px'}}>

                                    <h2 data-dsn-grid="move-section" data-dsn-move="-30" data-dsn-duration="100%"
                                        data-dsn-opacity="1.2" data-dsn-responsive="tablet" style={{color:'#000', textAlign: 'center'}}>
                                       Economy
                                    </h2>

                                    <p class="economy-page-header-desc" style={{fontSize: '1rem', fontWeight: '500', maxWidth:'100%', color:'#000', textAlign: 'center', paddingLeft: '80px', paddingRight: '80px'}}>
                                    Under Pawan Chamling’s leadership, Sikkim saw the steepest fall in poverty, exemplary rise in GSDP and State Revenue, as well as a period of industrialization with major multinational companies setting up their manufacturing centres in Sikkim. The focus of the SDF’s economic policies has been to build the rural economy, at the same time making Sikkim an attractive place for major industry players to invest. The unparalleled economic growth seen under Pawan Chamling’s leadership has led to a rise in Human Develop Index which shows growth in standard of living.
                                    
                                    </p>


                                </div>
                            </div>

                        </div>
                    </div>

                </section>

                <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/good-governance/inside-page/Economy.png" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                  

                </div>
            </header>
        </>
    );
};

export default EconomyHeader;