import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr6Feb2022 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/6Feb.jpg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>06 Feb, 2022</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}> Former Chief Minister and the President of the SDF party Shri Pawan Chamling has expressed his anguish over acute shortage of teachers in government schools due to the discontinuation of the services of the ad hoc teachers. Situation is so bad that night guards and OFOJ employees are taking classes in some government schools. The SDF government appointed over 9000 teachers through regular interviews but the SKM government hasn’t been able to announce the results of one interview. The new policy is not fair for meritorious candidates as they have to wait for 8 years for regularisation. He has conveyed his heartfelt sympathies with students who are struggling with shortage of teachers and those teachers who lost their jobs and have been made to wait for interview results so long.
                            
                            

                            
                            </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                   “ Q. Ad hoc teachers who have requested the government to reappoint them since June of last year were asked to sit for interviews. The interviews were finally conducted in November/December. Interviews for primary teachers have still not been held. The results for interviews already completed have not been announced. Students in many schools have a huge teacher crisis. Even night guards and OFOJ employees have been given classes to conduct. But in a press conference, the SKM party blamed the situation on the reasoning that there was no policy in place, which they have now done. They claimed that Ad hoc teachers had been working for 23 years without being regularized. What would you say about the turmoil in the education department?
                                </p>

                               

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span>There is a saying in Nepali – a clumsy dancer blames his poor dancing on the ground, calling it crooked. There is another saying – an ungenerous grandmother observes Sunday as a restrictive day for donating. During the SDF government, we conducted regular selection exams and interviews for teachers and hundreds of teachers working on an ad hoc basis were brought on as regular teachers. Anyone with the required qualifications could apply through exams. A total of over 9000 regular teachers were appointed during our rule. We annually appointed teachers on an ad hoc basis to meet the instant requirements of the department and later, teachers who completed three years of service on an ad hoc basis were regularized. Over 4000 ad hoc teachers’ job were regularized. We did not discontinue anyone’s jobs during our governance. Ad hoc teachers never lost their jobs as they are losing them today. Let me also remind the people that over 95% of college teachers (assistant professors, associate professors) were appointed during our governance. Think of all the job opportunities that were created after universities were established in Sikkim.

                                </p>

                                <img class="w-100" src="assets/img/pressrelease/6Feb.jpg" alt="" />
                                <br />
                                <br />

                                <p style={{color: '#000'}}>
                                  The present policy formulated by the SKM government has made it mandatory for all new teachers to waste eight precious years. Shouldn’t the HRDD conduct at least yearly interviews for regular teachers? There will be no appointment of regular teachers in Sikkim for eight years? What a cruel and foolish policy! What an injustice to schools and meritorious teacher candidates!

                                </p>
                                 <p style={{color: '#000'}}>
                                   We also chalked out promotion avenues for teachers guarded by clear-cut norms. For example, graduate teachers could sit for the position of Head Master after completing five years in their job. High School Head Masters could apply for a promotion to Deputy Director or Principal. School Principals could apply for a promotion to Joint Director. PGT could apply for the post of principal, etc. We also fixed the scale for primary school HM.
                                </p>
                                 <p style={{color: '#000'}}>
                                    The SDF government raised the teachers’ pay scales making them the highest paid teachers in India. For example, the Primary Teacher scale is higher than the equivalent LDC post in other departments. Graduate teachers, PGT, HM and Principal’s salaries are higher than their equivalent posts in other departments.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                 This was done to make teaching a special, dignified and attractive profession. Sikkim should never forget their huge contribution to the overall development of Sikkim. Our progress is largely because of their hard work. I take this opportunity to congratulate and thank them for taking Sikkim’s literacy rate to India’s highest at 96.75 %. Sikkim also became the best performing state in education in 2016.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   I am saddened to see the humiliation meted out to the teachers of Sikkim. These young qualified Sikkimese people whose ad hoc teacher’s jobs have been discontinued in the name of a new policy are our children. They want to serve our schools. When they asked for their rights, they were chased away. Other groups of teachers were stirred up against them. Look at the condition of schools today. Students are struggling without teachers. Night guards and OFOJ employees are filling in for teachers. Able and qualified young people are asking the government to send them to those schools. And the HRDD has no courage to face the teachers. After telling hundreds of lies, they send out SKM party cadres to defend the department. Shame! Shame on the SKM government chief. Shame on the HRDD minister. Shame on the HRDD secretary. If there is any moral sense left, the HRDD Minister and Secretary should resign and let more honest and efficient people handle the situation.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   The government can appoint ad hoc teachers. The government can appoint regular teachers duly following the norms. The government can increase the salary of ad hoc teachers. But this government cannot do these things. All they can do is harass teachers and students and pit one group of teachers and students against the other. This government can expel students. They can arrest vocal citizens. They can pelt stones at their opponents. They can beat their own minister and MLA. They are endowed with all the destructive powers and zero constructive power.

                                </p>
                                
                                
                                
                                <p style={{color: '#000'}}>
                                    I had hoped that the SKM government would learn from our innovative schemes like the Chief Minister Meritorious Scholarship Scheme 2009 under which nearly 1000 students from government school students got the chance to study in the country’s renowned schools. I have followed their success with my chest swelling in pride. The children of poor Sikkimese parents have now been studying with the children from privileged families in the country. This scheme gave hope to all poor students that nothing is impossible. Sikkim will ultimately benefit from them. Under the Chief Minister’s Free Scholarship scheme 2011, Sikkimese children have been able to study in the world’s top 20 universities free of cost. We started a soft Education loan 2003, Comprehensive Education Loans 2007 and Free Coaching for UPSC exams to benefit Sikkimese students. But the SKM government lacks the vision even to continue these schemes, let alone start new such schemes.
                                    </p>
                                    
                                    <p style={{color: '#000'}}>
                                        Now we know why the SKM government was distributing TVs instead of laptops. The government knew that they would not be able to send teachers to fill all the vacant positions. But students can now entertain themselves watching TV. The government is busy making weird appointments such as a lab attendant in Jorethang Sr Sec School being promoted to AEO. A medical doctor has been made a bureaucrat in the Home department. We will soon see school peons being promoted to a Medical officer, an LDC becoming an HSO in a police station and teachers being given the responsibility of an engineer! Sikkim is mutely watching these dramas as democracy has been kidnapped by the government.
                                    </p>
                                <p style={{color: '#000'}}>
                                    My heart goes out to all the school children who have been going through this terrible Covid pandemic and teacher shortage. I am also thinking of the hundreds of those young Sikkimese who have been removed from their jobs as ad hoc teachers. Your losses are our losses. We will do our best to provide justice to our children and secure their future.”
                                    </p>

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr6Feb2022;