import React from 'react';

const TourismBody = () => {
    return(
        <>
                      <div class="container section-margin">
            <div class="row">
                <div class="good-gov-inner-page-container">
                    
                    
                    <ul>
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Growth in Tourism: </span></h4>
                            <p>Due to the thrust of the SDF government on tourism with the development of pilgrimage, cultural and unique tourist centres, the annual influx of domestic tourists into Sikkim more than doubled from 550,000 to 1,374,000 between 2011 to 2017.

</p>
                            
                        </li>
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>Global Recognition::  </span></h4>
                            <ul>
                                <li>
                                    <p><span style={{fontWeight: '700'}}>New York Times:</span> Sikkim ranked number 17 in the “52 places to go in 2017” by the New York Times.
</p>
                                </li>
                                
                                <li>
                                    <p><span style={{fontWeight: '700'}}>Lonely Planet:</span> Sikkim named the best region to visit in 2014 by Lonely Planet.

</p>
                                </li>
                            </ul>


                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>PILGRIMAGE TOURISM: </span></h4>
                            <p>Today, Sikkim is home to some of the most picturesque religious and cultural centres in India. These places of religious significance reflect the culture of Sikkim and at the same time also serve as tourist spots that push the economy of the state. Some of major religious and cultural centres are:

</p>

                            <ul>
                                <li>
                                    <p>
                                        <span style={{fontWeight: '700'}}>Guru Padmasambhawa statue</span>
                                        at Samdruptse,  Namchi is the tallest statue of Guru Padmasambhawa at 135 ft. The foundation for the statue was laid by His Holiness the Dalai Lama on 21.10.97. It was completed and inaugurated by the then Chief Minister Pawan Chamling on 28.11.2003. 

                                    </p>
                                </li>
                                
                                <li>
                                    <p>
                                        <span style={{fontWeight: '700'}}>Char Dham </span>
                                        also known as Siddheshwar Dham, located at Solophok, Namchi, houses the replicas of all char dhams and 12 jyotirlingas in India, statue of Kirateshwar, as well as a massive statue of Shiva at 108 ft. The project began on 13.2.2005  and it was completed on 25.11.2009 with the Pran Pratishthan done by Shri Jagadguru Shankaracharya Maharaj. The Char Dham was inaugurated on 31.10.2011 by His Excellency the President of India Shri Pranab Mukherjee. Shri Jagadguru Maharaj gave the name of Chardham as “Kirateshwar Dham” during the Pran Pratishthan.

                                    </p>
                                </li>
                                
                                <li>
                                    <p>
                                        <span style={{fontWeight: '700'}}>Chenrezig  </span>
                                        statue at Sanga Choeling, Pelling, stands at 137 ft tall and it was inaugurated on 1.11.2018. The statue complex also includes a sky walk which is the first sky walk in India. The foundation stone was laid on 25.11.2009.

                                    </p>
                                </li>
                                
                                <li>
                                    <p>
                                        <span style={{fontWeight: '700'}}>Sirdi Sai Baba Mandir,  </span>
                                       Assangthang, was inaugurated by Pawan Chamling on 3.11.2013.
                                    </p>
                                </li>
                                
                                <li>
                                    <p>
                                        <span style={{fontWeight: '700'}}>Stairway to Heaven, Daramdin </span>
                                        or Rumlyang Tungrong is based on the Lepcha mythology in which Lepchas built a stairway with earthern pots to heaven or Rumlyang. The project is currently under construction. 
                                    </p>
                                </li>
                                
                                 <li>
                                    <p>
                                        <span style={{fontWeight: '700'}}>Shri Krishna Pranami Mangaldham </span>
                                        located at Bermiok Namphing, was inaugurated by Pawan Chamling, 20.1.2017.
 
                                    </p>
                                </li>
                                
                                 <li>
                                    <p>
                                        <span style={{fontWeight: '700'}}>Sarva Dharma Kendra </span>
                                        Daramdin, was inaugurated by Pawan Chamling on 15.4.2007.
    
                                    </p>
                                </li>
                                
                                 <li>
                                    <p>
                                        <span style={{fontWeight: '700'}}>Teyongshi Srijunga Singthebe </span>
                                       statue was inaugurated on 3.12.2017 by Pawan Chamling, marking the day of the 313th birth anniversary of Teyongshi Srijunga Singthebe. The statue is located at Hee Bermiok. The complex also houses a study centre and manghim, a place of worship. The foundation stone was laid on 3.12.2006.

                                    </p>
                                </li>
                                
                                 <li>
                                    <p>
                                        <span style={{fontWeight: '700'}}>Siddhi Vinayak Mandir</span>Rhenock was inaugurated on 3.10.2016. The mandir has been constructed in the land where a Radha Krishna mandir stood previously.
or Rumlyang Tungrong is based on the Lepcha mythology in which Lepchas built a stairway with earthern pots to heaven or Rumlyang. The project is currently under construction. 
                                    </p>
                                </li>
                                
                                 <li>
                                    <p>
                                        <span style={{fontWeight: '700'}}>Thakurbari Mandir</span>
                                        Gangtok, was rebuilt and upgraded to include a multipurpose hall and library in 2011.

                                    </p>
                                </li>
                                
                                 <li>
                                    <p>
                                        <span style={{fontWeight: '700'}}>Gnadak Gumpa </span>
                                       Namchi, was inaugurated on 15.1.2014.

                                    </p>
                                </li>
                                
                                 <li>
                                    <p>
                                        <span style={{fontWeight: '700'}}>Statue of Unity  </span>
                                        showcasing the statues of Thekung Tek, Nyokung Ngal and Khye Bumsa, representative of the blood brotherhood between the Lepchas and Bhutias in Sikkim, has been erected at the beginning of M.G. Marg.

                                    
                                    </p>
                                </li>
                                
                                
                                
                                
                                
                            </ul>


                        </li>
                        
                        
                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>UNIQUE TOURISM DESTINATIONS :  </span></h4>
                            <ul>
                                <li>
                                    <p>
                                        <span style={{fontWeight: '700'}}>Bhaleydhunga Sky Walk </span>
                                        in Rangang-Yangang, South Sikkim, is set to be one of the most iconic tourist attractions in India. The Sky Walk at 10,300 ft height is poised to be the tallest in Asia. The project for which the foundation stone was laid in 2013 is currently under construction. The sky walk project also includes a ropeway from Dhappar to the Bhaleydhunga cliff.

                                    </p>
                                </li>
                                
                                <li>
                                    <p>
                                        <span style={{fontWeight: '700'}}>Khangchendzonga Tourist Villa Ranka</span>
                                        East Sikkim, was inaugurated by President of India Smt Pratibha Patil on 15.4. 2010.

                                    </p>
                                </li>
                                
                                <li>
                                    <p>
                                        <span style={{fontWeight: '700'}}>Banjhankri Falls  </span>
                                       is a unique theme park near Ranka which has changed the tourism prospects of the entire area. It celebrates and spreads awareness on shamanic practices in Sikkim. It was inaugurated on 5.1.2008.

                                    </p>
                                </li>
                                
                                <li>
                                    <p>
                                        <span style={{fontWeight: '700'}}>Ethnic Culture Centre  </span>
                                       at Bariakhop, Daramdin, West Sikkim, is currently under construction. The foundation stone was laid on 28.2.2018.

                                    </p>
                                </li>
                                
                                <li>
                                    <p>
                                        <span style={{fontWeight: '700'}}>Yangang Eco-Heritage and Cultural Park’s </span>
                                       foundation stone was laid at in 2007. The park is located in Rangang Yangang village, South Sikkim.

                                    </p>
                                </li>
                                
                                
                                
                                </ul>
                        </li>

                        <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>VILLAGE TOURISM : </span></h4>
                            <p>Sikkim's growing fame as a tourist destination is largely because of the innovative policies implemented by the SDF government. The government drew up a master plan of creating over 1000 new tourist destination in Sikkim. Every village and panchayat ward is promoted under the tourism plan. Some success stories are:

</p>
                            <ul>
                                <li>
                                    <p>Indian Himalayan Centre for Adventure and Eco Tourism (IHCAE) at Chemchey, South Sikkim.</p>
                                </li>
                                
                                <li>
                                    <p>Tenzing Hillary Memorial Park, Uttarey.
</p>
                                </li>
                                
                                 <li>
                                    <p>Lam Pokhari Lake, Aritar.

</p>
                                </li>
                            </ul>

                        </li>
                        



                        
                        
                         <li style={{color: '#000', marginBottom:'20px'}}>
                            <h4><span style={{fontWeight: '700', color: '#000', marginBottom:'10px'}}>ECOTOURISM:  </span></h4>
                            <p>An Ecotourism Policy was framed in 2011 to guide tourism activities in the state keeping the protection and promotion of the state’s natural and cultural heritage at the forefront. The aim of the policy was to promote ecotourism in a sustainable manner based on Global Sustainable Tourism Criteria (GSTC), generate incentives for the local communities to manage ecotourism and establish Sikkim as the ultimate ecotourism destination. There has been a proliferation of home stay tourism in Sikkim under this policy.

</p>

                        </li>
                        
                        
                    
                        
                        
                      
                        
                        
                    </ul>
                </div>
            </div>
          </div>
        </>
    );
};

export default TourismBody;