import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr24Jan2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/CapitolHill.jpeg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>24 Jan, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and the President of the SDF party Shri Pawan Chamling answers the question about his failure to prepare alternative leadership
                           </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p> “ Q. The change of guard in the United States of America has been viewed globally in various ways. What are your thoughts on Joe Biden removing Donald Trump as the President of the World’s greatest democracy?


</p>



<p style={{color: '#000'}}>
    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> Before I answer the question, let me remind you of the historic “storming of the United States Capitol” on 6 January 2021. I looked upon it as the shock of my lifetime. Who in his right mind had ever thought that US citizens, allegedly egged on by their president would stoop so low to commit such an act. The country’s image built in so many years was tarnished within a few hours. The world watched in horror as the mob, supporting Donald Trump, murdered democracy in broad daylight.



</p>

<div>
    <img class="w-100" src="assets/img/pressrelease/CapitolHill.jpeg" alt="" />
    <p style={{color: '#000', fontSize:'14px'}}>
        On 6.1.2021, around 2500 supporters of the then U.S President Donald Trump attacked the Capitol Building in Washington D.C.	
    </p>
</div>

<p style={{color: '#000'}}>
    
This is a burning example of what happens when power is entrusted to the wrong leadership. This is a burning example of how a wrong leader can misuse the public mandate to destroy a democratic civilization.


</p>
 <p style={{color: '#000'}}>
    I said to myself - even a civilized and advanced country like the US can make the mistake of choosing the wrong leader as its President. This is a huge cause for concern. However, what gives the world hope is the fact that many Americans realized their mistakes, regretted their decision and amended their ways by defeating Donald Trump. 

</p>
 <p style={{color: '#000'}}>
    I believe that Joe Biden taking over the reins of the US administration as 46th President is a defining moment - not only for American history but also in world history, in some senses. The defining message from Joe Biden’s victory is simply this – this world cannot be ruled by a single idea. Exclusivist politics are destructive and outdated. The world needs inclusive politics that respect all colours, castes and creeds as equal. This gives the world hope.



</p>

<p style={{color: '#000'}}>
   Another highlight of Joe Biden’s presidency has been his instant decisions on some of the most significant policies and programs like the reversal of Trump’s decision to withdraw from the WHO, climate change accord, ending the ‘Muslim travel ban’ etc. These will have lasting impacts globally. This is true leadership. His word has value and truthfulness to it. A leader’s words must be translated into action. I appeal to all the Sikkimese leaders to learn from Joe Biden and back tall promises with solid actions.




</p>

<p style={{color: '#000'}}>
    On a personal note, I greatly admire his speeches. He may not be one of the best speakers in terms of oratory skill but I enjoy listening to him. He speaks from his heart with an unmistakable mark of honesty, sincerity, guilelessness and humility on his face. Even when many were writing him off, he spoke with unwavering confidence and when he finally won the election, he never went over the top. He is confident and grounded at all times. To me, these are the marks of great leadership.




</p>


<p style={{color: '#000'}}>
 Finally, the world must learn that even lies, injustice and prejudice can be justified if people silently accept them. Destructive ideas, if accepted by the people, can become popular ideas. That is what the USA experienced during the last four years. But thankfully, Americans didn’t miss their first opportunity to reverse their historic blunder. They democratically corrected their mistakes. I appeal to my fellow Sikkimese people to stay alert lest we end up justifying lies, injustice and prejudice by silently accepting them. We must rise up and do all it takes to oppose them and reinstate truth, justice and equality in Sikkim.”



</p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr24Jan2021;