import React from 'react';

import {Link} from 'react-router-dom';

const Footer = () => {
    return(
        <>
            <footer class="footer footer-one" style={{backgroundColor: '#3559c7',
     paddingTop: '0px', minHeight: 'auto'}}>
                <div class="container">
                    <div class="footer-links p-relative">
                        <div class="row footer-row">
                            <div class="col-md-3 dsn-col-footer">
                                <div class="footer-block">
                                    <div class="footer-logo" style={{width: 'auto', color: '#fff', fontWeight: '700'}}>
                                        Pawan Chamling
                                    </div>

                                    <div class="footer-social">
                                        
                                        <ul style={{paddingLeft: '0'}}>
                                            
                                            <li>
                                                <Link to="https://www.facebook.com/BeautifulSikkim" link="_blank" style={{color: '#fff', borderColor: '#ccc'}}>
                                                    <i class="fab fa-facebook-f" style={{marginLeft: '0px'}}></i>
                                                </Link>
                                            </li>
                                            
                                            <li>
                                                <Link to="https://www.instagram.com/pawankchamling/?hl=en" link="_blank" style={{color: '#fff', borderColor: '#ccc'}}>
                                                    <i class="fab fa-instagram" style={{marginLeft: '0px'}}></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="https://twitter.com/pawanchamling5" link="_blank" style={{color: '#fff', borderColor: '#ccc'}}>
                                                    <i class="fab fa-twitter" style={{marginLeft: '0px'}}></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="https://www.youtube.com/channel/UCbWxZ1g9vWLm16cuXXql-bA" link="_blank" style={{color: '#fff', borderColor: '#ccc'}}>
                                                    <i class="fab fa-youtube" style={{marginLeft: '0px'}}></i>
                                                </Link>
                                            </li>

                                        </ul>

                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3 col-xs-6 dsn-col-footer">
                                <div class="footer-block col-menu">
                                    <h4 class="footer-title">Navigation</h4>
                                    <nav>
                                        <ul style={{marginLeft: '0', paddingLeft: '0'}}>
                                            <li><Link to="/timeline2">Timeline</Link>
                                            </li>
                                            <li><Link to="/biography">Biography</Link></li>
                                            <li><Link to="/press-releases">Press Release</Link></li>
                                            <li><Link to="/contacts">Contact</Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>

                            <div class="col-md-3 col-xs-6 dsn-col-footer">
                                <div class="footer-block col-contact">
                                    <h4 class="footer-title">Contact</h4>
                                    <p><strong>T</strong> <span>:</span> 03592-281586</p>
                                    <p><strong>F</strong> <span>:</span>03692-280595</p>
                                    <p class="over-hidden"><strong>E</strong> <span>:</span>
                                    <Link class="link-hover" data-hover-text="pawanchamling50@gmail.com" >pawanchamling50 <br /> @gmail.com</Link>
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-3 col-xs-12 dsn-col-footer">
                                <div class="col-address">
                                    <h4 class="footer-title">Address</h4>

                                    <p>SDF Bhawan Indira Bypass <br />
                                        Gangtok<br />
                                        East Sikkim</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="copyright">
                        <div class="text-center">
                            <p>© 2022 Pawan Chamling</p>
                          
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;