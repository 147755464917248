import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import Header2 from "../../components/nav/Header2";


import Footer2 from "../../components/nav/Footer2";





const PlanningCommission = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">
                    <header>
                        <div class="header-single-post" data-dsn-header="project">
                            <div class="post-parallax-wrapper" data-overlay="3">
                                <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pHOTO04.jpg" alt=""
                                    data-dsn-header="blog" data-dsn-ajax="img" />
                            </div>
                            <div class="container">

                                <div class="inner-box m-section">
                                    <div class="post-info">
                                        <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>Yojana Bhawan, New Delhi</a>
                                        <div class="blog-post-cat dsn-link">
                                            <a href="#" style={{color: '#000'}}>April 03, 2013</a>
                                        </div>
                                    </div>

                                    <h3 class="title-box mt-10" style={{color: '#000'}}>Planning Commission Meeting</h3>
                                </div>

                            </div>

                        </div>
                    </header>


                        <div class="wrapper">

                        <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tagged with: </h5>
                                        <a href="#"><span>Lorem ipsum</span></a>
                                        <a href="#"><span>Lorem ipsum</span></a>
                                        <a href="#"><span>Lorem ipsum</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p style={{color: '#000', fontWeight: '700'}}>
                                    
                                  Hon’ble Deputy Chairman, Dr. Montek Singh Ahluwalia ji, Hon’ble Members, Hon’ble Advisers and senior officials of this august body. On behalf of the people of Sikkim, I convey my warm greetings to the Hon’ble Deputy Chairman and the distinguished Members present today.

</p>

                                

                                <p style={{color: '#000'}}>
                                    I am happy to appraise this august body about the many development milestones achieved by the State Government and share our concerns and difficulties that are unique to a  Himalayan border state like Sikkim. I humbly assure you that the outcome of this important meeting will guide us and help us carry forward in our resolve and commitment through the 12th Plan, traversing further milestones of progress and development in Sikkim.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                    The range of development strategies we have adopted during the last eighteen years are tailored to meet the special needs of the people of Sikkim, their hopes and aspirations. They are based on the constitutional provision with reference to Sikkim’s merger with the Indian mainstream in the mid 1970s. Although, we joined the planning process much  later, we are today amongst the rapidly developing states in the Country. As a landlocked Himalayan State with  international borders on three sides, we face the  severest of constraints in terms of access to available development instruments and hence, we are unable to realize our  development potential to the  fullest.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                    Therefore, we maintain that our position needs to be viewed separately and our concerns appreciated with the  required perspective. On this note, I would like to thank the Hon’ble Prime Minister and Hon’ble Deputy Chairman and his entire team at the Planning  Commission for their support and assistance extended to us in our path to develop   Sikkim in the best way and in the quickest time possible.

                                </p>

                                <img class="w-100" src="assets/img/pHOTO04.jpg" alt="" />

                                <p style={{color: '#000'}}>
                                    <a href="#" style={{color: '#000'}}>Hon’ble Sir,</a>
                                    <br/>
                                   Despite our late entry in the planning process, we have seriously taken stock of our strengths and weaknesses as well as potential areas of development  with comprehensive development programs. We have judiciously utilized all resources and institutional supports forthcoming from the Central Government to benefit the larger interest of the  people  of  Sikkim.

                                </p>
                                 <p style={{color: '#000'}}>
                                    During the last 18 years, the State Government has won more than 20 National and regional awards in various categories conferred by the Government of India and other agencies in the field of education, tourism, health, environment, security and justice. More recently, we were conferred with the prestigious IBN7 Diamond State Awards 2012 in the categories of Best State in Citizen Security, Environment, Water and Sanitation and a Special Award under India’s Best Small State category.
                                
                                </p>
                                 <p style={{color: '#000'}}>
                                   We are happy to announce that we are commissioning the 100 MW Chuzachen Hydro Electric Power Project in the third week of May which is slated to fetch us about Rs. 50 crores annually. This is in addition to the already commissioned 510 MW Teesta Stage V and 60 MW  Rangit Hydro-power project. We  believe  that  this  is  a good beginning  and there are many more to follow. The 1200 MW Teesta Urja project development is also under progress. We             are monitoring its progress along with that of other projects which are to be                  commissioned as per schedule.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Sikkim hosted the 2nd International Flower Show from 23rd to 27th February, 2013 at Saramsa Garden, Gangtok. Hon’ble Deputy Chairman is familiar with the flower show having inaugurated the first one in March 2008. Sir, you will   appreciate the State Government’s unwavering commitment to harness the natural wealth of the State on a long term and sustainable basis and to re-establish floriculture and horticulture as vibrant and sustainable economic sectors of 21st century Sikkim.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                    
                                    
                                    
                                  Education and health are our priority sectors and facilities available under them are virtually free in Sikkim. We are perhaps the only State to have earmarked                       over 20 percent of our annual allocation to the education sector.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Sikkim has recorded an impressive improvement in the literacy rate at 82.2%. The teacher pupil ratio of 1:14 is perhaps one of the best in the country. A host of educational facilities at the elementary and higher education have been provided. The new  Central University is coming up as well in the state. Apart from this, there           are 4 Private Universities that cater to higher education in the State.

                                
                                </p>
                                
                                
                                
                                <p style={{color: '#000'}}>
                                  The on-going Chief Minister’s Meritorious Scholarship Scheme, Chief Minister’s Special Merit Scholarship, Prerna Yojana, small family scheme which is aimed at promoting  academic  excellence among girl students, have helped many of our promising children from weak economic backgrounds to excel at the regional, national and international levels.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                  This past year, we also started another unique programme to sponsor Sikkimese educated youths for civil services coaching in selected coaching centres across the Country. We have sent 146 young Sikkimese students  to undergo extensive coaching in reputed  Institutes of Hyderabad, Delhi and Tamil Nadu. In times to come, we hope to see  many  candidates from Sikkim making their mark in All India Services and Central Services. Under the existing Comprehensive Education Loan scheme, a  large number of people  have availed of the interest free loan to pursue higher education in Colleges and Universities within and outside the Country. We also regularly upgrade our existing scholarship grants              for  higher education including research on various subjects.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                  We are successfully running innovative human resource development programs in the State to train our people in different trades and vocations, based on the market demand at the local level as well as at the regional level,  making people employable in the job  market within and outside the State. We started the Skill Development and Capacity  Building programme in 2003. The Directorate of Capacity Building and the State  Institute of Capacity Building are fully functional and various training programmes are being successfully held round the year with placement figures exceeding over 70% in  the hospitality sector alone.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Establishment of 41 Livelihood Schools across the State has added a new dimension of training the local youth. These schools train local youth in trades and professions ranging from carpentry to basic engineering so that the students can find gainful employment after the training.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Delivery of quality health services has always remained our priority. Over the decades, we have worked out  a  comprehensive strategy for a healthy Sikkim. Sikkim was perhaps the first State in the Country to provide free Hepatitis B vaccination to children. Infant Mortality Rate (IMR) of 26 per 1000 is much higher than the national average. The life expectancy in the State has increased by over 3 – 5 years on average as compared to 1994.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                   Over the years, we  have consistently improved our health indicators largely because of improved coverage and efficiency of the health delivery system    in the State. Launched in 2010, the Chief Minister’s Comprehensive Annual and Total Check-Up for Healthy Sikkim (CATCH) program has been designed to provide routine check-up, preventive and remedial measures on a compulsory basis annually. We expect to achieve 100 percent coverage by the end of 2013. We can boast of being the number one  State in such a preventive program in the Country.
                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                    
                                  The construction of a 575-bedded multi-specialty hospital at Gangtok is expected to facilitate people with access to super class medical facilities. The civil structure of  the                 project stands at 70% complete.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                  Sikkim with its natural beauty, diverse flora and fauna, and home to friendly and hospitable people,  has emerged as  a  premiere eco-tourism destination in the Country. Our long term goal is to transform the State into a leading domestic and international tourism destination. We aim to promote tourism as the new profession of the 21st century youth of Sikkim. The tourism industry shall have multiple impacts  by way of employment generation,  revenue generation and other cascading effects across sectors to benefit our people  including budding entrepreneurs as well as the farming community. We will continue to pursue  eco-tourism as a thrust area for development of  the State in the 12th Five Year Period.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                    
                                  We have built and completed some extraordinary projects to promote pilgrimage tourism in the State. The recently consecrated Tathagata Tsal (Buddha Park) at Rabongla will be another contribution from Sikkim to the Country in its quest for completing  the Buddhist Pilgrimage Circuit Tourism. We are promoting village tourism, rural tourism, and homestays as a means to diversify livelihood options for our people.

                                </p>
                                
                                <p style={{color: '#000'}}>
                                  In the field of environment conservation, Sikkim has created a new benchmark. Creation of smriti bans, biodiversity park, butterfly park, herbal gardens are some of our initiatives and the list goes on. We have launched the Green Mission and Ten Minutes to Earth program. We have imposed a prohibition on a large number of human activities to conserve our greenery, protect animal life and the life of many species of flora and fauna found in Sikkim. In total, the impact has been very encouraging as our forest cover has increased by over 3-4 percent over the last eighteen years. We seek to pursue our green goals as highlighted above  during this plan period, while also contributing towards the conservation of  our Himalayan eco- system. During the 12th Five Year Plan, we will further promote our green  campaign.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                  Sikkim has been regularly applauded for its green record. The Centre for Science and Environment rated Sikkim as the Greenest State through popular vote in 1999. The findings of Centre for Development Finance, Chennai has also been very encouraging. Sikkim has been rated as “most sustainable” under various parameters like reducing pressure on the environment and state’s response to maintaining the environment. We have adopted a very scientific approach towards solid waste management, regulation of water usage and discouragement of encroachment. We were honoured with the first Nirmal            Rajya award in December 2008 for achieving total sanitation coverage in Sikkim.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                  We have made an impressive headway in the promotion of Organic Farming, duly applying the concept in our agricultural activities, in horticulture, floriculture and the animal  husbandry sector. We propose to promote horticulture by increasing the area under cultivation of fruits and flowers. Irrigation facilities including rain water harvesting,  community water tanks and construction of low cost greenhouses are being taken up. We have developed a unique model of floriculture farming by adopting a cluster approach involving groups of 20 to 100 farmers. We have initiated work to develop 18 clusters of cymbidium orchids in 18 constituencies. To leverage our strength in the floriculture  sector, we plan to set up one state-of-art flower auction centre and post-harvest infrastructure in Pakyong, East Sikkim close to the upcoming Greenfield Airport.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                  We will continue to further promote these sectors during the 12th Five Year Plan. Double cropping, setting up of fruit processing industries, cold chain refrigerator and providing market linkages to the farmers would be the major focus area.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                  Sikkim has the distinct identity of maintaining gender equity and the percentage of women work force in many areas are above that of their male counterparts. They are contributing handsomely towards the State as equal development partners. We instituted a  landmark   50% reservation of seats for women under the Panchayati Raj System and today, we have women occupying 50% panchayat seats at the Gram Panchayat, Zilla Panchayat  level and in the post of Adhyakshya and Upa-Adhayakshya as per this reservation policy.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                  The Government of Sikkim’s policy to devolve 70% of the annual outlay for rural development remains the cornerstone of our development agenda. Based on this decision, the Government of  Sikkim has ensured that development initiatives are evenly distributed to fulfil Planning Commission’s goal of inclusive growth and development.
                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                  Much emphasis have been laid in implementing programmes pertaining to village employment guarantee, drinking water, electricity, sanitation, housing and rural connectivity. Innovative, pro-poor programmes and a large number of welfare schemes have revived Sikkim’s socio-cultural vibrancy and also institutionalized their rightful claim to all modern means of livelihood. Therefore, in the areas of food security for the poor and the disadvantaged, the State Government has regularly stepped up welfare measures to ensure a life of dignity and respect. For example, by raising state’s component, we have increased grants under the Old  Age Pension     scheme from Rs. 400 to Rs. 600 per month and we also provide Rs. 1000 for elderly citizens above 80 years of  age. A  Samajik  Sewa  Bhatta of  Rs. 500  per month has been introduced for all our traditional  faith healers. This has not only provided relief and due respect to people in  our society, it has helped conserve, revive and promote our cultural history from the brink  of extinction.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                  A Universal Financial Inclusion program, livelihood linked investment, preventive health  check-ups and social safety nets for vulnerable groups have also  been institutionalized. Sustained efforts over the last three decades has ensured rapid strides in  various sectors.  This has led to achievements of several milestones in the areas of environment, tourism,   energy, empowerment, sanitation, housing, electrification and rural connectivity.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                  Located in the Himalayas, we are beset with problems and shortcomings unique   to our region and geography. In a majority of cases, schemes and projects launched at the national level with particular specifications do not actually match with our ground condition. Most of our food commodities and construction materials come from the mainland States and so  the fruits of development is ploughed back to other States.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                  Connectivity has been our recurring problem and Sikkim’s hilly terrain is highly vulnerable to natural calamities. We are told that just across the border in Tibet on the Chinese side, 11 four lane  roads have been completed and 4 more roads are under construction, which are all directed towards  Sikkim. Whereas in Sikkim, we still have our old roads with little improvements and the widening  going on at a very slow pace. We, as a border state, are very concerned.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                  We are heavily dependent on our only existing 31A National Highway for all purposes. We have a limited resource base and there are limitations to that extent  caused by our geography, fragile ecology and our economic capacity. All these  issues need to be realistically accessed and examined, so that the Central Government is    able to fully appreciate our concerns and our claims.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                  I would like to flag a few areas of concern before this august gathering and share my views as follows:
                                    
                                    <ul>
                                        <li>
                                            <p style={{color: '#000'}}>
                                                The Government of India may consider 100% central funding of all the flagship schemes considering the limited resource base in the State.

                                            </p>
                                        </li>
                                        
                                        <li>
                                            <p style={{color: '#000'}}>
                                                Sikkim shares the development concerns and priorities of the rest of the nation. However some requirements of the State are unique due to its strategic location, distinctive socio-cultural ethos and limited availability of resource.

                                            </p>
                                        </li>
                                        
                                        <li>
                                            <p style={{color: '#000'}}>
                                                The plan document has highlighted the need for PPP mode in a number of  sectors and for the creation of infrastructure. However, ground realities in our  region being what they are, PPP mode will find few takers. Unless the Viability Gap Funding (VGF) guidelines are amended and made more liberal  in relation to Sikkim so as to make the projects economically viable, no  private partner would take interest in the projects.

                                            </p>
                                        </li>
                                        
                                        <li>
                                            <p style={{color: '#000'}}>
                                                The Plan contains elaborate proposals for expanding and strengthening all aspects of  the communication network including roads, air, railway and port. However, Sikkim is entirely dependent on road networks and hence, we will only  benefit marginally. Therefore, the permanent upgradation and restoration of  the National Highway 31A into a two-lane road from Sevoke in West Bengal  to Gangtok is of critical and vital importance. The other alternative highway through Rongli in East Sikkim and Chalsa to Siliguri in West Bengal needs  to be taken up at the earliest due to the fragile state of the existing National    Highway 31A. The Government of India may also expedite the construction of the road from Gangtok to Nathula border being carried out under SARDP- NE.

                                            </p>
                                        </li>
                                        
                                        <li>
                                            <p style={{color: '#000'}}>
                                                I would also like to suggest that the maintenance of assets being created requires substantial additional fund. Hence, allocation of a dedicated maintenance  fund for the hilly States is, therefore, proposed.

                                            </p>
                                        </li>
                                        
                                        <li>
                                            <p style={{color: '#000'}}>
                                                Need for flexibility of norms under Centrally Sponsored Schemes/ Central Sector Schemes is highly necessary. Some schemes are funded on 50:50%, 75:25% basis etc. Due to the lack of an adequate resource base, we find it difficult  to meet the State Share, rendering many schemes ineffective.

                                            </p>
                                        </li>
                                        
                                        <li>
                                            <p style={{color: '#000'}}>
                                                The NLCPR and NEC funding can be made more liberal to special category States  with timely release of requisite resources.

                                            </p>
                                        </li>
                                        
                                        <li>
                                            <p style={{color: '#000'}}>
                                                The projects and schemes under SPA/ACA be considered and sanctioned early along with timely release of resource so that the projects are not delayed on the ground.

                                            </p>
                                        </li>
                                        
                                        <li>
                                            <p style={{color: '#000'}}>
                                                The Rs.1000.00 crore PM’s package forms a part and parcel of the Annual Plan. As you are aware, the entire package is earmarked for specific projects post the earthquake damage of September, 2011, leaving very little room for the State Government to maneuver. Therefore, it is our earnest submission that the Planning Commission, Government of India, may kindly consider allocation of  more  funds  under Normal Central Assistance and Special Central  Assistance to the Government of Sikkim in a liberal manner so that the  momentum of overall growth and development of the State is not hampered.

                                            </p>
                                        </li>
                                        
                                        <li>
                                            <p style={{color: '#000'}}>
                                               We request that the existing norms be more flexible so that the State can tap the entire allocation under JNNURM and EAP meaningfully. In the past, the State has found it extremely difficult to tap the JNNURM funds.
                                            </p>
                                        </li>
                                        
                                        <li>
                                            <p style={{color: '#000'}}>
                                                It is not justifiable to include the North Eastern Council funding under the State Plan funding as the Council is a regional body which has been mandated to  meet the regional inter-state infrastructure gaps. The allocation under North  Eastern Council, therefore, is not based on equitable distribution but depends on the various infrastructures gaps which are to be supported in the eight  North Eastern States.

                                            </p>
                                        </li>
                                        
                                        <li>
                                            <p style={{color: '#000'}}>
                                                The Ministry of  DoNER was created as a Special Ministry to look after the problems and various gaps in the development process of the NE States. It was also created for the purpose of funding bigger projects which  could not be taken up under State Plan due to limited resources of the North Eastern States. It is therefore  requested that the NLCPR status  of  funding which is existing under the DoNER Ministry to cater to special  problems in infrastructure gaps of North Eastern States, should not be changed and included under State Plan funding.

                                            </p>
                                        </li>
                                        
                                        <li>
                                            <p style={{color: '#000'}}>
                                                During the earthquake of  September 2011, many of the Monasteries that have existed for more than 300 years and  which are  an emotional and cultural heritage  of the Buddhists in Sikkim, have not been addressed under the PM’s package  of Rs.1000 crore. The total project for conservation  and reconstruction of 6 monasteries costs Rs 40.00 crores. Out of the 6 monasteries, 4 monasteries  are located in North Sikkim, the epicenter of the earthquake. The monasteries in North Sikkim are Chawang, Chungthang, Tholung and Ringhim gumpas and the two in West  Sikkim are  Hungri and Tashiding and their reconstruction and conservation projects have been forwarded to the Ministry of Culture for funding  assistance. Unfortunately, it is learnt that it has been turned down. The Planning Commission is requested to kindly look into this emotional issue of the people of  Sikkim and provide the Rs. 40.00 crore shortfall under Special Plan Assistance.

                                            </p>
                                        </li>
                                    </ul>
                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                  As it stands today, we can well categorize Sikkim’s development saga which has crossed several milestones over the decades  starting   from its agricultural age. Until the early 1990s, the economy in Sikkim was chiefly agrarian in nature. The next stage brought about a growth in the service sector  with ecotourism and transportation making significant contributions in the GSDP growth in Sikkim.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                  During the last 10-15 years, Sikkim slowly moved from the service sector boom into a  period of industrialization with the establishment of numerous high  value low volume, eco-friendly and clean industrial units being set up in Sikkim  including the pharma units and  hydro-power projects. Now as we move into Sikkim’s fourth saga of development, we witness a growth in knowledge based innovative ventures including  innovative  industry,  innovative  tourism, innovative  agriculture and so on.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                  We are committed to push forward and further develop the State through all means and support forthcoming from the Central Government. We would require more institutional support to further design our development model that best fits our aspirations. We would need serious contemplation in terms of enhancing our revenue base, proper earmarking of our resources and creation of large number of infrastructure across the State to further catapult Sikkim into  a  high growth trajectory.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                    <a href="#" style={{color: '#000'}}>Hon’ble Sir,</a>
                                    <br/>
                                  With your support and your  due consideration of our inherent development hurdles, we are ready to walk that extra mile to make Sikkim a fully developed State and the Sikkimese people happy and prosperous. With all development inputs being designed and implemented in the State, the larger challenge now is in translating these material inputs into the overall happiness of  the people, which is the ultimate goal. Nevertheless, with your support and consideration, we remain confident that Sikkim  will continue to set new benchmarks with regard to new development goals and  higher aims  of living, thus creating the ideal citizenry that India would be proud to have and cherish forever.

                                
                                </p>
                                
                                <p style={{color: '#000'}}>
                                  With this hope and confidence in your indulgence, I request you for special consideration and seek your generosity and wisdom as always.

                                
                                </p>
                                
                               
                                
                                 <p style={{color: '#000'}}>
                                  Thank you Sir,
                                  
                                  <br />
                                    Jai Hind.
                                </p>
                                

                            </div>
                        </div>

         


                    </div>


                </div>

          

               <div class="clearfix"></div>

               <Footer2 />


                        </div>
                    </div>
                </main>

            </>
    );

};

export default PlanningCommission;