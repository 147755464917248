import React, {useEffect} from 'react';

import Header2 from "../../components/nav/Header2";


// import Footer2 from "../components/nav/Footer2";
import Footer from "../../components/nav/Footer";

// import "../goodgov.css";




const Pr31Oct2021 = () => {

    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
          var script = document.createElement('script')
          script.src = src
          script.addEventListener('load', function () {
            resolve()
          })
          script.addEventListener('error', function (e) {
            reject(e)
          })
          document.body.appendChild(script)
          document.body.removeChild(script)
        })
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScript(`${process.env.PUBLIC_URL}assets/js/plugins.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/dsn-grids.js`);
        loadScript(`${process.env.PUBLIC_URL}assets/js/custom.js`);
   
    },[]);

    return(
            <>

                <Header2 />

                <main class="main-root">
                    <div id="dsn-scrollbar">

                    <header>
                <div class="header-single-post" data-dsn-header="project">
                    <div class="post-parallax-wrapper" data-overlay="3">
                        <img id="dsn-hero-parallax-img" class="w-100 has-top-bottom" src="assets/img/pressrelease/31oCT.jpeg" alt=""
                            data-dsn-header="blog" data-dsn-ajax="img" />
                    </div>
                    <div class="container">

                        <div class="inner-box m-section">
                            <div class="post-info">
                                <a href="#" class="blog-post-date dsn-link" style={{color: '#000'}}>31 Oct, 2021</a>
                                <div class="blog-post-cat dsn-link">
                                    <a href="#" style={{color: '#000'}}>Uncategorized</a>
                                </div>
                            </div>

                            <h3 class="title-box mt-10" style={{color: '#000', fontSize:'22px'}}>  
                            Former Chief Minister and the President of the SDF party Shri Pawan Chamling has answered a question asked to him on his birthday. Responding to a question about one good thing the SKM government has done in the last two years, he has said that instead of good things, the new government has spoiled all the good things done by the SDF government. Shri PS Tamang led SKM government is replicating the Nazi government led by Hitler who forced all the German citizens to join the Nazi party and interfered and directly controlled the personal and private lives of the citizens.
                           </h3>
                        </div>

                    </div>

                </div>
            </header>


            <div class="wrapper">
                <div class="container" style={{paddingBottom: '80px'}}>
                    <div class="news-content">
                        <div class="news-content-inner">
                            <div class="News-socials-wrapper">

                                <div>

                                    
                                    
                                    <div class="cat">
                                        <h5 class="title-caption" style={{color: '#000'}}>Tags: </h5>
                                        <a href="#"><span>#OFOJ</span></a>
                                    </div>
                                </div>

                            </div>

                            <div class="post-content" style={{backgroundColor: '#fff'}}>
                            <p>
                                    “ Q. On your birthday, a question was asked to you  - "What is the 'one good thing” that the SKM government did during the last 2 years. What is your response to that?

                                </p>

                                

                                <p style={{color: '#000'}}>
                                    <span style={{fontSize:'22px', fontWeight: '700'}}>Ans:</span> On the contrary, instead of doing good things, the SKM government has spoiled all the good things we did. The SKM government has to understand that the work the SDF government did was not Pawan Chamling’s personal work. Our government’s programs, policies and schemes were for the interest and welfare of the state and the people. Sikkim’s prosperity has been possible because of its successful formulation and implementation. Everywhere in the world, a new government will continue on the good things of the previous government. But look at what they have done with Organic Farming! We showed the world that organic farming is possible. But now they are giving a wrong message to the world, saying that it is not possible. The SKM party does not realize that they are destroying the very hope of the world that the SDF had given. This is just one example. Sadly, all these great programs, laws, schemes and indicators of good governance of the SDF government have been deserted, distorted and destroyed.



                                </p>

                                <img class="w-100" src="assets/img/pressrelease/31oct2.jpg" alt="" />
                                <br/>
                                <br/>

                                <p style={{color: '#000'}}>
                                   In an advanced country like the US, the achievements of previous governments are recorded and preserved in museums. When we formed the government in 1994, there was no record of the former administrations. We sought out all the available documents from the time of the late Chogyals, the late Kazi Lhendup and the late NB Bhandari and digitalized them. We have preserved everything we could retrieve. But the first thing the SKM government did was to burn 16 truckloads of books of our achievements. Can you believe such a thing? Such a regressive mindset! The burning of books and records used to happen in the primitive ages. The SKM burning books remind me of one of those black ages of human history. They have created history again, by burning books in the 21st century. I hate to think that we are living under a 'books-burning-government'. I would like to ask a reverse question – what good things can we expect from a 'book-burning-government'?


                                </p>
                                 <p style={{color: '#000'}}>
                                   The SKM government is reversing all of the good things the SDF government had started. Look at some of these disturbing facts.
 

                                </p>
                                 <p style={{color: '#000'}}>
                                   1.     We commissioned hydropower in Sikkim. They are selling them one by one. One BAD THING.
                                   
                                   <br/><br/>
 
2.     We built one of the best hospitals in Socheyganag, the new STNM hospital. They are selling the old STNM hospital to outsiders. Another BAD thing.

<br/><br/>
 
3.     We made Sikkim the world’s first organic state. They are reversing the process. Another BAD thing.

<br/><br/>
 
4.     India’s cleanest state (Sikkim became first Nirmal Rajya in 2008) is now becoming the dirtiest state in India. Another BAD thing.

<br/><br/>
 
5.     We beautified, upgraded and preserved historic Ridge Park. They are selling sites in the park for housing. Another BAD thing.

<br/><br/>
 
6.     We started industrialization and generated employment for our people. Today, our people are losing employment and some industries are on the verge of closing down. Another BAD thing.

<br/><br/>
 
7.     We built multi-storeyed parking facilities in different locations in Gangtok and other towns of Sikkim. They started selling them. The one below the Police Headquarters has already been sold to an outsider.

<br/><br/>
 
8.     We developed Mother Dairy Farms and built a Training Centre and Directorate of Capacity Building in Karfectar. They sold the whole land to an outsider. Another BAD thing.

<br/><br/>
 
9.     We increased the forest cover by nearly 5%- a record in the country. They are mindlessly cutting down trees and exporting timber outside Sikkim. Another BAD thing.

<br/><br/>
 
10.  We gave government employment to one lakh Sikkimese people. They are removing them from their jobs. They are removing OFOJ employees from their jobs. Yet another BAD thing.

<br/><br/>
 
11.  We gave jobs openly. They are giving jobs to their relatives secretly. Another BAD thing.

<br/><br/>
 
12.  We achieved 31% economic growth which was a record in the country. Today, Sikkim’s economic growth is one of the lowest in the country and the lowest in the NE. Another BAD thing.
<br/><br/>
 
13.  We achieved a national record literacy of 97%, the country’s highest presently. We made education free up to the college level, another national record. We built the highest number of institutions for learning with a growth rate of 15% which was double the national average at 7%. We started university education in Sikkim. Today, students and teachers are feeling insecure. Shockingly, four college students have been expelled.
<br/><br />
 
14.  Sikkim’s record in women empowerment was applauded everywhere. Women were appointed as Speakers in the SLA, Ministers in the state cabinet, 50% reservation in local self-governance, 33% job reservation in government services. Over 50% of government employees in Sikkim are women. Today, do you see any woman in the post of speaker or minister? One more BAD thing.

<br/><br />
 
15.  Sikkim was adjudged the Safest State for Women in India by the National Crime Record Bureau. Sikkim was recognized as the state with the best working conditions for women in India by the Centre for Strategic and International Studies (CSIS) and  Nathan Associates, a top American think tank. Today, cases of sexual harassment and rapes are on the rise. Shockingly enough, the person in the highest position in the government has been accused of sexual harassment. Women do not feel safe anymore. One more terrible BAD thing.

<br/><br />
 
16.  We set up revenue-generating infrastructures like hydropower, lottery and industrialization. That revenue is public property. The SKM government sold all of those public properties and with that money, Chief Minister PS Tamang is purchasing vehicles for a few party workers. The SKM chief is distributing this loot with his close aides in Mintokgang. It looks like the SKM party is wealthier than the Sikkim government. If it is not public money, we would like to know where Shri PS Tamang is getting that money from. People will ask for an accounting soon. Let the Chief Minister know that he has no right to sell our youths’ future to purchase vehicles for his close party workers and distribute  money with his closest aides. This is a terribly BAD thing the SKM government is doing.




                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                   Peace and human security is on the wane. We had the lowest crime rate in the country. Today people are losing trust in the police department. Just a few days ago, a few of the SDF party workers were stopped by  SKM goons while returning from a meeting in Rolep. Our party colleagues had to break their barriers to come home. Some of our supporters have been asked to join the SKM party to prevent the transfer of family members, passing of contract bills and the protection of their houses from the UDHD on the pretext of violating blueprints, etc.




                                </p>
                                
                                
                                <p style={{color: '#000'}}>
                                     
 
I want to draw your attention to how Hitler functioned as a leader. When he formed the government in Germany, the first thing he did was to force all German citizens to join the Nazi party and take an oath of loyalty. The government under Hitler directly controlled the private and personal spheres of citizens’ lives. This is exactly what is happening in Sikkim today. This is one terribly, seriously and disturbingly BAD thing.”


                                </p>
                                

                            </div>
                        </div>

                      


                    </div>


                </div>

            

               <div class="clearfix"></div>

               <Footer />

            </div>
                        
                    </div>
                </main>

            </>
    );

};

export default Pr31Oct2021;